import React from 'react';
import {
  SafeAreaView,
   View,
  StyleSheet,
  ActivityIndicator,
  Image
} from 'react-native';

function LoadingScreen() {
  

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.contentContainer}>
          <ActivityIndicator size = 'large' color = 'black'/>
      </View>
    </SafeAreaView>
  );
};

export default LoadingScreen;

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
    
    // backgroundColor: '#52b372',
  },
  
  contentContainer: {
    top: '20%',
    alignItems: 'center',
  },
  image: {
    width: 300,
    height: 300,
  },
  
});