import {
	FormControl,
	VStack,
	Heading,
	Text,
	Input,
	InputField,
	InputSlot,
	InputIcon,
	Button,
	ButtonText,
	EyeIcon,
	EyeOffIcon,
	LockIcon,
	MailIcon,
} from '@gluestack-ui/themed'
import React from 'react'
import { Dimensions } from 'react-native'
import { useState } from 'react'
import ButtonComponent from '../../newComponents/ButtonComponent'

const windowWidth = Dimensions.get('window').width

function LoginInputComponent({
	buttonText,
	onPress,
	userData,
	setUserData,
	loading,
	screen,
	btnMv,
	btnDisable,
}) {
	const [passwordFocus, setPasswordFocus] = useState(false)
	const [showPassword, setShowPassword] = useState(false)
	const [emailFocus, setEmailFocus] = useState(false)

	const onEmailFocus = () => {
		setEmailFocus(true)
	}
	const onEmailBlur = () => {
		setEmailFocus(false)
	}
	const onPasswordFocus = () => {
		setPasswordFocus(true)
	}
	const onPasswordBlur = () => {
		setPasswordFocus(false)
	}
	const togglePasswordShow = () => {
		setShowPassword(!showPassword)
	}

	return (
		<FormControl w={'100%'}>
			<VStack space={windowWidth > 480 ? 'xl' : 'lg'}>
				{screen !== 'firsttime' ? (
					<VStack w={'100%'}>
						<Input
							textAlign="center"
							paddingHorizontal={10}
							paddingVertical={windowWidth > 480 ? 5 : 3}
							bg="#f7f7fc"
							borderRadius={12}
						>
							<InputSlot>
								<InputIcon
									as={MailIcon}
									color={
										emailFocus
											? '$primary500'
											: '$primary200'
									}
								/>
							</InputSlot>
							<InputField
								onFocus={onEmailFocus}
								onBlur={onEmailBlur}
								placeholder="Email"
								type="text"
								fontFamily="Open Sans"
								fontSize={14}
								onChangeText={(text) => {
									setUserData({ ...userData, email: text })
								}}
							/>
						</Input>
					</VStack>
				) : null}

				<VStack>
					<Input
						textAlign="center"
						paddingHorizontal={10}
						paddingVertical={5}
						bg="#f7f7fc"
						borderRadius={12}
					>
						<InputSlot>
							<InputIcon
								as={LockIcon}
								color={
									passwordFocus
										? '$primary500'
										: '$primary200'
								}
							/>
						</InputSlot>
						<InputField
							placeholder="Password"
							type={showPassword ? 'text' : 'password'}
							onFocus={onPasswordFocus}
							onBlur={onPasswordBlur}
							fontFamily="$fontContent"
							fontSize={14}
							onChangeText={(text) => {
								{
									screen === 'firsttime'
										? setUserData({
												...userData,
												Newpassword: text,
										  })
										: setUserData({
												...userData,
												password: text,
										  })
								}
							}}
						/>
						<InputSlot onPress={togglePasswordShow}>
							<InputIcon
								as={showPassword ? EyeIcon : EyeOffIcon}
								color={
									passwordFocus
										? '$primary500'
										: '$primary200'
								}
							/>
						</InputSlot>
					</Input>
				</VStack>

				<ButtonComponent
					text={buttonText}
					width={'100%'}
					onPress={onPress}
					loading={loading}
					marginVertical={btnMv}
					disabled={btnDisable}
				/>
			</VStack>
		</FormControl>
	)
}

export default LoginInputComponent
