import React, { useState } from 'react'
import { Box } from '@gluestack-ui/themed'
import { points } from '../../../newComponents/pointsComponents/pointsconfig'
import ShareSkeleton from './ShareSkeleton'
import { Dimensions } from 'react-native'

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

const SharePeers = ({
	setTab,
	peers,
	setPeers,
	totalCapabilityPoints,
	saveDetails,
	loadingBtn,
	prevData,
	capEditView,
}) => {
	const [wordBubbles, setWordBubbles] = useState([])

	const handleInputChange = (value) => {
		setPeers(value)
		const words = value
			.split(',')
			.map((word) => word.trim())
			.filter(Boolean)
		setWordBubbles(words)
	}

	const removeWordBubble = (index) => {
		const updatedBubbles = wordBubbles.filter((_, i) => i !== index)
		setWordBubbles(updatedBubbles)
		const updatedPeers = updatedBubbles.join(', ')
		setPeers(updatedPeers)
	}
	return (
		<Box>
			<ShareSkeleton
				prevFieldData={prevData}
				titleNormal1={'Invite someone '}
				titleColored1={"you've collaborated "}
				titleNormal2={'with to vouch for your '}
				titleColored2={'experience'}
				titleNormal3={'.'}
				subtitle={'Team Acknowledgment'}
				screen={'peers'}
				placeholderInput={
					'Add the work email of your friend/colleague/mentor (comma separated)'
				}
				valueInput={peers}
				setInputValueChange={handleInputChange}
				wordBubbles={wordBubbles}
				removeWordBubble={removeWordBubble}
				infoStripText={`Teamwork is crucial. 🤝 By adding a collaborator's perspective, you not only validate your skills but also show off your fantastic ability to work harmoniously—music to any employer's ears!`}
				buttonText={
					windowWidth > 480
						? 'Submit'
						: peers === '' || peers === null
						? 'Skip'
						: 'Next'
				}
				buttonOnPress={() => {
					windowWidth > 480 ? saveDetails() : setTab('PreviewHidi')
				}}
				backButtonOnPress={() => {
					setTab('WorkPlace')
				}}
				totalCapabilityPoints={totalCapabilityPoints}
				pointsAssigned={points.peers}
				loadingBtn={loadingBtn}
				capEditView={capEditView}
				disableCopyPaste={false}
				disableRightClick={false}
			/>
		</Box>
	)
}

export default SharePeers
