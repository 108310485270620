import {
	StyleSheet,
	Text,
	View,
	Dimensions,
	FlatList,
	ActivityIndicator,
	Image,
} from 'react-native'
import React from 'react'
import { useState, useEffect } from 'react'
import Moment from 'react-moment'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
import { getNewprofileByUserIdForDashboardInsights } from '../graphql/custom-queries'
import { Auth, API } from 'aws-amplify'
import DashBoardViewComponent from '../components/dashBoardViewComponent'
import useUserStore from '../Userstore/UserStore'
export default function DashboardInsightScreen() {
	// const profiledata = route.params.profiledata
	const [loading, setloading] = useState(true)
	const [profiledata, setProfileData] = useState('')
	const profile = useUserStore((state) => state.profile)
	const userid = useUserStore((state) => state.userId)

	async function getprofile(props) {
		await API.graphql({
			query: getNewprofileByUserIdForDashboardInsights,
			//  authMode: 'API_KEY',
			variables: {
				UserId: userid,
			},
		}).then((res) => {
		
			setProfileData(res.data.getNewprofileByUserId?.items[0])

			setloading(false)
			//   getUserData()
		})
		// .catch((err) => {
		// 	console.log(err)
		// 	window.alert(
		// 		'pls check yout internet connection or url is not correct'
		// 	)
		// })
	}

	useEffect(() => {
		getprofile()
	}, [])
	const Renderrecommended = ({ item }) => {
		return (
			<View style={styles.subcontainer}>
				<Text style={styles.subtext}>
					You were Recommended to
					<Text
						style={{
							...styles.subtext,
							fontWeight: 'bold',
							fontFamily: 'Lexend',
							color: '#000000',
						}}
					>
						{item?.businessProfile.name}
					</Text>
				</Text>
				<Text style={styles.date}>
					{' '}
					<Moment fromNow ago interval={30000} element={Text}>
						{item?.createdAt}
					</Moment>{' '}
					ago
				</Text>
				<View
					style={{
						width: '90%',
						height: 1,
						backgroundColor: '#DEE1E7',
					}}
				/>
			</View>
		)
	}
	const Renderinterested = ({ item }) => {
		return (
			<View style={styles.subcontainer}>
				<Text style={styles.subtext}>
					<Text
						style={{
							fontWeight: 'bold',
							fontFamily: 'Lexend',
							color: '#000000',
						}}
					>
						{item.businessProfile.name}
					</Text>{' '}
					Has shown interest in you
				</Text>
				<Text style={styles.date}>
					{' '}
					<Moment fromNow ago interval={30000} element={Text}>
						{item.createdAt}
					</Moment>{' '}
					ago
				</Text>
				<View
					style={{
						width: '90%',
						height: 1,
						backgroundColor: '#DEE1E7',
					}}
				/>
			</View>
		)
	}

	return (
		<>
			{loading ? (
				<ActivityIndicator />
			) : (
				<View
					style={{
						width: windowWidth < 480 ? '90%' : '79.9%',
						alignSelf: 'center',
					}}
				>
					<DashBoardViewComponent props={profiledata} />

					<Text style={styles.textheading}>
						Employer Recommendations
					</Text>
					<View style={styles.flatlistcontainer}>
						<FlatList
							data={profiledata?.inpreference?.items}
							renderItem={Renderrecommended}
							ListEmptyComponent={() => {
								return (
									<>
										{windowWidth < 480 && (
											<Image
												resizeMode="contain"
												style={{
													width: windowWidth * 0.8,
													height: windowHeight * 0.6,
													alignSelf: 'center',
													marginBottom: 30,
												}}
												source={require('../assets/Images/noinsights-mob.png')}
											/>
										)}
										{windowWidth > 480 && (
											<Image
												resizeMode="contain"
												style={{
													width: windowWidth,
													height: windowHeight * 0.6,
													alignSelf: 'center',
													marginBottom: 30,
												}}
												source={require('../assets/Images/noinsights.png')}
											/>
										)}
									</>
								)
							}}
							keyExtractor={(item) => item.id}
						/>
					</View>
					<Text style={styles.textheading}>Employer Interested</Text>
					<View style={styles.flatlistcontainer}>
						<FlatList
							data={profiledata?.comapanyshortlists?.items}
							ListEmptyComponent={() => {
								return (
									<>
										{windowWidth < 480 && (
											<Image
												resizeMode="contain"
												style={{
													width: windowWidth * 0.8,
													height: windowHeight * 0.6,
													alignSelf: 'center',
													marginBottom: 30,
												}}
												source={require('../assets/Images/noinsights-mob.png')}
											/>
										)}
										{windowWidth > 480 && (
											<Image
												resizeMode="contain"
												style={{
													width: windowWidth,
													height: windowHeight * 0.6,
													alignSelf: 'center',
													marginBottom: 30,
												}}
												source={require('../assets/Images/noinsights.png')}
											/>
										)}
									</>
								)
							}}
							renderItem={Renderinterested}
							keyExtractor={(item) => item.id}
						/>
					</View>
				</View>
			)}
		</>
	)
}

const styles = StyleSheet.create({
	flatlistcontainer: {
		borderWidth: 2,
		borderRadius: 15,
		paddingHorizontal: 10,
		marginHorizontal: 20,
		borderColor: '#F1F1F1',
		width: '80%',
		alignSelf: 'center',
		// alignSelf: "center"
	},
	textheading: {
		fontWeight: 'bold',
		fontSize: 18,
		marginHorizontal: 20,
		paddingVertical: 10,
		fontFamily: 'Lexend',
		marginTop: 10,

		width: '80%',
		alignSelf: 'center',
	},
	subcontainer: {
		// width: "70%"
		// marginVertical: 10
	},
	subtext: {
		padding: 10,
		fontFamily: 'Lexend',
		color: '#686868',
		fontSize: 15,
	},
	date: {
		paddingHorizontal: 10,
		paddingVertical: 5,
		fontFamily: 'Lexend',
		color: '#AAAAAA',
	},
})
