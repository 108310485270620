import React from 'react'
import { VStack, Spinner, Text } from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width

function SpinnerComponent({ text, size, color, smallText, button }) {
	return (
		<VStack space={'sm'} alignItems="center" justifyContent="center">
			<Spinner color={color ? color : '$primary500'} size={size} />
			{button ? (
				<></>
			) : (
				<>
					<Text
						size="md"
						fontSize={windowWidth > 480 ? 14 : 10}
						fontWeight="$bold"
						fontFamily="$fontContent"
						color="$primary950"
						textAlign="center"
					>
						{smallText}
					</Text>
					<Text
						size="md"
						fontSize={windowWidth > 480 ? 19 : 15}
						fontWeight="$bold"
						fontFamily="$fontContent"
						color="$primary950"
						textAlign="center"
					>
						{text}
					</Text>
				</>
			)}
		</VStack>
	)
}

export default SpinnerComponent
