import { API } from 'aws-amplify'
import {
	createObjective,
	createObjectiveEditHistory,
	deleteObjective,
	updateObjective,
	deleteInitiative
} from '../graphql/custom-mutations'
import {
	listObjectivesByCompanyQrewId,
	listObjectivesToUpdateByQrewId,
} from '../graphql/custom-queries'

import { createObjectiveKeyResult, updateObjectiveKeyResult, deleteObjectiveKeyResult, createInitiative, updateInitiative, updateDeployedQrew } from '../graphql/custom-mutations'
import { listObjectiveKeyResultsbyObjectiveId, listObjectiveKeyResultsbyObjectiveIdAccordian } from '../graphql/custom-queries'
import { createContractEventTable, createDeployedQrewHistory } from '../graphql/custom-mutations'
import { updateJobApplications } from '../graphql/custom-mutations'

export const createQrewObjectives = async (input) => {
	try {
		const response = await API.graphql({
			query: createObjective,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
		return response.data.createObjective
	} catch (error) {
		console.log('Error creating objective', error)
	}
}
export const createQrewInitiative = async (input) => {
	try {
		const response = await API.graphql({
			query: createInitiative,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
		return response.data.createInitiative
	} catch (error) {
		console.log('Error creating objective', error)
	}
}
export const updateQrewInitiative = async (input) => {
	try {
		const response = await API.graphql({
			query: updateInitiative,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
		return response.data.updateInitiative
	} catch (error) {
		console.log('Error creating objective', error)
	}
}
export const updateQrewObjectives = async (input) => {
	try {
		const response = await API.graphql({
			query: updateObjective,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
		return response.data.updateObjective
	} catch (error) {
		console.log('Error updating objective', error)
	}
}
export const deleteQrewObjectives = async (input) => {
	try {
		const response = await API.graphql({
			query: deleteObjective,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
		return response.data.createObjective
	} catch (error) {
		console.log('Error creating objective', error)
	}
}

export const listQrewObjectivesByQrewId = async (id) => {
	try {
		const response = await API.graphql({
			query: listObjectivesByCompanyQrewId,
			authMode: 'API_KEY',
			variables: {
				companyQrewId: id,
			},
		})
		return response.data.listObjectivesByCompanyQrewId.items
	} catch (error) {
		console.log('Error fetching objectives', error)
	}
}
export const listQrewObjectiveKeyResultsbyObjectiveId = async (id) => {
	try {
		const response = await API.graphql({
			query: listObjectiveKeyResultsbyObjectiveId,
			authMode: 'API_KEY',
			variables: {
				objectiveId: id,
			},
		})
		return response.data.listObjectiveKeyResultsbyObjectiveId.items
	} catch (error) {
		console.log('Error fetching objectives', error)
	}
}
export const listQrewObjectiveKeyResultsbyObjectiveIdAccordian = async (id) => {
	try {
		const response = await API.graphql({
			query: listObjectiveKeyResultsbyObjectiveIdAccordian,
			authMode: 'API_KEY',
			variables: {
				objectiveId: id,
			},
		})
		return response.data.listObjectiveKeyResultsbyObjectiveId.items
	} catch (error) {
		console.log('Error fetching objectives', error)
	}
}
export const listQrewObjectivesToUpdateByQrewId = async (id) => {
	try {
		const response = await API.graphql({
			query: listObjectivesToUpdateByQrewId,
			authMode: 'API_KEY',
			variables: {
				companyQrewId: id,
			},
		})
		return response.data.listObjectivesByCompanyQrewId.items
	} catch (error) {
		console.log('Error fetching objectives', error)
	}
}

export const createObjectiveEdit = async (input) => {
	try {
		const response = await API.graphql({
			query: createObjectiveEditHistory,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
		return response.data.createObjectiveEditHistory
	} catch (error) {
		console.log('Error creating objective edit', error)
	}
}
export const createQrewObjectiveKeyResult = async (input) => {
	try {
		const response = await API.graphql({
			query: createObjectiveKeyResult,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
		return response.data.createObjectiveKeyResult
	} catch (error) {
		console.log('Error creating objective', error)
	}
}
export const updateQrewObjectiveKeyResult = async (input) => {
	try {
		const response = await API.graphql({
			query: updateObjectiveKeyResult,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
		return response.data.updateObjectiveKeyResult
	} catch (error) {
		console.log('Error creating objective', error)
	}
}
export const deleteQrewObjectiveKeyResult = async (input) => {
	try {
		const response = await API.graphql({
			query: deleteObjectiveKeyResult,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
		return response.data.deleteObjectiveKeyResult
	} catch (error) {
		console.log('Error creating objective', error)
	}
}
export const DeleteInitiatives = async (input) => {
	try {
		const response = await API.graphql({
			query: deleteInitiative,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
		return response.data.deleteInitiative
	} catch (error) {
		console.log('Error creating objective', error)
	}
}

export const UpdateDeployedQrew = async (input) => {
	try {
		const response = await API.graphql({
			query: updateDeployedQrew,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
		return response.data.updateDeployedQrew
	} catch (error) {
		console.log('Error creating objective', error)
	}
}

export const createEventContractTableOnContractStatus = async (input) => {
	try {
		const response = await API.graphql({
			query: createContractEventTable,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
		return response.data.createContractEventTable
	} catch (error) {
		console.log('Error creating objective', error)
	}
}

export const CreateDeployedQrewHistory = async (input) => {
	try {
		const response = await API.graphql({
			query: createDeployedQrewHistory,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
		return response.data.createDeployedQrewHistory
	} catch (error) {
		console.log('Error creating deployedQrewHistory', error)
	}
}
export const UpdateToAlplhaLevel = async (input) => {
	try {
		const response = await API.graphql({
			query: updateJobApplications,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
		return response.data.updateJobApplications
	} catch (error) {
		console.log('Error creating objective', error)
	}
}