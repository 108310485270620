import { Dimensions } from 'react-native'
import { Box, Text, FlatList, Pressable } from '@gluestack-ui/themed'
import React, { useEffect, useState } from 'react'
import LabelWithInputComponent from './LabelWithInputComponent'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const SearchAndSelectInput = ({
	data,
	needName,
	setSelectedItem,
	selectedItem,
	itemsPerPage,
	label,
	placeholder,
	important,
	renderSearchSelectItem,
	actionOnSelect,
	autoFocus,
	top,
}) => {
	const [searchTerm, setSearchTerm] = useState(selectedItem)
	const [suggestions, setSuggestions] = useState([])
	const [isVisible, setIsVisible] = useState(false)
	const [currentPage, setCurrentPage] = useState(1)

	useEffect(() => {
		setSuggestions(data?.slice(0, itemsPerPage))
	}, [data])

	useEffect(() => {
		const matchingItem = data?.find((item) => item.name === selectedItem)
		if (matchingItem) {
			handleSelectItem(matchingItem)
		}
	}, [data, selectedItem])

	const handleInputChange = (text) => {
		setSearchTerm(text)
		const filteredData = data?.filter((item) => {
			return item?.name?.toLowerCase()?.includes(text?.toLowerCase())
		})
		setSuggestions(filteredData?.slice(0, itemsPerPage))
	}

	const handleSelectItem = (item) => {
		if (needName) {
			setSelectedItem(item?.name)
		} else {
			setSelectedItem(item)
		}
		setSearchTerm(item?.name)
		actionOnSelect && actionOnSelect()
		setTimeout(() => {
			setSuggestions([])
		}, 200)
	}

	const handleLoadMore = () => {
		const nextPage = currentPage + 1
		const start = (nextPage - 1) * itemsPerPage
		const end = start + itemsPerPage
		const newSuggestions = data
			?.filter((item) => {
				return item?.name
					?.toLowerCase()
					?.includes(searchTerm?.toLowerCase())
			})
			.slice(start, end)

		setSuggestions((prevSuggestions) => [
			...prevSuggestions,
			...newSuggestions,
		])
		setCurrentPage(nextPage)
	}

	const handleBlur = () => {
		setTimeout(() => {
			setIsVisible(false)
		}, 300)
	}
	const handleFocus = () => {
		setTimeout(() => {
			setIsVisible(true)
			actionOnSelect && actionOnSelect()
		}, 100)
	}

	const renderItem = ({ item }) => {
		return (
			<Pressable
				onPress={() => {
					handleSelectItem(item)
				}}
				sx={{
					':hover': {
						bg: '$borderDark0',
					},
				}}
			>
				{renderSearchSelectItem(item)}
			</Pressable>
		)
	}

	return (
		<Box>
			<Box zIndex={1}>
				<LabelWithInputComponent
					label={label}
					important={important}
					placeholder={placeholder}
					value={searchTerm}
					onBlur={handleBlur}
					onFocus={handleFocus}
					setValueChange={handleInputChange}
					numberOfLines={1}
					autoFocus={autoFocus}
				/>
			</Box>
			{isVisible && (
				<Box
					maxHeight={150}
					position="absolute"
					w={'$full'}
					top={top ? top : 82}
					borderWidth={1}
					bg="$borderGrey100"
					borderColor="$borderDark0"
				>
					<FlatList
						data={suggestions}
						keyExtractor={(item) => item.id} // Assuming id is unique
						renderItem={renderItem}
						onEndReached={handleLoadMore}
						onEndReachedThreshold={0.9}
						zIndex={10}
					/>
				</Box>
			)}
		</Box>
	)
}

export default SearchAndSelectInput
