import React, { useState, useCallback } from "react";
import { Text, StyleSheet, View, Image, Pressable, Modal, Dimensions, FlatList } from "react-native";
import { useNavigation } from "@react-navigation/native";
// import AutoLayoutVertical from "./AutoLayoutVertical";
import { FontFamily, FontSize, Padding, Border, Color } from '../FeedComponents/GlobalStyleFeed';
import Divider from "./Divider";
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
const CompanyShortlists = () => {
  const data = [
    {
      id: 'bd7acbea-c1b1-46c2-aed5-3ad53abb28ba',
      title: 'First Item',
    },
    {
      id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f63',
      title: 'Second Item',
    },
    {
      id: '58694a0f-3da1-471f-bd96-145571e29d72',
      title: 'Third Item',
    },
    {
        id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f631',
        title: 'Second Item',
      },
      {
        id: '58694a0f-3da1-471f-bd96-145571e29d722',
        title: 'Third Item',
      },
  ];
  const [typeButtonType2PrimaryTVisible, setTypeButtonType2PrimaryTVisible] =
    useState(false);
  const [typeButtonType2PrimaryT1Visible, setTypeButtonType2PrimaryT1Visible] =
    useState(false);
  const [typeButtonType2PrimaryT2Visible, setTypeButtonType2PrimaryT2Visible] =
    useState(false);
  const [typeButtonType2PrimaryT3Visible, setTypeButtonType2PrimaryT3Visible] =
    useState(false);
  const [typeButtonType2PrimaryT4Visible, setTypeButtonType2PrimaryT4Visible] =
    useState(false);
  const navigation = useNavigation();
  const [typeButtonType2PrimaryT5Visible, setTypeButtonType2PrimaryT5Visible] =
    useState(false);

  const openTypeButtonType2PrimaryT = useCallback(() => {
    setTypeButtonType2PrimaryTVisible(true);
  }, []);

  const closeTypeButtonType2PrimaryT = useCallback(() => {
    setTypeButtonType2PrimaryTVisible(false);
  }, []);

  const openTypeButtonType2PrimaryT1 = useCallback(() => {
    setTypeButtonType2PrimaryT1Visible(true);
  }, []);

  const closeTypeButtonType2PrimaryT1 = useCallback(() => {
    setTypeButtonType2PrimaryT1Visible(false);
  }, []);

  const openTypeButtonType2PrimaryT2 = useCallback(() => {
    setTypeButtonType2PrimaryT2Visible(true);
  }, []);

  const closeTypeButtonType2PrimaryT2 = useCallback(() => {
    setTypeButtonType2PrimaryT2Visible(false);
  }, []);

  const openTypeButtonType2PrimaryT3 = useCallback(() => {
    setTypeButtonType2PrimaryT3Visible(true);
  }, []);

  const closeTypeButtonType2PrimaryT3 = useCallback(() => {
    setTypeButtonType2PrimaryT3Visible(false);
  }, []);

  const openTypeButtonType2PrimaryT4 = useCallback(() => {
    setTypeButtonType2PrimaryT4Visible(true);
  }, []);

  const closeTypeButtonType2PrimaryT4 = useCallback(() => {
    setTypeButtonType2PrimaryT4Visible(false);
  }, []);

  const openTypeButtonType2PrimaryT5 = useCallback(() => {
    setTypeButtonType2PrimaryT5Visible(true);
  }, []);

  const closeTypeButtonType2PrimaryT5 = useCallback(() => {
    setTypeButtonType2PrimaryT5Visible(false);
  }, []);
  const renderItem = (item, index) => {
    const res = item;
    return (
      <View style={styles.autoBorder}>
      <View
        style={[
          styles.NameParent,
          styles.NameParentFlexBox,
        ]}
      >
        <Text
          style={[styles.canDesignAnd, styles.canDesignAndTypo]}
        >{`Can use Cloud 
services to build applications`}</Text>
      </View>
      <Pressable
        style={[
          styles.autoLayoutHorizontal,
          
        ]}
        // onPress={() => navigation.navigate("MacBookPro149")}
      >
        <View style={styles.typebuttonType2secondary}>
          <Text style={[styles.back2, styles.backTypo]}>Back</Text>
        </View>
        <Pressable
          style={styles.typebuttonType2primaryT}
          onPress={openTypeButtonType2PrimaryT2}
        >
          <Text style={[styles.provideEvidenceNow2, styles.backTypo]}>
            Provide Evidence Now
          </Text>
        </Pressable>
      </Pressable>
    </View>
    );
  };
  return (
    <>
      <View style={styles.companyshortlists}>
        <View
          style={[styles.NameParent, styles.NameParentFlexBox]}
        >
          <Text style={[styles.ShortlistName, styles.yearsTypo]}>
            Bhaskar dabhi
            <Text
            style={[styles.ofHasBeen, styles.CompanyName]}
          >{`of Has been shortlisted by `}
           <Text style={[styles.Companycolor, styles.CompanyName]}>Qrusible
           <Text style={[styles.ofHasBeen, styles.CompanyName]}>
            for the role role of
            <Text style={[styles.Companycolor, styles.CompanyName]}>
            React Natie Developer
          </Text>
          </Text>
           </Text>
          </Text>

          </Text>
         
         

         
        </View>
       <Divider />
        <View style={[styles.autoLayoutVertical, styles.autoBorder1]}>
          <View style={styles.autoBorder1}>
            <Image
              style={styles.image103Icon}
              resizeMode="contain"
              source={require("../assets/Images/QrusibleNewBlack.png")}
            />
           
          </View>
          <Image
            style={[styles.themelightDividerIcon1, styles.autoParentSpaceBlock]}
            resizeMode="cover"
            // source={require("../assets/themelight-divider3.png")}
          />
          <View
            style={[styles.autoLayoutHorizontal1, styles.autoParentSpaceBlock]}
          >
            <Text
              style={[styles.reactNativeDeveloper, styles.canDesignAndTypo]}
            >
              React Native developer
            </Text>
          </View>
         <Divider/>
          <View
            style={[styles.autoLayoutHorizontal1, styles.autoParentSpaceBlock]}
          >
            <Text style={styles.experience}>Experience</Text>
            <Text style={[styles.years, styles.yearsTypo]}>3+ years</Text>
          </View>
          <View
            style={[styles.autoLayoutHorizontal1, styles.autoParentSpaceBlock]}
          >
            <Text style={styles.experience}>Location</Text>
            <Text style={[styles.years, styles.yearsTypo]}>Remote</Text>
          </View>
          <Divider />
          <View
            style={[
              styles.autoLayoutVerticalParent,
              styles.autoParentSpaceBlock,
            ]}
          >
             <FlatList
        data={data}
        renderItem={renderItem}
        keyExtractor={item => item.id.toString()}
        horizontal 
        
      />
            
          </View>
         <Divider />
          
          <View
            style={[
              styles.typebuttonType2secondaryParent,
              styles.autoParentSpaceBlock,
            ]}
          >
            <View style={styles.typebuttonBorder}>
              <Text style={[styles.checkOpportunity, styles.checkTypo]}>
                Check Opportunity
              </Text>
            </View>
            <View
              style={[
                styles.typebuttonType2secondary7,
                styles.typebuttonBorder,
              ]}
            >
              <Text style={[styles.checkEligibility, styles.checkTypo]}>
                Check Eligibility
              </Text>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  NameParentFlexBox: {
    alignItems: "center",
    flexDirection: "row",
  },
  yearsTypo: {
    fontFamily: FontFamily.bodyLargeBold,
    fontWeight: "700",
  },
  CompanyName: {
    marginLeft: 5,
    textAlign: "left",
    fontSize: FontSize.size_sm,
  },
 
  autoBorder1: {
    padding: Padding.p_3xs,
    borderRadius: Border.br_xl,
    borderWidth: 1,
    borderColor: Color.colorWhitesmoke_100,
    borderStyle: "solid",
    backgroundColor: Color.othersWhite,
  },
 
  backTypo: {
    textShadowRadius: 24,
    textShadowOffset: {
      width: 4,
      height: 8,
    },
    textShadowColor: "rgba(36, 107, 253, 0.25)",
    height: 22,
    display: "flex",
    textAlign: "center",
    lineHeight: 22,
    letterSpacing: 0,
    fontSize: FontSize.bodyLargeBold_size,
    justifyContent: "center",
    flex: 1,
    fontFamily: FontFamily.bodyLargeBold,
    fontWeight: "700",
    alignItems: "center",
  },
  canDesignAndTypo: {
    color: Color.colorGray_200,
    textAlign: "left",
    fontFamily: FontFamily.bodyLargeBold,
    fontWeight: "700",
  },
  autoBorder: {
    marginLeft: 10,
    paddingHorizontal: Padding.p_5xs,
    paddingVertical: Padding.p_3xs,
    flex: 1,
    borderRadius: Border.br_xl,
    borderWidth: 1,
    borderColor: Color.colorWhitesmoke_100,
    borderStyle: "solid",
    backgroundColor: Color.othersWhite,
  },
  checkTypo: {
    width: 130,
    lineHeight: 20,
    textShadowRadius: 24,
    textShadowOffset: {
      width: 4,
      height: 8,
    },
    textShadowColor: "rgba(36, 107, 253, 0.25)",
    display: "flex",
    textAlign: "center",
    letterSpacing: 0,
    justifyContent: "center",
    fontFamily: FontFamily.bodyLargeBold,
    fontWeight: "700",
    fontSize: FontSize.size_sm,
    alignItems: "center",
  },
  typebuttonBorder: {
    paddingVertical: Padding.p_6xs,
    paddingHorizontal: Padding.p_3xs,
    borderWidth: 2,
    borderColor: Color.primary500,
    justifyContent: "center",
    borderRadius: Border.br_81xl,
    alignItems: "center",
    flexDirection: "row",
    borderStyle: "solid",
    marginHorizontal: 2,
  },
  ShortlistName: {
    textAlign: "left",
    color: Color.primary500,
    fontSize: FontSize.size_sm,
  },
  ofHasBeen: {
    lineHeight: 16,
    fontFamily: FontFamily.urbanistRegular,
    color: Color.colorGray_300,
  },
  Companycolor: {
    color: Color.primary500,
    fontFamily: FontFamily.bodyLargeBold,
    fontWeight: "700",
  },
  NameParent: {
    alignSelf: "stretch",
    alignItems: "center",
  },
  
  image103Icon: {
    width: 59,
    height: 17,
  },
  back: {
    color: Color.primary500,
  },
  typebuttonType2secondary: {
    backgroundColor: Color.colorLavender,
    paddingVertical: Padding.p_lg,
    justifyContent: "center",
    paddingHorizontal: Padding.p_base,
    borderRadius: Border.br_81xl,
    flex: 1,
    display: "none",
    alignItems: "center",
    flexDirection: "row",
  },
  typeButtonType2PrimaryTOverlay: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(113, 113, 113, 0.3)",
  },
  typeButtonType2PrimaryTBg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
  },
  provideEvidenceNow: {
    color: Color.othersWhite,
  },
  typebuttonType2primaryT: {
    marginLeft: 12,
    paddingVertical: Padding.p_3xs,
    backgroundColor: Color.primary500,
    justifyContent: "center",
    paddingHorizontal: Padding.p_base,
    borderRadius: Border.br_81xl,
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
  },
  autoLayoutHorizontal: {
    display: "none",
    marginTop: 10,
    flexDirection: "row",
  },
 
  reactNativeDeveloper: {
    lineHeight: 19,
    fontSize: FontSize.bodyLargeBold_size,
    color: Color.colorGray_200,
    flex: 1,
  },
  autoLayoutHorizontal1: {
    alignItems: "center",
    flexDirection: "row",
  },
  experience: {
    fontSize: FontSize.size_3xs,
    lineHeight: 12,
    fontWeight: "500",
    fontFamily: FontFamily.urbanistMedium,
    color: Color.colorDimgray_100,
    textAlign: "left",
  },
  years: {
    fontSize: FontSize.size_xs,
    lineHeight: 14,
    color: Color.colorGray_100,
    marginLeft: 12,
    textAlign: "left",
  },
  canDesignAnd: {
    lineHeight: 17,
    width: 148,
    fontSize: FontSize.size_sm,
  },
  back1: {
    color: Color.primary500,
  },
  typeButtonType2PrimaryT1Overlay: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(113, 113, 113, 0.3)",
  },
  typeButtonType2PrimaryT1Bg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
  },
  provideEvidenceNow1: {
    color: Color.othersWhite,
  },
 
  back2: {
    color: Color.primary500,
  },
  typeButtonType2PrimaryT2Overlay: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(113, 113, 113, 0.3)",
  },
  typeButtonType2PrimaryT2Bg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
  },
  provideEvidenceNow2: {
    color: Color.othersWhite,
  },
  back3: {
    color: Color.primary500,
  },
  typeButtonType2PrimaryT3Overlay: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(113, 113, 113, 0.3)",
  },
  typeButtonType2PrimaryT3Bg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
  },
  provideEvidenceNow3: {
    color: Color.othersWhite,
  },
  back4: {
    color: Color.primary500,
  },
  typeButtonType2PrimaryT4Overlay: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(113, 113, 113, 0.3)",
  },
  typeButtonType2PrimaryT4Bg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
  },
  provideEvidenceNow4: {
    color: Color.othersWhite,
  },

  autoLayoutVerticalParent: {
    flexDirection: "row",
    marginTop: 10,
  },
  back5: {
    color: Color.primary500,
  },
  typeButtonType2PrimaryT5Overlay: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(113, 113, 113, 0.3)",
  },
  typeButtonType2PrimaryT5Bg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
  },
  provideEvidenceNow5: {
    color: Color.othersWhite,
  },
  checkOpportunity: {
    color: Color.primary500,
  },
  checkEligibility: {
    color: Color.othersWhite,
  },
  typebuttonType2secondary7: {
    backgroundColor: Color.primary500,
    paddingVertical: Padding.p_6xs,
    paddingHorizontal: Padding.p_3xs,
    borderWidth: 2,
    borderColor: Color.primary500,
  },
  typebuttonType2secondaryParent: {
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 10,
  },
  autoLayoutVertical: {
    marginTop: 20,
    alignSelf: "stretch",
  },
  companyshortlists: {
    borderRadius: Border.br_5xl,
    width: windowWidth < 480 ? windowWidth*0.95 : windowWidth*0.60,
    padding: Padding.p_xl,
    borderWidth: 1,
    borderColor: Color.colorWhitesmoke_100,
    borderStyle: "solid",
    backgroundColor: Color.othersWhite,
    // alignSelf: windowWidth < 480 ? 'center' : 'auto',
    alignSelf :'center',
    marginTop: 15
  },
});

export default CompanyShortlists;
