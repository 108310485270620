import { Dimensions } from 'react-native'
import React from 'react'
import useImageFileLink from '../../../customhook/useImageLinkhook'
import {
	Pressable,
	HStack,
	VStack,
	Box,
	Text,
	Icon,
	TrashIcon,
	CloseIcon,
} from '@gluestack-ui/themed'
import AvatarComponent from '../../../newComponents/AvatarComponent'
import useUserStore from '../../../Userstore/UserStore'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const RecommendedContainer = ({
	headingText,
	companyname,
	props,
	rolename,
	navigation,
	text,
	onPressBtn,
	index,
	selectedItemIndex,
	setSelectedIndex,
	JobPostData,
	JobApplicantId,
	ExperienceSharedOnMatching,
	matchingClaimedCapabilites,
	requiredCapabilities,
	companyQrewId,
	SelectedTab
}) => {
	const typeofuser = useUserStore((state) => state.group)
	const ShortListButton = () => {
		console.log(props.acceptanceStatus)
		return (
			<Box
				alignItems="flex-start"
				justifyContent="space-between"
				flexDirection="row"
				marginVertical={windowWidth > 480 ? 15 : 7}
			>
				<Pressable
					disabled={true}
					borderWidth={1}
					borderRadius={10}
					bgColor={
						props.acceptanceStatus == 'Shortlisted'
							? '$green600'
							: 'red'
					}
					alignItems="center"
					justifyContent="center"
					borderColor={
						props.acceptanceStatus == 'Shortlisted'
							? '$green600'
							: 'red'
					}
					marginBottom={5}
					h={30}
					width={windowWidth > 480 ? 150 : 100}
				>
					<Text
						color="$white"
						fontWeight={700}
						fontFamily="$fontHeading"
					>
						{props.acceptanceStatus == 'Shortlisted'
							? 'Shortlisted'
							: 'Rejected'}
					</Text>
				</Pressable>
			</Box>
		)
	}
	const imagelink = useImageFileLink(props?.candidateprofile?.imagekey)

	const navigateToProfile = () => {
		navigation.push('NewProfile', {
			companyQrewId : companyQrewId ,
			UserId: props?.candidateprofile?.UserId,
			PreferenceId: props?.preferencesId
				? props?.preferencesId
				: props.CompanyPreferenceId,
			RecommendationId: props?.id,
			JobPostData: JobPostData,
			JobApplicantId: JobApplicantId,
			acceptancestatus: props.acceptanceStatus,
			ReasonofRecommendation: props.ReasonofRecommendation,
			SelectedTab : SelectedTab
		})
	}

	const formatTimeElapsed = (createdAt) => {
		const createdDate = new Date(createdAt)
		const now = new Date()

		const timeDifference = now - createdDate
		const minutes = Math.floor(timeDifference / (1000 * 60))

		if (minutes < 1) {
			return 'Just now'
		} else if (minutes === 1) {
			return '1 min ago'
		} else if (minutes < 60) {
			return `${minutes} mins ago`
		} else {
			const hours = Math.floor(minutes / 60)
			if (hours === 1) {
				return '1 hour ago'
			} else if (hours < 24) {
				return `${hours} hours ago`
			} else {
				const days = Math.floor(hours / 24)
				if (days === 1) {
					return '1 day ago'
				} else {
					return `${days} days ago`
				}
			}
		}
	}

	return (
		<Pressable
			paddingVertical={windowWidth > 480 ? 20 : 10}
			paddingHorizontal={windowWidth > 480 ? 10 : 5}
			w={'$full'}
			bg={
				windowWidth > 480 && selectedItemIndex === index
					? '$borderDark0'
					: null
			}
			// borderWidth={1}
			onPress={() => {
				windowWidth < 480
					? navigateToProfile()
					: setSelectedIndex(index)
			}}
		>
			<HStack
				justifyContent="space-between"
				flexWrap="wrap"
				alignItems="center"
			>
				<HStack
					alignItems="center"
					gap={windowWidth > 480 ? 10 : 7}
					flex={1}
				>
					<AvatarComponent
						imgSource={imagelink}
						name={props?.candidateprofile?.name}
						height={windowWidth > 480 ? 60 : 45}
						width={windowWidth > 480 ? 60 : 45}
					/>
					<VStack flex={1}>
						<Text
							fontFamily="$fontHeading"
							lineHeight={windowWidth > 480 ? 20 : 15}
							fontSize={windowWidth > 480 ? 16 : 13}
							fontWeight="600"
						>
							{props?.candidateprofile?.name}
						</Text>
						<Text
							fontFamily="$fontContent"
							lineHeight={windowWidth > 480 ? 20 : 15}
							fontSize={
								windowWidth > 480
									? '$fontContent'
									: '$fontContentMobile'
							}
							color="$fontGray100"
							fontWeight="600"
							maxWidth={windowWidth > 480 && 250}
						>
							{props?.candidateprofile?.expertise}
						</Text>
						<Text
							fontFamily="$fontContent"
							lineHeight={windowWidth > 480 ? 20 : 15}
							fontSize={windowWidth > 480 ? 9 : 7}
							fontWeight="500"
							color="$fontGray"
						>
							{formatTimeElapsed(props.createdAt)}
						</Text>
						<Text
							fontFamily="$fontContent"
							lineHeight={windowWidth > 480 ? 20 : 15}
							fontSize={windowWidth > 480 ? 10 : 10}
							color="$fontGray400"
							fontWeight="600"
						>
							{matchingClaimedCapabilites}/{requiredCapabilities}{' '}
							Capabilities Claimed • {ExperienceSharedOnMatching}{' '}
							{ExperienceSharedOnMatching > 1
								? 'Experiences'
								: 'Experience'}
						</Text>
						{typeofuser == 'QrusibleSuperAdmin' ? (
							<>
								{props?.candidateprofile?.IntroVideo && (
									<Text
										fontFamily="$fontContent"
										lineHeight={windowWidth > 480 ? 20 : 15}
										fontSize={windowWidth > 480 ? 14 : 14}
										color="$green700"
										fontWeight="900"
									>
										Video Available
									</Text>
								)}
							</>
						) : null}
					</VStack>
				</HStack>
				{text ? (
					<Pressable
						w={'fit-content'}
						onPress={onPressBtn ? onPressBtn : navigateToProfile}
						borderWidth={2}
						m={windowWidth > 480 ? 10 : 5}
						p={2}
						borderRadius={50}
						borderColor="$secondary500"
						bg="$secondary500"
					>
						<Icon
							as={CloseIcon}
							h={windowWidth > 480 ? 18 : 15}
							w={windowWidth > 480 ? 18 : 15}
							color={'$primary0'}
						/>
					</Pressable>
				) : null}
			</HStack>

			{/* {props.acceptanceStatus && 
				<ShortListButton />
				} */}
		</Pressable>
	)
}

export default RecommendedContainer
