import React from 'react'
import { Box, Image, Text } from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'
import ButtonComponent from '../../../newComponents/ButtonComponent'
import useUserStore from '../../../Userstore/UserStore'

const windowWidth = Dimensions.get('window').width

const AddVideo = ({ navigation }) => {
	

	return (
		<Box
			alignItems="center"
			gap={15}
			borderWidth={1}
			borderColor="$borderDark50"
			p={20}
			borderRadius={15}
			flex={1}
		>
			<Text
				fontFamily="$fontHeading"
				fontSize={windowWidth > 480 ? 14 : 12}
				color="$primary950"
				fontWeight="600"
			>
				Add a video introduction
			</Text>

			<Image
				source={require('../../../assets/Images/introVid.jpeg')}
				borderRadius={200}
				h={windowWidth > 480 ? 100 : 70}
				w={windowWidth > 480 ? 100 : 70}
			/>

			<Box alignItems="center" my={5}>
				<Text
					fontFamily="$fontHeading"
					fontSize={windowWidth > 480 ? 13 : 11}
					color="#4A789C"
					textAlign="center"
				>
					An introduction video is a great way to showcase your
					personality and skills to potential employers.
				</Text>
				<Text
					fontFamily="$fontHeading"
					textAlign="center"
					fontSize={windowWidth > 480 ? 13 : 11}
					color="#4A789C"
				>
					Make sure to keep it short and sweet, and remember to smile!
				</Text>
			</Box>

			<ButtonComponent
				text={'Record'}
				height={35}
				onPress={() => {
					
					navigation.push('IntroVideo')
				}}
			/>
		</Box>
	)
}

export default AddVideo
