import React, { useEffect, useState } from 'react'
import {
	View,
	Dimensions,
	StyleSheet,
	TouchableOpacity,
	TextInput,
	ScrollView,
	Image,
	FlatList,
	Text,
} from 'react-native'
import { HStack, Divider, Center, VStack } from 'native-base'
import Editor from 'react-medium-editor'
import OutcomeComponent from '../newComponents/OutcomeComponent'
import UserAvatar from 'react-native-user-avatar'
import Hidiheader from './Hidiheader'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const Hidicontainer = ({
	ownerName,
	item,
	heading,
	refInputs,
	background,
	summary,
	screen,
	hideModal,
	id,
	profile,
	outcomes,
}) => {
	const [ref, setRef] = useState(refInputs.current)
	const hidiModalVisible = () => {
		hideModal(false)
	}

	const openProfile = () => {
		const url = `https://app.qrusible.com/Q-profile/${id}`
		window.open(url, '_blank')
	}

	useEffect(() => {
		if (screen === 'example') {
			setRef(refInputs)
		}
	})

	const getCurrentDateTimeFormatted = () => {
		const options = {
			year: 'numeric',
			month: 'short',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
			hour12: true, // Use 12-hour format (AM/PM)
		}

		return `Last updated at ${new Date().toLocaleString(
			undefined,
			options
		)}`
	}

	// Example usage:
	const formattedDateTime = getCurrentDateTimeFormatted()

	const CustomHeader = ({ props, openProfile }) => {
		return (
			<View style={styles.headercontainer}>
				<HStack style={styles.imagetextcontainer}>
					<TouchableOpacity onPress={() => openProfile()}>
						<UserAvatar size={30} name={props.name} />
					</TouchableOpacity>

					<View style={styles.nametext}>
						<TouchableOpacity onPress={() => openProfile()}>
							<Text style={styles.nametext1}>{props.name}</Text>
						</TouchableOpacity>

						<Text style={styles.generatltext}>
							{props.expertise}
						</Text>
					</View>
				</HStack>
			</View>
		)
	}

	return (
		<ScrollView>
			<VStack style={screen === 'example' && styles.container}>
				<View
					style={[
						styles.hidiBox,
						screen === 'example' && {
							width:
								windowWidth < 480
									? windowWidth * 0.8
									: windowWidth * 0.57,
						},
					]}
				>
					<Text style={styles.title}>{heading}</Text>

					<View style={styles.dateDisplay}>{formattedDateTime}</View>
					{screen === 'example' ? (
						<CustomHeader props={item} openProfile={openProfile} />
					) : (
						<Hidiheader props={profile} />
					)}
					<Divider style={styles.divider} />
					<Text style={styles.abouttext}>{background}</Text>

					{screen === 'example' ? (
						<Text style={styles.abouttext}>{summary}</Text>
					) : (
						<>
							<Editor
								style={styles.abouttext}
								text={summary}
								options={{
									toolbar: false,
									disableEditing: true,
								}}
							/>
						</>
					)}
					<Divider style={styles.divider} />

					<Text style={styles.outcomeHeading}>Outcomes</Text>
					{screen === 'edit' && (
						<>
							{outcomes?.items?.length > 0 && (
								<View>
									<OutcomeComponent props={outcomes} />
								</View>
							)}
						</>
					)}

					{ref && ref.length && (
						<View style={styles.innerBox}>
							{ref.map((value, i) => {
								const outcomeNumber = i + 1

								const editOutcomeNumber =
									outcomes?.items?.length > 0
										? outcomes?.items?.length + i + 1
										: i + 1

								return (
									<>
										{value ? (
											<View style={styles.outcomeBox}>
												<View
													style={{
														flexDirection: 'row',
														alignItems:
															'flex-start',
														gap: 7,
														marginBottom: 10,
													}}
													key={i}
												>
													<View
														style={
															styles.outcomeNumber
														}
													>
														<Text
															style={
																styles.numberText
															}
														>
															{screen === 'edit'
																? editOutcomeNumber
																: outcomeNumber}
														</Text>
													</View>
													<Text
														style={
															styles.outcomeText
														}
													>{`${value}`}</Text>
												</View>
											</View>
										) : null}
									</>
								)
							})}
						</View>
					)}
				</View>
				{screen === 'example' && (
					<View style={styles.hideModalContainer}>
						<TouchableOpacity onPress={hidiModalVisible}>
							<Text style={styles.hideModal}>Cancel</Text>
						</TouchableOpacity>
					</View>
				)}
			</VStack>
		</ScrollView>
	)
}

const styles = StyleSheet.create({
	container: {
		// backgroundColor: 'white',
		width: windowWidth < 480 ? windowWidth * 0.8 : windowWidth * 0.6,
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 20,
	},
	hidiBox: {
		width: windowWidth < 480 ? windowWidth * 0.92 : windowWidth * 0.5,
		flexDirection: 'column',
		borderRadius: 24,
		// backgroundColor: 'white',
	},
	title: {
		color: '#212121',
		fontFamily: 'Helvetica',
		fontWeight: 800,
		marginBottom: 9,
		fontSize: windowWidth <= 480 ? 32 : 42,
	},
	outcomeBox: {
		flexDirection: 'column',
		gap: 1,
	},
	outcomeText: {
		fontSize: 14,
		color: '#272727',
		fontWeight: 700,
		paddingRight: 5,
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
	},
	outcomeNumber: {
		borderWidth: 1,
		borderColor: '#868686',
		backgroundColor: '#868686',
		borderRadius: 50,
		textAlign: 'center',
		padding: 4,
		width: 24,
	},
	numberText: {
		color: '#FFFFFF',
		fontSize: 10,
		fontWeight: 'bold',
	},

	abouttext: {
		color: '#424242',
		fontFamily: 'Open Sans',
		fontWeight: 400,
		fontSize: 14,
		width: '100%',
	},
	hideModal: {
		backgroundColor: '#FF0000',
		borderRadius: 10,
		textAlign: 'center',
		fontSize: 17,
		fontWeight: 'bold',
		fontFamily: 'Helvetica',
		color: '#FFFFFF',
		paddingHorizontal: 20,
		paddingVertical: 10,
	},
	hideModalContainer: {
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 10,
	},
	ownerNameContainer: {
		width: '100%',
	},
	ownerName: {
		fontSize: 20,
		fontWeight: 'bold',
	},

	headercontainer: {
		flexDirection: windowWidth <= 480 ? 'column' : 'row',
	},
	profileimage: {
		height: 30,
		width: 30,
		borderRadius: 50,
	},
	hidiImage: {
		height: 50,
		width: 50,
		borderRadius: 50,
	},
	viewprofilebutton: {
		backgroundColor: '#335EF7',
		borderRadius: 10,
		width: windowWidth <= 480 ? '90%' : '30%',
		height: 40,
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row',
	},
	viewprofiletext: {
		textAlign: 'center',
		color: '#fff',
		fontFamily: 'Helvetica',
	},
	nametext: {
		fontFamily: 'Helvetica',
		flex: 1,
	},
	imagetextcontainer: {
		width: windowWidth <= 480 ? '90%' : '90%',
		alignItems: 'center',
		gap: 5,
	},
	nametext1: {
		color: '#335EF7',
		fontWeight: 'bold',
		fontSize: 13,
		fontFamily: 'Helvetica',
	},
	profilestyle: {
		width: 13,
		height: 13,
	},
	generatltext: {
		color: '#AAAAAA',
		fontSize: 11,
		fontFamily: 'Helvetica',
	},
	divider: {
		marginVertical: 20,
	},
	outcomeHeading: {
		fontFamily: 'Helvetica',
		fontSize: windowWidth <= 480 ? 22 : 30,
		fontWeight: 'bold',
		marginBottom: 15,
	},
	dateDisplay: {
		fontFamily: 'Open Sans',
		fontStyle: 'italic',
		fontSize: 11,
		marginBottom: 10,
		marginLeft: 5,
	},
})

export default Hidicontainer
