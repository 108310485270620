let textPromises = {}

export const checkAiText = async (text) => {
	if (!text || typeof text !== 'string') {
		throw new Error("Invalid input. 'text' must be a non-empty string.")
	}

	if (textPromises[text]) {
		return textPromises[text]
	}

	const apiUrl = 'https://chatgpt-detector.p.rapidapi.com/gpt/detect'
	const apiKey = 'b9b70dbb0bmsh33df35b862098f2p106b23jsn3c5af641bcda'

	const options = {
		method: 'POST',
		headers: {
			'X-RapidAPI-Key': apiKey,
			'content-type': 'application/json',
			'X-RapidAPI-Host': 'chatgpt-detector.p.rapidapi.com',
		},
		body: JSON.stringify({
			text: text,
		}),
	}

	const promise = new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(apiUrl, options)

			if (!response.ok) {
				resolve({
					isGPTGenerated: null,
					fakeProbability: null,
					realProbability: null,
				})
				return
			}

			const data = await response.json()

			const isGPTGenerated = data.data.output.probability_fake > 0.35
			const fakeProbability = data.data.output.probability_fake
				.toFixed(2)
				.toString()

			const realProbability = data.data.output.probability_real
				.toFixed(2)
				.toString()

			resolve({ isGPTGenerated, fakeProbability, realProbability })
		} catch (error) {
			console.error(error)
			reject(error)
		}
	})

	textPromises[text] = promise

	return promise
}
