import { Dimensions } from 'react-native'
import React, { useEffect } from 'react'
import { useState } from 'react'

// import { createCapabilities } from '../../graphql/mutations'
import { createCapabilities } from '../../../graphql/custom-mutations'
import { API } from 'aws-amplify'
import useUserStore from '../../../Userstore/UserStore'
import CompanyMultipleSelectCapability from './CompanyMultipleSelectCapability'

import { list_globalcapbilities_by_ActivityStatus_filer_by_domain } from '../../../graphql/custom-queries'
import { showToast } from '../../../components/ErrorToast'
import { Box, Divider, Heading, Text } from '@gluestack-ui/themed'
import SpinnerComponent from '../../../newComponents/SpinnerComponent'
import ButtonComponent from '../../../newComponents/ButtonComponent'


const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
export default function CompanySelectCapabilities({
	handleGoBack,
	screen,
	showModal,
	feedState,
	navigation,
	searchQuery,
	setJobOnboardModal,
	JobDomain,
	selectedCapabilities,
	setselectedCapabilities,
	setAlreadyMadeCapability,
	AlreadyMadeCapability,
	handleRemoveAddedCapability

	
}) {
	
	const profileId = useUserStore((state) => state.profileId)
	const [status, setStatus] = useState('uncheck')
	
	const [loading, setloading] = useState(true)

	const [loadingApI, setloadingApI] = useState(false)
	const [globalCapabilities, setGlobalCapabilitiesList] = useState('')

	
	

	const setLoadingCallback = (value) => {
		setloading(value)
	}

	async function getCapabilitylist() {
		setloading(true)
		await API.graphql({
			query: list_globalcapbilities_by_ActivityStatus_filer_by_domain,
			variables: {
				ActivityStatus: 'active',
				domain: {
					eq: JobDomain,
				},
			},
		}).then((res) => {
			setGlobalCapabilitiesList(
				res.data
					.list_globalcapbilities_by_ActivityStatus_filer_by_domain
					.items,
			)
			setloading(false)
		}).catch((err) => console.log(err))
	}
	useEffect(() => {
		getCapabilitylist()
	},[])
	async function CreateCapability() {
		setloadingApI(true)
		capabilitySelected.map((item) => {
			const CreateUserInput = {
				ProfileId: profileId,
				capabilitiesCapabilityConfigId: item,
			}
			API.graphql({
				query: createCapabilities,
				variables: { input: CreateUserInput },
			})
				.then((res) => {
					setClaimedCapabailities(
						res?.data?.createCapabilities?.userProfile?.Capabilities
							?.items
					)
				})
				.catch((err) => {
					console.log(err)
				})
		})

		setloadingApI(false)
	}

	const onPressContinue = () => {
		if (feedState == true) {
			return
		} else if (screen === 'profile') {
			return
		} else {
			
		}
	}

	const OnpressClaimandProceed = () => {
		if (feedState == true) {
			CreateCapability()
			showToast({
				message: 'Capabilities claimed. Unlock them to get recognised.',
				type: 'success',
			})
			// setCongratsModal(true)
			onPressContinue()
		} else {
			CreateCapability()
			screen === 'profile' &&
				showToast({
					message:
						'Capabilities claimed. Unlock them to get recognised.',
					type: 'success',
				})
			// setCongratsModal(true)
			screen === 'profile' && showModal && showModal(false)
			onPressContinue()
		}
	}
	return (
		<Box
			bg="$primary0"
			w={'100%'}
			paddingVertical={
				screen !== 'profile' && windowWidth > 480 ? 20 : 10
			}
			paddingHorizontal={windowWidth > 480 ? 50 : 0}
			marginBottom={30}
			borderRadius={screen !== 'profile' && 6}
			borderWidth={screen !== 'profile' && 1}
			borderColor="$borderDark0"
		>
			{screen === 'profile' ? null : (
				<>
					<Box marginBottom={5}>
						
						{/* <Text
							fontFamily="$fontContent"
							fontSize={
								windowWidth > 480
									? '$fontContent'
									: '$fontContentMobile'
							}
							fontWeight="600"
							lineHeight={12}
							color="$secondary500"
						>
							*Highlight the required Capabilities for the candidates
						</Text> */}
					</Box>
					<Divider marginTop={10} bgColor="$borderDark0" h={0.5} />
				</>
			)}
			<Box>
				{loading ? (
					<Box marginTop={10}>
						<SpinnerComponent
							size={'large'}
							smallText={'Loading...'}
						/>
					</Box>
				) : (
					<Box>
						<CompanyMultipleSelectCapability
							capabilitySelected={AlreadyMadeCapability}
							setcapabilitySelected={setAlreadyMadeCapability}
							data={globalCapabilities}
							selectedCapabilities={selectedCapabilities}
							searchQueryText={searchQuery}
							setselectedCapabilities ={setselectedCapabilities}
							handleRemoveAddedCapability ={handleRemoveAddedCapability}
							
						/>
					</Box>
				)}
			</Box>
			<Box
				flexDirection={windowWidth > 650 ? 'row' : 'column-reverse'}
				justifyContent={
					screen === 'profile' ? 'center' : 'space-between'
				}
				w={windowWidth > 480 ? '80%' : '95%'}
				alignSelf="center"
			>
				

				{/* <CongratsOnboarding
					isOpen={congratsModal}
					onClose={() => {
						setCongratsModal(false)
					}}
					points={capabilitySelected.length}
					onPressContinue={() => {
						setCongratsModal(false)
						onPressContinue()
					}}
				/> */}
				
			</Box>
		</Box>
	)
}
