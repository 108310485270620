import { Dimensions } from 'react-native'
import React from 'react'
import { useState, useEffect } from 'react'
import { API, Storage, graphqlOperation } from 'aws-amplify'
import useUserStore from '../../Userstore/UserStore'
import { updateNewprofile } from '../../graphql/mutations'
import { useNavigation } from '@react-navigation/native'
import useImageFileLink from '../../customhook/useImageLinkhook'
import { Box, HStack, Image, Text, Pressable, View } from '@gluestack-ui/themed'
import ButtonComponent from '../../newComponents/ButtonComponent'
import UploadComponent from '../../newComponents/UploadComponent'
import { showToast } from '../../components/ErrorToast'
import { ResumeParser } from '../../graphql/queries'
import {
	createCandidateEducation,
	createCandidateExperience,
} from '../../graphql/mutations'
import { onCreateUserParsedResumeByUserId } from '../../graphql/subscriptions'

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

export default function UploadResume({
	handleProceed,
	setModal,
	CallLambda,
	skipState,
	routes,
	navigation,
	screen,
	setCongratsModal,
	profileScreen,
	inModal,
	setResumeUploaded,
}) {
	const [fileKey, setfilekey] = useState('')
	const fileLink = useImageFileLink(fileKey)
	const [file, setFile] = useState()
	const [key, setKey] = useState('')
	const [image, setImage] = useState(null)
	const [filelocal, setFilelocal] = useState(null)

	const [loading, setLoading] = useState(false)
	const [cvResponse, setCVResponse] = useState(null)
	const profileId = useUserStore((state) => state.profileId)
	const userId = useUserStore((state) => state.userId)
	const [uploadedFileName, setUploadedFileName] = useState('')
	const [fileName, setFileName] = useState('')
	const [fileUploaded, setFileUploaded] = useState(false)
	const [fileUploadedOnS3, setFileUploadedOnS3] = useState(false)

	const ResumeParserFunction = async () => {
		try {
			await API.graphql({
				query: ResumeParser,
				variables: {
					msg: userId,
				},
			}).then((response) => {
				
			})
			return
		} catch (error) {
			console.log('resumeparserresponse error', error)
			return
		}
	}

	function handleChange(e) {
		const selectedFile =
			e?.target?.files[0] == undefined ? e[0] : e?.target?.files[0]
		if (selectedFile.type == 'application/pdf') {
			setFilelocal(URL.createObjectURL(selectedFile))
			setFile(selectedFile)
			setKey(selectedFile.lastModified)
			setFileName(selectedFile.name)
			setFileUploaded(true)
			setFileUploadedOnS3(false)
		} else {
			return showToast({
				message: 'Please choose file only in pdf format',
				type: 'error',
			})
		}
	}

	const pathToImageFile = async () => {
		setLoading(true)
		if (file) {
			try {
				await Storage.put(userId, file, {
					level: 'public',
					contentType: 'doc/pdf',
				}).then((res) => {
					if (screen === 'jobDetails') {
						setModal(false)
						ResumeParserFunction()
						showToast({
							message: 'Resume uploaded successfully',
							type: 'success',
						})
						setCongratsModal(true)
					} else if (screen === 'ResumeView') {
						ResumeParserFunction()
						showToast({
							message: 'Resume uploaded successfully',
							type: 'success',
						})
						setFileUploadedOnS3(true)
						inModal && setModal(false)
						setResumeUploaded && setResumeUploaded(true)
						setLoading(false)
						!setResumeUploaded && navigation.push('Profile')
					} else {
						CallLambda()
						handleProceed && handleProceed()
					}
				})
			} catch (err) {
				showToast({
					message: 'Pls try uploading file again',
					type: 'error',
				})
				console.log('Error uploading file:', err)
				setModal && setModal(false)
				setCongratsModal && setCongratsModal(true)
				setLoading(false)
				console.log('Error uploading file:', err)
			}
		} else {
			setLoading(false)
		}
	}

	async function updateResume(res) {
		setLoading(true)
		const CreateUserInput = {
			id: profileId,
			resumekey: key,
		}
		API.graphql({
			query: updateNewprofile,
			variables: { input: CreateUserInput },
		}).then((res) => {
			setLoading(false)
			navigation.push('Profile', {
				flag: 'FirstTimeUser',
			})
		})
	}

	return (
		<>
			{profileScreen ? (
				<Box w={'$full'} bg="white">
					<UploadComponent
						handleChange={handleChange}
						width={windowWidth > 480 ? 400 : windowWidth * 0.5}
						height={50}
						flexDirection={'row'}
						text={file ? fileName : 'Upload your resume'}
					/>
					<View alignItems="center">
						<Text
							fontFamily="$fontContent"
							fontSize={windowWidth > 480 ? 12 : 10}
							fontWeight="600"
						>
							Uploading the resume helps us build a
						</Text>
						<Text
							fontFamily="$fontContent"
							fontSize={windowWidth > 480 ? 12 : 10}
							fontWeight="600"
						>
							better profile to showcase to the employer
						</Text>
					</View>
					<Box alignItems="center">
						<ButtonComponent
							text={fileUploadedOnS3 ? 'Uploaded' : 'Upload'}
							marginVertical={10}
							width={120}
							disabled={!fileUploaded || fileUploadedOnS3}
							loading={loading}
							onPress={pathToImageFile}
							color={fileUploadedOnS3 ? 'green' : '$primary300'}
							textColor={file ? 'black' : 'white'}
							borderColor={
								fileUploadedOnS3 ? 'green' : '$primary300'
							}
							spinnerColor={'black'}
						/>
					</Box>
				</Box>
			) : (
				<Box
					w={'100%'}
					borderWidth={1}
					borderColor="$borderDark0"
					borderRadius={6}
					paddingVertical={windowWidth > 480 ? 20 : 10}
					h={'auto'}
					marginBottom={30}
					bg="$primary0"
				>
					<UploadComponent
						handleChange={handleChange}
						width={windowWidth > 480 ? '60%' : '90%'}
						height={100}
						flexDirection={'column'}
						text={
							'Click here to browse files and add Resume/CV. \n Choose only PDF format'
						}
					/>
					<HStack
						justifyContent="center"
						alignItems="center"
						marginTop={20}
						borderWidth={1}
						w={windowWidth > 480 ? '60%' : '90%'}
						h={50}
						alignSelf="center"
						gap={5}
						borderRadius={6}
						bg={fileUploaded ? '$primary100' : '$secondary100'}
						borderColor="$borderDark0"
					>
						<Image
							source={require('../../assets/Images/pdf.png')}
							h={25}
							w={25}
							alt="PDF Image"
						/>
						<Text
							fontFamily="$fontContent"
							fontSize={12}
							color="$primary950"
							fontWeight="700"
						>
							{file ? fileName : 'No file selected'}
						</Text>
					</HStack>
					<Box alignItems="center">
						<ButtonComponent
							text={'Upload and Continue'}
							marginVertical={25}
							width={windowWidth > 650 ? 210 : '90%'}
							disabled={!fileUploaded}
							loading={loading}
							onPress={pathToImageFile}
						/>
					</Box>
				</Box>
			)}
		</>
	)
}
