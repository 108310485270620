import * as React from "react";
import { Text, StyleSheet, View, Image, FlatList, Dimensions } from "react-native";
import { Padding, Color, Border, FontFamily, FontSize } from '../FeedComponents/GlobalStyleFeed'
import Divider from "./Divider";
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
const PeersProfiles = () => {
    const data = [
        {
          id: 'bd7acbea-c1b1-46c2-aed5-3ad53abb28ba',
          title: 'First Item',
        },
        {
          id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f63',
          title: 'Second Item',
        },
        {
          id: '58694a0f-3da1-471f-bd96-145571e29d72',
          title: 'Third Item',
        },
        {
            id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f6311',
            title: 'Second Item',
          },
          {
            id: '58694a0f-3da1-471f-bd96-145571e29d7222',
            title: 'Third Item',
          },
      ];
    const renderItem = (item, index) => {
        const res = item;
        return (
            <View style={[styles.autoLayoutVertical, styles.peersprofilesBorder]}>
            <View style={styles.frameParent}>
              <View style={styles.ellipseWrapper}>
                <Image
                  style={styles.frameLayout}
                  resizeMode="cover"
                  source={require("../assets/Images/blueuserprofile.png")}
                />
              </View>
              <View style={styles.frameGroup}>
                <View style={styles.meganRapinoeWrapper}>
                  <Text style={[styles.meganRapinoe, styles.meganRapinoeTypo]}>
                    Megan Rapinoe
                  </Text>
                </View>
                <View style={styles.frontEndHeadMonsterWrapper}>
                  <Text style={[styles.frontEndHead, styles.yearsTypo]}>
                    Front End Head @ Monster
                  </Text>
                </View>
                <Text style={[styles.yearsExperience, styles.yearsTypo]}>
                  6 Years Experience
                </Text>
               <Divider/>
              </View>
              <View style={styles.check1Parent}>
                <Image
                  style={styles.check1Icon}
                  resizeMode="cover"
                  source={require("../assets/Images/check.png")}
                />
                <View
                  style={[
                    styles.autoLayoutHorizontal,
                    styles.autoLayoutHorizontalFlexBox,
                  ]}
                >
                  <Text style={[styles.canDesignAnd, styles.canTypo]}>
                    Can design and implement RESTful APIs.
                  </Text>
                </View>
              </View>
              <View style={styles.typebuttonShadowBox}>
                <Text style={styles.seeTypo}>See How</Text>
              </View>
            </View>
          </View>
        );
      };
  return (
    <View style={[styles.peersprofiles, styles.peersprofilesBorder]}>
      <View
        style={[
          styles.peersWhoHaveRecentlyUnlockWrapper,
          styles.autoLayoutHorizontalFlexBox,
        ]}
      >
        <Text style={styles.peersWhoHave}>
          Peers who have recently unlocked “In-Demand” Capabilities
        </Text>
      </View>
      <Divider />
      <View style={styles.autoLayoutVerticalParent}>
     
       
      <FlatList
        data={data}
        renderItem={renderItem}
        keyExtractor={item => item.id.toString()}
        horizontal 
        
      />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  peersprofilesBorder: {
    padding: Padding.p_xl,
    borderWidth: 1,
    borderColor: Color.colorWhitesmoke_100,
    borderStyle: "solid",
    backgroundColor: Color.othersWhite,
    borderRadius: Border.br_5xl,
  },
  autoLayoutHorizontalFlexBox: {
    alignItems: "center",
    flexDirection: "row",
  },
 
  meganRapinoeTypo: {
    color: Color.colorDarkslategray,
    fontFamily: FontFamily.bodyLargeBold,
    fontWeight: "700",
    letterSpacing: 0,
    textAlign: "left",
  },
  yearsTypo: {
    fontFamily: FontFamily.urbanistMedium,
    fontWeight: "500",
    color: Color.colorDarkslategray,
    letterSpacing: 0,
    textAlign: "left",
  },
  canTypo: {
    color: Color.colorGray_200,
    lineHeight: 17,
    fontFamily: FontFamily.bodyLargeBold,
    fontWeight: "700",
    textAlign: "left",
    fontSize: FontSize.size_sm,
  },
  autoBorder: {
    marginLeft: 15,
    padding: Padding.p_xl,
    borderWidth: 1,
    borderColor: Color.colorWhitesmoke_100,
    borderStyle: "solid",
    backgroundColor: Color.othersWhite,
    borderRadius: Border.br_5xl,
  },
  frameLayout: {
    height: 30,
    width: 30,
  },
  peersWhoHave: {
    lineHeight: 16,
    fontFamily: FontFamily.urbanistRegular,
    color: Color.colorGray_300,
    textAlign: "left",
    fontSize: FontSize.size_sm,
  },
  peersWhoHaveRecentlyUnlockWrapper: {
    alignSelf: "stretch",
  },

  ellipseWrapper: {
    borderRadius: Border.br_smi,
    width: 50,
    height: 50,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    borderWidth: 1,
    borderColor: Color.colorWhitesmoke_100,
    borderStyle: "solid",
    backgroundColor: Color.othersWhite,
  },
  meganRapinoe: {
    lineHeight: 20,
    fontSize: FontSize.size_sm,
  },
  meganRapinoeWrapper: {
    flexDirection: "row",
  },
  frontEndHead: {
    lineHeight: 14,
    fontSize: FontSize.size_3xs,
    fontWeight: "500",
  },
  frontEndHeadMonsterWrapper: {
    marginTop: 5,
    overflow: "hidden",
    alignItems: "center",
    flexDirection: "row",
  },
  yearsExperience: {
    lineHeight: 14,
    fontSize: FontSize.size_3xs,
    fontWeight: "500",
    marginTop: 5,
  },
 
  frameGroup: {
    width: 153,
    marginTop: 15,
  },
  check1Icon: {
    width: 20,
    height: 20,
  },
  canDesignAnd: {
    width: 148,
  },
  autoLayoutHorizontal: {
    marginLeft: 10,
    flex: 1,
  },
  check1Parent: {
    marginTop: 15,
    flexDirection: "row",
    alignSelf: "stretch",
  },
  seeTypo: {
    textShadowRadius: 24,
    textShadowOffset: {
      width: 4,
      height: 8,
    },
    textShadowColor: "rgba(36, 107, 253, 0.25)",
    height: 22,
    display: "flex",
    textAlign: "center",
    color: Color.othersWhite,
    flex: 1,
    fontFamily: FontFamily.bodyLargeBold,
    fontWeight: "700",
    lineHeight: 20,
    letterSpacing: 0,
    justifyContent: "center",
    fontSize: FontSize.size_sm,
    alignItems: "center",
  },
  typebuttonShadowBox: {
    paddingVertical: Padding.p_3xs,
    paddingHorizontal: Padding.p_base,
    height: 28,
    shadowOpacity: 1,
    elevation: 24,
    shadowRadius: 24,
    shadowOffset: {
      width: 4,
      height: 8,
    },
    shadowColor: "rgba(36, 107, 253, 0.25)",
    backgroundColor: Color.primary500,
    borderRadius: Border.br_81xl,
    marginTop: 15,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    alignSelf: "stretch",
  },
  frameParent: {
    alignSelf: "stretch",
  },
  autoLayoutVertical: {
    width: '90%',
  },
  giorgioChiellini: {
    fontSize: FontSize.bodyLargeBold_size,
    lineHeight: 22,
  },
  reactNativeDev: {
    lineHeight: 20,
    fontSize: FontSize.size_sm,
  },
  yearsExperience1: {
    marginTop: 5,
    lineHeight: 20,
    fontSize: FontSize.size_sm,
  },
  frameView: {
    marginTop: 20,
  },
  autoLayoutVertical1: {
    width: 158,
    display: "none",
  },
  frameInner: {
    display: "none",
  },
  ellipseIcon: {
    marginLeft: 10,
  },
  canCreateResponsive: {
    flex: 1,
  },
  autoLayoutVertical2: {
    width: 193,
  },
  autoLayoutVerticalParent: {
    marginTop: 10,
    flexDirection: "row",
    alignSelf: "stretch",
  },
  peersprofiles: {
    width: windowWidth < 480 ? windowWidth*0.95 : windowWidth*0.60,
    // alignSelf: windowWidth < 480 ? 'center' : 'auto',
    alignSelf: 'center',
    marginTop: 15
  },
});

export default PeersProfiles;
