import React from 'react'
import { Dimensions, Clipboard} from 'react-native'
import { Amplify, Auth } from 'aws-amplify'
import awsmobile from '../../aws-exports'
import useUserStore from '../../Userstore/UserStore'
import { isIE } from 'react-device-detect';
import { getMobileOperatingSystem } from '../../HeplerFunctions/Utlis'
import ButtonComponent from '../../newComponents/ButtonComponent'
import {
	Box,
	Icon,
	Text,
	HStack,
	VStack,
	Center,
	Pressable,
	Button,
	ButtonIcon,
	ButtonText,
	Image,
	Divider
} from '@gluestack-ui/themed'
import { useState } from 'react'
import ModalComponent from '../../newComponents/ModalComponent'
import { showToast } from '../../components/ErrorToast'
Amplify.configure(awsmobile)

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
import { isBrowser, isMobile, browserName} from 'react-device-detect';
export default function FederatedLogin ({ loginScreen, routes, setTab })  {
	const [ModalOpen, setOpenModal] = useState(false)
	const webViewRegexRules = [
        'WebView',
        '(iPhone|iPod|iPad)(?!.*Safari)',
        'Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})',
        'Linux; U; Android'
      ];
	  
      const webViewRegExp = new RegExp('(' + webViewRegexRules.join('|') + ')', 'ig')
      const isWebView = !!window.navigator.userAgent.match(webViewRegExp);

	
	
	const signInWithGoogle = useUserStore((state) => state.signInWithGoogle)
	const OS = getMobileOperatingSystem()

	
	
	const handleSignInWithGoogle = async () => {
		await signInWithGoogle()
	}
const OpeartingSystemCheckHandler = () => {
	if(isWebView == false){

		handleSignInWithGoogle()
	}
	else{
		if(OS == 'iOS' && isMobile == true){

			showToast({
				message : "This browser is not supported. Please open the link in another browser.",
				type : 'error'
			})
			setOpenModal(true)
		}
		else{
			handleSignInWithGoogle()
		}
	}
}
	return (
		<Center w={'100%'}>
			<ModalComponent
			heading={"To open the link, follow the provided instructions."}
			
			// height={400}
			// w
			
			renderBody={() => {
				return(
					<Box alignItems='center' padding={10} borderRadius={10} >
					<Divider padding={1} marginBottom={10}/>
					<Image
					resizeMode='contain'
					borderRadius={10}
					w={320}
					h = {500}
					source={require('../../assets/Images/BrowserOpeningTutorial.png')}
					/>
					</Box>
				)
			}}
			isOpen={ModalOpen}
			onClose={ () => setOpenModal(false)}
			/>
			<VStack w={'100%'}>
				<Button
					variant="outline"
					size="xl"
					borderRadius={6}
					bg="white"
					gap={10}
					justifyContent="center"
					alignItems="center"
					borderWidth={1.5}
					borderColor={'#EEEEEE'}
					w={'100%'}
					// marginVertical={20}
					onPress={OpeartingSystemCheckHandler}
				>
					<Image
						h={windowWidth > 480 ? 20 : 17}
						w={windowWidth > 480 ? 20 : 17}
						alt="Google logo"
						source={require('../../assets/Images/google.png')}
					/>
					<ButtonText
						color="black"
						fontSize={windowWidth > 480 ? 15 : 12}
						opacity={0.7}
						fontFamily="$fontContent"
					>
						Continue with Google
					</ButtonText>
				</Button>
				{loginScreen ? (
					<HStack
						alignItems="center"
						justifyContent="center"
						gap={12}
						marginVertical={10}
					>
						<>
							<Box
								borderWidth={1}
								borderColor="#EEEEEE"
								h={1}
								w={windowWidth > 480 ? '9rem' : '7rem'}
							></Box>
							<Text fontFamily="$fontContent" fontSize={12}>
								or
							</Text>
						</>

						<Box
							borderWidth={1}
							borderColor="#EEEEEE"
							h={1}
							w={windowWidth > 480 ? '9rem' : '7rem'}
						></Box>
					</HStack>
				) : null}
			</VStack>
		</Center>
	)
}


