import { Dimensions } from 'react-native'
import React, { useEffect, useState } from 'react'
import {
	Box,
	HStack,
	Pressable,
	ScrollView,
	Text,
	VStack,
} from '@gluestack-ui/themed'

import { useNavigation } from '@react-navigation/native'
import { API, graphqlOperation } from 'aws-amplify'
// import { list_JobApplicants_by_companyPreferenceId } from '../graphql/queries';
import { list_JobApplicants_by_companyPreferenceId } from '../../graphql/custom-queries'
import { list_JobApplicants_by_Level_sortbyCompanyprefernceId } from '../../graphql/custom-queries'
import { listrecommendedprofieByPreferencesByAcceptance } from '../../graphql/custom-queries'
import { listrecommendedprofieByPreferencesByAcceptancewithcapabilities } from '../../graphql/custom-queries'
import { listRejectedByPreferencesByDate } from '../../graphql/custom-queries'
import PrefBoxes from './components/prefBoxes'
import RecomendedScreen from './RecomendedScreen'
import ShortlistScreen from './ShortlistScreen'
import JobApplicantsList from './JobApplicantsList'
import JobApplyingCandidateList from './JobApplyingCandidateList'
import SpinnerComponent from '../../newComponents/SpinnerComponent'
import Level1 from './Level1'
import Level2 from './Level2'
import Level3 from './Level3'
import Level4 from './Level4'
import Level0 from './Level0'
import LevelAlpha from './LevelAlpha'
import LevelRecommended from './LevelRecommended'
import LevelQFactor from './LevelQFactor'
import LevelV from './LevelV'
// import { Get_JobPost_by_companyPreferenceId } from '../../graphql/queries'
import { Get_JobPost_by_companyPreferenceId } from '../../graphql/custom-queries'
import { OpenJobPostView } from '../../HeplerFunctions/OpenJobPostView'
import useUserStore from '../../Userstore/UserStore'

import RejectedScreen from './RejectedScreen'
import { onUpdateRecommendedprofile , onUpdateJobApplications} from '../../graphql/custom-subscriptions'

import ButtonComponent from '../../newComponents/ButtonComponent'
import ActiveButtonComponent from '../../newComponents/ActiveButtonComponent'
import ActiveTextButton from '../../newComponents/ActiveTextButton'
import LightTextButton from '../../newComponents/LightTextButton'
import LightButtonComponent from '../../newComponents/LightButtonComponent'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const Preferencescontainer = ({ props, companyname, daysPassed, screen, companyProfileId }) => {
	
	const navigation = useNavigation()
	const selectedOption = useUserStore((state) => state.selectedOption)
	const setSelectedOption = useUserStore((state) => state.setSelectedOption)
	const typeofuser = useUserStore((state) => state.group)
	const [JobApplicantslist, setJobApplicantslist] = useState('')
	const [JobPostData, setJobPostData] = useState('')
	const [RecommendedProfiles, setRecommendedProfiles] = useState([])
	const [level1, setlevel1] = useState('')
	const [level2, setlevel2] = useState('')
	const [level3, setlevel3] = useState('')
	const [level4, setlevel4] = useState('')
	const [level0, setlevel0] = useState('')
	const [levelAlpha, setlevelAlpha] = useState('')
	const [levelQFactor, setlevelQFactor] = useState('')
	const [levelRecommended, setlevelRecommended] = useState('')
	const [levelV, setlevelV] = useState('')
	const [RejectedData, setRejectedData] = useState('')

	const [tab, setTab] = useState(selectedOption)
	const [loading, setLoading] = useState(true)
	const ShorlistedProfiles = RecommendedProfiles.filter(
		(item) => item.acceptanceStatus == 'Shortlisted'
	)
	const RejectedProfiles = RecommendedProfiles.filter(
		(item) => item.acceptanceStatus == 'Rejected'
	)
	const DeployedProfiles = RecommendedProfiles.filter(
		(item) => item.acceptanceStatus == 'Deployed'
	)
	
	const RemainingRecommendedProfiles = RecommendedProfiles.filter(
		(item) => item.acceptanceStatus == null && 
		(new Date() - new Date(item.updatedAt) <= 30 * 24 * 60 * 60 * 1000) // Check if updatedAt is within the last 15 days
	)
	
	async function getJobApplications() {
		await API.graphql({
			query: list_JobApplicants_by_companyPreferenceId,
			variables: {
				CompanyPreferenceId: props?.id,
				// acceptanceStatus: { eq: 'recommend' },
			},
		})
			.then((res) => {
				setJobApplicantslist(
					res.data.list_JobApplicants_by_companyPreferenceId.items
				)
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}
	async function getJobPost() {
		await API.graphql({
			query: Get_JobPost_by_companyPreferenceId,
			variables: {
				CompanyPreferenceId: props?.id,

				// acceptanceStatus: { eq: 'recommend' },
			},
		})
			.then((res) => {
		
				setJobPostData(
					res.data.Get_JobPost_by_companyPreferenceId.items[0]
				)
				
			})
			.catch((err) => {
				console.log(err)
			})
	}
	// async function getJobApplicationsApplying() {
	// 	console.log(props.id)
	// 	await API.graphql({
	// 		query: list_JobApplicants_by_companyPreferenceId,
	// 		variables: {
	// 			CompanyPreferenceId: props?.id,
	// 			ApplyingStatus: { eq: 'Applying' },
	// 			// acceptanceStatus: { eq: 'recommend' },
	// 		},
	// 	})
	// 		.then((res) => {
	// 			console.log('applying', res)
	// 			setJobApplicantsApplying(
	// 				res.data.list_JobApplicants_by_companyPreferenceId.items
	// 			)
	// 			setLoading(false)
	// 		})
	// 		.catch((err) => {
	// 			console.log(err)
	// 		})
	// }
	async function getRecommendedprofiles() {
		
		await API.graphql({
			query:
				screen == 'Candidates'
					? listrecommendedprofieByPreferencesByAcceptancewithcapabilities
					: listrecommendedprofieByPreferencesByAcceptance,
			variables: {
				preferencesId: props.id,
				sortDirection: 'DESC',
				// acceptanceStatus: { eq: 'recommend' },
			},
			
		})
			.then((res) => {
				
				// getJobApplications()
				setRecommendedProfiles(
					res.data.listrecommendedprofieByPreferencesByAcceptance
						.items
				)
				
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}
	async function getLevel1() {
		await API.graphql({
			query: list_JobApplicants_by_Level_sortbyCompanyprefernceId,
			variables: {
				Level: 1,
				CompanyPreferenceId: { eq: props?.id },
			},
		})
			.then((res) => {
				setlevel1(
					res.data
						.list_JobApplicants_by_Level_sortbyCompanyprefernceId
						.items
				)
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}
	async function getLevel2() {
		await API.graphql({
			query: list_JobApplicants_by_Level_sortbyCompanyprefernceId,
			variables: {
				Level: 2,
				CompanyPreferenceId: { eq: props?.id },
			},
		})
			.then((res) => {
				setlevel2(
					res.data
						.list_JobApplicants_by_Level_sortbyCompanyprefernceId
						.items
				)
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}
	async function getLevel3() {
		await API.graphql({
			query: list_JobApplicants_by_Level_sortbyCompanyprefernceId,
			variables: {
				Level: 3,
				CompanyPreferenceId: { eq: props?.id },
			},
		})
			.then((res) => {
				setlevel3(
					res.data
						.list_JobApplicants_by_Level_sortbyCompanyprefernceId
						.items
				)
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}
	async function getLevel4() {
		await API.graphql({
			query: list_JobApplicants_by_Level_sortbyCompanyprefernceId,
			variables: {
				Level: 4,
				CompanyPreferenceId: { eq: props?.id },
			},
		})
			.then((res) => {
				setlevel4(
					res.data
						.list_JobApplicants_by_Level_sortbyCompanyprefernceId
						.items
				)
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}
	async function getLevel0() {
		await API.graphql({
			query: list_JobApplicants_by_Level_sortbyCompanyprefernceId,
			variables: {
				Level: 0,
				CompanyPreferenceId: { eq: props?.id },
			},
		})
			.then((res) => {
				setlevel0(
					res.data
						.list_JobApplicants_by_Level_sortbyCompanyprefernceId
						.items
				)
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}
	async function getLevelAplha() {
		await API.graphql({
			query: list_JobApplicants_by_Level_sortbyCompanyprefernceId,
			variables: {
				Level: 999,
				CompanyPreferenceId: { eq: props?.id },
			},
		})
			.then((res) => {
				setlevelAlpha(
					res.data
						.list_JobApplicants_by_Level_sortbyCompanyprefernceId
						.items
				)
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}
	async function getLevelQFactor() {
		await API.graphql({
			query: list_JobApplicants_by_Level_sortbyCompanyprefernceId,
			variables: {
				Level: 1000,
				CompanyPreferenceId: { eq: props?.id },
			},
		})
			.then((res) => {
				setlevelQFactor(
					res.data
						.list_JobApplicants_by_Level_sortbyCompanyprefernceId
						.items
				)
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}
	async function getLevelV() {
		await API.graphql({
			query: list_JobApplicants_by_Level_sortbyCompanyprefernceId,
			variables: {
				Level: 998,
				CompanyPreferenceId: { eq: props?.id },
			},
		})
			.then((res) => {
				setlevelV(
					res.data
						.list_JobApplicants_by_Level_sortbyCompanyprefernceId
						.items
				)
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}
	async function getLevelRecommended() {
		await API.graphql({
			query: list_JobApplicants_by_Level_sortbyCompanyprefernceId,
			variables: {
				Level: 1001,
				CompanyPreferenceId: { eq: props?.id },
			},
		})
			.then((res) => {
				setlevelRecommended(
					res.data
						.list_JobApplicants_by_Level_sortbyCompanyprefernceId
						.items
				)
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
		await API.graphql({
			query: listRejectedByPreferencesByDate,
			variables: {
				preferencesId: props.id,
			},
		})
			.then((res) => {
				setRejectedData(res.data.listRejectedByPreferencesByDate.items)

				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}
	const initSubscriptions = async () => {
		let _subscriptions = []
		const subscription = API.graphql(
			graphqlOperation(onUpdateRecommendedprofile)
		).subscribe({
			next: ({ provider, value }) => {
				getRecommendedprofiles()

				// setIsParticipant(true);
			},
			error: (error) => {
				// console.log(error)
				// setConnectionStatus(false);
				console.warn(error.error)
			},
		})

		return
	}
	const initSubscriptionsforLevels = async () => {
		let _subscriptions = []
		const subscription = API.graphql(
			graphqlOperation(onUpdateJobApplications)
		).subscribe({
			next: ({ provider, value }) => {
				
				getLevel1()
				getLevel2()
				getLevel3()
				getLevel4()
				getLevel0()
				getLevelAplha()
				getLevelQFactor()
				getLevelRecommended()
				getLevelV()

				// setIsParticipant(true);
			},
			error: (error) => {
				// console.log(error)
				// setConnectionStatus(false);
				console.warn(error.error)
			},
		})

		return
	}
	useEffect(() => {
		initSubscriptions()
		if (typeofuser == 'QrusibleSuperAdmin') {
			getJobApplications()
			getLevel1()
			getLevel2()
			getLevel3()
			getLevel4()
			getLevel0()
			getLevelAplha()
			getLevelQFactor()
			getLevelRecommended()
			getLevelV()
			initSubscriptionsforLevels()
		}
		// getJobApplicationsApplying()
		getJobPost()
		getJobApplications()
		getRecommendedprofiles()
		// getRejected()
	}, [props?.id])

	const showCandidates = () => {
		if (screen !== 'Candidates') {
			navigation.push('Candidates', {
				item: props,
				companyname: companyname,
				daysPassed: daysPassed,
			})
		}
	}

	return (
		<>
		{loading ? (null) : (
			<>
		{/* {DeployedProfiles.length == props.numberOfCandidateRequired ? (null) :( */}
		<Box>
			<Pressable
				marginVertical={windowWidth > 480 ? 20 : 10}
				onPress={() => {
					setSelectedOption(0)
					if(props.JobPublished == false){
						
						navigation.push('CreateJobPost', {
							preferenceId: props.id
						})
					}else{
						showCandidates()
					}
					
				}}
				bg="$primary0"
				p={screen === 'Candidates' ? (windowWidth > 480 ? 20 : 5) : 0}
				borderRadius={6}
				borderWidth={screen === 'Candidates' && 1}
				borderColor="$borderDark0"
			>
				<HStack
					alignItems="center"
					justifyContent="space-between"
					flexWrap="wrap"
					gap={windowWidth > 480 ? null : 10}
				>
					<VStack>
						<Text
							color="$fontGray"
							fontFamily="$fontContent"
							fontSize={windowWidth > 480 ? 9 : 8}
							fontWeight="700"
						>
							{daysPassed > 1
								? `${daysPassed} days ago`
								: `${daysPassed} day ago`}
						</Text>
						<Text
							fontFamily="$fontHeading"
							fontWeight="700"
							color= { props.JobPublished == false ? 'lightgrey': "$primary950"}
							fontSize={windowWidth > 480 ? 18 : 15}
							lineHeight={windowWidth > 480 ? null : 15}
						>
							{props?.rolename}
						</Text>
						
						
						{/* {typeofuser == 'QrusibleSuperAdmin' &&
						<Pressable
						onPress={ () => OpenJobPostView(props.JobPosts.items[0].id)}
						>
						<Text
							color='$blue400'
							fontFamily="$fontHeading"
							fontSize={windowWidth > 480 ? 14 : 12}
							fontWeight="700"
							lineHeight={15}
						>
							Go to JobPost
						</Text>

						</Pressable>
						} */}
						
						
						<Text
							color="$fontGray"
							fontFamily="$fontHeading"
							fontSize={windowWidth > 480 ? 12 : 11}
							fontWeight="700"
							lineHeight={15}
						>
							{companyname}
						</Text>
						<Text
							color="$fontGray"
							fontFamily="$fontHeading"
							fontSize={windowWidth > 480 ? 12 : 10}
							fontWeight="500"
							lineHeight={15}
						>
							{props?.modeofwork}
						</Text>
						<Text
							color="$fontGray"
							fontFamily="$fontHeading"
							fontSize={windowWidth > 480 ? 12 : 10}
							fontWeight="500"
							lineHeight={15}
						>
							Total Applicants: {JobApplicantslist?.length}
						</Text>
						<HStack paddingBottom={10} gap={10}>
				{typeofuser == 'QrusibleSuperAdmin' && 
					<ActiveTextButton
					text={'Edit JobPost'}
					width={'fit-content'}
					onPress={() => navigation.push('CreateJobPost', {
						preferenceId: props.id,
						companyProfileId: companyProfileId
					})}
					height={30}
				/>
				}
				{typeofuser == 'QrusibleSuperAdmin' &&
				<LightTextButton
					text={'View JobPost'}
					width={'fit-content'}
					onPress={ () => OpenJobPostView(props.JobPosts.items[0].id)}
					height={30}
				/>
			}
				</HStack>
					</VStack>
					<Box w={windowWidth > 480 ? 'fit-content' : '$full'}>
						<ScrollView
							horizontal
							showsHorizontalScrollIndicator={false}
						>
							{!loading ? (
								<>
									{typeofuser == 'QrusibleSuperAdmin' ? (
										<PrefBoxes
											counts={[
												RemainingRecommendedProfiles.length,
											    DeployedProfiles.length,
												ShorlistedProfiles.length,
												RejectedProfiles.length,
												JobApplicantslist?.length,
												level0?.length,
												level1?.length,
												level2?.length,
												level3?.length,
												level4?.length,
												levelV?.length,
												levelAlpha?.length,
												levelQFactor?.length,
												levelRecommended?.length
												
											]}
											text={[
												'Recommendations',
												'Deployed',
												'Shortlisted',
												'Rejected',
												'Applicants',
												'Level 0',
												'Level 1',
												'Level 2',
												'Level 3',
												'Level 4',
												'Level V',
												'Level Alpha',
												'Level Q-Factor',
												'Level Recommended'
												
											]}
											setTab={setTab}
											tab={tab}
											screen={screen}
											showCandidates={() =>
												showCandidates()
											}
										/>
									) : (
										<PrefBoxes
											counts={[
												RemainingRecommendedProfiles.length,
												DeployedProfiles.length,
												ShorlistedProfiles.length,
												RejectedProfiles.length,
											]}
											text={[
												'Q-Rated',
												'Deployed',
												// 'Applicants',
												'Shortlisted',
												'Rejected',
											]}
											setTab={setTab}
											tab={tab}
											screen={screen}
											showCandidates={() =>
												showCandidates()
											}
										/>
									)}
								</>
							) : (
								<SpinnerComponent />
							)}
						</ScrollView>
					</Box>
				</HStack>
				
			
			</Pressable>

			{screen === 'Candidates' && tab === 0 && (
				<RecomendedScreen
					preferenceId={props?.id}
					companyName={companyname}
					rolename={props?.rolename}
					prefferedSalary={props?.package}
					minExperience={props?.minExperienceRequired}
					maxExpeirence={props?.maxExperienceRequired}
					navigation={navigation}
					JobPostData={JobPostData}
					SelectedTab={'None'}
					companyQrewId  ={props.companyQrewId}
					recommendedData={
						RemainingRecommendedProfiles.length == 0
							? []
							: RemainingRecommendedProfiles
					}
				/>
			)}
			{screen === 'Candidates' && tab === 1 && (
				<RecomendedScreen
					preferenceId={props?.id}
					companyName={companyname}
					rolename={props?.rolename}
					prefferedSalary={props?.package}
					minExperience={props?.minExperienceRequired}
					maxExpeirence={props?.maxExperienceRequired}
					companyQrewId  ={props.companyQrewId}
					navigation={navigation}
					JobPostData={JobPostData}
					SelectedTab={'Deployed'}
					recommendedData={
						DeployedProfiles.length == 0 ? [] : DeployedProfiles
					}
				/>
			)}
			{screen === 'Candidates' && tab === 2 && (
				<RecomendedScreen
					preferenceId={props?.id}
					companyName={companyname}
					rolename={props?.rolename}
					prefferedSalary={props?.package}
					minExperience={props?.minExperienceRequired}
					maxExpeirence={props?.maxExperienceRequired}
					companyQrewId  ={props.companyQrewId}
					navigation={navigation}
					JobPostData={JobPostData}
					SelectedTab={'Shortlisted'}
					recommendedData={
						ShorlistedProfiles.length == 0 ? [] : ShorlistedProfiles
					}
				/>
			)}
			{screen === 'Candidates' && tab === 3 && (
				<RecomendedScreen
					preferenceId={props?.id}
					companyName={companyname}
					rolename={props?.rolename}
					prefferedSalary={props?.package}
					minExperience={props?.minExperienceRequired}
					maxExpeirence={props?.maxExperienceRequired}
					companyQrewId  ={props.companyQrewId}
					navigation={navigation}
					JobPostData={JobPostData}
					SelectedTab={'Rejected'}
					recommendedData={
						RejectedProfiles.length == 0 ? [] : RejectedProfiles
					}
				/>
			)}
			{/* {screen === 'Candidates' && tab === 1 && (
				<JobApplyingCandidateList
					preferenceId={props?.id}
					companyName={companyname}
					rolename={props?.rolename}
					navigation={navigation}
					JobPostData={JobPostData}
				/>
			)} */}
			{screen === 'Candidates' && tab === 4 && (
				<JobApplicantsList
					preferenceId={props?.id}
					companyName={companyname}
					rolename={props?.rolename}
					navigation={navigation}
					JobPostData={JobPostData}
					prefferedSalary={props?.package}
					companyQrewId  ={props.companyQrewId}
					minExperience={props?.minExperienceRequired}
					maxExpeirence={props?.maxExperienceRequired}
					SelectedTab={'AllApplicants'}
					JobApplicantsList={
						JobApplicantslist.length == 0 ? [] : JobApplicantsList
					}
				/>
			)}
			{/* 			
			{screen === 'Candidates' && tab === 1 && 
			
			(
				<ShortlistScreen
					preferenceId={props?.id}
					companyName={companyname}
					rolename={props?.rolename}
					navigation={navigation}
					JobPostData={JobPostData}
				
				/>
			)} */}
			{/* {screen === 'Candidates'  && tab === 2 &&  (
				<RejectedScreen
					preferenceId={props?.id}
					companyName={companyname}
					rolename={props?.rolename}
					navigation={navigation}
					JobPostData={JobPostData}
				/>
		)} */}
		{screen === 'Candidates' &&
				tab === 5 &&
				typeofuser == 'QrusibleSuperAdmin' && (
					<Level0
						preferenceId={props?.id}
						companyName={companyname}
						rolename={props?.rolename}
						navigation={navigation}
						JobPostData={JobPostData}
						prefferedSalary={props?.package}
						minExperience={props?.minExperienceRequired}
						companyQrewId  ={props.companyQrewId}
						maxExpeirence={props?.maxExperienceRequired}
						SelectedTab={'Level0'}
						levelData={level0.length == 0 ? [] : level0}
					/>
				)}
			{screen === 'Candidates' &&
				tab === 6 &&
				typeofuser == 'QrusibleSuperAdmin' && (
					<Level1
						preferenceId={props?.id}
						companyName={companyname}
						rolename={props?.rolename}
						navigation={navigation}
						JobPostData={JobPostData}
						prefferedSalary={props?.package}
						minExperience={props?.minExperienceRequired}
						companyQrewId  ={props.companyQrewId}
						maxExpeirence={props?.maxExperienceRequired}
						SelectedTab={'Level1'}
						levelData={level1.length == 0 ? [] : level1}
					/>
				)}
			{screen === 'Candidates' &&
				tab === 7 &&
				typeofuser == 'QrusibleSuperAdmin' && (
					<Level2
						preferenceId={props?.id}
						companyName={companyname}
						rolename={props?.rolename}
						navigation={navigation}
						JobPostData={JobPostData}
						prefferedSalary={props?.package}
						minExperience={props?.minExperienceRequired}
						companyQrewId  ={props.companyQrewId}
						maxExpeirence={props?.maxExperienceRequired}
						SelectedTab={'Level2'}
						levelData={level2.length == 0 ? [] : level2}
					/>
				)}
			{screen === 'Candidates' &&
				tab === 8 &&
				typeofuser == 'QrusibleSuperAdmin' && (
					<Level3
						preferenceId={props?.id}
						companyName={companyname}
						rolename={props?.rolename}
						navigation={navigation}
						JobPostData={JobPostData}
						prefferedSalary={props?.package}
						minExperience={props?.minExperienceRequired}
						companyQrewId  ={props.companyQrewId}
						maxExpeirence={props?.maxExperienceRequired}
						SelectedTab={'Level3'}
						levelData={level3.length == 0 ? [] : level3}
					/>
				)}
			{screen === 'Candidates' &&
				tab === 9 &&
				typeofuser == 'QrusibleSuperAdmin' && (
					<Level4
						preferenceId={props?.id}
						companyName={companyname}
						rolename={props?.rolename}
						navigation={navigation}
						JobPostData={JobPostData}
						prefferedSalary={props?.package}
						minExperience={props?.minExperienceRequired}
						companyQrewId  ={props.companyQrewId}
						maxExpeirence={props?.maxExperienceRequired}
						SelectedTab={'Level4'}
						levelData={level4.length == 0 ? [] : level4}
					/>
				)}
				{screen === 'Candidates' &&
				tab === 10 &&
				typeofuser == 'QrusibleSuperAdmin' && (
					<LevelV
						preferenceId={props?.id}
						companyName={companyname}
						rolename={props?.rolename}
						navigation={navigation}
						JobPostData={JobPostData}
						prefferedSalary={props?.package}
						minExperience={props?.minExperienceRequired}
						companyQrewId  ={props.companyQrewId}
						maxExpeirence={props?.maxExperienceRequired}
						SelectedTab={'Level-V'}
						levelData={levelAlpha.length == 0 ? [] : levelAlpha}
					/>
				)}
			{screen === 'Candidates' &&
				tab === 11 &&
				typeofuser == 'QrusibleSuperAdmin' && (
					<LevelAlpha
						preferenceId={props?.id}
						companyName={companyname}
						rolename={props?.rolename}
						navigation={navigation}
						JobPostData={JobPostData}
						prefferedSalary={props?.package}
						minExperience={props?.minExperienceRequired}
						companyQrewId  ={props.companyQrewId}
						maxExpeirence={props?.maxExperienceRequired}
						SelectedTab={'LevelAlpha'}
						levelData={levelAlpha.length == 0 ? [] : levelAlpha}
					/>
				)}
				{screen === 'Candidates' &&
				tab === 12 &&
				typeofuser == 'QrusibleSuperAdmin' && (
					<LevelQFactor
						preferenceId={props?.id}
						companyName={companyname}
						rolename={props?.rolename}
						navigation={navigation}
						JobPostData={JobPostData}
						prefferedSalary={props?.package}
						minExperience={props?.minExperienceRequired}
						companyQrewId  ={props.companyQrewId}
						maxExpeirence={props?.maxExperienceRequired}
						SelectedTab={'Q-Factor'}
						levelData={levelQFactor.length == 0 ? [] : levelQFactor}
					/>
				)}
				{screen === 'Recommended' &&
				tab === 13 &&
				typeofuser == 'QrusibleSuperAdmin' && (
					<LevelRecommended
						preferenceId={props?.id}
						companyName={companyname}
						rolename={props?.rolename}
						navigation={navigation}
						JobPostData={JobPostData}
						prefferedSalary={props?.package}
						minExperience={props?.minExperienceRequired}
						companyQrewId  ={props.companyQrewId}
						maxExpeirence={props?.maxExperienceRequired}
						SelectedTab={'Q-Factor'}
						levelData={levelQFactor.length == 0 ? [] : levelQFactor}
					/>
				)}
		</Box>
	{/* )}  */}
		</>
	)}
		</>
		
		
	)
}

export default Preferencescontainer
