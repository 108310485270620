import React, { useState } from 'react'
import { Dimensions } from 'react-native'
import { Box, Text, Pressable, FlatList, HStack, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from '@gluestack-ui/themed'
import useUserStore from '../../Userstore/UserStore'
import ModalComponent from '../../newComponents/ModalComponent'
import ButtonComponent from '../../newComponents/ButtonComponent'
import LabelWithInputComponent from '../../newComponents/LabelWithInputComponent'
import { showToast } from '../../components/ErrorToast'
import { UpdateDeployedQrew } from '../../HeplerFunctions/CandidateQrewInitiativeQuery'
import { createEventContractTableOnContractStatus, CreateDeployedQrewHistory } from '../../HeplerFunctions/CandidateQrewHelperQuery'
import { getNewprofileByUserId } from '../../graphql/custom-queries'
import { useEffect, useCallback } from 'react'
// import UpdateContractExtendStatus from '../../graphql/mutations/UpdateContractExtendStatus'
// import { UpdateContractExtendStatus } from '../../graphql/mutations'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
import { API } from 'aws-amplify'



export default function CandidateQrewHomeScreen({navigation}) {
	
    const userProfile = useUserStore((state) => state.profile)
	const userId = useUserStore((state) => state.userId)
    const [showApprovalModal, setShowApprovalModal] = useState('')
	const [showApprovalModalRejection, setShowApprovalModalRejection] = useState(false)
	const [showApprovalModalReview, setShowApprovalModalReview] = useState(false)
	const [reasonForRejection, setReasonForRejection] = useState('')
	const [reasonForReview, setReasonForReview] = useState('')
	const [ContractStatusLoading, setContractStatusLoading] = useState(false)
	const setUserProfile = useUserStore((state) => state.setUserProfile)
    const [selectedItem, setSelectedItem] = useState(null)
	const FetchDeployedQrew = async() =>{
		
	}

	useEffect(() => {
		
	}, [])
	const getprofile = useCallback(async () => {
		try {
			const response = await API.graphql({
				query: getNewprofileByUserId,
				variables: { UserId: userId },
			})
			
			const profileData = response.data.getNewprofileByUserId?.items[0]
			
		
			setUserProfile(profileData)
			

		
		} catch (error) {
			console.log(error)
			if (profile?.length !== 0) {
				console.error(error)
			} else {
				setProfileId(null)
				console.error(error)
				if (savedurl.includes('JobDetails')) {
					gotoJobPage()
				} else {
					navigation.push('Onboarding', { stepState: 0 })
					return
				}
			}
		}
	}, [])
	const CreateDeployedQrewHistoryOnAccepted = async(status) => {
	
		try {
			
			
			


			const input = {
				companyQrewId: showApprovalModal.companyQrewId,
				businessProfileId:  showApprovalModal.businessProfileId,
				finalCTCQrew:  showApprovalModal.finalCTCQrew,
				candidateprofileId:  showApprovalModal.candidateprofileId,
				qrewPosition:  showApprovalModal.qrewPosition,

				preferencesId:  showApprovalModal.preferencesId,

				recommededId:  showApprovalModal.recommededId,
				joiningDate:  showApprovalModal.joiningDate,
				endingDate:  showApprovalModal.endingDate,
				NDAkey:  showApprovalModal.NDAkey,
				Contractkey:  showApprovalModal.Contractkey,
				KYCdocumentkey:  showApprovalModal.KYCdocumentkey,
				WorkingOnit:  showApprovalModal.WorkingOnit,
				EndContract:  showApprovalModal.EndContract,
				ContractEndingDate : showApprovalModal?.ContractEndingDate ,
				CandidateAcceptanceStatus :  showApprovalModal.CandidateAcceptanceStatus,
				CandidateContractRejectionReason:  showApprovalModal.CandidateContractRejectionReason,
				ExtensionDurationInMonths :  showApprovalModal.ExtensionDurationInMonths,
				ExtensionDate :  showApprovalModal?.ExtensionDate,
				ExtendingContract :  showApprovalModal.ExtendingContract,
				NewContractCTC :  showApprovalModal.NewContractCTC,
				ContractEndedBy :  showApprovalModal.ContractEndedBy,
				ContractPausedBy :  showApprovalModal.ContractPausedBy,
				JobApplicationsId:  showApprovalModal.JobApplicationsId,
				deployedQrewId :  showApprovalModal.deployedQrewId,
				CandidateAcceptanceStatus:  showApprovalModal.CandidateAcceptanceStatus,
				CandidateContractRejectionReason :  showApprovalModal.CandidateContractRejectionReason,
				deployedQrewId : showApprovalModal.id
			}
			
			if (status == 'Accepted'){
				
				const response = await CreateDeployedQrewHistory(input)
				return response
				
				
				// setLoadingButton(false)
				
			
				
			
		}
		
		}catch(error){
			console.log(error)
		}
		
	}

	const UpdateDeployedQrewStatus = async(status) => {
		console.log(status)
		setContractStatusLoading(true)
		
		try {
			
			
			
			
			const inputRejectNewContract = {
				id: showApprovalModal.id,
				CandidateAcceptanceStatus: status,
				CandidateContractRejectionReason : reasonForRejection
			}
			
			if (status == 'Rejected'){
				if(reasonForRejection){
				const response = await UpdateDeployedQrew(inputRejectNewContract)
				console.log(response)
				showToast({
					message : 'Contract Rejected Successfully!',
					type : 'success'
				})
				setShowApprovalModal('')
				// setLoadingButton(false)
				setContractStatusLoading(false)
				getprofile()
				ContractEventStatusCapture(inputRejectNewContract)
			}
			else{
				showToast({
					message : 'Please provide the reason for rejection!',
					type : 'error'
				})
			}
				
			
		}
		const inputReviewNewContract = {
			id: showApprovalModal.id,
			CandidateAcceptanceStatus: status,
			CandidateContractNegotiatingReason: reasonForReview
		}
		if (status == 'Negotiable'){
			if(reasonForReview){
			const response = await UpdateDeployedQrew(inputReviewNewContract)
			console.log(response)
			showToast({
				message : 'Contract Sent For Review Successfully!',
				type : 'success'
			})
			setShowApprovalModal('')
			// setLoadingButton(false)
			setContractStatusLoading(false)
			getprofile()
			ContractEventStatusCapture(inputReviewNewContract)
		}
		else{
			showToast({
				message : 'Please provide the reason for review!',
				type : 'error'
			})
		}
			
		
	}

		const inputAcceptNewContract = {
			id: showApprovalModal.id,
			CandidateAcceptanceStatus: status,
			ExtendingContract : false,
				ExtensionDurationInMonths : null,
				ExtensionDate : null,
				finalCTCQrew : showApprovalModal.NewContractCTC,
				ContractEndingDate : showApprovalModal.ExtensionDate

			
		

		}
		if (status == 'Accepted'){
				
			const CreatingOldHistory = await CreateDeployedQrewHistoryOnAccepted(status)
			console.log(CreatingOldHistory)
			if(CreatingOldHistory) {
				const response = await UpdateDeployedQrew(inputAcceptNewContract)
				console.log(response)
			}
			
	
			
			showToast({
				message : 'Contract Accepted Successfully!',
				type : 'success'
			})
			setShowApprovalModal('')
			setContractStatusLoading(false)
			getprofile()
			ContractEventStatusCapture(inputAcceptNewContract)
		
		
		
		// setLoadingButton(false)
		
	}
		}catch(error){
			console.log(error)
		}
		
	}
	const ContractEventStatusCapture = async (input) => {
		try{
			input = {		
				EventNameTableKeyId: input.id,
				EventName : "ContractRenewalEvent",
				EventAttributes : JSON.stringify(input),
				EventCreatedbyUserCognitoId : userId,
			
	

			}
			const response = await createEventContractTableOnContractStatus(input)
			
		}
		catch (error){
			console.log(error)
		}
	}
	const renderApprovalBodyConfirmationExtend = () => {
			
		return (
			<Box h={   440 } padding={20} alignItems='center'  >
				
				
			 
				
			
			
				<Text
					marginTop={50}
					fontSize={14}
					fontWeight="600"
					fontFamily="$fontHeading"
				>
				Review the client's proposed contract terms and conditions.
				</Text>
				<Text
					marginTop={50}
					fontSize={14}
					fontWeight="600"
					fontFamily="$fontHeading"
				>
			Proposed Duration :  {showApprovalModal?.ExtensionDurationInMonths} months
				</Text>
				<Text
					marginTop={50}
					fontSize={14}
					fontWeight="600"
					fontFamily="$fontHeading"
				>
			Proposed New Ending Date :  {showApprovalModal?.ExtensionDate}
				</Text>
				
				<Text
					marginTop={50}
					fontSize={14}
					fontWeight="600"
					fontFamily="$fontHeading"
				>
			Proposed Contract CTC :  {showApprovalModal?.NewContractCTC} Rs
				</Text>
				

				
			<Box>
	
			
			</Box>
				<HStack justifyContent="flex-end" gap={10} marginTop={20}>
				<ButtonComponent
						text={'Accept'}
						color={'green'}
						borderColor={'green'}
						loading={ContractStatusLoading}
						disabled={ContractStatusLoading}
						spinnerColor={'white'}
						onPress={() => {
							const flag = 'Accepted'
							UpdateDeployedQrewStatus(flag)
							
							
						}}
						width={120}
					/>
					<ButtonComponent
						text={'Review'}
						onPress={() => {
							setShowApprovalModalReview(true)
							// setShowApprovalModal('')
							
						}}
						loading={ContractStatusLoading}
						disabled={ContractStatusLoading}
						spinnerColor={'white'}
						width={120}
						borderColor={'$primary600'}
						color={'$primary600'}
						
					/>
					<ButtonComponent
						text={'Reject'}
						onPress={() => {
							setShowApprovalModalRejection(true)
							// setShowApprovalModal('')
							
						}}
						loading={ContractStatusLoading}
						disabled={ContractStatusLoading}
						spinnerColor={'white'}
						width={120}
						borderColor={'red'}
						color={'red'}
						
					/>
				</HStack>
			</Box>
		)
	}
	const renderApprovalBodyForRejection = () => {
			
		return (
			<Box h={   280 } padding={20} alignItems='center'  >
				
				
			 
				
			
			
			

				<LabelWithInputComponent
								label={'Reason for Rejection'}
								placeholder={'Enter Reason for Rejection'}
								height={100}
								multiline={true}
								textArea={true}
								heightTextArea={100}
								setValueChange={(text) => {
									setReasonForRejection(text)
								}}
							
								important={true}
								value={reasonForRejection}
							/>

				
			<Box>
	
			
			</Box>
				<HStack justifyContent="flex-end" gap={10} marginTop={20}>
				<ButtonComponent
						text={'Submit'}
						color={'green'}
						borderColor={'green'}
						
						onPress={() => {
						const flag = "Rejected"
						UpdateDeployedQrewStatus(flag)
							setShowApprovalModalRejection(false)
							
						}}
						width={120}
					/>
					
					<ButtonComponent
						text={'Cancel'}
						onPress={() => {
							setReasonForRejection('')
							setShowApprovalModalRejection(false)
							
						}}
						width={120}
						borderColor={'red'}
						color={'red'}
						
					/>
				</HStack>
			</Box>
		)
	}
	const renderApprovalBodyForReview = () => {
			
		return (
			<Box h={   280 } padding={20} alignItems='center'  >
				
				
			 
				
			
			
			

				<LabelWithInputComponent
								label={'Reason for Review'}
								placeholder={'Enter Reason for Review'}
								height={100}
								multiline={true}
								textArea={true}
								heightTextArea={100}
								setValueChange={(text) => {
									setReasonForReview(text)
								}}
							
								important={true}
								value={reasonForReview}
							/>

				
			<Box>
	
			
			</Box>
				<HStack justifyContent="flex-end" gap={10} marginTop={20}>
				<ButtonComponent
						text={'Submit'}
						color={'green'}
						borderColor={'green'}
						
						onPress={() => {
						const flag = "Negotiable"
						UpdateDeployedQrewStatus(flag)
							setShowApprovalModalReview(false)
							
						}}
						width={120}
					/>
					
					<ButtonComponent
						text={'Cancel'}
						onPress={() => {
							setReasonForReview('')
							setShowApprovalModalReview(false)
							
						}}
						width={120}
						borderColor={'red'}
						color={'red'}
						
					/>
				</HStack>
			</Box>
		)
	}
	
    const updateContractExtendStatus = async (id, status) => {
        try {
            const response = await API.graphql({
                // query: UpdateContractExtendStatus,
                variables: {
                    input: {
                        id: id,
                        contractExtendStatus: status,
                    },
                },
            })
            console.log('Contract extension status updated:', response)
            // You may want to update the local state or refetch data here
        } catch (error) {
            console.error('Error updating contract extension status:', error)
        }
    }

    const renderQrews = ({item}) => {
		
        return (
            <Box
                bg="$primary0"
                w={'100%'}
                borderRadius={6}
                padding={windowWidth > 480 ? 20 : 10}
                borderWidth={2}
                softShadow="5"
                borderColor="$borderOrange100"
                mb={4}
            >

				<ModalComponent
					isOpen={showApprovalModal.id === item.id}
					onClose={() => {
						setShowApprovalModal('')
					}}
					heading={`Contractor Review`}
					
					renderBody={renderApprovalBodyConfirmationExtend}
				/>

				<ModalComponent
					isOpen={showApprovalModalRejection}
					onClose={() => {
						setShowApprovalModalRejection(false)
					}}
					heading={`Please provide the reason for rejection`}
					
					renderBody={renderApprovalBodyForRejection}
				/>
				<ModalComponent
					isOpen={showApprovalModalReview}
					onClose={() => {
						setShowApprovalModalReview(false)
					}}
					heading={`Please provide the reason for review`}
					
					renderBody={renderApprovalBodyForReview}
				/>
				<HStack justifyContent="space-between" alignItems="center">
				<Box>
				
                    <Text
                        fontFamily="$fontContent"
                        color="$fontGray"
                        fontSize={11}
                        fontWeight="600"
                    >
                       Qrew Name
                    </Text>
                    <Pressable onPress={() => navigation.navigate('QrewObjectiveScreen', {qrewId: item.companyQrewId})}>
                        <Text
                            fontFamily="$fontHeading"
                            fontSize={'$fontSize15'}
                            flexWrap="wrap"
                            fontWeight="700"
                        >
                            {item.companyQrew?.qrewname}
                        </Text>
                    </Pressable>
					{item?.CandidateAcceptanceStatus == 'Pending' && (
                    <Pressable
                        onPress={() => {
                        
                            setShowApprovalModal(item)
                        }}
                        mt={4}
                    >
                        <Text color="$primary500">Take Action on Contract Extension</Text>
                    </Pressable>
                )}
                </Box>
              <Box>
			  <Pressable onPress={() => navigation.navigate('QrewObjectiveScreen', {qrewId: item.companyQrewId})}>
			  <Text
							flexWrap="wrap"
							fontFamily="$fontHeading"
							color="$primary0"
							fontSize={
								windowWidth > 480
									? '$fontContent'
									: '$fontContentMobile'
							}
							fontWeight="$bold"
							paddingHorizontal={10}
							paddingVertical={3}
							backgroundColor="$primary500"
							borderRadius={6}
						>
							Add Initiatives
						</Text>
					
</Pressable>
			  
			  </Box>
                	
				</HStack>
               
               
            </Box>
        )
    }

    return (
		<Box
		bg="$backgroundGray"
		minHeight={windowHeight - 62}
		marginTop={62}
		marginBottom={windowWidth > 480 ? null : 50}
		paddingHorizontal={windowWidth > 480 ? 100 : 5}
		paddingVertical={windowWidth > 480 ? 20 : 5}
		paddingBottom={windowWidth > 480 ? null : 50}
	>
            <FlatList
                data={userProfile?.deployedforqrews?.items}
                renderItem={renderQrews}
                keyExtractor={(item, index) => index.toString()}
            />
        </Box>
    )
}