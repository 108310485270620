import {
	StyleSheet,
	View,
	Dimensions,
	ScrollView,
	ActivityIndicator,
	TouchableOpacity,
} from 'react-native'
import React, { useEffect, useState } from 'react'
import ButtonComponent from '../newComponents/ButtonComponent'
import HIdidetails from '../newComponents/HIdidetails'
import { Drawer, MobileHeader } from '../newComponents/header/Header'
import { showToast } from '../components/ErrorToast'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
import { getNewhidi } from '../graphql/custom-queries'
import { Pressable } from '@gluestack-ui/themed'
import { updateConvoOnHidi } from '../graphql/custom-mutations'
import { createConvoOnHidi } from '../graphql/custom-mutations'
import { SendEmailtoCandidates } from '../graphql/queries'
import { API } from 'aws-amplify'
import Editor from 'react-medium-editor'
import useUserStore from '../Userstore/UserStore'
import LabelWithInputComponent from '../newComponents/LabelWithInputComponent'
import ModalComponent from '../newComponents/ModalComponent'
import { Box, FlatList, Button, Text } from '@gluestack-ui/themed'
import SpinnerComponent from '../newComponents/SpinnerComponent'
import ConvoOnhidi from '../newComponents/ConvoOnhidi'
export default function NewHIdi({ route, navigation }) {
	const [loading, setloading] = useState(true)
	const [createloading, setCreateloading] = useState(false)
	const typeOfUser = useUserStore((state) => state.group)
	const [hidiData, setHidiData] = useState('')
	const [profileData, setProfileData] = useState('')
	const [showModal, setShowModal] = useState(false)
	const [showUserModal, setShowUserModal] = useState(false)
	const [ConvoOnHidiItem, setConvoOnHidiItem] = useState('')
	const [ConvoOnHidiData, setConvoOnHidiData] = useState([])
	const [bodytext, setBodyText] = useState('')
	const [subject, setSubject] = useState('')
	const HidiId = route.params.hidiId
	const CompanyProfileId = useUserStore((state) => state.companyProfileId)
	const companyname = useUserStore((state) => state.companyName)
	const CompanyUserProfileId = useUserStore(
		(state) => state.companyuserprofileId
	)
	const userId = useUserStore((state) => state.userId)

	async function CreateQuestionsOnConvo() {
		setCreateloading(true)
		const CreateUserInput = {
			Question: subject,
			Answer: '',
			profileId: profileData.id,
			UserId: profileData.UserId,
			PreferenceId: route?.params?.PreferenceId,
			companyProfileId: CompanyProfileId,
			companyUserProfileId: CompanyUserProfileId,
			HidiId: HidiId,
		}

		API.graphql({
			query: createConvoOnHidi,
			variables: { input: CreateUserInput },
		})
			.then((res) => {
				setConvoOnHidiData(
					res.data.createConvoOnHidi.NewHidi.ConvoOnHidi.items
				)
				setSubject('')
				setCreateloading(false)
				setShowModal(false)
				CallEmailLambda()
			})
			.catch((err) => {
				console.log(err)
			})
	}
	const CallEmailLambda = async () => {
		try {
			await API.graphql({
				query: SendEmailtoCandidates,
				variables: {
					EmailBody: 'Question Asked ',
					Emails: companyname
						? [profileData?.Email]
						: [ConvoOnHidiItem?.companyUser.email],
					EmailSubject: companyname
						? 'An employer asked you a question'
						: 'The question you asked was answered',
					Link: companyname
						? `https://app.qrusible.com/hidi/${hidiData?.id}`
						: `https://app.qrusible.com/hidi/${hidiData?.id}/${ConvoOnHidiItem?.PreferenceId}`,
					Question: companyname ? subject : ConvoOnHidiItem.Question,
					Answer: companyname ? '' : subject,
					EmployerName: companyname
						? companyname
						: ConvoOnHidiItem.CompanyProfile?.name,
					CandidateName: hidiData?.profile?.name,
					TypeOfMailenum: 'QuestionAnswer',
				},
			}).then((response) => {
				setCreateloading(false)
				console.log('succeeded')
			})
		} catch (error) {
			console.log(error)

			setValues([])

			return console.log(error)
		}
	}

	async function updateQuestionsOnConvo() {
		setCreateloading(true)
		const CreateUserInput = {
			id: ConvoOnHidiItem.id,
			Answer: subject,
		}
		API.graphql({
			query: updateConvoOnHidi,
			variables: { input: CreateUserInput },
		})
			.then((res) => {
				setConvoOnHidiData(
					res.data.updateConvoOnHidi.NewHidi.ConvoOnHidi.items
				)
				setCreateloading(false)
				setShowUserModal(false)
				setConvoOnHidiItem('')
				setSubject('')
				CallEmailLambda()
			})
			.catch((err) => {
				console.log(err)
			})
	}
	const renderItem = ({ item }) => {
		return (
			<Box>
				<ConvoOnhidi
					routeParams={route.params}
					screen={route?.params?.screen}
					navigation={navigation}
					props={hidiData}
					Props={item}
					onPress={() => {
						setConvoOnHidiItem(item)
						setShowUserModal(true)
					}}
					onPressEdit={() => {
						setSubject(item.Answer)
						setConvoOnHidiItem(item)
						setShowUserModal(true)
					}}
				/>
			</Box>
		)
	}
	const DualFlatlistComponent = () => {
		const nullAnswers = ConvoOnHidiData?.filter(
			(obj) => obj.hasOwnProperty('Answer') && obj.Answer == ''
		)
		const nonNullAnswers = ConvoOnHidiData?.filter(
			(obj) => !obj.hasOwnProperty('Answer') || obj.Answer !== ''
		)

		return (
			<Box paddingTop={20}>
				{userId === profileData?.UserId ||
				(nullAnswers &&
					nullAnswers[0]?.PreferenceId ==
						route?.params?.PreferenceId) ? (
					<>
						{nullAnswers?.length > 0 && (
							<Box>
								<Text
									fontFamily="$fontHeading"
									fontSize={
										windowWidth > 480
											? '$fontContent'
											: '$fontContentMobile'
									}
									fontWeight="900"
									color={'$grey100'}
								>
									Unanswered Questions -{' '}
									{`(${nullAnswers?.length})`}
								</Text>

								<FlatList
									data={nullAnswers}
									renderItem={renderItem}
									keyExtractor={(item) => item.id}
								/>
							</Box>
						)}
					</>
				) : null}
				{nonNullAnswers?.length > 0 && (
					<Box>
						<Text
							fontFamily="$fontHeading"
							fontSize={
								windowWidth > 480
									? '$fontContent'
									: '$fontContentMobile'
							}
							fontWeight="900"
							color={'$grey100'}
						>
							Answered Questions - {`(${nonNullAnswers?.length})`}
						</Text>
						<FlatList
							data={nonNullAnswers}
							renderItem={renderItem}
							keyExtractor={(item) => item.id}
						/>
					</Box>
				)}
			</Box>
		)
	}
	const renderModalBody = () => {
		return (
			<Box>
				<LabelWithInputComponent
					height={50}
					editable={!createloading}
					// placeholder={'Enter the Subject of mail'}
					value={subject}
					setValueChange={(text) => setSubject(text)}
				/>

				<Box alignSelf="center" padding={5} marginTop={10}>
					<ButtonComponent
						text={'Ask Question'}
						loading={createloading}
						onPress={() => {
							CreateQuestionsOnConvo()
						}}
						width={windowWidth > 480 ? 200 : 100}
					/>
				</Box>
			</Box>
		)
	}
	const renderModalUserBody = () => {
		return (
			<Box>
				<LabelWithInputComponent
					// placeholder={'Enter the Subject of mail'}
					height={100}
					editable={!createloading}
					value={subject}
					setValueChange={(text) => setSubject(text)}
				/>

				<Box alignSelf="center" padding={5} marginTop={10}>
					<ButtonComponent
						text={'Answer'}
						loading={createloading}
						onPress={() => {
							updateQuestionsOnConvo()
						}}
						width={windowWidth > 480 ? 200 : 100}
					/>
				</Box>
			</Box>
		)
	}
	async function getnewhididata() {
		await API.graphql({
			query: getNewhidi,
			authMode: 'API_KEY',
			variables: {
				id: HidiId,
			},
		})
			.then((res) => {
				setHidiData(res.data.getNewhidi)
				setConvoOnHidiData(res.data.getNewhidi?.ConvoOnHidi.items)
				setProfileData(res?.data?.getNewhidi?.profile)
				setloading(false)
				// getUserData()
			})
			.catch((err) => {
				console.log('founerror', err)
			})
	}

	useEffect(() => {
		getnewhididata()
	}, [HidiId])

	return (
		<Box bg="$backgroundGray">
			{typeOfUser === 'companys' && (
				<MobileHeader
					props={'Candidate Profile'}
					navigation={navigation}
				/>
			)}
			<ModalComponent
				isOpen={showModal}
				onClose={() => {
					setShowModal(false)
				}}
				heading={'Ask a Question'}
				renderBody={renderModalBody}
			/>
			<ModalComponent
				isOpen={showUserModal}
				onClose={() => {
					setShowUserModal(false)
				}}
				heading={'Answer'}
				renderBody={renderModalUserBody}
			/>
			<Box
				bg="$backgroundGray"
				minHeight={windowHeight - 62}
				alignSelf="center"
				marginTop={62}
				w={
					typeOfUser === 'candidates'
						? '100%'
						: windowWidth > 480
						? '70%'
						: '100%'
				}
				marginBottom={windowWidth > 480 ? null : 50}
				paddingHorizontal={windowWidth > 480 ? 100 : 5}
				paddingVertical={windowWidth > 480 ? 20 : 5}
				paddingBottom={windowWidth > 480 ? null : 50}
			>
				{loading ? (
					<SpinnerComponent />
				) : (
					<Box
						width="100%"
						justifyContent="center"
						alignSelf="center"
					>
						<HIdidetails
							props={hidiData}
							profileData={profileData}
							routeParams={route.params}
							screen={route?.params?.screen}
							navigation={navigation}
							hideEdit={typeOfUser === 'companys' ? true : false}
							hidiSkills={
								hidiData?.ConnectedCapability?.items[0]
									?.Capability?.JoinedSkills?.items
							}
						/>
						{CompanyProfileId &&
							route?.params?.PreferenceId &&
							(typeOfUser === 'QrusibleSuperAdmin' ||
								typeOfUser === 'companys') && (
								<Pressable
									width={'100%'}
									justifyContent="center"
									marginVertical={10}
									onPress={() => {
										setShowModal(true)
									}}
								>
									<Box
										alignItems="center"
										bg="$primary500"
										borderRadius={20}
										paddingVertical={15}
										// borderWidth={2}
										marginTop={20}
									>
										<Text
											color="$primary50"
											fontFamily="$fontHeading"
											fontSize={
												windowWidth > 480
													? '$fontHeading'
													: '$fontSize16'
											}
											fontWeight="700"
										>
											Ask a Question
										</Text>
									</Box>
								</Pressable>
							)}
						<DualFlatlistComponent />
					</Box>
				)}
			</Box>
		</Box>
	)
}
