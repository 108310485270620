import { Box, Progress, ProgressFilledTrack } from '@gluestack-ui/themed'
import React, { useEffect, useState } from 'react'
import { Dimensions } from 'react-native'
import CongratsLevelCrossed from '../../../newComponents/pointsComponents/CongratsLevelCrossed'
import ProgressBar from '../../../newComponents/ProgressBar'
const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

const TopProgressBar = ({
	levelOne,
	levelTwo,
	levelThree,
	levelFour,
	jobPostId,
	setIntroVidModal,
	videoUploadedStatus,
	progressLevelOne,
	progressLevelTwo,
	progressLevelThree,
	progressLevelFour,
	btnText,
	congratsLevel1Modal,
	congratsLevel2Modal,
	congratsLevel3Modal,
	congratsLevel4Modal,
	setCongratsLevel1Modal,
	setCongratsLevel2Modal,
	setCongratsLevel3Modal,
	setCongratsLevel4Modal,
	companyPrefId,
	setConsentModal,
	consentToShare,
}) => {
	useEffect(() => {
		const levels = [
			{
				key: 'LevelOne',
				level: progressLevelOne,
				levelBoolean: levelOne,
				nextLevel: progressLevelTwo,
				modalSetter: setCongratsLevel1Modal,
			},
			{
				key: 'LevelTwo',
				level: progressLevelTwo,
				levelBoolean: levelTwo,
				nextLevel: progressLevelThree,
				modalSetter: setCongratsLevel2Modal,
			},
			{
				key: 'LevelThree',
				level: progressLevelThree,
				levelBoolean: levelThree,
				nextLevel: progressLevelFour,
				modalSetter: setCongratsLevel3Modal,
			},
			{
				key: 'LevelFour',
				levelBoolean: levelFour,
				level: progressLevelFour,
				nextLevel: null,
				modalSetter: setCongratsLevel4Modal,
			},
		]

		levels.forEach(
			({ key, level, nextLevel, levelBoolean, modalSetter }) => {
				const hasTriggered = localStorage.getItem(`${key}_${jobPostId}`)

				if (
					level === 100 &&
					nextLevel < 100 &&
					levelBoolean &&
					!hasTriggered
				) {
					if (!videoUploadedStatus) {
						setIntroVidModal(true)
					} else if (!consentToShare && key !== 'LevelOne') {
						setConsentModal(true)
					} else {
						modalSetter(true)
						setIntroVidModal(false)
						setConsentModal(false)
					}
					localStorage.setItem(`${key}_${jobPostId}`, 'true')
				}
			}
		)
	}, [
		progressLevelOne,
		progressLevelTwo,
		progressLevelThree,
		progressLevelFour,
		levelOne,
		levelTwo,
		levelThree,
		levelFour,
		setCongratsLevel1Modal,
		setCongratsLevel2Modal,
		setCongratsLevel3Modal,
		setCongratsLevel4Modal,
		jobPostId,
		videoUploadedStatus,
	])

	return (
		<Box>
			{congratsLevel1Modal && (
				<CongratsLevelCrossed
					isOpen={congratsLevel1Modal}
					onClose={() => {
						setCongratsLevel1Modal(false)
					}}
					level={1}
					btnText={btnText}
					companyPrefId={companyPrefId}
				/>
			)}
			{congratsLevel2Modal && (
				<CongratsLevelCrossed
					isOpen={congratsLevel2Modal}
					onClose={() => {
						setCongratsLevel2Modal(false)
					}}
					level={2}
					btnText={btnText}
					companyPrefId={companyPrefId}
				/>
			)}
			{congratsLevel3Modal && (
				<CongratsLevelCrossed
					isOpen={congratsLevel3Modal}
					onClose={() => {
						setCongratsLevel3Modal(false)
					}}
					level={3}
					btnText={btnText}
					companyPrefId={companyPrefId}
				/>
			)}
			{congratsLevel4Modal && (
				<CongratsLevelCrossed
					isOpen={congratsLevel4Modal}
					onClose={() => {
						setCongratsLevel4Modal(false)
					}}
					level={4}
					btnText={btnText}
					companyPrefId={companyPrefId}
				/>
			)}
			<ProgressBar
				progress={
					progressLevelOne < 99
						? progressLevelOne
						: progressLevelTwo < 99
						? progressLevelTwo
						: progressLevelThree < 99
						? progressLevelThree
						: progressLevelFour < 99
						? progressLevelFour
						: 100
				}
				bgColor={
					congratsLevel1Modal
						? '#2FBEC8'
						: congratsLevel2Modal
						? '#53B54E'
						: congratsLevel3Modal
						? '#EDCF11'
						: congratsLevel4Modal
						? '#EB5353'
						: progressLevelOne < 99
						? '#2FBEC8'
						: progressLevelTwo < 99
						? '#53B54E'
						: progressLevelThree < 99
						? '#EDCF11'
						: '#EB5353'
				}
				width={windowWidth > 480 ? 350 : windowWidth * 0.9}
				animationType={'spring'}
			/>
		</Box>
	)
}

export default TopProgressBar
