import React from 'react'
import { View, Text } from 'react-native'
import { toast } from 'react-toastify'

const CustomToast = ({ text }) => {
	const slicedItems = text.includes('@') ? [text] : text.split('.')

	return (
		<>
			<View>
				{slicedItems.map((item, index) => (
					<Text
						key={index}
						style={{
							color: 'black',
							fontWeight: '500',
							fontSize: 16,
						}}
					>
						{item.trim()}
					</Text>
				))}
			</View>
		</>
	)
}

export const showToast = ({ message, type }) => {
	{
		type === 'success'
			? toast.success(<CustomToast text={message} />, {
					position: 'top-right',
					// top: 60,
					bottom: 20,
					autoClose: 10000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
					style: { zIndex: 10001 },
			  })
			: toast.warn(<CustomToast text={message} />, {
					position: 'top-right',
					// top: 60,
					bottom: 20,
					autoClose: 10000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
					style: { zIndex: 10001 },
			  })
	}
}
