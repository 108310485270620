import React, {useEffect, useRef} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableHighlight,
  RNrest,
  SafeAreaView,
  ActivityIndicator,
  Modal,
  Platform
} from 'react-native';
import {
  FormControl,
  Input,
  TextArea,
  Button,
  HStack,
  VStack,
  KeyboardAvoidingView,
} from 'native-base';
import {
  StackActions,
  CommonActions,
  useFocusEffect,
} from '@react-navigation/native';
import {useState, useCallback} from 'react';

import {HeaderWithBack} from '../components/Header';
import {Auth} from 'aws-amplify';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {API, Storage} from 'aws-amplify';
import * as mutations from '../graphql/mutations';

const DeleteModal = ({modalVisible, setModalVisible, user}) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [requestStatus, setRequestStatus] = useState(false);
  const [formVisible, setFormVisible] = useState(true);

  // async function submitRequest({navigation}) {
  //   const input = {
  //     description: description,
  //     title: title,
  //     userId: user.id,
  //   };
  //   await API.graphql({
  //     query: mutations.createDeleteAccountRequest,
  //     variables: {input: input},
  //   });

  //   setRequestStatus(true);
  //   setTitle('');
  //   setDescription('');
  //   setFormVisible(false);
  // }

  function closeModal() {
    setModalVisible(false);
  }

  return (
    <Modal
      style={styles.modal}
      animationType="slide"
      onRequestClose={closeModal}
      transparent
      visible={modalVisible}>
      <View style={styles.modalContainer}>
        <KeyboardAvoidingView
          h={{
            //base: "520px",
            lg: 'auto',
          }}
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
          <View style={styles.modalInnerContainer}>
            <Text bold fontSize="xl" mb="4">
              Sorry to see you leave.
            </Text>
            {formVisible ? (
              <>
                <FormControl mb="2">
                  <FormControl.Label>Reason for deletion</FormControl.Label>
                  <Input onChangeText={setTitle} />
                  <FormControl.HelperText>
                    Eg: Not what I expected
                  </FormControl.HelperText>
                </FormControl>
                <FormControl mb="5">
                  <FormControl.Label>
                    Any feedback you want to give us?
                  </FormControl.Label>
                  <TextArea
                    onChangeText={setDescription}
                    h={40}
                    placeholder="Anything you want to tell us"
                    w="100%"
                  />
                </FormControl>
                <HStack style={styles.buttons}>
                  <Button
                    disabled={title === '' ? true : false}
                    size="md"
                    variant="solid"
                    // onPress={submitRequest}
                    >
                    Delete account
                  </Button>
                  <Button
                    size="md"
                    variant="solid"
                    colorScheme="secondary"
                    onPress={closeModal}>
                    Cancel
                  </Button>
                </HStack>
              </>
            ) : (
              <View>
                <Text>Your account will be delete in the next 24 hrs</Text>
                <Button
                  size="md"
                  variant="solid"
                  colorScheme="secondary"
                  onPress={closeModal}>
                  Done
                </Button>
              </View>
            )}
          </View>
        </KeyboardAvoidingView>
      </View>
    </Modal>
  );
};
export default function ProfileDetails({navigation}) {
  const [modalVisible, setModalVisible] = useState(false);

  const [user, setUser] = useState({
    fullName: 'Amit Tekwani',
    company: 'Qrusible',
    designation: 'Full stack',
    bio: 'hello',
  });
  const [loading, setLoading] = useState(false);
  const renderCounter = useRef(0);
  
  renderCounter.current = renderCounter.current + 1;

  // if (props.route.params == true && renderCounter.current <= 4) {
  //   getUserData();
  // }
  // if (props.route.params == false) {
  //   renderCounter.current = 0;
  // }
  
  async function getUserData() {
    Auth.currentAuthenticatedUser()
      .then(res => {
        setUser({
          id: res?.attributes?.sub,
          designation: res?.attributes['custom:Designation'],
          company: res?.attributes['custom:Company'],
          fullName: res?.attributes['custom:Fullname'],
          bio: res?.attributes['custom:Bio'],
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  // useEffect(() => {
  //   getUserData();
  // }, []);

  async function signOut() {
    setLoading(true);
    try {
      await Auth.signOut();
      await AsyncStorage.removeItem('userdata');
      // await AsyncStorage.removeItem('NotFirstTime');
      
      console.log('signed out');
      // RNRestart.Restart();
      // navigation.dispatch(
      //   StackActions.replace('Login')
      // );
      setLoading(false);
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
  return (
    <SafeAreaView style={{flex: 1}}>
      <View style={styles.container}>
        {/* <HeaderWithBack title={'Profile Details'} /> */}
        <View style={styles.header}>
          <View style={styles.headerContent}>
            {/* <HStack style={{alignItems:'center', justifyContent:'center'}}> */}
          
           
           
            {/* <VStack style={{paddingLeft:10}}> */}
            <Text style={styles.name}>{user.fullName} </Text>
            {/* <Text style={styles.userInfo}>{user.company} </Text> */}
            <Text style={styles.userInfo}>{user.bio} </Text>
            {user.designation ? (
              <Text style={styles.userInfo}>
                {user.designation} @ {user.company}
              </Text>
            ) : null}

            {/* </VStack> */}
            {/* </HStack> */}
            <TouchableHighlight
              onPress={() => navigation.navigate('EditProfile')}
              style={styles.buttonContainer}>
              <Text style={styles.button}>Edit Profile</Text>
            </TouchableHighlight>
            <TouchableHighlight
              onPress={() => navigation.navigate('EditPreferences')}
              style={styles.buttonContainer}>
              <Text style={styles.button}>Edit Prefernces</Text>
            </TouchableHighlight>
            <TouchableHighlight
              onPress={() => signOut()}
              style={styles.buttonContainer}>
              <Text style={styles.button}>Logout</Text>
            </TouchableHighlight>
            <TouchableHighlight
              onPress={() => pathToImageFile()}
              style={styles.buttonContainer}>
              <Text style={styles.button}>Upload</Text>
            </TouchableHighlight>
            {/* <TouchableHighlight
              onPress={() => {
                setModalVisible(true);
              }}
              style={styles.buttonContainer}>
              <Text style={styles.button}>Delete account</Text>
            </TouchableHighlight> */}

            <ActivityIndicator
              animating={loading}
              size="small"
              color="#002DE3"
            />
          </View>
        </View>
        <DeleteModal
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          user={user}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // height: "100%",
  },
  header: {
    backgroundColor: '#F7F7FC',
  },
  headerContent: {
    padding: 30,
    alignItems: 'center',
    height: '100%',
  },
  avatar: {
    width: 130,
    height: 130,
    borderRadius: 63,
    borderWidth: 4,
    borderColor: 'white',
    marginBottom: 10,
  },
  name: {
    fontSize: 22,
    color: '#000000',
    fontWeight: '600',
  },
  userInfo: {
    fontSize: 16,
    color: '#778899',
    fontWeight: '600',
  },
  body: {
    backgroundColor: '#778899',
    height: 500,
    alignItems: 'center',
  },
  item: {
    flexDirection: 'row',
  },
  infoContent: {
    flex: 1,
    alignItems: 'flex-start',
    paddingLeft: 5,
  },
  iconContent: {
    flex: 1,
    alignItems: 'flex-end',
    paddingRight: 5,
  },
  icon: {
    width: 30,
    height: 30,
    marginTop: 20,
  },
  info: {
    fontSize: 18,
    marginTop: 20,
    color: '#FFFFFF',
  },
  buttonContainer: {
    height: 45,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
    marginTop: 20,
    width: 250,
    borderRadius: 15,
    backgroundColor: '#002DE3',
  },
  button: {
    color: 'white',
  },
  modalContainer: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    flex: 1,
    justifyContent: 'flex-end',
  },
  modalInnerContainer: {
    backgroundColor: '#fff',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    justifyContent: 'center',
    padding: 16,
  },
  modalInput: {
    borderBottomWidth: 1,
    marginBottom: 16,
    padding: 8,
  },
  modalDismissButton: {
    marginLeft: 'auto',
  },
  modalDismissText: {
    fontSize: 20,
    fontWeight: '700',
  },
  buttons: {
    alignSelf: 'center',
    justifyContent: 'space-between',
    width: '80%',
    marginTop: 10,
    marginBottom: 10,
  },
  modal: {justifyContent: 'flex-end', margin: 0},
});
