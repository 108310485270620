import {
	StyleSheet,
	View,
	Dimensions,
	TouchableOpacity,
	Linking,
} from 'react-native'
import { Auth } from 'aws-amplify'
import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useState, useContext } from 'react'
import useUserStore from '../../Userstore/UserStore'
import AsyncStorage from '@react-native-async-storage/async-storage'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
import UserAvatar from 'react-native-user-avatar'
import useImageFileLink from '../../customhook/useImageLinkhook'
import { Divider } from 'native-base'
import Hidiheader from '../../components/Hidiheader'
import UserDetails from '../../components/UserDetails'
import {
	ArrowLeftIcon,
	ArrowRightIcon,
	Box,
	Button,
	ButtonText,
	HStack,
	Icon,
	Image,
	Menu,
	MenuIcon,
	MenuItem,
	MenuItemLabel,
	Pressable,
	Text,
} from '@gluestack-ui/themed'
import AvatarComponent from '../AvatarHeaderComponent'
// import UserAvatar from 'react-native-user-avatar';

const DekstopHeaderCandidate = ({ navigationRef }) => {
	const isLoggedIn = useUserStore((state) => state.isLoggedIn)
	const userProfile = useUserStore((state) => state.profile)
	const imgKey = useUserStore((state) => state.imagekeyprofile)
	const setSavedUrl = useUserStore((state) => state.setSavedUrl)
	const setScreenSideNav = useUserStore((state) => state.setScreenSideNav)
	const typeofuser = useUserStore((state) => state.group)

	const fileLink = useImageFileLink(imgKey)
	const signOut = useUserStore((state) => state.signOut)

	const onSignout = async () => {
		await signOut()
		AsyncStorage.clear()
	}
	const [isOpen, setIsOpen] = useState(false)

	const toggleDrawer = () => {
		setIsOpen(!isOpen)
	}

	const onClickLogo = () => {
		navigationRef.current.navigate('Feed')
		setSavedUrl('')
	}

	return (
		<Box
			paddingVertical={10}
			marginBottom={10}
			paddingRight={windowWidth > 480 ? 100 : 5}
			paddingLeft={
				windowWidth > 480 ? (windowWidth > 1500 ? '3%' : '2%') : 5
			}
			position="fixed"
			top={0}
			bg="$primary950"
			w={'100%'}
			zIndex={100}
			borderBottomWidth={2}
			borderColor="$borderDark0"
			h={62}
			justifyContent="center"
		>
			<HStack justifyContent="space-between">
				<Pressable
					onPress={() => {
						setScreenSideNav('Home')
						isLoggedIn && typeofuser !== 'companys'
							? onClickLogo()
							: window.open('https://app.qrusible.com/')
					}}
				>
					<Image
						h={windowWidth > 480 ? 40 : 30}
						w={windowWidth > 480 ? 40 : 30}
						alt="Qrusible Logo"
						source={require('../../assets/Images/QLogoWhite.png')}
					/>
				</Pressable>
				<Box>
					<Menu
						placement="bottom left"
						// right={windowWidth > 750 ? 90 : 0}
						left={
							windowWidth > 480
								? windowWidth - 255
								: windowWidth - 110
						}
						top={55}
						p={windowWidth > 480 ? 10 : 5}
						borderRadius={6}
						position="fixed"
						trigger={({ ...triggerProps }) => {
							return (
								<Pressable {...triggerProps}>
									<AvatarComponent
										height={windowWidth > 480 ? 40 : 30}
										width={windowWidth > 480 ? 40 : 30}
										name={userProfile?.name}
										imgSource={fileLink}
									/>
								</Pressable>
							)
						}}
					>
						<MenuItem
							action="secondary"
							closeOnSelect={false}
							bg={isLoggedIn ? '$secondary100' : '$primary950'}
							values={'Logout'}
							textValue="Logout"
							borderRadius={6}
							w={windowWidth > 480 ? 140 : 100}
							p={0}
						>
							<Pressable
								onPress={() => {
									isLoggedIn
										? onSignout()
										: window.open(
												'https://app.qrusible.com/'
										  )
								}}
								flexDirection="row"
								alignItems="center"
								borderWidth={2}
								borderRadius={6}
								borderColor={
									isLoggedIn ? '$secondary500' : '$primary500'
								}
								paddingVertical={windowWidth > 480 ? 8 : 5}
								paddingHorizontal={windowWidth > 480 ? 15 : 5}
								w={windowWidth > 480 ? 140 : 100}
							>
								<Icon
									as={
										isLoggedIn
											? ArrowLeftIcon
											: ArrowRightIcon
									}
									size={windowWidth > 480 ? 'sm' : 'xs'}
									mr="$2"
									color={
										isLoggedIn
											? '$secondary600'
											: '$primary600'
									}
								/>
								<Text
									fontFamily="$fontContent"
									fontSize={
										windowWidth > 480
											? '$fontContent'
											: '$fontContentMobile'
									}
									fontWeight="700"
									color={
										isLoggedIn
											? '$secondary600'
											: '$primary600'
									}
								>
									{isLoggedIn ? 'Logout' : 'Login'}
								</Text>
							</Pressable>
						</MenuItem>
					</Menu>
				</Box>
			</HStack>
		</Box>
	)
}

const DekstopHeader = () => {
	const navigation = useNavigation()
	const signOut = useUserStore((state) => state.signOut)
	async function onSignout() {
		await signOut()
	}

	return (
		<View style={styles.Dekstopheader}>
			<View style={{ alignSelf: 'center' }}>
				<TouchableOpacity
					onPress={() => navigation.push('PreferencesScreen')}
				>
					<Image
						resizeMode="contain"
						style={styles.Dekstopheaderimage}
						source={require('../../assets/Images/QLogoWhite.png')}
					/>
				</TouchableOpacity>
			</View>

			{/* <Text style ={styles.dekstopheadertext}>QRUSIBLE RECOMMENDED TALENT</Text> */}
			<View
				style={{
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-evenly',
					width: windowWidth * 0.3,
					alignSelf: 'center',
				}}
			>
				<TouchableOpacity
					onPress={() => {
						// setSelected(0)
						navigation.push('PreferencesScreen')
					}}
				>
					<Text style={{ fontWeight: 'bold', fontFamily: 'Lexend' }}>
						Home
					</Text>
				</TouchableOpacity>
				{/* <TouchableOpacity onPress={() => {
         
          navigation.navigate("Shortlist")}}
        >
        <Text style ={{fontWeight: "bold", fontFamily: "Lexend"}}>
        SHORTLIST
       </Text>
        </TouchableOpacity> */}
				<TouchableOpacity
					onPress={() => {
						// Auth.signOut()
						onSignout()
					}}
				>
					<Image
						style={{ width: 30, height: 30 }}
						source={require('../../assets/Images/QLogoWhite.png')}
					/>
				</TouchableOpacity>
			</View>
		</View>
	)
}

const MobileHeader = ({ props, navigation }) => {
	const signOut = useUserStore((state) => state.signOut)
	async function onSignout() {
		await signOut()
		AsyncStorage.clear()
	}

	async function getUserData() {
		await Auth.currentAuthenticatedUser()
			.then((response) => {
				if (
					response.signInUserSession.accessToken.payload[
						'cognito:groups'
					][0] == 'companys'
				) {
					navigation.push('PreferencesScreen')
				} else {
					navigation.push('Profile')
				}

				// checkprofile()
			})
			.catch((error) => {
				console.log(error)
			})
	}
	return (
		<>
			<Box
				paddingVertical={10}
				marginBottom={10}
				// paddingRight={windowWidth > 480 ? 100 : 5}
				paddingHorizontal={
					windowWidth > 480 ? (windowWidth > 1500 ? '3%' : '2%') : 5
				}
				position="fixed"
				top={0}
				bg="$primary0"
				w={'100%'}
				zIndex={1000}
				borderBottomWidth={2}
				borderColor="$borderDark0"
				h={62}
				justifyContent="center"
			>
				<HStack justifyContent="space-between" alignItems="center">
					<Pressable onPress={() => getUserData()}>
						<Image
							h={windowWidth > 480 ? 40 : 30}
							w={windowWidth > 480 ? 41 : 31}
							alt="Qrusible Logo"
							source={require('../../assets/Images/QLogoWhite.png')}
						/>
					</Pressable>
					<Box>
						<Text
							fontFamily="$fontContent"
							fontSize={
								windowWidth > 480
									? '$fontContent'
									: '$fontContentMobile'
							}
							fontWeight="600"
						>
							{props}
						</Text>
					</Box>
					<Box>
						<Menu
							placement="bottom left"
							left={
								windowWidth > 480
									? windowWidth - 255
									: windowWidth - 110
							}
							borderWidth={0.1}
							borderColor="$borderDark0"
							top={55}
							p={windowWidth > 480 ? 10 : 5}
							borderRadius={6}
							position="fixed"
							trigger={({ ...triggerProps }) => {
								return (
									<Pressable {...triggerProps}>
										<Icon
											as={MenuIcon}
											size={
												windowWidth > 480 ? 'xl' : 'lg'
											}
										/>
									</Pressable>
								)
							}}
							gap={windowWidth > 480 ? 10 : 5}
						>
							<MenuItem
								action="secondary"
								closeOnSelect={false}
								bg={'$primary100'}
								values={'Logout'}
								textValue="Logout"
								borderRadius={6}
								w={windowWidth > 480 ? '$full' : 100}
								p={0}
							>
								<Pressable
									onPress={() =>
										navigation.push('PreferencesScreen')
									}
									flexDirection="row"
									alignItems="center"
									borderWidth={2}
									borderRadius={6}
									borderColor={'$primary500'}
									paddingVertical={windowWidth > 480 ? 8 : 5}
									paddingHorizontal={
										windowWidth > 480 ? 15 : 5
									}
									w={windowWidth > 480 ? '$full' : 100}
								>
									<Text
										fontFamily="$fontContent"
										fontSize={
											windowWidth > 480
												? '$fontContent'
												: '$fontContentMobile'
										}
										fontWeight="700"
										color={'$primary600'}
									>
										Home
									</Text>
								</Pressable>
							</MenuItem>
							<MenuItem
								action="secondary"
								closeOnSelect={false}
								bg={'$secondary100'}
								values={'Logout'}
								textValue="Logout"
								borderRadius={6}
								w={windowWidth > 480 ? '$full' : 100}
								p={0}
							>
								<Pressable
									onPress={() => {
										onSignout()
									}}
									flexDirection="row"
									alignItems="center"
									borderWidth={2}
									borderRadius={6}
									borderColor={'$secondary500'}
									paddingVertical={windowWidth > 480 ? 8 : 5}
									paddingHorizontal={
										windowWidth > 480 ? 15 : 5
									}
									w={windowWidth > 480 ? '$full' : 100}
								>
									<Icon
										as={ArrowLeftIcon}
										size={windowWidth > 480 ? 'sm' : 'xs'}
										mr="$2"
										color={'$secondary600'}
									/>
									<Text
										fontFamily="$fontContent"
										fontSize={
											windowWidth > 480
												? '$fontContent'
												: '$fontContentMobile'
										}
										fontWeight="700"
										color={'$secondary600'}
									>
										Logout
									</Text>
								</Pressable>
							</MenuItem>
						</Menu>
					</Box>
				</HStack>
			</Box>
		</>
	)
}

const Drawer = () => {
	const signOut = useUserStore((state) => state.signOut)
	async function onSignout() {
		await signOut()
	}

	const [isOpen, setIsOpen] = useState(false)
	const navigation = useNavigation()

	const toggleDrawer = () => {
		setIsOpen(!isOpen)
	}

	return (
		<View style={styles.drawer}>
			<TouchableOpacity onPress={toggleDrawer}>
				<Image
					source={require('../../assets/Images/menu.png')}
					style={styles.menuIcon}
				/>
			</TouchableOpacity>
			{isOpen && (
				<View style={styles.drawerMenu}>
					{/* <TouchableOpacity onPress={() => navigation.navigate("RecomendedScreen")}
          style={styles.drawerMenuItem}>
            <Text style={styles.drawerMenuText}>Recommended Candidate</Text>
          </TouchableOpacity>
         */}
					<TouchableOpacity
						onPress={() => navigation.push('PreferencesScreen')}
						style={styles.drawerMenuItem}
					>
						<Text style={styles.drawerMenuText}>Home </Text>
					</TouchableOpacity>
					<TouchableOpacity
						onPress={
							() => onSignout()
							// Auth.signOut()
						}
						style={styles.drawerMenuItem}
					>
						<Text style={styles.drawerMenuText}>Logout </Text>
					</TouchableOpacity>
				</View>
			)}
		</View>
	)
}

const styles = StyleSheet.create({
	header: {
		width: windowWidth * 1,
		height: windowHeight * 0.1,
		// borderWidth: 1,
		borderColor: '#D9D9D9',
		borderBottomWidth: 1,
		// borderColor: "black",
		// marginTop: -5,
		flexDirection: 'row',
		// justifyContent: "space-between",
		alignItems: 'center',
		width: '100%',
		// justifyContent: "center",
		paddingHorizontal: '3%',
	},
	recommendtextheading: {
		// padding: 10,
		fontWeight: '700',
		fontSize: 20,
		textAlign: 'center',
		// marginRight: windowWidth * 0.13,
		// position: "absolute",
		// marginLeft: "10%",
		fontFamily: 'Lexend',
	},
	Dekstopheader: {
		width: windowWidth * 1,
		height: windowHeight * 0.09,
		borderBottomWidth: 1,
		borderColor: '#eeeeee',
		marginTop: -5,
		alignSelf: 'center',
		// alignItems: "center",
		backgroundColor: '#fff',
	},
	headerIcons: {
		height: windowWidth > 480 ? windowHeight * 0.03 : windowHeight * 0.04,
		width: windowWidth > 480 ? windowHeight * 0.03 : windowHeight * 0.04,
	},
	headerIconsDesktop: {
		height: windowHeight * 0.04,
		width: windowHeight * 0.04,
	},
	DekstopheaderInnerContainer: {
		width: '70%',
		height: windowHeight * 0.09,

		alignSelf: 'center',
		// alignItems: "center",
		justifyContent: 'space-between',
		flexDirection: 'row',
	},
	Dekstopheaderimage: {
		height: windowHeight * 0.045,
		width: windowHeight * 0.045,
	},
	dekstopheadertext: {
		// alignSelf: "center",
		padding: 10,
		fontWeight: '600',
		fontSize: 14,
		textAlign: 'center',
		// marginRight: windowWidth*0.13
	},
	headerimage: {
		height: windowWidth < 480 ? windowHeight * 0.08 : windowHeight * 0.05,
		width: windowWidth * 0.08,
		marginLeft: 5,
		// alignSelf: "stretch"
		// alignSelf: "center"
		// marginTop: 10
	},
	container: {
		flex: 1,
		backgroundColor: '#fff',
	},

	logo: {
		width: 50,
		height: 50,
	},
	menu: {
		flexDirection: 'row',
	},
	menuItem: {
		paddingHorizontal: 20,
	},
	menuText: {
		color: '#fff',
		fontWeight: 'bold',
	},
	drawer: {
		position: 'absolute',
		top: windowHeight * 0.03,
		right: 20,
		width: 'auto',
		height: 'auto',
		// borderWidth: 1,
		zIndex: 1,
		gap: windowHeight * 0.03,
	},

	menuIcon: {
		width: 30,
		height: 30,
		alignSelf: 'flex-end',
	},
	drawerMenu: {
		backgroundColor: '#fff',
		paddingVertical: 12,
		overflow: 'hidden',
		shadowColor: 'rgba(0, 0, 0, 0.1)',
		shadowRadius: 20,
		shadowOpacity: 1,
		borderRadius: 15,
	},
	drawerMenuDesktop: {
		paddingVertical: 10,
		position: 'absolute',
		top: 50,
		right: 0,
		backgroundColor: '#fff',
		overflow: 'visible',
		shadowColor: 'rgba(0, 0, 0, 0.1)',
		shadowRadius: 20,
		shadowOpacity: 1,
		borderRadius: 15,
	},
	drawerMenuItem: {
		paddingVertical: 10,
		paddingHorizontal: 20,
		flexDirection: 'row',
	},
	drawerMenuText: {
		color: '#424242',
		fontWeight: '900',
		fontFamily: 'Urbanist',
		fontSize: 14,
	},
	drawerMenuIcons: {
		height: 14,
		width: 14,
	},
})
export { DekstopHeader, MobileHeader, Drawer, DekstopHeaderCandidate }
