import React from 'react'
import { Dimensions } from 'react-native'
import { Box } from '@gluestack-ui/themed'
import EditExperience from './EditExperience'
import { useState, useEffect } from 'react'
import useUserStore from '../../../Userstore/UserStore'
import {
	createCandidateExperience,
	deleteCandidateExperience,
	updateCandidateExperience,
} from '../../../graphql/mutations'
import { API } from 'aws-amplify'
import { listCandidateExperienceByUserIdByDate } from '../../../graphql/queries'
import { showToast } from '../../../components/ErrorToast'
import CandidateCareerRenderComponent from '../CandidateCareerRenderComponent'
import CandidateCareerViewComponent from '../CandidateCareerViewComponent'
import { useCallback } from 'react'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const WorkExperience = ({ ref, userId, screen }) => {
	const userIdPrivate = useUserStore((state) => state.userId)
	const [DATA, setDATA] = useState([])
	const [loading, setLoading] = useState(true)
	const [showModal, setShowModal] = useState(false)
	const [arrangedData, setArrangedData] = useState([])
	const [viewHeight, setViewHeight] = useState('')
	const [fields, setFields] = useState({
		id: '',
		designation: '',
		company: '',
		StartDate: '',
		EndDate: '',
		isPresent: false,
	})
	useEffect(() => {}, [])
	const onMainViewLayout = (event) => {
		const { height } = event.nativeEvent.layout
		setViewHeight(height)
	}

	const addExperience = async (newData) => {
		if (!newData.id) {
			const updatedData = {
				designation: newData.designation,
				company: newData.company,
				EndDate: newData.EndDate,
				startDate: newData.StartDate,
				Present: newData.isPresent,
				UserId: userId ? userId : userIdPrivate,
			}
			try {
				const result = await API.graphql({
					query: createCandidateExperience,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
					variables: {
						input: updatedData,
					},
				})
				const createdExperience = result.data.createCandidateExperience
				setDATA([...DATA, createdExperience])
			} catch (error) {
				console.error('Error creating experience details:', error)
			}
		} else {
			const updatedData = {
				id: newData.id,
				designation: newData.designation,
				company: newData.company,
				EndDate: newData.EndDate,
				startDate: newData.StartDate,
				Present: newData.isPresent,
				UserId: userId ? userId : userIdPrivate,
			}
			try {
				const result = await API.graphql({
					query: updateCandidateExperience,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
					variables: {
						input: updatedData,
					},
				})
				const updatedExperience = result.data.updateCandidateExperience
				UpdateDataObject(updatedExperience)
			} catch (error) {
				console.error('Error creating experience details:', error)
			}
		}
	}

	const UpdateDataObject = (updatedObject) => {
		const RemainingData = DATA.filter((obj) => obj.id !== updatedObject.id)
		setDATA([...RemainingData, updatedObject])
	}

	const handleDelete = async (itemId) => {
		try {
			const updatedData = DATA.filter((item) => item.id !== itemId)
			setDATA(updatedData)

			await API.graphql({
				query: deleteCandidateExperience,
				authMode: 'AMAZON_COGNITO_USER_POOLS',
				variables: {
					input: { id: itemId },
				},
			})
		} catch (error) {
			console.error('Error deleting experience details:', error)
		}
	}

	useEffect(() => {
		getExperienceData()
	}, [userId, userIdPrivate])

	useEffect(() => {
		const sortedData = [...DATA].sort((a, b) => {
			const startDateA = a.startDate ? new Date(a.startDate) : new Date()
			const startDateB = b.startDate ? new Date(b.startDate) : new Date()
			const endDateA = a.EndDate ? new Date(a.EndDate) : new Date()
			const endDateB = b.EndDate ? new Date(b.EndDate) : new Date()

			if (a.Present && !b.Present) {
				return -1
			}
			if (!a.Present && b.Present) {
				return 1
			}

			if (!a.EndDate && b.EndDate) {
				return -1
			}
			if (a.EndDate && !b.EndDate) {
				return 1
			}

			return (
				endDateB.getTime() - endDateA.getTime() ||
				startDateB.getTime() - startDateA.getTime()
			)
		})

		setArrangedData(sortedData)
	}, [DATA])

	const getExperienceData = useCallback(async () => {
		try {
			const response = await API.graphql({
				query: listCandidateExperienceByUserIdByDate,
				authMode: 'API_KEY',
				variables: {
					UserId: userId ? userId : userIdPrivate,
				},
			})

			const experienceData =
				response.data.listCandidateExperienceByUserIdByDate.items

			setDATA(experienceData)
			setLoading(false)
		} catch (error) {
			console.log(error)
		}
	}, [userId, userIdPrivate])
	const toastShow = () => {
		showToast({ message: 'Please fill the all the necessary fields.' })
	}

	const renderItemMethod = ({ item }) => {
		const startDate = item.startDate ? new Date(item.startDate) : ''
		const endDate = item?.EndDate ? new Date(item.EndDate) : ''

		const formattedStartDate = startDate.toLocaleString('en-US', {
			month: 'short',
			year: 'numeric',
		})

		const formattedEndDate = endDate.toLocaleString('en-US', {
			month: 'short',
			year: 'numeric',
		})

		let logoUrl =
			'https://logo.clearbit.com/' +
			item?.company?.split(' ')[0].trim() +
			'.com'

		return (
			<Box w={'100%'} marginVertical={windowWidth > 480 ? 10 : 5}>
				<CandidateCareerRenderComponent
					defaultImgSource={require('../../../assets/Images/experience.png')}
					imgSource={logoUrl}
					title={item?.designation}
					institution={item?.company}
					startDate={item?.startDate ? formattedStartDate : null}
					endDate={item?.EndDate ? formattedEndDate : null}
					present={item?.Present}
					duration={item?.duration}
					onEdit={() => {
						setFields({
							id: item.id,
							designation: item.designation,
							company: item.company,
							StartDate: item.startDate
								? new Date(item.startDate)
								: '',
							EndDate: item?.EndDate
								? new Date(item.EndDate)
								: '',
							isPresent: item?.Present,
						})
						setShowModal(true)
					}}
					onDelete={() => {
						handleDelete(item.id)
					}}
					screen={screen}
				/>
			</Box>
		)
	}

	return (
		<>
			{screen == 'public' && DATA.length == 0 ? (
				<></>
			) : (
				<Box
					id="main-view"
					onLayout={onMainViewLayout}
					bg="$primary0"
					marginBottom={12}
					p={windowWidth > 480 ? 20 : 10}
					borderWidth={1}
					borderColor="$borderDark0"
					borderRadius={6}
				>
					<CandidateCareerViewComponent
						data={arrangedData}
						loading={loading}
						title={'Employment'}
						renderItem={renderItemMethod}
						onPress={() => {
							setShowModal(true)
						}}
						description={'No employment details added'}
						buttonText={'Add Your Employment'}
						addMoreImage={require('../../../assets/Images/addwork.png')}
						screen={screen}
					/>

					<Box>
						<EditExperience
							setFields={setFields}
							fields={fields}
							onSave={addExperience}
							modalVisibility={showModal}
							setShowModal={setShowModal}
							toast={toastShow}
							ref={ref}
						/>
					</Box>
				</Box>
			)}
		</>
	)
}

export default WorkExperience
