import React from 'react'
import SelectSkillModalBody from '../opportunities/components/SelectSkillModalBody'
import {
	Box,
	Divider,
	HStack,
	Icon,
	Image,
	Pressable,
	Text,
} from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'
import useUserStore from '../../Userstore/UserStore'
import { useState, useEffect } from 'react'
import { listSkills } from '../opportunities/components/HelperFunctions'
import ButtonComponent from '../../newComponents/ButtonComponent'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const SkillsComponent = ({ screen, userId, navigation }) => {
	const existingSkills = useUserStore((state) => state.userSkills)
	const setExistingSkills = useUserStore((state) => state.setUserSkills)
	const [existingSkillsLoading, setExistingSkillsLoading] = useState(false)
	const [showDelete, setShowDelete] = useState(false)

	const listSkillsByUserId = async () => {
		setExistingSkillsLoading(true)
		const skills = await listSkills(userId)
		setExistingSkills(skills)
		setExistingSkillsLoading(false)
	}

	useEffect(() => {
		listSkillsByUserId()
	}, [userId])

	return (
		<Box
			bg="white"
			my={20}
			borderWidth={1}
			borderColor="$borderDark0"
			p={20}
			display={
				existingSkills?.length === 0 && screen === 'public' && 'none'
			}
		>
			<HStack justifyContent="space-between">
				<Text
					fontFamily="$fontHeading"
					fontWeight="600"
					fontSize={windowWidth > 480 ? 20 : 15}
					color="$primary950"
				>
					Skills
				</Text>

				{screen !== 'public' ? (
					showDelete ? (
						<Box>
							<ButtonComponent
								text={'Done'}
								onPress={() => {
									setShowDelete(false)
								}}
								height={windowWidth > 480 ? 25 : 30}
								width={windowWidth > 480 ? 80 : 80}
							/>
						</Box>
					) : (
						<Pressable
							onPress={() => {
								setShowDelete(true)
							}}
							my={3.7}
						>
							<Image
								source={require('../../assets/Images/edit.png')}
								h={18}
								w={18}
							/>
						</Pressable>
					)
				) : null}
			</HStack>
			<Divider
				marginVertical={windowWidth > 480 ? 20 : 10}
				bg={'$borderDark0'}
			/>
			<SelectSkillModalBody
				inModal={false}
				screen={screen}
				userId={userId}
				existingSkills={existingSkills}
				setExistingSkills={setExistingSkills}
				existingSkillsLoading={existingSkillsLoading}
				setExistingSkillsLoading={setExistingSkillsLoading}
				showDelete={showDelete}
				navigation={navigation}
			/>
		</Box>
	)
}

export default SkillsComponent
