import React from 'react'
import {
	Box,
	Heading,
	Modal,
	ModalBackdrop,
	ModalCloseButton,
	ModalHeader,
	Icon,
	CloseIcon,
	ModalBody,
	ModalFooter,
	Button,
	ButtonText,
	Switch,
	Text,
	VStack,
	Center,
	ModalContent,
	Pressable,
} from '@gluestack-ui/themed'

import { Dimensions } from 'react-native'
import LabelWithInputComponent from '../LabelWithInputComponent'
import SpinnerComponent from '../SpinnerComponent'
import CustomDatePicker from '../DatePickerComponent'
import { useState } from 'react'
import { ToastContainer } from 'react-toastify'

const EditCandidateCareerModal = ({
	heading,
	isOpen,
	onClose,
	ref,
	labels,
	importants,
	placeholders,
	values,
	setValueChanges,
	handleSave,
	date,
	startDate,
	onStartDateChange,
	endDate,
	onEndDateChange,
	isPresent,
	toggleSwitch,
	loading,
}) => {
	const windowWidth = Dimensions.get('window').width
	const windowHeight = Dimensions.get('window').height
	const [zIndexStart, setZIndexStart] = useState(-1)

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			minHeight={windowHeight}
			finalFocusRef={ref}
			position="fixed"
		>
			<ModalBackdrop />
			<ToastContainer />
			<ModalContent
				maxHeight={windowHeight * 0.9}
				minWidth={
					windowWidth > 480 ? windowWidth * 0.7 : windowWidth * 0.9
				}
				overflow={false}
			>
				<ModalHeader bg="white">
					<Heading
						fontFamily="$fontHeading"
						fontSize={
							windowWidth > 480
								? '$fontHeading'
								: '$fontHeadingContent'
						}
					>
						{heading}
					</Heading>
					<ModalCloseButton>
						<Icon as={CloseIcon} />
					</ModalCloseButton>
				</ModalHeader>
				<ModalBody>
					<VStack>
						{labels.map((label, index) =>
							label ? (
								<Box key={index}>
									<LabelWithInputComponent
										key={index}
										label={label}
										important={importants[index]}
										placeholder={placeholders[index]}
										value={values[index]}
										setValueChange={setValueChanges[index]}
										marginVertical={5}
									/>
								</Box>
							) : null
						)}
						{date ? (
							<Box>
								<Box
									flexDirection={
										windowWidth > 480 ? 'row' : 'column'
									}
									gap={windowWidth > 480 ? 20 : 5}
									zIndex={1}
									h={windowWidth > 480 ? null : 130}
								>
									<Pressable
										onPress={() => setZIndexStart(1)}
										zIndex={zIndexStart}
									>
										<CustomDatePicker
											label={'Start Date'}
											selectedDate={startDate}
											onDateChange={onStartDateChange}
										/>
									</Pressable>

									{isPresent ? (
										<></>
									) : (
										<Pressable
											onPress={() => setZIndexStart(-1)}
											zIndex={-zIndexStart}
										>
											<CustomDatePicker
												label={'End Date'}
												selectedDate={endDate}
												onDateChange={onEndDateChange}
												disabled={isPresent}
											/>
										</Pressable>
									)}
								</Box>
								<Box
									zIndex={-10}
									marginTop={windowWidth > 480 ? 10 : 5}
									gap={windowWidth > 480 ? 5 : 0}
								>
									<Switch
										value={isPresent}
										onValueChange={toggleSwitch}
										size={windowWidth > 480 ? 'md' : 'sm'}
									/>
									{isPresent ? (
										<Text
											zIndex={-10}
											fontFamily="$fontContent"
											fontSize={
												windowWidth > 480 ? 12 : 10
											}
											fontWeight="300"
											color="$primary950"
										>
											Toggle to set an 'End Date'
										</Text>
									) : (
										<Text
											zIndex={-10}
											fontFamily="$fontContent"
											fontSize={
												windowWidth > 480 ? 12 : 10
											}
											fontWeight="300"
											// fontStyle="italic"
											color="$primary950"
										>
											{heading === 'Employment'
												? 'I am still working here'
												: 'I am still studying here'}
										</Text>
									)}
								</Box>
							</Box>
						) : null}
					</VStack>
				</ModalBody>
				<ModalFooter justifyContent="flex-end">
					<Button
						size="sm"
						action="secondary"
						borderWidth="$0"
						w={windowWidth > 480 ? 100 : 70}
						onPress={handleSave}
						sx={{
							bg: '$primary500',
							':hover': {
								bg: '$primary400',
							},
							':active': {
								bg: '$primary500',
							},
						}}
					>
						{loading ? (
							<SpinnerComponent
								color={'$primary0'}
								button={true}
							/>
						) : (
							<ButtonText
								fontSize={
									windowWidth > 480
										? '$fontContent'
										: '$fontContentMobile'
								}
								fontFamily="$fontContent"
							>
								Save
							</ButtonText>
						)}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default EditCandidateCareerModal
