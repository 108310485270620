import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Alert,
  Share,
  Pressable,
  ImageBackground,
} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import React, {useEffect, useState, useContext} from 'react';
// import UserAvatar from 'react-native-user-avatar';
import {HStack, VStack} from 'native-base';
// import Logo from '../assets/Images/logo.png';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { Auth } from 'aws-amplify';


// require("../assets/Images/logo.png")= Image.resolveAssetSource(Logo).uri;

const HeaderWithBack = ({title, leftSvg, sx, rightSvg}) => {
  const navigation = useNavigation();
  return (
    <View style={{...styles.header}}>
      <HStack alignItems={'center'}>
        <TouchableOpacity
          style={styles.btnSend}
          onPress={() => navigation.goBack()}>
          <Image
            source={{
              uri: 'https://cdn-icons-png.flaticon.com/128/152/152417.png',
            }}
            style={styles.btnback}
          />
        </TouchableOpacity>
        <Text style={styles.headerContent}>{title}</Text>
      </HStack>
      {/* {rightSvg} */}
    </View>
  );
};

// const FeedFlowHeader = ({data}) => {
//   function navigationScreen() {
//     if (data == true) {
//       ('Home');
//     } else {
//       navigation.goBack();
//     }
//   }
//   const navigation = useNavigation();
//   return (
//     <View style={{...styles.feedFlowHeader}}>
//       <HStack alignItems={'center'}>
//         <TouchableOpacity
//           style={styles.btnSend}
//           onPress={() => navigationScreen()}>
//           <Image
//             source={{
//               uri: 'https://cdn-icons-png.flaticon.com/128/152/152417.png',
//             }}
//             style={styles.btnback}
//           />
//         </TouchableOpacity>
//         <Text style={styles.headerContent}>Replies</Text>
//       </HStack>
//       {/* {rightSvg} */}
//     </View>
//   );
// };
// const NotificationHeader = ({data}) => {
//   const navigation = useNavigation();
//   return (
//     <View style={{...styles.feedFlowHeader}}>
//       <HStack alignItems={'center'}>
//         <TouchableOpacity
//           style={styles.btnSend}
//           onPress={() => navigation.goBack()}>
//           <Image
//             source={{
//               uri: 'https://cdn-icons-png.flaticon.com/128/152/152417.png',
//             }}
//             style={styles.btnback}
//           />
//         </TouchableOpacity>
//         <Text style={styles.headerContent}>Notifications</Text>
//       </HStack>
//       {/* {rightSvg} */}
//     </View>
//   );
// };
// const UserPreferHeader = ({data}) => {
//   const navigation = useNavigation();
//   return (
//     <View style={{...styles.feedFlowHeader}}>
//       <HStack alignItems={'center'}>
//         <TouchableOpacity
//           style={styles.btnSend}
//           onPress={() => navigation.goBack()}>
//           <Image
//             source={{
//               uri: 'https://cdn-icons-png.flaticon.com/128/152/152417.png',
//             }}
//             style={styles.btnback}
//           />
//         </TouchableOpacity>
//         <VStack>
//         <Text style={styles.headerContent2}>select at least four</Text>
//         <Text style={styles.headerContent}>Choose what you like to do</Text>
//         </VStack>
        
//       </HStack>
//       {/* {rightSvg} */}
//     </View>
//   );
// };
// const FeedHeader = ({title, leftSvg, sx, rightSvg}) => {
//   const navigation = useNavigation();
//   const [user, setUser] = useState({});
//   useEffect(() => {
//     Auth.currentAuthenticatedUser()
//       .then(res => {
//         setUser({
//           id: res?.attributes?.sub,
//           designation: res?.attributes['custom:Designation'],
//           company: res?.attributes['custom:Company'],
//           fullName: res?.attributes['custom:Fullname'],
//           bio: res?.attributes['custom:Bio'],
//         });
//       })
//       .catch(error => {
//         console.log(error);
//       });
//   }, []);
//   return (
//     <View style={{...styles.header}}>
//       <HStack alignItems={'center'}>
//         {/* <TouchableOpacity
//           style={styles.btnSend}
//           onPress={() => navigation.navigate("Home")}>
//           <Image
//             source={{
//               uri: 'https://cdn-icons-png.flaticon.com/128/152/152417.png',
//             }}
//             style={styles.btnback}
//           />
//         </TouchableOpacity> */}
//         <VStack style={styles.conversationHeader}>
//           <TouchableOpacity
//             onPress={() =>
//               ('notificationBadges')
//             }></TouchableOpacity>
//           <Image source={require("../assets/Images/logo.png")} style={styles.Logo} />
//           {/* <Text style={styles.headerHeading}>Active conversations on</Text>
//           <View style={styles.seperator} />
//           <Text style={styles.headerContent}>{title}</Text> */}
//         </VStack>
//       </HStack>

//       <View style={{width: '10%'}}>
//         <TouchableOpacity
//           style={{...styles.btnSend}}
//           onPress={() => navigation.navigate('ProfileDetails', false)}>
//           <UserAvatar
//             size={30}
//             name={user.fullName}
//             style={{...styles.btnSend, width: 40}}
//           />
//         </TouchableOpacity>
//       </View>
//       {/* {rightSvg} */}
//     </View>
//   );
// };
// const ConversationHeader = ({title, leftSvg, sx, rightSvg}) => {
//   const {notiData, setNotiData, badges, setBadge} = useContext(BadgesContext);
//   const navigation = useNavigation();
//   const [username, setUsername] = useState();
//   useEffect(() => {
//     AsyncStorage.getItem('userdata').then(Res => {
//       let res = JSON.parse(Res);
//       setUsername(res.attributes['custom:Fullname']);
//     });
//   }, []);
//   return (
//     <View style={{...styles.header}}>
//       <HStack alignItems={'center'}>
//         {/* <TouchableOpacity
//           style={styles.btnSend}
//           onPress={() => navigation.navigate("Home")}>
//           <Image
//             source={{
//               uri: 'https://cdn-icons-png.flaticon.com/128/152/152417.png',
//             }}
//             style={styles.btnback}
//           />
//         </TouchableOpacity> */}
//         <VStack style={styles.conversationHeader}>
//           <TouchableOpacity
//             onPress={() =>
//               navigation.navigate('notificationBadges')
//             }></TouchableOpacity>
//           <Text style={styles.headerHeading}>Active spaces for</Text>
//           <View style={styles.seperator} />
//           <Text style={styles.headerContent}>{title}</Text>
//         </VStack>
//       </HStack>

//       <View style={{width: '15%'}}>
//         <TouchableOpacity
//           style={{...styles.btnSend}}
//           onPress={() => navigation.navigate('ProfileDetails', false)}>
//           <UserAvatar
//             size={40}
//             name={username}
//             style={{...styles.btnSend, width: 40}}
//           />
//         </TouchableOpacity>
//       </View>
//       {/* {rightSvg} */}
//     </View>
//   );
// };
// const JourneyHeader = ({leftSvg, sx, rightSvg}) => {
//   const navigation = useNavigation();
//   const [user, setUser] = useState();
//   useEffect(() => {
//     AsyncStorage.getItem('userdata').then(Res => {
//       let res = JSON.parse(Res);
//       setUser(res.attributes['custom:Fullname']);
//     });
//   }, []);

//   return (
//     <View style={{...styles.header}}>
//       <HStack alignItems={'center'}>
//         <VStack style={{...styles.conversationHeader, width: '85%'}}>
//           <Text style={styles.headerHeading}>Active topics</Text>
//           <View style={styles.seperator} />
//           <Text style={styles.headerContent}>Goals being discussed </Text>
//         </VStack>
//       </HStack>
//       <View style={{width: '15%'}}>
//         <TouchableOpacity
//           style={{...styles.btnSend}}
//           onPress={() => navigation.navigate('ProfileDetails', false)}>
//           <UserAvatar
//             size={40}
//             name={user}
//             style={{...styles.btnSend, width: 40}}
//           />
//         </TouchableOpacity>
//       </View>
//     </View>
//   );
// };
// const ProfileHeader = ({title, leftSvg, sx, rightSvg}) => {
//   const navigation = useNavigation();
//   const [user, setUser] = useState();
//   useEffect(() => {
//     AsyncStorage.getItem('userdata').then(Res => {
//       let res = JSON.parse(Res);
//       setUser(res.attributes['custom:Fullname']);
//       // console.log(res.attributes['custom:Fullname']);
//     });
//   }, []);
//   return (
//     <View style={{...styles.header}}>
//       <HStack alignItems="center">
//         {/*<TouchableOpacity style={styles.btnSend} onPress={() => navigation.goBack()}>
//                         <Image source={{ uri: "https://cdn-icons-png.flaticon.com/512/3135/3135715.png" }} style={styles.btnSend} />
//       </TouchableOpacity> */}
//         <Text style={styles.HWBTitle}>{title}</Text>
//       </HStack>
//       <TouchableOpacity
//         style={styles.btnSend}
//         onPress={() => navigation.navigate('ProfileDetails', false)}>
//         <UserAvatar size={40} name={user} style={styles.btnSend} />
//       </TouchableOpacity>
//     </View>
//   );
// };
// const SpaceHeader = () => {
//   const {isParticipant, conversation, user, navigation} =
//     useContext(SpaceContext);
//   const [shareStatus, setShareStatus] = useState(false);
//   const [shareURL, setShareURL] = useState('https://tinyurl.com/qrusible');
//   const firstpart = 'I would like to invite you to the space: "* ';
//   const secondpart = ' .*" Join me by clicking on the link : ';

//   useEffect(() => {
//     shareStatus ? onShare(shareURL) : null;
//     setShareStatus(false);
//   }, [shareStatus]);

//   const onShare = async props => {
//     try {
//       const result = await Share.share({
//         title: 'Invitation',
//         message: firstpart + conversation.description + secondpart + shareURL,
//         //url: 'https://www.qrusible.com/',
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   return (
//     <View style={{...styles.spaceHeader}}>
//       <HStack alignItems={'center'} flex={0.95}>
//         <TouchableOpacity
//           style={{...styles.btnShareContainer}}
//           // onPress={() => navigation.navigate('Conversations', {item : props.journey})}
//           onPress={() => navigation.goBack()}>
//           <Image
//             source={{
//               uri: 'https://cdn-icons-png.flaticon.com/128/152/152417.png',
//             }}
//             style={styles.btnback}
//           />
//         </TouchableOpacity>
//         <TouchableOpacity
//           onPress={() => navigation.push('SpaceDetails', conversation.id)}
//           style={{width: '70%'}}>
//           <VStack>
//             <HStack>
//               <Text style={{...styles.author, color: '#000'}}>
//                 Participants:
//               </Text>
//               {conversation?.userConversations?.items?.length > 0 ? (
//                 <Text ml={1} style={{...styles.author, color: '#2C37E1'}}>
//                   {conversation?.author.fullname} +{' '}
//                   {conversation?.userConversations.items.length}{' '}
//                 </Text>
//               ) : (
//                 <Text ml={1} style={{...styles.author, color: '#2C37E1'}}>
//                   {conversation?.author.fullname}
//                 </Text>
//               )}
//             </HStack>
//             <View style={styles.seperator} />
//             <Text numberOfLines={2} style={styles.headerContent}>
//               {conversation.description}
//             </Text>
//           </VStack>
//         </TouchableOpacity>
//       </HStack>
//       <TouchableOpacity
//         style={{...styles.btnShareContainer}}
//         onPress={() => setShareStatus(true)}>
//         <Image
//           style={styles.btnShare}
//           source={require('../assets/Images/share.png')}
//         />
//       </TouchableOpacity>
//       {/* <TouchableOpacity style={styles.btnSend}>
//                         <Image source={{ uri: "https://cdn-icons-png.flaticon.com/512/56/56763.png" }} style={styles.btnback} />
//       </TouchableOpacity> */}
//     </View>
//   );
// };
// const SpaceDetailsHeader = ({title, leftSvg, sx, rightSvg, item}) => {
//   const navigation = useNavigation();
//   const [shareStatus, setShareStatus] = useState(false);
//   const [conversationName, setConversationName] = useState(title);
//   const [shareURL, setShareURL] = useState('');
//   const firstpart = 'You are invited to the space: `';
//   const secondpart = 'https://www.qrusible.com/';
//   useEffect(() => {
//     shareStatus ? onShare(shareURL) : null;
//   }, [shareStatus]);

//   const onShare = async props => {
//     try {
//       const result = await Share.share({
//         title: '',
//         message: firstpart + conversationName + secondpart + shareURL,
//         //url: 'https://www.qrusible.com/',
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   return (
//     <View style={{...styles.spaceHeader}}>
//       <HStack alignItems={'center'} flex={0.95}>
//         <TouchableOpacity
//           style={{...styles.btnShareContainer}}
//           onPress={() => navigation.goBack()}>
//           <Image
//             source={{
//               uri: 'https://cdn-icons-png.flaticon.com/128/152/152417.png',
//             }}
//             style={styles.btnback}
//           />
//         </TouchableOpacity>
//         <TouchableOpacity
//           onPress={() => navigation.navigate('SpaceDetails', item)}
//           style={{width: '70%'}}>
//           <VStack>
//             <Text numberOfLines={2} style={styles.spaceTitle}>
//               {title}
//             </Text>
//           </VStack>
//         </TouchableOpacity>
//       </HStack>
//       {/* <TouchableOpacity
//         style={{...styles.btnShareContainer}}
//         onPress={() => setShareStatus(true)}>
//         <Image
//           style={styles.btnShare}
//           source={require('../assets/Images/share.png')}
//         />
//       </TouchableOpacity> */}
//       {/* <TouchableOpacity style={styles.btnSend}>
//                         <Image source={{ uri: "https://cdn-icons-png.flaticon.com/512/56/56763.png" }} style={styles.btnback} />
//       </TouchableOpacity> */}
//     </View>
//   );
// };
export {
  HeaderWithBack,
  // ProfileHeader,
  // SpaceHeader,
  // ConversationHeader,
  // JourneyHeader,
  // SpaceDetailsHeader,
  // FeedHeader,
  // FeedFlowHeader,
  // NotificationHeader,
  // UserPreferHeader
};

const styles = StyleSheet.create({
  header: {
    shadowColor: 'black',
    elevation: 6,
    shadowOffset: {
      height: 4,
      width: 1,
    },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    zIndex: 999,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15,
    height: 70,
    backgroundColor: 'white',
    width: '100%',
  },
  feedFlowHeader: {
    width: '100%',
    shadowColor: 'black',
    elevation: 6,
    shadowOffset: {
      height: 4,
      width: 1,
    },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    zIndex: 999,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    //marginBottom: 1,
    paddingVertical: 10,
    minHeight: 60,
    maxHeight: 100,
    backgroundColor: '#fff',
  },
  spaceHeader: {
    width: '100%',
    shadowColor: 'black',
    elevation: 6,
    shadowOffset: {
      height: 4,
      width: 1,
    },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    zIndex: 999,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    //marginBottom: 1,
    paddingVertical: 10,
    minHeight: 60,
    maxHeight: 100,
    backgroundColor: '#fff',
  },
  btnSend: {
    //backgroundColor: "#002DE3",
    width: 40,
    height: 40,
    borderRadius: 360,
    alignItems: 'center',
    justifyContent: 'center',
    // width: '15%',
  },
  btnShare: {
    //backgroundColor: "#002DE3",
    width: 20,
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnShareContainer: {
    //backgroundColor: "#002DE3",
    width: 40,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  btnback: {
    //backgroundColor: "#002DE3",
    width: 20,
    height: 20,
    //borderRadius: 360,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerHeading: {
    color: 'black',
    fontSize: 12,
    //fontWeight: 'bold',
    marginLeft: 10,
  },
  headerContent2: {
    color: 'black',
    fontSize: 12,
    marginLeft: 10,
  },
  headerContent: {
    color: 'black',
    fontSize: 14,
    fontWeight: 'bold',
    marginLeft: 10,
  },
  seperator: {
    height: 0.5,
    backgroundColor: '#ADB5BD',
    opacity: 0.3,
    width: '100%',
    marginTop: 5,
  },
  HWBTitle: {
    //   ...Font.AvenirNextBold700,
    color: 'black',
    fontSize: 24,
    fontWeight: 'normal',
    marginLeft: 20,
  },
  conversationHeader: {
    width: '85%',
    justifyContent: 'flex-start',
    alignSelf: 'center',
  },
  spaceTitle: {
    color: 'black',
    fontSize: 14,
    fontWeight: 'normal',
  },
  author: {
    fontSize: 12,
    marginLeft: 10,
  },
  Logo: {
    height: 30,
    width: 122.24,
  },
});
