import { Box, Text, Pressable, FlatList, Heading } from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'

import { API } from 'aws-amplify'
import React from 'react'
import { listNewhidisByUserIdByDate } from '../../../graphql/queries'
import { useState, useEffect } from 'react'
import useUserStore from '../../../Userstore/UserStore'
import HidiViewProfile from './HidiViewProfile'
import SpinnerComponent from '../../../newComponents/SpinnerComponent'
import { MobileHeader } from '../../../newComponents/header/Header'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

export default function HidiListScreen({ route, navigation }) {
	const [loading, setloading] = useState(true)
	const group = useUserStore((state) => state.group)
	const [hidis, setHidis] = useState([])
	const userId = useUserStore((state) => state.userId)
	const loggedIn = useUserStore((state) => state.isLoggedIn)
	const profile = useUserStore((state) => state.profile)

	async function gethididata() {
		await API.graphql({
			query: listNewhidisByUserIdByDate,
			authMode: 'API_KEY',
			variables: {
				UserId: route.params.UserId,
			},
		})
			.then((res) => {
				console.log(res)
				setHidis(res.data.listNewhidisByUserIdByDate.items)
				setloading(false)
				// getUserData()
			})
			.catch((err) => {
				console.log(err)
			})
	}

	useEffect(() => {
		gethididata()
	}, [])
	const pinnedItems = hidis.filter((item) => item.pinnedFlag)
	const nonPinnedItems = hidis.filter((item) => !item.pinnedFlag)

	// const newData = []
	const newData = [...pinnedItems, ...nonPinnedItems]

	const renderItemMethod = ({ item }) => {
		return (
			<Box>
				<HidiViewProfile
					item={item}
					navigation={navigation}
					view={route?.params?.screen}
				/>
			</Box>
		)
	}

	return (
		<>
			{group === 'companys' && (
				<MobileHeader
					props={'Candidate Profile'}
					navigation={navigation}
				/>
			)}

			<Box
				bg="$backgroundGray"
				minHeight={windowHeight - 62}
				marginTop={62}
				marginBottom={windowWidth > 480 ? null : 50}
				paddingHorizontal={windowWidth > 480 ? 100 : 5}
				paddingVertical={windowWidth > 480 ? 20 : 5}
				paddingBottom={windowWidth > 480 ? null : 50}
			>
				{loading ? (
					<SpinnerComponent />
				) : (
					<Box justifyContent="center" w={'100%'}>
						<Box
							flexDirection="row"
							gap={20}
							alignItems="center"
							justifyContent={
								windowWidth < 480 && 'space-between'
							}
						>
							<Heading
								fontFamily="$fontHeading"
								fontSize={
									windowWidth > 480
										? '$fontHeading'
										: '$fontHeadingMobile'
								}
								textAlign={
									windowWidth < 480 &&
									screen === 'public' &&
									'center'
								}
								w={
									windowWidth < 480 &&
									screen === 'public' &&
									'$full'
								}
							>
								Experiences
							</Heading>
							{route?.params?.screen !== 'public' && (
								<Pressable
									onPress={() => {
										navigation.push('CapCreateHidi', {
											profile: profile,
										})
									}}
								>
									<Text
										paddingVertical={
											windowWidth > 480 ? 5 : 3
										}
										paddingHorizontal={
											windowWidth > 480 ? 10 : 5
										}
										textAlign="center"
										color="$primary500"
										borderRadius={6}
										borderWidth={2}
										borderColor="$primary500"
										fontFamily="$fontContent"
										fontSize={
											windowWidth > 480
												? '$fontContent'
												: '$fontContentMobile'
										}
										fontWeight="700"
									>
										+ Add HIDI
									</Text>
								</Pressable>
							)}
						</Box>
						<Box>
							{newData.length > 0 ? (
								<FlatList
									data={newData}
									renderItem={renderItemMethod}
									keyExtractor={(item) => item.id.toString()}
								/>
							) : (
								<Text
									marginTop={100}
									textAlign="center"
									fontFamily="$fontContent"
									fontSize={
										windowWidth > 480
											? '$fontSize25'
											: '$fontSize20'
									}
								>
									No experiences Found
								</Text>
							)}
						</Box>
					</Box>
				)}
			</Box>
		</>
	)
}
