/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createHIDI = /* GraphQL */ `
  mutation CreateHIDI(
    $input: CreateHIDIInput!
    $condition: ModelHIDIConditionInput
  ) {
    createHIDI(input: $input, condition: $condition) {
      createdAt
      Email
      id
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        resume
        education
        experience
        expertise
        graduation
        iframescorelink
        iframehidilink
        updatedAt
        owner
        __typename
      }
      Talents {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          Talent
          Level
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signals {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          signalname
          score
          signalcategory
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      MobileNumber
      name
      UserId
      PSStitle
      PSSdescription
      DMStitle
      DMSdescription
      CTtitle
      CTdescription
      Atitle
      Adescription
      Verified
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateHIDI = /* GraphQL */ `
  mutation UpdateHIDI(
    $input: UpdateHIDIInput!
    $condition: ModelHIDIConditionInput
  ) {
    updateHIDI(input: $input, condition: $condition) {
      createdAt
      Email
      id
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        resume
        education
        experience
        expertise
        graduation
        iframescorelink
        iframehidilink
        updatedAt
        owner
        __typename
      }
      Talents {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          Talent
          Level
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signals {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          signalname
          score
          signalcategory
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      MobileNumber
      name
      UserId
      PSStitle
      PSSdescription
      DMStitle
      DMSdescription
      CTtitle
      CTdescription
      Atitle
      Adescription
      Verified
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteHIDI = /* GraphQL */ `
  mutation DeleteHIDI(
    $input: DeleteHIDIInput!
    $condition: ModelHIDIConditionInput
  ) {
    deleteHIDI(input: $input, condition: $condition) {
      createdAt
      Email
      id
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        resume
        education
        experience
        expertise
        graduation
        iframescorelink
        iframehidilink
        updatedAt
        owner
        __typename
      }
      Talents {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          Talent
          Level
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signals {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          signalname
          score
          signalcategory
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      MobileNumber
      name
      UserId
      PSStitle
      PSSdescription
      DMStitle
      DMSdescription
      CTtitle
      CTdescription
      Atitle
      Adescription
      Verified
      updatedAt
      owner
      __typename
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      createdAt
      name
      UserId
      Email
      id
      phone
      about
      hidis {
        items {
          createdAt
          Email
          id
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          MobileNumber
          name
          UserId
          PSStitle
          PSSdescription
          DMStitle
          DMSdescription
          CTtitle
          CTdescription
          Atitle
          Adescription
          Verified
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Skills {
        items {
          createdAt
          profileId
          UserId
          description
          YearsOfExperience
          id
          Skill
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          JoinedCapabilities {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Talents {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          Talent
          Level
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signals {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          signalname
          score
          signalcategory
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      resume
      education
      experience
      expertise
      graduation
      iframescorelink
      iframehidilink
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      createdAt
      name
      UserId
      Email
      id
      phone
      about
      hidis {
        items {
          createdAt
          Email
          id
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          MobileNumber
          name
          UserId
          PSStitle
          PSSdescription
          DMStitle
          DMSdescription
          CTtitle
          CTdescription
          Atitle
          Adescription
          Verified
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Skills {
        items {
          createdAt
          profileId
          UserId
          description
          YearsOfExperience
          id
          Skill
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          JoinedCapabilities {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Talents {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          Talent
          Level
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signals {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          signalname
          score
          signalcategory
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      resume
      education
      experience
      expertise
      graduation
      iframescorelink
      iframehidilink
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      createdAt
      name
      UserId
      Email
      id
      phone
      about
      hidis {
        items {
          createdAt
          Email
          id
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          MobileNumber
          name
          UserId
          PSStitle
          PSSdescription
          DMStitle
          DMSdescription
          CTtitle
          CTdescription
          Atitle
          Adescription
          Verified
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Skills {
        items {
          createdAt
          profileId
          UserId
          description
          YearsOfExperience
          id
          Skill
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          JoinedCapabilities {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Talents {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          Talent
          Level
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      signals {
        items {
          createdAt
          hidiId
          UserId
          Spotter
          description
          id
          signalname
          score
          signalcategory
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            resume
            education
            experience
            expertise
            graduation
            iframescorelink
            iframehidilink
            updatedAt
            owner
            __typename
          }
          hidi {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      resume
      education
      experience
      expertise
      graduation
      iframescorelink
      iframehidilink
      updatedAt
      owner
      __typename
    }
  }
`;
export const createNewprofile = /* GraphQL */ `
  mutation CreateNewprofile(
    $input: CreateNewprofileInput!
    $condition: ModelNewprofileConditionInput
  ) {
    createNewprofile(input: $input, condition: $condition) {
      createdAt
      name
      UserId
      Email
      id
      phone
      about
      hidis {
        items {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CandidateEducation {
        items {
          createdAt
          UserId
          id
          degree
          institute
          duration
          startDate
          EndDate
          Present
          percentage
          description
          location
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedforqrews {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      CandidateExperience {
        items {
          createdAt
          UserId
          id
          startDate
          EndDate
          Present
          company
          designation
          description
          duration
          location
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      comapanyshortlists {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      comapanyrejected {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      inpreference {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          candidateprofileId
          acceptanceStatus
          ReasonofRecommendation
          ReasonofShortlist
          Reasonofrejection
          acceptanceStatus_by_CompanyUser_ID
          acceptanceStatus_by_CompanyUser_name
          recommendedbyId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          preferencesId
          companyQrewId
          JobApplicationsId
          JobApplication {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          JobPostId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          new
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Skills {
        items {
          createdAt
          profileId
          UserId
          description
          YearsOfExperience
          id
          Skill
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          JoinedCapabilities {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      profileFeedback {
        items {
          createdAt
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          designation
          description
          UserId
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      certifications {
        items {
          createdAt
          id
          Certificatename
          description
          Source
          certificateId
          validity
          UserId
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Capabilities {
        items {
          createdAt
          ProfileId
          id
          PointsEarned {
            items {
              id
              Project
              Hack
              ProjectLink
              Video
              Photo
              Presentation
              experience
              Claim
              Outcomes
              PeersVouch
              GlobalCapabilityConfigId
              Helped
              Problem
              Challenges
              CapabilityPoints
              UserId
              capabilityPointsCapabilityClaimedId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          userProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ConnectedHidis {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          capabilitiesCapabilityConfigId
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          JoinedSkills {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CandidatePreferences {
        items {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobApplications {
        items {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ActivatedCandisatePreferencesID
      activeCandidatePreferences {
        createdAt
        id
        rolename
        jobType
        workType
        rolelevel
        benefits
        priority
        expectation
        Salaryexpectation
        ContractCompensation
        currentSalary
        companyType
        workStyle
        minimumContractPeriod
        servingNotice
        noticePeriod
        workSchedule
        Activelylooking
        contractType
        compensationStructure
        AvailableDate
        userDomain
        roletype
        location1
        location2
        location3
        Country
        CurrencyCountry
        ActivatePreference
        prevContractCount
        profileId
        UserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ProfilebyCandidatePreferencesId {
          items {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      ConnectedUserSegments {
        items {
          UserSegmentId
          UserId
          createdAt
          updatedAt
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      salarycurrent
      salaryexpectation
      certification
      noticeperiod
      currentoffer
      education
      experience
      expertise
      graduation
      image
      resume
      location
      imageicon1
      link1
      imageicon2
      link2
      imageicon3
      link3
      profileviews
      relevant
      connected
      imagekey
      typeofwork
      resumekey
      ConsentToShareInfo
      IntroVideo
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateNewprofile = /* GraphQL */ `
  mutation UpdateNewprofile(
    $input: UpdateNewprofileInput!
    $condition: ModelNewprofileConditionInput
  ) {
    updateNewprofile(input: $input, condition: $condition) {
      createdAt
      name
      UserId
      Email
      id
      phone
      about
      hidis {
        items {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CandidateEducation {
        items {
          createdAt
          UserId
          id
          degree
          institute
          duration
          startDate
          EndDate
          Present
          percentage
          description
          location
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedforqrews {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      CandidateExperience {
        items {
          createdAt
          UserId
          id
          startDate
          EndDate
          Present
          company
          designation
          description
          duration
          location
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      comapanyshortlists {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      comapanyrejected {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      inpreference {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          candidateprofileId
          acceptanceStatus
          ReasonofRecommendation
          ReasonofShortlist
          Reasonofrejection
          acceptanceStatus_by_CompanyUser_ID
          acceptanceStatus_by_CompanyUser_name
          recommendedbyId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          preferencesId
          companyQrewId
          JobApplicationsId
          JobApplication {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          JobPostId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          new
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Skills {
        items {
          createdAt
          profileId
          UserId
          description
          YearsOfExperience
          id
          Skill
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          JoinedCapabilities {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      profileFeedback {
        items {
          createdAt
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          designation
          description
          UserId
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      certifications {
        items {
          createdAt
          id
          Certificatename
          description
          Source
          certificateId
          validity
          UserId
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Capabilities {
        items {
          createdAt
          ProfileId
          id
          PointsEarned {
            items {
              id
              Project
              Hack
              ProjectLink
              Video
              Photo
              Presentation
              experience
              Claim
              Outcomes
              PeersVouch
              GlobalCapabilityConfigId
              Helped
              Problem
              Challenges
              CapabilityPoints
              UserId
              capabilityPointsCapabilityClaimedId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          userProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ConnectedHidis {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          capabilitiesCapabilityConfigId
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          JoinedSkills {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CandidatePreferences {
        items {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobApplications {
        items {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ActivatedCandisatePreferencesID
      activeCandidatePreferences {
        createdAt
        id
        rolename
        jobType
        workType
        rolelevel
        benefits
        priority
        expectation
        Salaryexpectation
        ContractCompensation
        currentSalary
        companyType
        workStyle
        minimumContractPeriod
        servingNotice
        noticePeriod
        workSchedule
        Activelylooking
        contractType
        compensationStructure
        AvailableDate
        userDomain
        roletype
        location1
        location2
        location3
        Country
        CurrencyCountry
        ActivatePreference
        prevContractCount
        profileId
        UserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ProfilebyCandidatePreferencesId {
          items {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      ConnectedUserSegments {
        items {
          UserSegmentId
          UserId
          createdAt
          updatedAt
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      salarycurrent
      salaryexpectation
      certification
      noticeperiod
      currentoffer
      education
      experience
      expertise
      graduation
      image
      resume
      location
      imageicon1
      link1
      imageicon2
      link2
      imageicon3
      link3
      profileviews
      relevant
      connected
      imagekey
      typeofwork
      resumekey
      ConsentToShareInfo
      IntroVideo
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteNewprofile = /* GraphQL */ `
  mutation DeleteNewprofile(
    $input: DeleteNewprofileInput!
    $condition: ModelNewprofileConditionInput
  ) {
    deleteNewprofile(input: $input, condition: $condition) {
      createdAt
      name
      UserId
      Email
      id
      phone
      about
      hidis {
        items {
          createdAt
          Email
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          UserId
          challenges {
            items {
              createdAt
              id
              challenge
              HidiId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          outcomes {
            items {
              createdAt
              id
              Outcome
              HidiId
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConnectedCapability {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedFeeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          summary
          description
          title
          Verified
          likes
          views
          steps
          jobs
          outcomelink
          impact
          impactOverall
          imagelink
          outcome
          pinnedFlag
          tag1
          tag2
          htmlheading
          htmlsummary
          htmloutput
          peerEmails
          helped
          problem
          Hack
          experience
          projectLink
          whereitwasdone
          whereitwasdonecompany
          SkillUsed
          ExperinceInSkill
          outcomejson
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          AiGenerated
          ProbabilityFake
          ProbabilityReal
          hidiTopic
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CandidateEducation {
        items {
          createdAt
          UserId
          id
          degree
          institute
          duration
          startDate
          EndDate
          Present
          percentage
          description
          location
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedforqrews {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      CandidateExperience {
        items {
          createdAt
          UserId
          id
          startDate
          EndDate
          Present
          company
          designation
          description
          duration
          location
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      comapanyshortlists {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      comapanyrejected {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      inpreference {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          candidateprofileId
          acceptanceStatus
          ReasonofRecommendation
          ReasonofShortlist
          Reasonofrejection
          acceptanceStatus_by_CompanyUser_ID
          acceptanceStatus_by_CompanyUser_name
          recommendedbyId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          preferencesId
          companyQrewId
          JobApplicationsId
          JobApplication {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          JobPostId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          new
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Skills {
        items {
          createdAt
          profileId
          UserId
          description
          YearsOfExperience
          id
          Skill
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          JoinedCapabilities {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      profileFeedback {
        items {
          createdAt
          id
          profileId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          name
          designation
          description
          UserId
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      certifications {
        items {
          createdAt
          id
          Certificatename
          description
          Source
          certificateId
          validity
          UserId
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Capabilities {
        items {
          createdAt
          ProfileId
          id
          PointsEarned {
            items {
              id
              Project
              Hack
              ProjectLink
              Video
              Photo
              Presentation
              experience
              Claim
              Outcomes
              PeersVouch
              GlobalCapabilityConfigId
              Helped
              Problem
              Challenges
              CapabilityPoints
              UserId
              capabilityPointsCapabilityClaimedId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          userProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ConnectedHidis {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          capabilitiesCapabilityConfigId
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          JoinedSkills {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CandidatePreferences {
        items {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobApplications {
        items {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ActivatedCandisatePreferencesID
      activeCandidatePreferences {
        createdAt
        id
        rolename
        jobType
        workType
        rolelevel
        benefits
        priority
        expectation
        Salaryexpectation
        ContractCompensation
        currentSalary
        companyType
        workStyle
        minimumContractPeriod
        servingNotice
        noticePeriod
        workSchedule
        Activelylooking
        contractType
        compensationStructure
        AvailableDate
        userDomain
        roletype
        location1
        location2
        location3
        Country
        CurrencyCountry
        ActivatePreference
        prevContractCount
        profileId
        UserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ProfilebyCandidatePreferencesId {
          items {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      ConnectedUserSegments {
        items {
          UserSegmentId
          UserId
          createdAt
          updatedAt
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      salarycurrent
      salaryexpectation
      certification
      noticeperiod
      currentoffer
      education
      experience
      expertise
      graduation
      image
      resume
      location
      imageicon1
      link1
      imageicon2
      link2
      imageicon3
      link3
      profileviews
      relevant
      connected
      imagekey
      typeofwork
      resumekey
      ConsentToShareInfo
      IntroVideo
      updatedAt
      owner
      __typename
    }
  }
`;
export const createSavedJobs = /* GraphQL */ `
  mutation CreateSavedJobs(
    $input: CreateSavedJobsInput!
    $condition: ModelSavedJobsConditionInput
  ) {
    createSavedJobs(input: $input, condition: $condition) {
      id
      JobPostId
      ProfileId
      UserId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSavedJobs = /* GraphQL */ `
  mutation UpdateSavedJobs(
    $input: UpdateSavedJobsInput!
    $condition: ModelSavedJobsConditionInput
  ) {
    updateSavedJobs(input: $input, condition: $condition) {
      id
      JobPostId
      ProfileId
      UserId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteSavedJobs = /* GraphQL */ `
  mutation DeleteSavedJobs(
    $input: DeleteSavedJobsInput!
    $condition: ModelSavedJobsConditionInput
  ) {
    deleteSavedJobs(input: $input, condition: $condition) {
      id
      JobPostId
      ProfileId
      UserId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCandidatePreferences = /* GraphQL */ `
  mutation CreateCandidatePreferences(
    $input: CreateCandidatePreferencesInput!
    $condition: ModelCandidatePreferencesConditionInput
  ) {
    createCandidatePreferences(input: $input, condition: $condition) {
      createdAt
      id
      rolename
      jobType
      workType
      rolelevel
      benefits
      priority
      expectation
      Salaryexpectation
      ContractCompensation
      currentSalary
      companyType
      workStyle
      minimumContractPeriod
      servingNotice
      noticePeriod
      workSchedule
      Activelylooking
      contractType
      compensationStructure
      AvailableDate
      userDomain
      roletype
      location1
      location2
      location3
      Country
      CurrencyCountry
      ActivatePreference
      prevContractCount
      profileId
      UserProfile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      ProfilebyCandidatePreferencesId {
        items {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const createNewProfileFeedback = /* GraphQL */ `
  mutation CreateNewProfileFeedback(
    $input: CreateNewProfileFeedbackInput!
    $condition: ModelNewProfileFeedbackConditionInput
  ) {
    createNewProfileFeedback(input: $input, condition: $condition) {
      createdAt
      id
      profileId
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      name
      designation
      description
      UserId
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateNewProfileFeedback = /* GraphQL */ `
  mutation UpdateNewProfileFeedback(
    $input: UpdateNewProfileFeedbackInput!
    $condition: ModelNewProfileFeedbackConditionInput
  ) {
    updateNewProfileFeedback(input: $input, condition: $condition) {
      createdAt
      id
      profileId
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      name
      designation
      description
      UserId
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteNewProfileFeedback = /* GraphQL */ `
  mutation DeleteNewProfileFeedback(
    $input: DeleteNewProfileFeedbackInput!
    $condition: ModelNewProfileFeedbackConditionInput
  ) {
    deleteNewProfileFeedback(input: $input, condition: $condition) {
      createdAt
      id
      profileId
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      name
      designation
      description
      UserId
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCertifications = /* GraphQL */ `
  mutation CreateCertifications(
    $input: CreateCertificationsInput!
    $condition: ModelCertificationsConditionInput
  ) {
    createCertifications(input: $input, condition: $condition) {
      createdAt
      id
      Certificatename
      description
      Source
      certificateId
      validity
      UserId
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCertifications = /* GraphQL */ `
  mutation UpdateCertifications(
    $input: UpdateCertificationsInput!
    $condition: ModelCertificationsConditionInput
  ) {
    updateCertifications(input: $input, condition: $condition) {
      createdAt
      id
      Certificatename
      description
      Source
      certificateId
      validity
      UserId
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCertifications = /* GraphQL */ `
  mutation DeleteCertifications(
    $input: DeleteCertificationsInput!
    $condition: ModelCertificationsConditionInput
  ) {
    deleteCertifications(input: $input, condition: $condition) {
      createdAt
      id
      Certificatename
      description
      Source
      certificateId
      validity
      UserId
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteNewhidi = /* GraphQL */ `
  mutation DeleteNewhidi(
    $input: DeleteNewhidiInput!
    $condition: ModelNewhidiConditionInput
  ) {
    deleteNewhidi(input: $input, condition: $condition) {
      createdAt
      Email
      id
      profileId
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      name
      UserId
      challenges {
        items {
          createdAt
          id
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          challenge
          HidiId
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      outcomes {
        items {
          createdAt
          id
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          Outcome
          HidiId
          Verified
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ConnectedCapability {
        items {
          CapabilityId
          HidiId
          Capability {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          createdAt
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      connectedFeeds {
        items {
          id
          UserSegmentId
          HidiId
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          CapabilityConfigId
          jobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          tags
          domain
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          Description
          Payload
          FeedType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      summary
      description
      title
      Verified
      likes
      views
      steps
      jobs
      outcomelink
      impact
      impactOverall
      imagelink
      outcome
      pinnedFlag
      tag1
      tag2
      htmlheading
      htmlsummary
      htmloutput
      peerEmails
      helped
      problem
      Hack
      experience
      projectLink
      whereitwasdone
      whereitwasdonecompany
      SkillUsed
      ExperinceInSkill
      outcomejson
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      AiGenerated
      ProbabilityFake
      ProbabilityReal
      hidiTopic
      updatedAt
      owner
      __typename
    }
  }
`;
export const createConvoOnHidi = /* GraphQL */ `
  mutation CreateConvoOnHidi(
    $input: CreateConvoOnHidiInput!
    $condition: ModelConvoOnHidiConditionInput
  ) {
    createConvoOnHidi(input: $input, condition: $condition) {
      id
      Question
      Answer
      profileId
      UserId
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      PreferenceId
      companyProfileId
      CompanyProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      companyUserProfileId
      HidiId
      NewHidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      companyUser {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        UserRole
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateConvoOnHidi = /* GraphQL */ `
  mutation UpdateConvoOnHidi(
    $input: UpdateConvoOnHidiInput!
    $condition: ModelConvoOnHidiConditionInput
  ) {
    updateConvoOnHidi(input: $input, condition: $condition) {
      id
      Question
      Answer
      profileId
      UserId
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      PreferenceId
      companyProfileId
      CompanyProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      companyUserProfileId
      HidiId
      NewHidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      companyUser {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        UserRole
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteConvoOnHidi = /* GraphQL */ `
  mutation DeleteConvoOnHidi(
    $input: DeleteConvoOnHidiInput!
    $condition: ModelConvoOnHidiConditionInput
  ) {
    deleteConvoOnHidi(input: $input, condition: $condition) {
      id
      Question
      Answer
      profileId
      UserId
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      PreferenceId
      companyProfileId
      CompanyProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      companyUserProfileId
      HidiId
      NewHidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      companyUser {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        UserRole
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createOutcome = /* GraphQL */ `
  mutation CreateOutcome(
    $input: CreateOutcomeInput!
    $condition: ModelOutcomeConditionInput
  ) {
    createOutcome(input: $input, condition: $condition) {
      createdAt
      id
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      Outcome
      HidiId
      Verified
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateOutcome = /* GraphQL */ `
  mutation UpdateOutcome(
    $input: UpdateOutcomeInput!
    $condition: ModelOutcomeConditionInput
  ) {
    updateOutcome(input: $input, condition: $condition) {
      createdAt
      id
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      Outcome
      HidiId
      Verified
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteOutcome = /* GraphQL */ `
  mutation DeleteOutcome(
    $input: DeleteOutcomeInput!
    $condition: ModelOutcomeConditionInput
  ) {
    deleteOutcome(input: $input, condition: $condition) {
      createdAt
      id
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      Outcome
      HidiId
      Verified
      updatedAt
      owner
      __typename
    }
  }
`;
export const createChallenges = /* GraphQL */ `
  mutation CreateChallenges(
    $input: CreateChallengesInput!
    $condition: ModelChallengesConditionInput
  ) {
    createChallenges(input: $input, condition: $condition) {
      createdAt
      id
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      challenge
      HidiId
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateChallenges = /* GraphQL */ `
  mutation UpdateChallenges(
    $input: UpdateChallengesInput!
    $condition: ModelChallengesConditionInput
  ) {
    updateChallenges(input: $input, condition: $condition) {
      createdAt
      id
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      challenge
      HidiId
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteChallenges = /* GraphQL */ `
  mutation DeleteChallenges(
    $input: DeleteChallengesInput!
    $condition: ModelChallengesConditionInput
  ) {
    deleteChallenges(input: $input, condition: $condition) {
      createdAt
      id
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      challenge
      HidiId
      updatedAt
      owner
      __typename
    }
  }
`;
export const createTimeline = /* GraphQL */ `
  mutation CreateTimeline(
    $input: CreateTimelineInput!
    $condition: ModelTimelineConditionInput
  ) {
    createTimeline(input: $input, condition: $condition) {
      createdAt
      UserId
      profileId
      description
      id
      Timelinestring
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateTimeline = /* GraphQL */ `
  mutation UpdateTimeline(
    $input: UpdateTimelineInput!
    $condition: ModelTimelineConditionInput
  ) {
    updateTimeline(input: $input, condition: $condition) {
      createdAt
      UserId
      profileId
      description
      id
      Timelinestring
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteTimeline = /* GraphQL */ `
  mutation DeleteTimeline(
    $input: DeleteTimelineInput!
    $condition: ModelTimelineConditionInput
  ) {
    deleteTimeline(input: $input, condition: $condition) {
      createdAt
      UserId
      profileId
      description
      id
      Timelinestring
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCompanyprofile = /* GraphQL */ `
  mutation CreateCompanyprofile(
    $input: CreateCompanyprofileInput!
    $condition: ModelCompanyprofileConditionInput
  ) {
    createCompanyprofile(input: $input, condition: $condition) {
      createdAt
      businessUserId
      name
      companyMailId
      id
      preferences
      about
      websiteUrl
      LogoImageKey
      IndustryType
      Shortlists {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CompanyQrew {
        items {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      prefferedprofile {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          candidateprofileId
          acceptanceStatus
          ReasonofRecommendation
          ReasonofShortlist
          Reasonofrejection
          acceptanceStatus_by_CompanyUser_ID
          acceptanceStatus_by_CompanyUser_name
          recommendedbyId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          preferencesId
          companyQrewId
          JobApplicationsId
          JobApplication {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          JobPostId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          new
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      preferenceslist {
        items {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      rejected {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      companyusers {
        items {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          UserRole
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCompanyprofile = /* GraphQL */ `
  mutation UpdateCompanyprofile(
    $input: UpdateCompanyprofileInput!
    $condition: ModelCompanyprofileConditionInput
  ) {
    updateCompanyprofile(input: $input, condition: $condition) {
      createdAt
      businessUserId
      name
      companyMailId
      id
      preferences
      about
      websiteUrl
      LogoImageKey
      IndustryType
      Shortlists {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CompanyQrew {
        items {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      prefferedprofile {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          candidateprofileId
          acceptanceStatus
          ReasonofRecommendation
          ReasonofShortlist
          Reasonofrejection
          acceptanceStatus_by_CompanyUser_ID
          acceptanceStatus_by_CompanyUser_name
          recommendedbyId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          preferencesId
          companyQrewId
          JobApplicationsId
          JobApplication {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          JobPostId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          new
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      preferenceslist {
        items {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      rejected {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      companyusers {
        items {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          UserRole
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCompanyprofile = /* GraphQL */ `
  mutation DeleteCompanyprofile(
    $input: DeleteCompanyprofileInput!
    $condition: ModelCompanyprofileConditionInput
  ) {
    deleteCompanyprofile(input: $input, condition: $condition) {
      createdAt
      businessUserId
      name
      companyMailId
      id
      preferences
      about
      websiteUrl
      LogoImageKey
      IndustryType
      Shortlists {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CompanyQrew {
        items {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      prefferedprofile {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          candidateprofileId
          acceptanceStatus
          ReasonofRecommendation
          ReasonofShortlist
          Reasonofrejection
          acceptanceStatus_by_CompanyUser_ID
          acceptanceStatus_by_CompanyUser_name
          recommendedbyId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          preferencesId
          companyQrewId
          JobApplicationsId
          JobApplication {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          JobPostId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          new
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      preferenceslist {
        items {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      rejected {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      companyusers {
        items {
          createdAt
          username
          designation
          ProfilePictureKey
          businessProfileId
          id
          cognitoId
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          UserRole
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          email
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCompanyusers = /* GraphQL */ `
  mutation CreateCompanyusers(
    $input: CreateCompanyusersInput!
    $condition: ModelCompanyusersConditionInput
  ) {
    createCompanyusers(input: $input, condition: $condition) {
      createdAt
      username
      designation
      ProfilePictureKey
      businessProfileId
      id
      cognitoId
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      companyprofile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Shortlists {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      rejected {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      UserRole
      editObjectiveHistory {
        items {
          id
          createdAt
          updatedAt
          objectiveId
          Objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          prevTitle
          editedbyCompanyUserProfileId
          editbyCompanyUserProfile {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          editedbyDeployedQrewId
          editedDeployedQrewProfile {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      email
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCompanyusers = /* GraphQL */ `
  mutation UpdateCompanyusers(
    $input: UpdateCompanyusersInput!
    $condition: ModelCompanyusersConditionInput
  ) {
    updateCompanyusers(input: $input, condition: $condition) {
      createdAt
      username
      designation
      ProfilePictureKey
      businessProfileId
      id
      cognitoId
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      companyprofile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Shortlists {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      rejected {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      UserRole
      editObjectiveHistory {
        items {
          id
          createdAt
          updatedAt
          objectiveId
          Objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          prevTitle
          editedbyCompanyUserProfileId
          editbyCompanyUserProfile {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          editedbyDeployedQrewId
          editedDeployedQrewProfile {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      email
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCompanyusers = /* GraphQL */ `
  mutation DeleteCompanyusers(
    $input: DeleteCompanyusersInput!
    $condition: ModelCompanyusersConditionInput
  ) {
    deleteCompanyusers(input: $input, condition: $condition) {
      createdAt
      username
      designation
      ProfilePictureKey
      businessProfileId
      id
      cognitoId
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      companyprofile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Shortlists {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      rejected {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      UserRole
      editObjectiveHistory {
        items {
          id
          createdAt
          updatedAt
          objectiveId
          Objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          prevTitle
          editedbyCompanyUserProfileId
          editbyCompanyUserProfile {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          editedbyDeployedQrewId
          editedDeployedQrewProfile {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      email
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCompanyQrew = /* GraphQL */ `
  mutation CreateCompanyQrew(
    $input: CreateCompanyQrewInput!
    $condition: ModelCompanyQrewConditionInput
  ) {
    createCompanyQrew(input: $input, condition: $condition) {
      id
      qrewname
      aboutqrew
      businessProfileId
      companyprofile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Invoices {
        items {
          id
          CtcInvoiceNumber
          PlatformInvoiceNumber
          CompanyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          businessProfileId
          companyName
          CtcInvoiceS3Key
          PlarformInvoiceS3Key
          CtcTxnNumber
          PlatformTxnNumber
          CtcTxnNumberApproved
          PlatformTxnNumberApproved
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      qrewroles {
        items {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedQrew {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      objectives {
        items {
          id
          createdAt
          updatedAt
          title
          creator
          companyProfileId
          companyUserId
          ObjectivekeyResults {
            items {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrewId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      durationInmonths
      qrewLive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCompanyQrew = /* GraphQL */ `
  mutation UpdateCompanyQrew(
    $input: UpdateCompanyQrewInput!
    $condition: ModelCompanyQrewConditionInput
  ) {
    updateCompanyQrew(input: $input, condition: $condition) {
      id
      qrewname
      aboutqrew
      businessProfileId
      companyprofile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Invoices {
        items {
          id
          CtcInvoiceNumber
          PlatformInvoiceNumber
          CompanyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          businessProfileId
          companyName
          CtcInvoiceS3Key
          PlarformInvoiceS3Key
          CtcTxnNumber
          PlatformTxnNumber
          CtcTxnNumberApproved
          PlatformTxnNumberApproved
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      qrewroles {
        items {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedQrew {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      objectives {
        items {
          id
          createdAt
          updatedAt
          title
          creator
          companyProfileId
          companyUserId
          ObjectivekeyResults {
            items {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrewId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      durationInmonths
      qrewLive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCompanyQrew = /* GraphQL */ `
  mutation DeleteCompanyQrew(
    $input: DeleteCompanyQrewInput!
    $condition: ModelCompanyQrewConditionInput
  ) {
    deleteCompanyQrew(input: $input, condition: $condition) {
      id
      qrewname
      aboutqrew
      businessProfileId
      companyprofile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Invoices {
        items {
          id
          CtcInvoiceNumber
          PlatformInvoiceNumber
          CompanyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          businessProfileId
          companyName
          CtcInvoiceS3Key
          PlarformInvoiceS3Key
          CtcTxnNumber
          PlatformTxnNumber
          CtcTxnNumberApproved
          PlatformTxnNumberApproved
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      qrewroles {
        items {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedQrew {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      objectives {
        items {
          id
          createdAt
          updatedAt
          title
          creator
          companyProfileId
          companyUserId
          ObjectivekeyResults {
            items {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrewId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      durationInmonths
      qrewLive
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCompanyQrewInvoices = /* GraphQL */ `
  mutation CreateCompanyQrewInvoices(
    $input: CreateCompanyQrewInvoicesInput!
    $condition: ModelCompanyQrewInvoicesConditionInput
  ) {
    createCompanyQrewInvoices(input: $input, condition: $condition) {
      id
      CtcInvoiceNumber
      PlatformInvoiceNumber
      CompanyQrewId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      businessProfileId
      companyName
      CtcInvoiceS3Key
      PlarformInvoiceS3Key
      CtcTxnNumber
      PlatformTxnNumber
      CtcTxnNumberApproved
      PlatformTxnNumberApproved
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCompanyQrewInvoices = /* GraphQL */ `
  mutation UpdateCompanyQrewInvoices(
    $input: UpdateCompanyQrewInvoicesInput!
    $condition: ModelCompanyQrewInvoicesConditionInput
  ) {
    updateCompanyQrewInvoices(input: $input, condition: $condition) {
      id
      CtcInvoiceNumber
      PlatformInvoiceNumber
      CompanyQrewId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      businessProfileId
      companyName
      CtcInvoiceS3Key
      PlarformInvoiceS3Key
      CtcTxnNumber
      PlatformTxnNumber
      CtcTxnNumberApproved
      PlatformTxnNumberApproved
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCompanyQrewInvoices = /* GraphQL */ `
  mutation DeleteCompanyQrewInvoices(
    $input: DeleteCompanyQrewInvoicesInput!
    $condition: ModelCompanyQrewInvoicesConditionInput
  ) {
    deleteCompanyQrewInvoices(input: $input, condition: $condition) {
      id
      CtcInvoiceNumber
      PlatformInvoiceNumber
      CompanyQrewId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      businessProfileId
      companyName
      CtcInvoiceS3Key
      PlarformInvoiceS3Key
      CtcTxnNumber
      PlatformTxnNumber
      CtcTxnNumberApproved
      PlatformTxnNumberApproved
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createPreferences = /* GraphQL */ `
  mutation CreatePreferences(
    $input: CreatePreferencesInput!
    $condition: ModelPreferencesConditionInput
  ) {
    createPreferences(input: $input, condition: $condition) {
      createdAt
      id
      rolename
      businessUserId
      businessProfileId
      companyQrewId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      companyprofile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      recommendedprofiles {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          candidateprofileId
          acceptanceStatus
          ReasonofRecommendation
          ReasonofShortlist
          Reasonofrejection
          acceptanceStatus_by_CompanyUser_ID
          acceptanceStatus_by_CompanyUser_name
          recommendedbyId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          preferencesId
          companyQrewId
          JobApplicationsId
          JobApplication {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          JobPostId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          new
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      shortlistedProfiles {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      rejectedProfiles {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobApplications {
        items {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobPosts {
        items {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedQrew {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobTraits {
        items {
          id
          TraitName
          createdAt
          updatedAt
          preferencesID
          owner
          __typename
        }
        nextToken
        __typename
      }
      package
      certifications
      noticeperiod
      maxExperienceRequired
      minExperienceRequired
      modeofwork
      JobDomain
      other
      previousrole
      combinedrole
      currencyCountry
      JobType
      Location
      JobPublished
      numberOfCandidateRequired
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePreferences = /* GraphQL */ `
  mutation UpdatePreferences(
    $input: UpdatePreferencesInput!
    $condition: ModelPreferencesConditionInput
  ) {
    updatePreferences(input: $input, condition: $condition) {
      createdAt
      id
      rolename
      businessUserId
      businessProfileId
      companyQrewId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      companyprofile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      recommendedprofiles {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          candidateprofileId
          acceptanceStatus
          ReasonofRecommendation
          ReasonofShortlist
          Reasonofrejection
          acceptanceStatus_by_CompanyUser_ID
          acceptanceStatus_by_CompanyUser_name
          recommendedbyId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          preferencesId
          companyQrewId
          JobApplicationsId
          JobApplication {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          JobPostId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          new
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      shortlistedProfiles {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      rejectedProfiles {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobApplications {
        items {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobPosts {
        items {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedQrew {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobTraits {
        items {
          id
          TraitName
          createdAt
          updatedAt
          preferencesID
          owner
          __typename
        }
        nextToken
        __typename
      }
      package
      certifications
      noticeperiod
      maxExperienceRequired
      minExperienceRequired
      modeofwork
      JobDomain
      other
      previousrole
      combinedrole
      currencyCountry
      JobType
      Location
      JobPublished
      numberOfCandidateRequired
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePreferences = /* GraphQL */ `
  mutation DeletePreferences(
    $input: DeletePreferencesInput!
    $condition: ModelPreferencesConditionInput
  ) {
    deletePreferences(input: $input, condition: $condition) {
      createdAt
      id
      rolename
      businessUserId
      businessProfileId
      companyQrewId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      companyprofile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      recommendedprofiles {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          candidateprofileId
          acceptanceStatus
          ReasonofRecommendation
          ReasonofShortlist
          Reasonofrejection
          acceptanceStatus_by_CompanyUser_ID
          acceptanceStatus_by_CompanyUser_name
          recommendedbyId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          preferencesId
          companyQrewId
          JobApplicationsId
          JobApplication {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          JobPostId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          new
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      shortlistedProfiles {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      rejectedProfiles {
        items {
          createdAt
          businessProfileId
          businessUserId
          shortlistedbyId
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          UserId
          about
          candidateprofileId
          Reasonofrejection
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobApplications {
        items {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobPosts {
        items {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedQrew {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          finalCTCQrew
          candidateprofileId
          qrewPosition
          deployedUserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          preferencesId
          JobPostPreferences {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          deployedQrewHistory {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              NewContractCTC
              ContractEndedBy
              ContractPausedBy
              JobApplicationsId
              deployedQrewId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          CandidateContractNegotiatingReason
          NewContractCTC
          ContractEndedBy
          ContractExtendedBy
          ContractPausedBy
          JobApplicationsId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          editObjectiveHistory {
            items {
              id
              createdAt
              updatedAt
              objectiveId
              prevTitle
              editedbyCompanyUserProfileId
              editedbyDeployedQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      JobTraits {
        items {
          id
          TraitName
          createdAt
          updatedAt
          preferencesID
          owner
          __typename
        }
        nextToken
        __typename
      }
      package
      certifications
      noticeperiod
      maxExperienceRequired
      minExperienceRequired
      modeofwork
      JobDomain
      other
      previousrole
      combinedrole
      currencyCountry
      JobType
      Location
      JobPublished
      numberOfCandidateRequired
      updatedAt
      owner
      __typename
    }
  }
`;
export const createJobPostQuestions = /* GraphQL */ `
  mutation CreateJobPostQuestions(
    $input: CreateJobPostQuestionsInput!
    $condition: ModelJobPostQuestionsConditionInput
  ) {
    createJobPostQuestions(input: $input, condition: $condition) {
      id
      JobPostId
      preferencesID
      Question
      TypeOfQuesiton
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateJobPostQuestions = /* GraphQL */ `
  mutation UpdateJobPostQuestions(
    $input: UpdateJobPostQuestionsInput!
    $condition: ModelJobPostQuestionsConditionInput
  ) {
    updateJobPostQuestions(input: $input, condition: $condition) {
      id
      JobPostId
      preferencesID
      Question
      TypeOfQuesiton
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteJobPostQuestions = /* GraphQL */ `
  mutation DeleteJobPostQuestions(
    $input: DeleteJobPostQuestionsInput!
    $condition: ModelJobPostQuestionsConditionInput
  ) {
    deleteJobPostQuestions(input: $input, condition: $condition) {
      id
      JobPostId
      preferencesID
      Question
      TypeOfQuesiton
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createJobPostQuestionsAnswered = /* GraphQL */ `
  mutation CreateJobPostQuestionsAnswered(
    $input: CreateJobPostQuestionsAnsweredInput!
    $condition: ModelJobPostQuestionsAnsweredConditionInput
  ) {
    createJobPostQuestionsAnswered(input: $input, condition: $condition) {
      id
      JobPostQuestionsId
      JobPostId
      preferencesID
      answeredbyUserId
      Question
      Answer
      TypeOfQuesiton
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateJobPostQuestionsAnswered = /* GraphQL */ `
  mutation UpdateJobPostQuestionsAnswered(
    $input: UpdateJobPostQuestionsAnsweredInput!
    $condition: ModelJobPostQuestionsAnsweredConditionInput
  ) {
    updateJobPostQuestionsAnswered(input: $input, condition: $condition) {
      id
      JobPostQuestionsId
      JobPostId
      preferencesID
      answeredbyUserId
      Question
      Answer
      TypeOfQuesiton
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteJobPostQuestionsAnswered = /* GraphQL */ `
  mutation DeleteJobPostQuestionsAnswered(
    $input: DeleteJobPostQuestionsAnsweredInput!
    $condition: ModelJobPostQuestionsAnsweredConditionInput
  ) {
    deleteJobPostQuestionsAnswered(input: $input, condition: $condition) {
      id
      JobPostQuestionsId
      JobPostId
      preferencesID
      answeredbyUserId
      Question
      Answer
      TypeOfQuesiton
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createJobTraits = /* GraphQL */ `
  mutation CreateJobTraits(
    $input: CreateJobTraitsInput!
    $condition: ModelJobTraitsConditionInput
  ) {
    createJobTraits(input: $input, condition: $condition) {
      id
      TraitName
      createdAt
      updatedAt
      preferencesID
      owner
      __typename
    }
  }
`;
export const updateJobTraits = /* GraphQL */ `
  mutation UpdateJobTraits(
    $input: UpdateJobTraitsInput!
    $condition: ModelJobTraitsConditionInput
  ) {
    updateJobTraits(input: $input, condition: $condition) {
      id
      TraitName
      createdAt
      updatedAt
      preferencesID
      owner
      __typename
    }
  }
`;
export const deleteJobTraits = /* GraphQL */ `
  mutation DeleteJobTraits(
    $input: DeleteJobTraitsInput!
    $condition: ModelJobTraitsConditionInput
  ) {
    deleteJobTraits(input: $input, condition: $condition) {
      id
      TraitName
      createdAt
      updatedAt
      preferencesID
      owner
      __typename
    }
  }
`;
export const createShortlist = /* GraphQL */ `
  mutation CreateShortlist(
    $input: CreateShortlistInput!
    $condition: ModelShortlistConditionInput
  ) {
    createShortlist(input: $input, condition: $condition) {
      createdAt
      businessProfileId
      businessUserId
      UserId
      about
      candidateprofileId
      shortlistedbyId
      JobPostId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      shortlistedByUser {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        UserRole
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        updatedAt
        owner
        __typename
      }
      recommededId
      preferencesId
      prferenceType {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      id
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      businessProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateShortlist = /* GraphQL */ `
  mutation UpdateShortlist(
    $input: UpdateShortlistInput!
    $condition: ModelShortlistConditionInput
  ) {
    updateShortlist(input: $input, condition: $condition) {
      createdAt
      businessProfileId
      businessUserId
      UserId
      about
      candidateprofileId
      shortlistedbyId
      JobPostId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      shortlistedByUser {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        UserRole
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        updatedAt
        owner
        __typename
      }
      recommededId
      preferencesId
      prferenceType {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      id
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      businessProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteShortlist = /* GraphQL */ `
  mutation DeleteShortlist(
    $input: DeleteShortlistInput!
    $condition: ModelShortlistConditionInput
  ) {
    deleteShortlist(input: $input, condition: $condition) {
      createdAt
      businessProfileId
      businessUserId
      UserId
      about
      candidateprofileId
      shortlistedbyId
      JobPostId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      shortlistedByUser {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        UserRole
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        updatedAt
        owner
        __typename
      }
      recommededId
      preferencesId
      prferenceType {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      id
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      businessProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const createRejected = /* GraphQL */ `
  mutation CreateRejected(
    $input: CreateRejectedInput!
    $condition: ModelRejectedConditionInput
  ) {
    createRejected(input: $input, condition: $condition) {
      createdAt
      businessProfileId
      businessUserId
      shortlistedbyId
      shortlistedByUser {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        UserRole
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        updatedAt
        owner
        __typename
      }
      UserId
      about
      candidateprofileId
      Reasonofrejection
      recommededId
      preferencesId
      prferenceType {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      id
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      businessProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateRejected = /* GraphQL */ `
  mutation UpdateRejected(
    $input: UpdateRejectedInput!
    $condition: ModelRejectedConditionInput
  ) {
    updateRejected(input: $input, condition: $condition) {
      createdAt
      businessProfileId
      businessUserId
      shortlistedbyId
      shortlistedByUser {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        UserRole
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        updatedAt
        owner
        __typename
      }
      UserId
      about
      candidateprofileId
      Reasonofrejection
      recommededId
      preferencesId
      prferenceType {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      id
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      businessProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteRejected = /* GraphQL */ `
  mutation DeleteRejected(
    $input: DeleteRejectedInput!
    $condition: ModelRejectedConditionInput
  ) {
    deleteRejected(input: $input, condition: $condition) {
      createdAt
      businessProfileId
      businessUserId
      shortlistedbyId
      shortlistedByUser {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        UserRole
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        updatedAt
        owner
        __typename
      }
      UserId
      about
      candidateprofileId
      Reasonofrejection
      recommededId
      preferencesId
      prferenceType {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      id
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      businessProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const createTalent = /* GraphQL */ `
  mutation CreateTalent(
    $input: CreateTalentInput!
    $condition: ModelTalentConditionInput
  ) {
    createTalent(input: $input, condition: $condition) {
      createdAt
      hidiId
      UserId
      Spotter
      description
      id
      Talent
      Level
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        resume
        education
        experience
        expertise
        graduation
        iframescorelink
        iframehidilink
        updatedAt
        owner
        __typename
      }
      hidi {
        createdAt
        Email
        id
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          resume
          education
          experience
          expertise
          graduation
          iframescorelink
          iframehidilink
          updatedAt
          owner
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        MobileNumber
        name
        UserId
        PSStitle
        PSSdescription
        DMStitle
        DMSdescription
        CTtitle
        CTdescription
        Atitle
        Adescription
        Verified
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateTalent = /* GraphQL */ `
  mutation UpdateTalent(
    $input: UpdateTalentInput!
    $condition: ModelTalentConditionInput
  ) {
    updateTalent(input: $input, condition: $condition) {
      createdAt
      hidiId
      UserId
      Spotter
      description
      id
      Talent
      Level
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        resume
        education
        experience
        expertise
        graduation
        iframescorelink
        iframehidilink
        updatedAt
        owner
        __typename
      }
      hidi {
        createdAt
        Email
        id
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          resume
          education
          experience
          expertise
          graduation
          iframescorelink
          iframehidilink
          updatedAt
          owner
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        MobileNumber
        name
        UserId
        PSStitle
        PSSdescription
        DMStitle
        DMSdescription
        CTtitle
        CTdescription
        Atitle
        Adescription
        Verified
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteTalent = /* GraphQL */ `
  mutation DeleteTalent(
    $input: DeleteTalentInput!
    $condition: ModelTalentConditionInput
  ) {
    deleteTalent(input: $input, condition: $condition) {
      createdAt
      hidiId
      UserId
      Spotter
      description
      id
      Talent
      Level
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        resume
        education
        experience
        expertise
        graduation
        iframescorelink
        iframehidilink
        updatedAt
        owner
        __typename
      }
      hidi {
        createdAt
        Email
        id
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          resume
          education
          experience
          expertise
          graduation
          iframescorelink
          iframehidilink
          updatedAt
          owner
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        MobileNumber
        name
        UserId
        PSStitle
        PSSdescription
        DMStitle
        DMSdescription
        CTtitle
        CTdescription
        Atitle
        Adescription
        Verified
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCapabilities = /* GraphQL */ `
  mutation CreateCapabilities(
    $input: CreateCapabilitiesInput!
    $condition: ModelCapabilitiesConditionInput
  ) {
    createCapabilities(input: $input, condition: $condition) {
      createdAt
      ProfileId
      id
      PointsEarned {
        items {
          id
          Project
          Hack
          ProjectLink
          Video
          Photo
          Presentation
          experience
          Claim
          Outcomes
          PeersVouch
          GlobalCapabilityConfigId
          Helped
          Problem
          Challenges
          CapabilityPoints
          UserId
          capabilityPointsCapabilityClaimedId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      userProfile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      ConnectedHidis {
        items {
          CapabilityId
          HidiId
          Capability {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          createdAt
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      capabilitiesCapabilityConfigId
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      JoinedSkills {
        items {
          id
          UserId
          SkillId
          CapabilityId
          Skill {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Capabilities {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          GlobalCapabilityConfigId
          GlobalCapabilityConfigName
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCapabilities = /* GraphQL */ `
  mutation UpdateCapabilities(
    $input: UpdateCapabilitiesInput!
    $condition: ModelCapabilitiesConditionInput
  ) {
    updateCapabilities(input: $input, condition: $condition) {
      createdAt
      ProfileId
      id
      PointsEarned {
        items {
          id
          Project
          Hack
          ProjectLink
          Video
          Photo
          Presentation
          experience
          Claim
          Outcomes
          PeersVouch
          GlobalCapabilityConfigId
          Helped
          Problem
          Challenges
          CapabilityPoints
          UserId
          capabilityPointsCapabilityClaimedId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      userProfile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      ConnectedHidis {
        items {
          CapabilityId
          HidiId
          Capability {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          createdAt
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      capabilitiesCapabilityConfigId
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      JoinedSkills {
        items {
          id
          UserId
          SkillId
          CapabilityId
          Skill {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Capabilities {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          GlobalCapabilityConfigId
          GlobalCapabilityConfigName
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCapabilities = /* GraphQL */ `
  mutation DeleteCapabilities(
    $input: DeleteCapabilitiesInput!
    $condition: ModelCapabilitiesConditionInput
  ) {
    deleteCapabilities(input: $input, condition: $condition) {
      createdAt
      ProfileId
      id
      PointsEarned {
        items {
          id
          Project
          Hack
          ProjectLink
          Video
          Photo
          Presentation
          experience
          Claim
          Outcomes
          PeersVouch
          GlobalCapabilityConfigId
          Helped
          Problem
          Challenges
          CapabilityPoints
          UserId
          capabilityPointsCapabilityClaimedId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      userProfile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      ConnectedHidis {
        items {
          CapabilityId
          HidiId
          Capability {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          createdAt
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      capabilitiesCapabilityConfigId
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      JoinedSkills {
        items {
          id
          UserId
          SkillId
          CapabilityId
          Skill {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Capabilities {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          GlobalCapabilityConfigId
          GlobalCapabilityConfigName
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const createJoinHidisAndCapabilities = /* GraphQL */ `
  mutation CreateJoinHidisAndCapabilities(
    $input: CreateJoinHidisAndCapabilitiesInput!
    $condition: ModelJoinHidisAndCapabilitiesConditionInput
  ) {
    createJoinHidisAndCapabilities(input: $input, condition: $condition) {
      CapabilityId
      HidiId
      Capability {
        createdAt
        ProfileId
        id
        PointsEarned {
          items {
            id
            Project
            Hack
            ProjectLink
            Video
            Photo
            Presentation
            experience
            Claim
            Outcomes
            PeersVouch
            GlobalCapabilityConfigId
            Helped
            Problem
            Challenges
            CapabilityPoints
            UserId
            capabilityPointsCapabilityClaimedId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        userProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ConnectedHidis {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        capabilitiesCapabilityConfigId
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        JoinedSkills {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      createdAt
      id
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateJoinHidisAndCapabilities = /* GraphQL */ `
  mutation UpdateJoinHidisAndCapabilities(
    $input: UpdateJoinHidisAndCapabilitiesInput!
    $condition: ModelJoinHidisAndCapabilitiesConditionInput
  ) {
    updateJoinHidisAndCapabilities(input: $input, condition: $condition) {
      CapabilityId
      HidiId
      Capability {
        createdAt
        ProfileId
        id
        PointsEarned {
          items {
            id
            Project
            Hack
            ProjectLink
            Video
            Photo
            Presentation
            experience
            Claim
            Outcomes
            PeersVouch
            GlobalCapabilityConfigId
            Helped
            Problem
            Challenges
            CapabilityPoints
            UserId
            capabilityPointsCapabilityClaimedId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        userProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ConnectedHidis {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        capabilitiesCapabilityConfigId
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        JoinedSkills {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      createdAt
      id
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteJoinHidisAndCapabilities = /* GraphQL */ `
  mutation DeleteJoinHidisAndCapabilities(
    $input: DeleteJoinHidisAndCapabilitiesInput!
    $condition: ModelJoinHidisAndCapabilitiesConditionInput
  ) {
    deleteJoinHidisAndCapabilities(input: $input, condition: $condition) {
      CapabilityId
      HidiId
      Capability {
        createdAt
        ProfileId
        id
        PointsEarned {
          items {
            id
            Project
            Hack
            ProjectLink
            Video
            Photo
            Presentation
            experience
            Claim
            Outcomes
            PeersVouch
            GlobalCapabilityConfigId
            Helped
            Problem
            Challenges
            CapabilityPoints
            UserId
            capabilityPointsCapabilityClaimedId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        userProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ConnectedHidis {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        capabilitiesCapabilityConfigId
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        JoinedSkills {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      createdAt
      id
      updatedAt
      owner
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      cognitoId
      id
      username
      usertype
      fullname
      registered
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      cognitoId
      id
      username
      usertype
      fullname
      registered
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      cognitoId
      id
      username
      usertype
      fullname
      registered
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createSignal = /* GraphQL */ `
  mutation CreateSignal(
    $input: CreateSignalInput!
    $condition: ModelSignalConditionInput
  ) {
    createSignal(input: $input, condition: $condition) {
      createdAt
      hidiId
      UserId
      Spotter
      description
      id
      signalname
      score
      signalcategory
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        resume
        education
        experience
        expertise
        graduation
        iframescorelink
        iframehidilink
        updatedAt
        owner
        __typename
      }
      hidi {
        createdAt
        Email
        id
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          resume
          education
          experience
          expertise
          graduation
          iframescorelink
          iframehidilink
          updatedAt
          owner
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        MobileNumber
        name
        UserId
        PSStitle
        PSSdescription
        DMStitle
        DMSdescription
        CTtitle
        CTdescription
        Atitle
        Adescription
        Verified
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSignal = /* GraphQL */ `
  mutation UpdateSignal(
    $input: UpdateSignalInput!
    $condition: ModelSignalConditionInput
  ) {
    updateSignal(input: $input, condition: $condition) {
      createdAt
      hidiId
      UserId
      Spotter
      description
      id
      signalname
      score
      signalcategory
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        resume
        education
        experience
        expertise
        graduation
        iframescorelink
        iframehidilink
        updatedAt
        owner
        __typename
      }
      hidi {
        createdAt
        Email
        id
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          resume
          education
          experience
          expertise
          graduation
          iframescorelink
          iframehidilink
          updatedAt
          owner
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        MobileNumber
        name
        UserId
        PSStitle
        PSSdescription
        DMStitle
        DMSdescription
        CTtitle
        CTdescription
        Atitle
        Adescription
        Verified
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteSignal = /* GraphQL */ `
  mutation DeleteSignal(
    $input: DeleteSignalInput!
    $condition: ModelSignalConditionInput
  ) {
    deleteSignal(input: $input, condition: $condition) {
      createdAt
      hidiId
      UserId
      Spotter
      description
      id
      signalname
      score
      signalcategory
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            Talents {
              nextToken
              __typename
            }
            signals {
              nextToken
              __typename
            }
            MobileNumber
            name
            UserId
            PSStitle
            PSSdescription
            DMStitle
            DMSdescription
            CTtitle
            CTdescription
            Atitle
            Adescription
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        resume
        education
        experience
        expertise
        graduation
        iframescorelink
        iframehidilink
        updatedAt
        owner
        __typename
      }
      hidi {
        createdAt
        Email
        id
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Talents {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              Talent
              Level
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          signals {
            items {
              createdAt
              hidiId
              UserId
              Spotter
              description
              id
              signalname
              score
              signalcategory
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          resume
          education
          experience
          expertise
          graduation
          iframescorelink
          iframehidilink
          updatedAt
          owner
          __typename
        }
        Talents {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            Talent
            Level
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        signals {
          items {
            createdAt
            hidiId
            UserId
            Spotter
            description
            id
            signalname
            score
            signalcategory
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              resume
              education
              experience
              expertise
              graduation
              iframescorelink
              iframehidilink
              updatedAt
              owner
              __typename
            }
            hidi {
              createdAt
              Email
              id
              MobileNumber
              name
              UserId
              PSStitle
              PSSdescription
              DMStitle
              DMSdescription
              CTtitle
              CTdescription
              Atitle
              Adescription
              Verified
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        MobileNumber
        name
        UserId
        PSStitle
        PSSdescription
        DMStitle
        DMSdescription
        CTtitle
        CTdescription
        Atitle
        Adescription
        Verified
        updatedAt
        owner
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const createJoinUserSegmentsandNewProfile = /* GraphQL */ `
  mutation CreateJoinUserSegmentsandNewProfile(
    $input: CreateJoinUserSegmentsandNewProfileInput!
    $condition: ModelJoinUserSegmentsandNewProfileConditionInput
  ) {
    createJoinUserSegmentsandNewProfile(input: $input, condition: $condition) {
      UserSegmentId
      UserId
      createdAt
      updatedAt
      id
      owner
      __typename
    }
  }
`;
export const updateJoinUserSegmentsandNewProfile = /* GraphQL */ `
  mutation UpdateJoinUserSegmentsandNewProfile(
    $input: UpdateJoinUserSegmentsandNewProfileInput!
    $condition: ModelJoinUserSegmentsandNewProfileConditionInput
  ) {
    updateJoinUserSegmentsandNewProfile(input: $input, condition: $condition) {
      UserSegmentId
      UserId
      createdAt
      updatedAt
      id
      owner
      __typename
    }
  }
`;
export const deleteJoinUserSegmentsandNewProfile = /* GraphQL */ `
  mutation DeleteJoinUserSegmentsandNewProfile(
    $input: DeleteJoinUserSegmentsandNewProfileInput!
    $condition: ModelJoinUserSegmentsandNewProfileConditionInput
  ) {
    deleteJoinUserSegmentsandNewProfile(input: $input, condition: $condition) {
      UserSegmentId
      UserId
      createdAt
      updatedAt
      id
      owner
      __typename
    }
  }
`;
export const updateCandidatePreferences = /* GraphQL */ `
  mutation UpdateCandidatePreferences(
    $input: UpdateCandidatePreferencesInput!
    $condition: ModelCandidatePreferencesConditionInput
  ) {
    updateCandidatePreferences(input: $input, condition: $condition) {
      createdAt
      id
      rolename
      jobType
      workType
      rolelevel
      benefits
      priority
      expectation
      Salaryexpectation
      ContractCompensation
      currentSalary
      companyType
      workStyle
      minimumContractPeriod
      servingNotice
      noticePeriod
      workSchedule
      Activelylooking
      contractType
      compensationStructure
      AvailableDate
      userDomain
      roletype
      location1
      location2
      location3
      Country
      CurrencyCountry
      ActivatePreference
      prevContractCount
      profileId
      UserProfile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      ProfilebyCandidatePreferencesId {
        items {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCandidatePreferences = /* GraphQL */ `
  mutation DeleteCandidatePreferences(
    $input: DeleteCandidatePreferencesInput!
    $condition: ModelCandidatePreferencesConditionInput
  ) {
    deleteCandidatePreferences(input: $input, condition: $condition) {
      createdAt
      id
      rolename
      jobType
      workType
      rolelevel
      benefits
      priority
      expectation
      Salaryexpectation
      ContractCompensation
      currentSalary
      companyType
      workStyle
      minimumContractPeriod
      servingNotice
      noticePeriod
      workSchedule
      Activelylooking
      contractType
      compensationStructure
      AvailableDate
      userDomain
      roletype
      location1
      location2
      location3
      Country
      CurrencyCountry
      ActivatePreference
      prevContractCount
      profileId
      UserProfile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      ProfilebyCandidatePreferencesId {
        items {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const createNewhidi = /* GraphQL */ `
  mutation CreateNewhidi(
    $input: CreateNewhidiInput!
    $condition: ModelNewhidiConditionInput
  ) {
    createNewhidi(input: $input, condition: $condition) {
      createdAt
      Email
      id
      profileId
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      name
      UserId
      challenges {
        items {
          createdAt
          id
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          challenge
          HidiId
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      outcomes {
        items {
          createdAt
          id
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          Outcome
          HidiId
          Verified
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ConnectedCapability {
        items {
          CapabilityId
          HidiId
          Capability {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          createdAt
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      connectedFeeds {
        items {
          id
          UserSegmentId
          HidiId
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          CapabilityConfigId
          jobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          tags
          domain
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          Description
          Payload
          FeedType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      summary
      description
      title
      Verified
      likes
      views
      steps
      jobs
      outcomelink
      impact
      impactOverall
      imagelink
      outcome
      pinnedFlag
      tag1
      tag2
      htmlheading
      htmlsummary
      htmloutput
      peerEmails
      helped
      problem
      Hack
      experience
      projectLink
      whereitwasdone
      whereitwasdonecompany
      SkillUsed
      ExperinceInSkill
      outcomejson
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      AiGenerated
      ProbabilityFake
      ProbabilityReal
      hidiTopic
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateNewhidi = /* GraphQL */ `
  mutation UpdateNewhidi(
    $input: UpdateNewhidiInput!
    $condition: ModelNewhidiConditionInput
  ) {
    updateNewhidi(input: $input, condition: $condition) {
      createdAt
      Email
      id
      profileId
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      name
      UserId
      challenges {
        items {
          createdAt
          id
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          challenge
          HidiId
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      outcomes {
        items {
          createdAt
          id
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          Outcome
          HidiId
          Verified
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ConnectedCapability {
        items {
          CapabilityId
          HidiId
          Capability {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          createdAt
          id
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      connectedFeeds {
        items {
          id
          UserSegmentId
          HidiId
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          CapabilityConfigId
          jobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          tags
          domain
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          Description
          Payload
          FeedType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      summary
      description
      title
      Verified
      likes
      views
      steps
      jobs
      outcomelink
      impact
      impactOverall
      imagelink
      outcome
      pinnedFlag
      tag1
      tag2
      htmlheading
      htmlsummary
      htmloutput
      peerEmails
      helped
      problem
      Hack
      experience
      projectLink
      whereitwasdone
      whereitwasdonecompany
      SkillUsed
      ExperinceInSkill
      outcomejson
      ConvoOnHidi {
        items {
          id
          Question
          Answer
          profileId
          UserId
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          PreferenceId
          companyProfileId
          CompanyProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          companyUserProfileId
          HidiId
          NewHidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          companyUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      AiGenerated
      ProbabilityFake
      ProbabilityReal
      hidiTopic
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCandidateExperience = /* GraphQL */ `
  mutation CreateCandidateExperience(
    $input: CreateCandidateExperienceInput!
    $condition: ModelCandidateExperienceConditionInput
  ) {
    createCandidateExperience(input: $input, condition: $condition) {
      createdAt
      UserId
      id
      startDate
      EndDate
      Present
      company
      designation
      description
      duration
      location
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCandidateExperience = /* GraphQL */ `
  mutation UpdateCandidateExperience(
    $input: UpdateCandidateExperienceInput!
    $condition: ModelCandidateExperienceConditionInput
  ) {
    updateCandidateExperience(input: $input, condition: $condition) {
      createdAt
      UserId
      id
      startDate
      EndDate
      Present
      company
      designation
      description
      duration
      location
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCandidateExperience = /* GraphQL */ `
  mutation DeleteCandidateExperience(
    $input: DeleteCandidateExperienceInput!
    $condition: ModelCandidateExperienceConditionInput
  ) {
    deleteCandidateExperience(input: $input, condition: $condition) {
      createdAt
      UserId
      id
      startDate
      EndDate
      Present
      company
      designation
      description
      duration
      location
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCandidateEducation = /* GraphQL */ `
  mutation CreateCandidateEducation(
    $input: CreateCandidateEducationInput!
    $condition: ModelCandidateEducationConditionInput
  ) {
    createCandidateEducation(input: $input, condition: $condition) {
      createdAt
      UserId
      id
      degree
      institute
      duration
      startDate
      EndDate
      Present
      percentage
      description
      location
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCandidateEducation = /* GraphQL */ `
  mutation UpdateCandidateEducation(
    $input: UpdateCandidateEducationInput!
    $condition: ModelCandidateEducationConditionInput
  ) {
    updateCandidateEducation(input: $input, condition: $condition) {
      createdAt
      UserId
      id
      degree
      institute
      duration
      startDate
      EndDate
      Present
      percentage
      description
      location
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCandidateEducation = /* GraphQL */ `
  mutation DeleteCandidateEducation(
    $input: DeleteCandidateEducationInput!
    $condition: ModelCandidateEducationConditionInput
  ) {
    deleteCandidateEducation(input: $input, condition: $condition) {
      createdAt
      UserId
      id
      degree
      institute
      duration
      startDate
      EndDate
      Present
      percentage
      description
      location
      updatedAt
      owner
      __typename
    }
  }
`;
export const createRecommendedprofile = /* GraphQL */ `
  mutation CreateRecommendedprofile(
    $input: CreateRecommendedprofileInput!
    $condition: ModelRecommendedprofileConditionInput
  ) {
    createRecommendedprofile(input: $input, condition: $condition) {
      createdAt
      businessProfileId
      businessUserId
      UserId
      candidateprofileId
      acceptanceStatus
      ReasonofRecommendation
      ReasonofShortlist
      Reasonofrejection
      acceptanceStatus_by_CompanyUser_ID
      acceptanceStatus_by_CompanyUser_name
      recommendedbyId
      prferenceType {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      preferencesId
      companyQrewId
      JobApplicationsId
      JobApplication {
        id
        JobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        CompanyProfileId
        ProfileId
        CompanyPreferenceId
        companyQrewId
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ApplyingStatus
        Level
        CandidateProfileAnalysis
        createdAt
        updatedAt
        owner
        __typename
      }
      JobPostId
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      businessProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      new
      id
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateRecommendedprofile = /* GraphQL */ `
  mutation UpdateRecommendedprofile(
    $input: UpdateRecommendedprofileInput!
    $condition: ModelRecommendedprofileConditionInput
  ) {
    updateRecommendedprofile(input: $input, condition: $condition) {
      createdAt
      businessProfileId
      businessUserId
      UserId
      candidateprofileId
      acceptanceStatus
      ReasonofRecommendation
      ReasonofShortlist
      Reasonofrejection
      acceptanceStatus_by_CompanyUser_ID
      acceptanceStatus_by_CompanyUser_name
      recommendedbyId
      prferenceType {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      preferencesId
      companyQrewId
      JobApplicationsId
      JobApplication {
        id
        JobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        CompanyProfileId
        ProfileId
        CompanyPreferenceId
        companyQrewId
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ApplyingStatus
        Level
        CandidateProfileAnalysis
        createdAt
        updatedAt
        owner
        __typename
      }
      JobPostId
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      businessProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      new
      id
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteRecommendedprofile = /* GraphQL */ `
  mutation DeleteRecommendedprofile(
    $input: DeleteRecommendedprofileInput!
    $condition: ModelRecommendedprofileConditionInput
  ) {
    deleteRecommendedprofile(input: $input, condition: $condition) {
      createdAt
      businessProfileId
      businessUserId
      UserId
      candidateprofileId
      acceptanceStatus
      ReasonofRecommendation
      ReasonofShortlist
      Reasonofrejection
      acceptanceStatus_by_CompanyUser_ID
      acceptanceStatus_by_CompanyUser_name
      recommendedbyId
      prferenceType {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      preferencesId
      companyQrewId
      JobApplicationsId
      JobApplication {
        id
        JobPostId
        jobPost {
          id
          CompanyPreferenceId
          companyQrewId
          CompanyPreference {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          companyName
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedCandidates {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostQuestions {
            items {
              id
              JobPostId
              preferencesID
              Question
              TypeOfQuesiton
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          jobPostLink
          logoUrl
          htmldescription
          CompanyDescription
          AvailablityStatus
          experienceRequired
          jobType
          Location
          skills
          domain
          description
          ShortDesc
          RolesandResponsibility
          title
          CapabilitiesRequired {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          FeedsConnected {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CapabilitiesRequiredJSON
          createdAt
          updatedAt
          SavedJobPosts {
            items {
              id
              JobPostId
              ProfileId
              UserId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        CompanyProfileId
        ProfileId
        CompanyPreferenceId
        companyQrewId
        candidateprofile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ApplyingStatus
        Level
        CandidateProfileAnalysis
        createdAt
        updatedAt
        owner
        __typename
      }
      JobPostId
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      businessProfile {
        createdAt
        businessUserId
        name
        companyMailId
        id
        preferences
        about
        websiteUrl
        LogoImageKey
        IndustryType
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CompanyQrew {
          items {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        prefferedprofile {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        preferenceslist {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyusers {
          items {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      new
      id
      updatedAt
      owner
      __typename
    }
  }
`;
export const createDeployedQrewHistory = /* GraphQL */ `
  mutation CreateDeployedQrewHistory(
    $input: CreateDeployedQrewHistoryInput!
    $condition: ModelDeployedQrewHistoryConditionInput
  ) {
    createDeployedQrewHistory(input: $input, condition: $condition) {
      createdAt
      updatedAt
      companyQrewId
      businessProfileId
      finalCTCQrew
      candidateprofileId
      qrewPosition
      preferencesId
      recommededId
      joiningDate
      endingDate
      NDAkey
      Contractkey
      KYCdocumentkey
      WorkingOnit
      EndContract
      ContractEndingDate
      CandidateAcceptanceStatus
      CandidateContractRejectionReason
      ExtensionDurationInMonths
      ExtensionDate
      ExtendingContract
      NewContractCTC
      ContractEndedBy
      ContractPausedBy
      JobApplicationsId
      deployedQrewId
      id
      owner
      __typename
    }
  }
`;
export const updateDeployedQrewHistory = /* GraphQL */ `
  mutation UpdateDeployedQrewHistory(
    $input: UpdateDeployedQrewHistoryInput!
    $condition: ModelDeployedQrewHistoryConditionInput
  ) {
    updateDeployedQrewHistory(input: $input, condition: $condition) {
      createdAt
      updatedAt
      companyQrewId
      businessProfileId
      finalCTCQrew
      candidateprofileId
      qrewPosition
      preferencesId
      recommededId
      joiningDate
      endingDate
      NDAkey
      Contractkey
      KYCdocumentkey
      WorkingOnit
      EndContract
      ContractEndingDate
      CandidateAcceptanceStatus
      CandidateContractRejectionReason
      ExtensionDurationInMonths
      ExtensionDate
      ExtendingContract
      NewContractCTC
      ContractEndedBy
      ContractPausedBy
      JobApplicationsId
      deployedQrewId
      id
      owner
      __typename
    }
  }
`;
export const deleteDeployedQrewHistory = /* GraphQL */ `
  mutation DeleteDeployedQrewHistory(
    $input: DeleteDeployedQrewHistoryInput!
    $condition: ModelDeployedQrewHistoryConditionInput
  ) {
    deleteDeployedQrewHistory(input: $input, condition: $condition) {
      createdAt
      updatedAt
      companyQrewId
      businessProfileId
      finalCTCQrew
      candidateprofileId
      qrewPosition
      preferencesId
      recommededId
      joiningDate
      endingDate
      NDAkey
      Contractkey
      KYCdocumentkey
      WorkingOnit
      EndContract
      ContractEndingDate
      CandidateAcceptanceStatus
      CandidateContractRejectionReason
      ExtensionDurationInMonths
      ExtensionDate
      ExtendingContract
      NewContractCTC
      ContractEndedBy
      ContractPausedBy
      JobApplicationsId
      deployedQrewId
      id
      owner
      __typename
    }
  }
`;
export const createDeployedQrew = /* GraphQL */ `
  mutation CreateDeployedQrew(
    $input: CreateDeployedQrewInput!
    $condition: ModelDeployedQrewConditionInput
  ) {
    createDeployedQrew(input: $input, condition: $condition) {
      createdAt
      updatedAt
      companyQrewId
      businessProfileId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      finalCTCQrew
      candidateprofileId
      qrewPosition
      deployedUserProfile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      preferencesId
      JobPostPreferences {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      deployedQrewHistory {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          finalCTCQrew
          candidateprofileId
          qrewPosition
          preferencesId
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          NewContractCTC
          ContractEndedBy
          ContractPausedBy
          JobApplicationsId
          deployedQrewId
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      recommededId
      joiningDate
      endingDate
      NDAkey
      Contractkey
      KYCdocumentkey
      WorkingOnit
      EndContract
      ContractEndingDate
      ExtensionDurationInMonths
      ExtensionDate
      ExtendingContract
      CandidateAcceptanceStatus
      CandidateContractRejectionReason
      CandidateContractNegotiatingReason
      NewContractCTC
      ContractEndedBy
      ContractExtendedBy
      ContractPausedBy
      JobApplicationsId
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      editObjectiveHistory {
        items {
          id
          createdAt
          updatedAt
          objectiveId
          Objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          prevTitle
          editedbyCompanyUserProfileId
          editbyCompanyUserProfile {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          editedbyDeployedQrewId
          editedDeployedQrewProfile {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      id
      owner
      __typename
    }
  }
`;
export const updateDeployedQrew = /* GraphQL */ `
  mutation UpdateDeployedQrew(
    $input: UpdateDeployedQrewInput!
    $condition: ModelDeployedQrewConditionInput
  ) {
    updateDeployedQrew(input: $input, condition: $condition) {
      createdAt
      updatedAt
      companyQrewId
      businessProfileId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      finalCTCQrew
      candidateprofileId
      qrewPosition
      deployedUserProfile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      preferencesId
      JobPostPreferences {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      deployedQrewHistory {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          finalCTCQrew
          candidateprofileId
          qrewPosition
          preferencesId
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          NewContractCTC
          ContractEndedBy
          ContractPausedBy
          JobApplicationsId
          deployedQrewId
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      recommededId
      joiningDate
      endingDate
      NDAkey
      Contractkey
      KYCdocumentkey
      WorkingOnit
      EndContract
      ContractEndingDate
      ExtensionDurationInMonths
      ExtensionDate
      ExtendingContract
      CandidateAcceptanceStatus
      CandidateContractRejectionReason
      CandidateContractNegotiatingReason
      NewContractCTC
      ContractEndedBy
      ContractExtendedBy
      ContractPausedBy
      JobApplicationsId
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      editObjectiveHistory {
        items {
          id
          createdAt
          updatedAt
          objectiveId
          Objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          prevTitle
          editedbyCompanyUserProfileId
          editbyCompanyUserProfile {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          editedbyDeployedQrewId
          editedDeployedQrewProfile {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      id
      owner
      __typename
    }
  }
`;
export const deleteDeployedQrew = /* GraphQL */ `
  mutation DeleteDeployedQrew(
    $input: DeleteDeployedQrewInput!
    $condition: ModelDeployedQrewConditionInput
  ) {
    deleteDeployedQrew(input: $input, condition: $condition) {
      createdAt
      updatedAt
      companyQrewId
      businessProfileId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      finalCTCQrew
      candidateprofileId
      qrewPosition
      deployedUserProfile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      preferencesId
      JobPostPreferences {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      deployedQrewHistory {
        items {
          createdAt
          updatedAt
          companyQrewId
          businessProfileId
          finalCTCQrew
          candidateprofileId
          qrewPosition
          preferencesId
          recommededId
          joiningDate
          endingDate
          NDAkey
          Contractkey
          KYCdocumentkey
          WorkingOnit
          EndContract
          ContractEndingDate
          CandidateAcceptanceStatus
          CandidateContractRejectionReason
          ExtensionDurationInMonths
          ExtensionDate
          ExtendingContract
          NewContractCTC
          ContractEndedBy
          ContractPausedBy
          JobApplicationsId
          deployedQrewId
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      recommededId
      joiningDate
      endingDate
      NDAkey
      Contractkey
      KYCdocumentkey
      WorkingOnit
      EndContract
      ContractEndingDate
      ExtensionDurationInMonths
      ExtensionDate
      ExtendingContract
      CandidateAcceptanceStatus
      CandidateContractRejectionReason
      CandidateContractNegotiatingReason
      NewContractCTC
      ContractEndedBy
      ContractExtendedBy
      ContractPausedBy
      JobApplicationsId
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      editObjectiveHistory {
        items {
          id
          createdAt
          updatedAt
          objectiveId
          Objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          prevTitle
          editedbyCompanyUserProfileId
          editbyCompanyUserProfile {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          editedbyDeployedQrewId
          editedDeployedQrewProfile {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      id
      owner
      __typename
    }
  }
`;
export const createObjective = /* GraphQL */ `
  mutation CreateObjective(
    $input: CreateObjectiveInput!
    $condition: ModelObjectiveConditionInput
  ) {
    createObjective(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      title
      creator
      companyProfileId
      companyUserId
      ObjectivekeyResults {
        items {
          id
          createdAt
          updatedAt
          keyResult
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          progressStatus
          progresssPercentage
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedQrewId
      companyQrewId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      editHistory {
        items {
          id
          createdAt
          updatedAt
          objectiveId
          Objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          prevTitle
          editedbyCompanyUserProfileId
          editbyCompanyUserProfile {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          editedbyDeployedQrewId
          editedDeployedQrewProfile {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateObjective = /* GraphQL */ `
  mutation UpdateObjective(
    $input: UpdateObjectiveInput!
    $condition: ModelObjectiveConditionInput
  ) {
    updateObjective(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      title
      creator
      companyProfileId
      companyUserId
      ObjectivekeyResults {
        items {
          id
          createdAt
          updatedAt
          keyResult
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          progressStatus
          progresssPercentage
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedQrewId
      companyQrewId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      editHistory {
        items {
          id
          createdAt
          updatedAt
          objectiveId
          Objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          prevTitle
          editedbyCompanyUserProfileId
          editbyCompanyUserProfile {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          editedbyDeployedQrewId
          editedDeployedQrewProfile {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteObjective = /* GraphQL */ `
  mutation DeleteObjective(
    $input: DeleteObjectiveInput!
    $condition: ModelObjectiveConditionInput
  ) {
    deleteObjective(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      title
      creator
      companyProfileId
      companyUserId
      ObjectivekeyResults {
        items {
          id
          createdAt
          updatedAt
          keyResult
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveId
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          progressStatus
          progresssPercentage
          owner
          __typename
        }
        nextToken
        __typename
      }
      deployedQrewId
      companyQrewId
      companyQrew {
        id
        qrewname
        aboutqrew
        businessProfileId
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Invoices {
          items {
            id
            CtcInvoiceNumber
            PlatformInvoiceNumber
            CompanyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            businessProfileId
            companyName
            CtcInvoiceS3Key
            PlarformInvoiceS3Key
            CtcTxnNumber
            PlatformTxnNumber
            CtcTxnNumberApproved
            PlatformTxnNumberApproved
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qrewroles {
          items {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        objectives {
          items {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        durationInmonths
        qrewLive
        createdAt
        updatedAt
        owner
        __typename
      }
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      editHistory {
        items {
          id
          createdAt
          updatedAt
          objectiveId
          Objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          prevTitle
          editedbyCompanyUserProfileId
          editbyCompanyUserProfile {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          editedbyDeployedQrewId
          editedDeployedQrewProfile {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createObjectiveKeyResult = /* GraphQL */ `
  mutation CreateObjectiveKeyResult(
    $input: CreateObjectiveKeyResultInput!
    $condition: ModelObjectiveKeyResultConditionInput
  ) {
    createObjectiveKeyResult(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      keyResult
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      objectiveId
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      progressStatus
      progresssPercentage
      owner
      __typename
    }
  }
`;
export const updateObjectiveKeyResult = /* GraphQL */ `
  mutation UpdateObjectiveKeyResult(
    $input: UpdateObjectiveKeyResultInput!
    $condition: ModelObjectiveKeyResultConditionInput
  ) {
    updateObjectiveKeyResult(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      keyResult
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      objectiveId
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      progressStatus
      progresssPercentage
      owner
      __typename
    }
  }
`;
export const deleteObjectiveKeyResult = /* GraphQL */ `
  mutation DeleteObjectiveKeyResult(
    $input: DeleteObjectiveKeyResultInput!
    $condition: ModelObjectiveKeyResultConditionInput
  ) {
    deleteObjectiveKeyResult(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      keyResult
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      objectiveId
      initiatives {
        items {
          id
          createdAt
          updatedAt
          initiative
          initiativeDocumentation
          keyResultsAcheived
          deployedQrewId
          companyProfileId
          candidateProfileId
          companyQrewId
          objectiveKeyResultId
          objectiveId
          approvalStatus
          objective {
            id
            createdAt
            updatedAt
            title
            creator
            companyProfileId
            companyUserId
            ObjectivekeyResults {
              nextToken
              __typename
            }
            deployedQrewId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            editHistory {
              nextToken
              __typename
            }
            owner
            __typename
          }
          ObjectiveKeyResult {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          InitiativeCreatedBydeployedQrew {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          PercentageContributionOnkeyResult
          TimeRequiredToComplete
          owner
          __typename
        }
        nextToken
        __typename
      }
      progressStatus
      progresssPercentage
      owner
      __typename
    }
  }
`;
export const createObjectiveKeyResultProgressHistory = /* GraphQL */ `
  mutation CreateObjectiveKeyResultProgressHistory(
    $input: CreateObjectiveKeyResultProgressHistoryInput!
    $condition: ModelObjectiveKeyResultProgressHistoryConditionInput
  ) {
    createObjectiveKeyResultProgressHistory(
      input: $input
      condition: $condition
    ) {
      id
      createdAt
      updatedAt
      ObjectiveKeyResultId
      previousPercentageProgress
      ResponsibleInitiativeId
      prevkeyResult
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      objectiveId
      owner
      __typename
    }
  }
`;
export const updateObjectiveKeyResultProgressHistory = /* GraphQL */ `
  mutation UpdateObjectiveKeyResultProgressHistory(
    $input: UpdateObjectiveKeyResultProgressHistoryInput!
    $condition: ModelObjectiveKeyResultProgressHistoryConditionInput
  ) {
    updateObjectiveKeyResultProgressHistory(
      input: $input
      condition: $condition
    ) {
      id
      createdAt
      updatedAt
      ObjectiveKeyResultId
      previousPercentageProgress
      ResponsibleInitiativeId
      prevkeyResult
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      objectiveId
      owner
      __typename
    }
  }
`;
export const deleteObjectiveKeyResultProgressHistory = /* GraphQL */ `
  mutation DeleteObjectiveKeyResultProgressHistory(
    $input: DeleteObjectiveKeyResultProgressHistoryInput!
    $condition: ModelObjectiveKeyResultProgressHistoryConditionInput
  ) {
    deleteObjectiveKeyResultProgressHistory(
      input: $input
      condition: $condition
    ) {
      id
      createdAt
      updatedAt
      ObjectiveKeyResultId
      previousPercentageProgress
      ResponsibleInitiativeId
      prevkeyResult
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      objectiveId
      owner
      __typename
    }
  }
`;
export const createObjectiveEditHistory = /* GraphQL */ `
  mutation CreateObjectiveEditHistory(
    $input: CreateObjectiveEditHistoryInput!
    $condition: ModelObjectiveEditHistoryConditionInput
  ) {
    createObjectiveEditHistory(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      objectiveId
      Objective {
        id
        createdAt
        updatedAt
        title
        creator
        companyProfileId
        companyUserId
        ObjectivekeyResults {
          items {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrewId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      prevTitle
      editedbyCompanyUserProfileId
      editbyCompanyUserProfile {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        UserRole
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        updatedAt
        owner
        __typename
      }
      editedbyDeployedQrewId
      editedDeployedQrewProfile {
        createdAt
        updatedAt
        companyQrewId
        businessProfileId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        finalCTCQrew
        candidateprofileId
        qrewPosition
        deployedUserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        preferencesId
        JobPostPreferences {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        deployedQrewHistory {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            finalCTCQrew
            candidateprofileId
            qrewPosition
            preferencesId
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            NewContractCTC
            ContractEndedBy
            ContractPausedBy
            JobApplicationsId
            deployedQrewId
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        recommededId
        joiningDate
        endingDate
        NDAkey
        Contractkey
        KYCdocumentkey
        WorkingOnit
        EndContract
        ContractEndingDate
        ExtensionDurationInMonths
        ExtensionDate
        ExtendingContract
        CandidateAcceptanceStatus
        CandidateContractRejectionReason
        CandidateContractNegotiatingReason
        NewContractCTC
        ContractEndedBy
        ContractExtendedBy
        ContractPausedBy
        JobApplicationsId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        id
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateObjectiveEditHistory = /* GraphQL */ `
  mutation UpdateObjectiveEditHistory(
    $input: UpdateObjectiveEditHistoryInput!
    $condition: ModelObjectiveEditHistoryConditionInput
  ) {
    updateObjectiveEditHistory(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      objectiveId
      Objective {
        id
        createdAt
        updatedAt
        title
        creator
        companyProfileId
        companyUserId
        ObjectivekeyResults {
          items {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrewId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      prevTitle
      editedbyCompanyUserProfileId
      editbyCompanyUserProfile {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        UserRole
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        updatedAt
        owner
        __typename
      }
      editedbyDeployedQrewId
      editedDeployedQrewProfile {
        createdAt
        updatedAt
        companyQrewId
        businessProfileId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        finalCTCQrew
        candidateprofileId
        qrewPosition
        deployedUserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        preferencesId
        JobPostPreferences {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        deployedQrewHistory {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            finalCTCQrew
            candidateprofileId
            qrewPosition
            preferencesId
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            NewContractCTC
            ContractEndedBy
            ContractPausedBy
            JobApplicationsId
            deployedQrewId
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        recommededId
        joiningDate
        endingDate
        NDAkey
        Contractkey
        KYCdocumentkey
        WorkingOnit
        EndContract
        ContractEndingDate
        ExtensionDurationInMonths
        ExtensionDate
        ExtendingContract
        CandidateAcceptanceStatus
        CandidateContractRejectionReason
        CandidateContractNegotiatingReason
        NewContractCTC
        ContractEndedBy
        ContractExtendedBy
        ContractPausedBy
        JobApplicationsId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        id
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteObjectiveEditHistory = /* GraphQL */ `
  mutation DeleteObjectiveEditHistory(
    $input: DeleteObjectiveEditHistoryInput!
    $condition: ModelObjectiveEditHistoryConditionInput
  ) {
    deleteObjectiveEditHistory(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      objectiveId
      Objective {
        id
        createdAt
        updatedAt
        title
        creator
        companyProfileId
        companyUserId
        ObjectivekeyResults {
          items {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrewId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      prevTitle
      editedbyCompanyUserProfileId
      editbyCompanyUserProfile {
        createdAt
        username
        designation
        ProfilePictureKey
        businessProfileId
        id
        cognitoId
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        Shortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        UserRole
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        email
        updatedAt
        owner
        __typename
      }
      editedbyDeployedQrewId
      editedDeployedQrewProfile {
        createdAt
        updatedAt
        companyQrewId
        businessProfileId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        finalCTCQrew
        candidateprofileId
        qrewPosition
        deployedUserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        preferencesId
        JobPostPreferences {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        deployedQrewHistory {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            finalCTCQrew
            candidateprofileId
            qrewPosition
            preferencesId
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            NewContractCTC
            ContractEndedBy
            ContractPausedBy
            JobApplicationsId
            deployedQrewId
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        recommededId
        joiningDate
        endingDate
        NDAkey
        Contractkey
        KYCdocumentkey
        WorkingOnit
        EndContract
        ContractEndingDate
        ExtensionDurationInMonths
        ExtensionDate
        ExtendingContract
        CandidateAcceptanceStatus
        CandidateContractRejectionReason
        CandidateContractNegotiatingReason
        NewContractCTC
        ContractEndedBy
        ContractExtendedBy
        ContractPausedBy
        JobApplicationsId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        id
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createInitiative = /* GraphQL */ `
  mutation CreateInitiative(
    $input: CreateInitiativeInput!
    $condition: ModelInitiativeConditionInput
  ) {
    createInitiative(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      initiative
      initiativeDocumentation
      keyResultsAcheived
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      objectiveKeyResultId
      objectiveId
      approvalStatus
      objective {
        id
        createdAt
        updatedAt
        title
        creator
        companyProfileId
        companyUserId
        ObjectivekeyResults {
          items {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrewId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      ObjectiveKeyResult {
        id
        createdAt
        updatedAt
        keyResult
        deployedQrewId
        companyProfileId
        candidateProfileId
        companyQrewId
        objectiveId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        progressStatus
        progresssPercentage
        owner
        __typename
      }
      InitiativeCreatedBydeployedQrew {
        createdAt
        updatedAt
        companyQrewId
        businessProfileId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        finalCTCQrew
        candidateprofileId
        qrewPosition
        deployedUserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        preferencesId
        JobPostPreferences {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        deployedQrewHistory {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            finalCTCQrew
            candidateprofileId
            qrewPosition
            preferencesId
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            NewContractCTC
            ContractEndedBy
            ContractPausedBy
            JobApplicationsId
            deployedQrewId
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        recommededId
        joiningDate
        endingDate
        NDAkey
        Contractkey
        KYCdocumentkey
        WorkingOnit
        EndContract
        ContractEndingDate
        ExtensionDurationInMonths
        ExtensionDate
        ExtendingContract
        CandidateAcceptanceStatus
        CandidateContractRejectionReason
        CandidateContractNegotiatingReason
        NewContractCTC
        ContractEndedBy
        ContractExtendedBy
        ContractPausedBy
        JobApplicationsId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        id
        owner
        __typename
      }
      PercentageContributionOnkeyResult
      TimeRequiredToComplete
      owner
      __typename
    }
  }
`;
export const updateInitiative = /* GraphQL */ `
  mutation UpdateInitiative(
    $input: UpdateInitiativeInput!
    $condition: ModelInitiativeConditionInput
  ) {
    updateInitiative(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      initiative
      initiativeDocumentation
      keyResultsAcheived
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      objectiveKeyResultId
      objectiveId
      approvalStatus
      objective {
        id
        createdAt
        updatedAt
        title
        creator
        companyProfileId
        companyUserId
        ObjectivekeyResults {
          items {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrewId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      ObjectiveKeyResult {
        id
        createdAt
        updatedAt
        keyResult
        deployedQrewId
        companyProfileId
        candidateProfileId
        companyQrewId
        objectiveId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        progressStatus
        progresssPercentage
        owner
        __typename
      }
      InitiativeCreatedBydeployedQrew {
        createdAt
        updatedAt
        companyQrewId
        businessProfileId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        finalCTCQrew
        candidateprofileId
        qrewPosition
        deployedUserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        preferencesId
        JobPostPreferences {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        deployedQrewHistory {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            finalCTCQrew
            candidateprofileId
            qrewPosition
            preferencesId
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            NewContractCTC
            ContractEndedBy
            ContractPausedBy
            JobApplicationsId
            deployedQrewId
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        recommededId
        joiningDate
        endingDate
        NDAkey
        Contractkey
        KYCdocumentkey
        WorkingOnit
        EndContract
        ContractEndingDate
        ExtensionDurationInMonths
        ExtensionDate
        ExtendingContract
        CandidateAcceptanceStatus
        CandidateContractRejectionReason
        CandidateContractNegotiatingReason
        NewContractCTC
        ContractEndedBy
        ContractExtendedBy
        ContractPausedBy
        JobApplicationsId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        id
        owner
        __typename
      }
      PercentageContributionOnkeyResult
      TimeRequiredToComplete
      owner
      __typename
    }
  }
`;
export const deleteInitiative = /* GraphQL */ `
  mutation DeleteInitiative(
    $input: DeleteInitiativeInput!
    $condition: ModelInitiativeConditionInput
  ) {
    deleteInitiative(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      initiative
      initiativeDocumentation
      keyResultsAcheived
      deployedQrewId
      companyProfileId
      candidateProfileId
      companyQrewId
      objectiveKeyResultId
      objectiveId
      approvalStatus
      objective {
        id
        createdAt
        updatedAt
        title
        creator
        companyProfileId
        companyUserId
        ObjectivekeyResults {
          items {
            id
            createdAt
            updatedAt
            keyResult
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveId
            initiatives {
              nextToken
              __typename
            }
            progressStatus
            progresssPercentage
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrewId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      ObjectiveKeyResult {
        id
        createdAt
        updatedAt
        keyResult
        deployedQrewId
        companyProfileId
        candidateProfileId
        companyQrewId
        objectiveId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        progressStatus
        progresssPercentage
        owner
        __typename
      }
      InitiativeCreatedBydeployedQrew {
        createdAt
        updatedAt
        companyQrewId
        businessProfileId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        finalCTCQrew
        candidateprofileId
        qrewPosition
        deployedUserProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        preferencesId
        JobPostPreferences {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        deployedQrewHistory {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            finalCTCQrew
            candidateprofileId
            qrewPosition
            preferencesId
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            NewContractCTC
            ContractEndedBy
            ContractPausedBy
            JobApplicationsId
            deployedQrewId
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        recommededId
        joiningDate
        endingDate
        NDAkey
        Contractkey
        KYCdocumentkey
        WorkingOnit
        EndContract
        ContractEndingDate
        ExtensionDurationInMonths
        ExtensionDate
        ExtendingContract
        CandidateAcceptanceStatus
        CandidateContractRejectionReason
        CandidateContractNegotiatingReason
        NewContractCTC
        ContractEndedBy
        ContractExtendedBy
        ContractPausedBy
        JobApplicationsId
        initiatives {
          items {
            id
            createdAt
            updatedAt
            initiative
            initiativeDocumentation
            keyResultsAcheived
            deployedQrewId
            companyProfileId
            candidateProfileId
            companyQrewId
            objectiveKeyResultId
            objectiveId
            approvalStatus
            objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            ObjectiveKeyResult {
              id
              createdAt
              updatedAt
              keyResult
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveId
              progressStatus
              progresssPercentage
              owner
              __typename
            }
            InitiativeCreatedBydeployedQrew {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            PercentageContributionOnkeyResult
            TimeRequiredToComplete
            owner
            __typename
          }
          nextToken
          __typename
        }
        editObjectiveHistory {
          items {
            id
            createdAt
            updatedAt
            objectiveId
            Objective {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            prevTitle
            editedbyCompanyUserProfileId
            editbyCompanyUserProfile {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            editedbyDeployedQrewId
            editedDeployedQrewProfile {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        id
        owner
        __typename
      }
      PercentageContributionOnkeyResult
      TimeRequiredToComplete
      owner
      __typename
    }
  }
`;
export const createSkill = /* GraphQL */ `
  mutation CreateSkill(
    $input: CreateSkillInput!
    $condition: ModelSkillConditionInput
  ) {
    createSkill(input: $input, condition: $condition) {
      createdAt
      profileId
      UserId
      description
      YearsOfExperience
      id
      Skill
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      JoinedCapabilities {
        items {
          id
          UserId
          SkillId
          CapabilityId
          Skill {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Capabilities {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          GlobalCapabilityConfigId
          GlobalCapabilityConfigName
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSkill = /* GraphQL */ `
  mutation UpdateSkill(
    $input: UpdateSkillInput!
    $condition: ModelSkillConditionInput
  ) {
    updateSkill(input: $input, condition: $condition) {
      createdAt
      profileId
      UserId
      description
      YearsOfExperience
      id
      Skill
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      JoinedCapabilities {
        items {
          id
          UserId
          SkillId
          CapabilityId
          Skill {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Capabilities {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          GlobalCapabilityConfigId
          GlobalCapabilityConfigName
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteSkill = /* GraphQL */ `
  mutation DeleteSkill(
    $input: DeleteSkillInput!
    $condition: ModelSkillConditionInput
  ) {
    deleteSkill(input: $input, condition: $condition) {
      createdAt
      profileId
      UserId
      description
      YearsOfExperience
      id
      Skill
      profile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      JoinedCapabilities {
        items {
          id
          UserId
          SkillId
          CapabilityId
          Skill {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Capabilities {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          GlobalCapabilityConfigId
          GlobalCapabilityConfigName
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;
export const createJoinSkillandCapability = /* GraphQL */ `
  mutation CreateJoinSkillandCapability(
    $input: CreateJoinSkillandCapabilityInput!
    $condition: ModelJoinSkillandCapabilityConditionInput
  ) {
    createJoinSkillandCapability(input: $input, condition: $condition) {
      id
      UserId
      SkillId
      CapabilityId
      Skill {
        createdAt
        profileId
        UserId
        description
        YearsOfExperience
        id
        Skill
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        JoinedCapabilities {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Capabilities {
        createdAt
        ProfileId
        id
        PointsEarned {
          items {
            id
            Project
            Hack
            ProjectLink
            Video
            Photo
            Presentation
            experience
            Claim
            Outcomes
            PeersVouch
            GlobalCapabilityConfigId
            Helped
            Problem
            Challenges
            CapabilityPoints
            UserId
            capabilityPointsCapabilityClaimedId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        userProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ConnectedHidis {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        capabilitiesCapabilityConfigId
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        JoinedSkills {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      GlobalCapabilityConfigId
      GlobalCapabilityConfigName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateJoinSkillandCapability = /* GraphQL */ `
  mutation UpdateJoinSkillandCapability(
    $input: UpdateJoinSkillandCapabilityInput!
    $condition: ModelJoinSkillandCapabilityConditionInput
  ) {
    updateJoinSkillandCapability(input: $input, condition: $condition) {
      id
      UserId
      SkillId
      CapabilityId
      Skill {
        createdAt
        profileId
        UserId
        description
        YearsOfExperience
        id
        Skill
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        JoinedCapabilities {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Capabilities {
        createdAt
        ProfileId
        id
        PointsEarned {
          items {
            id
            Project
            Hack
            ProjectLink
            Video
            Photo
            Presentation
            experience
            Claim
            Outcomes
            PeersVouch
            GlobalCapabilityConfigId
            Helped
            Problem
            Challenges
            CapabilityPoints
            UserId
            capabilityPointsCapabilityClaimedId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        userProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ConnectedHidis {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        capabilitiesCapabilityConfigId
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        JoinedSkills {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      GlobalCapabilityConfigId
      GlobalCapabilityConfigName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteJoinSkillandCapability = /* GraphQL */ `
  mutation DeleteJoinSkillandCapability(
    $input: DeleteJoinSkillandCapabilityInput!
    $condition: ModelJoinSkillandCapabilityConditionInput
  ) {
    deleteJoinSkillandCapability(input: $input, condition: $condition) {
      id
      UserId
      SkillId
      CapabilityId
      Skill {
        createdAt
        profileId
        UserId
        description
        YearsOfExperience
        id
        Skill
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        JoinedCapabilities {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      Capabilities {
        createdAt
        ProfileId
        id
        PointsEarned {
          items {
            id
            Project
            Hack
            ProjectLink
            Video
            Photo
            Presentation
            experience
            Claim
            Outcomes
            PeersVouch
            GlobalCapabilityConfigId
            Helped
            Problem
            Challenges
            CapabilityPoints
            UserId
            capabilityPointsCapabilityClaimedId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        userProfile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        ConnectedHidis {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        capabilitiesCapabilityConfigId
        CapabilityConfig {
          id
          name
          tag
          domain
          createdAt
          weeklyActiveOpportunity
          capabilitiesClaimedCount
          CapabilityPoints
          capabilitiesClaimed {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          feeds {
            items {
              id
              UserSegmentId
              HidiId
              CapabilityConfigId
              jobPostId
              tags
              domain
              Description
              Payload
              FeedType
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          connectedJobPosts {
            items {
              id
              jobPostID
              GlobalCapabilityConfigID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivityStatus
          updatedAt
          owner
          __typename
        }
        JoinedSkills {
          items {
            id
            UserId
            SkillId
            CapabilityId
            Skill {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            Capabilities {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            GlobalCapabilityConfigId
            GlobalCapabilityConfigName
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      GlobalCapabilityConfigId
      GlobalCapabilityConfigName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createGlobalCapabilityConfig = /* GraphQL */ `
  mutation CreateGlobalCapabilityConfig(
    $input: CreateGlobalCapabilityConfigInput!
    $condition: ModelGlobalCapabilityConfigConditionInput
  ) {
    createGlobalCapabilityConfig(input: $input, condition: $condition) {
      id
      name
      tag
      domain
      createdAt
      weeklyActiveOpportunity
      capabilitiesClaimedCount
      CapabilityPoints
      capabilitiesClaimed {
        items {
          createdAt
          ProfileId
          id
          PointsEarned {
            items {
              id
              Project
              Hack
              ProjectLink
              Video
              Photo
              Presentation
              experience
              Claim
              Outcomes
              PeersVouch
              GlobalCapabilityConfigId
              Helped
              Problem
              Challenges
              CapabilityPoints
              UserId
              capabilityPointsCapabilityClaimedId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          userProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ConnectedHidis {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          capabilitiesCapabilityConfigId
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          JoinedSkills {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      feeds {
        items {
          id
          UserSegmentId
          HidiId
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          CapabilityConfigId
          jobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          tags
          domain
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          Description
          Payload
          FeedType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      connectedJobPosts {
        items {
          id
          jobPostID
          GlobalCapabilityConfigID
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ActivityStatus
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateGlobalCapabilityConfig = /* GraphQL */ `
  mutation UpdateGlobalCapabilityConfig(
    $input: UpdateGlobalCapabilityConfigInput!
    $condition: ModelGlobalCapabilityConfigConditionInput
  ) {
    updateGlobalCapabilityConfig(input: $input, condition: $condition) {
      id
      name
      tag
      domain
      createdAt
      weeklyActiveOpportunity
      capabilitiesClaimedCount
      CapabilityPoints
      capabilitiesClaimed {
        items {
          createdAt
          ProfileId
          id
          PointsEarned {
            items {
              id
              Project
              Hack
              ProjectLink
              Video
              Photo
              Presentation
              experience
              Claim
              Outcomes
              PeersVouch
              GlobalCapabilityConfigId
              Helped
              Problem
              Challenges
              CapabilityPoints
              UserId
              capabilityPointsCapabilityClaimedId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          userProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ConnectedHidis {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          capabilitiesCapabilityConfigId
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          JoinedSkills {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      feeds {
        items {
          id
          UserSegmentId
          HidiId
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          CapabilityConfigId
          jobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          tags
          domain
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          Description
          Payload
          FeedType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      connectedJobPosts {
        items {
          id
          jobPostID
          GlobalCapabilityConfigID
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ActivityStatus
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteGlobalCapabilityConfig = /* GraphQL */ `
  mutation DeleteGlobalCapabilityConfig(
    $input: DeleteGlobalCapabilityConfigInput!
    $condition: ModelGlobalCapabilityConfigConditionInput
  ) {
    deleteGlobalCapabilityConfig(input: $input, condition: $condition) {
      id
      name
      tag
      domain
      createdAt
      weeklyActiveOpportunity
      capabilitiesClaimedCount
      CapabilityPoints
      capabilitiesClaimed {
        items {
          createdAt
          ProfileId
          id
          PointsEarned {
            items {
              id
              Project
              Hack
              ProjectLink
              Video
              Photo
              Presentation
              experience
              Claim
              Outcomes
              PeersVouch
              GlobalCapabilityConfigId
              Helped
              Problem
              Challenges
              CapabilityPoints
              UserId
              capabilityPointsCapabilityClaimedId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          userProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ConnectedHidis {
            items {
              CapabilityId
              HidiId
              createdAt
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          capabilitiesCapabilityConfigId
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          JoinedSkills {
            items {
              id
              UserId
              SkillId
              CapabilityId
              GlobalCapabilityConfigId
              GlobalCapabilityConfigName
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      feeds {
        items {
          id
          UserSegmentId
          HidiId
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          CapabilityConfigId
          jobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          tags
          domain
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          Description
          Payload
          FeedType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      connectedJobPosts {
        items {
          id
          jobPostID
          GlobalCapabilityConfigID
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      ActivityStatus
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCapabilityPoints = /* GraphQL */ `
  mutation CreateCapabilityPoints(
    $input: CreateCapabilityPointsInput!
    $condition: ModelCapabilityPointsConditionInput
  ) {
    createCapabilityPoints(input: $input, condition: $condition) {
      id
      Project
      Hack
      ProjectLink
      Video
      Photo
      Presentation
      experience
      Claim
      Outcomes
      PeersVouch
      GlobalCapabilityConfigId
      Helped
      Problem
      Challenges
      CapabilityPoints
      UserId
      capabilityPointsCapabilityClaimedId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCapabilityPoints = /* GraphQL */ `
  mutation UpdateCapabilityPoints(
    $input: UpdateCapabilityPointsInput!
    $condition: ModelCapabilityPointsConditionInput
  ) {
    updateCapabilityPoints(input: $input, condition: $condition) {
      id
      Project
      Hack
      ProjectLink
      Video
      Photo
      Presentation
      experience
      Claim
      Outcomes
      PeersVouch
      GlobalCapabilityConfigId
      Helped
      Problem
      Challenges
      CapabilityPoints
      UserId
      capabilityPointsCapabilityClaimedId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCapabilityPoints = /* GraphQL */ `
  mutation DeleteCapabilityPoints(
    $input: DeleteCapabilityPointsInput!
    $condition: ModelCapabilityPointsConditionInput
  ) {
    deleteCapabilityPoints(input: $input, condition: $condition) {
      id
      Project
      Hack
      ProjectLink
      Video
      Photo
      Presentation
      experience
      Claim
      Outcomes
      PeersVouch
      GlobalCapabilityConfigId
      Helped
      Problem
      Challenges
      CapabilityPoints
      UserId
      capabilityPointsCapabilityClaimedId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createUserParsedResume = /* GraphQL */ `
  mutation CreateUserParsedResume(
    $input: CreateUserParsedResumeInput!
    $condition: ModelUserParsedResumeConditionInput
  ) {
    createUserParsedResume(input: $input, condition: $condition) {
      UserId
      ParsedString
      createdAt
      id
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUserParsedResume = /* GraphQL */ `
  mutation UpdateUserParsedResume(
    $input: UpdateUserParsedResumeInput!
    $condition: ModelUserParsedResumeConditionInput
  ) {
    updateUserParsedResume(input: $input, condition: $condition) {
      UserId
      ParsedString
      createdAt
      id
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUserParsedResume = /* GraphQL */ `
  mutation DeleteUserParsedResume(
    $input: DeleteUserParsedResumeInput!
    $condition: ModelUserParsedResumeConditionInput
  ) {
    deleteUserParsedResume(input: $input, condition: $condition) {
      UserId
      ParsedString
      createdAt
      id
      updatedAt
      owner
      __typename
    }
  }
`;
export const createFeed = /* GraphQL */ `
  mutation CreateFeed(
    $input: CreateFeedInput!
    $condition: ModelFeedConditionInput
  ) {
    createFeed(input: $input, condition: $condition) {
      id
      UserSegmentId
      HidiId
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      CapabilityConfigId
      jobPostId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      tags
      domain
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      Description
      Payload
      FeedType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateFeed = /* GraphQL */ `
  mutation UpdateFeed(
    $input: UpdateFeedInput!
    $condition: ModelFeedConditionInput
  ) {
    updateFeed(input: $input, condition: $condition) {
      id
      UserSegmentId
      HidiId
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      CapabilityConfigId
      jobPostId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      tags
      domain
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      Description
      Payload
      FeedType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteFeed = /* GraphQL */ `
  mutation DeleteFeed(
    $input: DeleteFeedInput!
    $condition: ModelFeedConditionInput
  ) {
    deleteFeed(input: $input, condition: $condition) {
      id
      UserSegmentId
      HidiId
      Hidi {
        createdAt
        Email
        id
        profileId
        profile {
          createdAt
          name
          UserId
          Email
          id
          phone
          about
          hidis {
            items {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateEducation {
            items {
              createdAt
              UserId
              id
              degree
              institute
              duration
              startDate
              EndDate
              Present
              percentage
              description
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedforqrews {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidateExperience {
            items {
              createdAt
              UserId
              id
              startDate
              EndDate
              Present
              company
              designation
              description
              duration
              location
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyshortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          comapanyrejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inpreference {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Skills {
            items {
              createdAt
              profileId
              UserId
              description
              YearsOfExperience
              id
              Skill
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          profileFeedback {
            items {
              createdAt
              id
              profileId
              name
              designation
              description
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          certifications {
            items {
              createdAt
              id
              Certificatename
              description
              Source
              certificateId
              validity
              UserId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          Capabilities {
            items {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CandidatePreferences {
            items {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ActivatedCandisatePreferencesID
          activeCandidatePreferences {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          ConnectedUserSegments {
            items {
              UserSegmentId
              UserId
              createdAt
              updatedAt
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          salarycurrent
          salaryexpectation
          certification
          noticeperiod
          currentoffer
          education
          experience
          expertise
          graduation
          image
          resume
          location
          imageicon1
          link1
          imageicon2
          link2
          imageicon3
          link3
          profileviews
          relevant
          connected
          imagekey
          typeofwork
          resumekey
          ConsentToShareInfo
          IntroVideo
          updatedAt
          owner
          __typename
        }
        name
        UserId
        challenges {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            challenge
            HidiId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        outcomes {
          items {
            createdAt
            id
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            Outcome
            HidiId
            Verified
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConnectedCapability {
          items {
            CapabilityId
            HidiId
            Capability {
              createdAt
              ProfileId
              id
              capabilitiesCapabilityConfigId
              updatedAt
              owner
              __typename
            }
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            createdAt
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedFeeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        summary
        description
        title
        Verified
        likes
        views
        steps
        jobs
        outcomelink
        impact
        impactOverall
        imagelink
        outcome
        pinnedFlag
        tag1
        tag2
        htmlheading
        htmlsummary
        htmloutput
        peerEmails
        helped
        problem
        Hack
        experience
        projectLink
        whereitwasdone
        whereitwasdonecompany
        SkillUsed
        ExperinceInSkill
        outcomejson
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        AiGenerated
        ProbabilityFake
        ProbabilityReal
        hidiTopic
        updatedAt
        owner
        __typename
      }
      CapabilityConfigId
      jobPostId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      tags
      domain
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      Description
      Payload
      FeedType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createUserSegments = /* GraphQL */ `
  mutation CreateUserSegments(
    $input: CreateUserSegmentsInput!
    $condition: ModelUserSegmentsConditionInput
  ) {
    createUserSegments(input: $input, condition: $condition) {
      id
      SegmentString
      ConnectedUsers {
        items {
          UserSegmentId
          UserId
          createdAt
          updatedAt
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUserSegments = /* GraphQL */ `
  mutation UpdateUserSegments(
    $input: UpdateUserSegmentsInput!
    $condition: ModelUserSegmentsConditionInput
  ) {
    updateUserSegments(input: $input, condition: $condition) {
      id
      SegmentString
      ConnectedUsers {
        items {
          UserSegmentId
          UserId
          createdAt
          updatedAt
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUserSegments = /* GraphQL */ `
  mutation DeleteUserSegments(
    $input: DeleteUserSegmentsInput!
    $condition: ModelUserSegmentsConditionInput
  ) {
    deleteUserSegments(input: $input, condition: $condition) {
      id
      SegmentString
      ConnectedUsers {
        items {
          UserSegmentId
          UserId
          createdAt
          updatedAt
          id
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createUserEvents = /* GraphQL */ `
  mutation CreateUserEvents(
    $input: CreateUserEventsInput!
    $condition: ModelUserEventsConditionInput
  ) {
    createUserEvents(input: $input, condition: $condition) {
      id
      Payload
      UserId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUserEvents = /* GraphQL */ `
  mutation UpdateUserEvents(
    $input: UpdateUserEventsInput!
    $condition: ModelUserEventsConditionInput
  ) {
    updateUserEvents(input: $input, condition: $condition) {
      id
      Payload
      UserId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUserEvents = /* GraphQL */ `
  mutation DeleteUserEvents(
    $input: DeleteUserEventsInput!
    $condition: ModelUserEventsConditionInput
  ) {
    deleteUserEvents(input: $input, condition: $condition) {
      id
      Payload
      UserId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createJobApplications = /* GraphQL */ `
  mutation CreateJobApplications(
    $input: CreateJobApplicationsInput!
    $condition: ModelJobApplicationsConditionInput
  ) {
    createJobApplications(input: $input, condition: $condition) {
      id
      JobPostId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      CompanyProfileId
      ProfileId
      CompanyPreferenceId
      companyQrewId
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      ApplyingStatus
      Level
      CandidateProfileAnalysis
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateJobApplications = /* GraphQL */ `
  mutation UpdateJobApplications(
    $input: UpdateJobApplicationsInput!
    $condition: ModelJobApplicationsConditionInput
  ) {
    updateJobApplications(input: $input, condition: $condition) {
      id
      JobPostId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      CompanyProfileId
      ProfileId
      CompanyPreferenceId
      companyQrewId
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      ApplyingStatus
      Level
      CandidateProfileAnalysis
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteJobApplications = /* GraphQL */ `
  mutation DeleteJobApplications(
    $input: DeleteJobApplicationsInput!
    $condition: ModelJobApplicationsConditionInput
  ) {
    deleteJobApplications(input: $input, condition: $condition) {
      id
      JobPostId
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      CompanyProfileId
      ProfileId
      CompanyPreferenceId
      companyQrewId
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        hidis {
          items {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateEducation {
          items {
            createdAt
            UserId
            id
            degree
            institute
            duration
            startDate
            EndDate
            Present
            percentage
            description
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedforqrews {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidateExperience {
          items {
            createdAt
            UserId
            id
            startDate
            EndDate
            Present
            company
            designation
            description
            duration
            location
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyshortlists {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        comapanyrejected {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        inpreference {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Skills {
          items {
            createdAt
            profileId
            UserId
            description
            YearsOfExperience
            id
            Skill
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            JoinedCapabilities {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        profileFeedback {
          items {
            createdAt
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            designation
            description
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        certifications {
          items {
            createdAt
            id
            Certificatename
            description
            Source
            certificateId
            validity
            UserId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CandidatePreferences {
          items {
            createdAt
            id
            rolename
            jobType
            workType
            rolelevel
            benefits
            priority
            expectation
            Salaryexpectation
            ContractCompensation
            currentSalary
            companyType
            workStyle
            minimumContractPeriod
            servingNotice
            noticePeriod
            workSchedule
            Activelylooking
            contractType
            compensationStructure
            AvailableDate
            userDomain
            roletype
            location1
            location2
            location3
            Country
            CurrencyCountry
            ActivatePreference
            prevContractCount
            profileId
            UserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ProfilebyCandidatePreferencesId {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ConvoOnHidi {
          items {
            id
            Question
            Answer
            profileId
            UserId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            PreferenceId
            companyProfileId
            CompanyProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            companyUserProfileId
            HidiId
            NewHidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            companyUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivatedCandisatePreferencesID
        activeCandidatePreferences {
          createdAt
          id
          rolename
          jobType
          workType
          rolelevel
          benefits
          priority
          expectation
          Salaryexpectation
          ContractCompensation
          currentSalary
          companyType
          workStyle
          minimumContractPeriod
          servingNotice
          noticePeriod
          workSchedule
          Activelylooking
          contractType
          compensationStructure
          AvailableDate
          userDomain
          roletype
          location1
          location2
          location3
          Country
          CurrencyCountry
          ActivatePreference
          prevContractCount
          profileId
          UserProfile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ProfilebyCandidatePreferencesId {
            items {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        ConnectedUserSegments {
          items {
            UserSegmentId
            UserId
            createdAt
            updatedAt
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        ConsentToShareInfo
        IntroVideo
        updatedAt
        owner
        __typename
      }
      ApplyingStatus
      Level
      CandidateProfileAnalysis
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createJobApplicantionRecommendationKPIs = /* GraphQL */ `
  mutation CreateJobApplicantionRecommendationKPIs(
    $input: CreateJobApplicantionRecommendationKPIsInput!
    $condition: ModelJobApplicantionRecommendationKPIsConditionInput
  ) {
    createJobApplicantionRecommendationKPIs(
      input: $input
      condition: $condition
    ) {
      id
      JobPostId
      CompanyProfileId
      CompanyPreferenceId
      NewApplicants
      NewRecommendations
      TotalApplicants
      companyPersonName
      companyMailId
      companyName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateJobApplicantionRecommendationKPIs = /* GraphQL */ `
  mutation UpdateJobApplicantionRecommendationKPIs(
    $input: UpdateJobApplicantionRecommendationKPIsInput!
    $condition: ModelJobApplicantionRecommendationKPIsConditionInput
  ) {
    updateJobApplicantionRecommendationKPIs(
      input: $input
      condition: $condition
    ) {
      id
      JobPostId
      CompanyProfileId
      CompanyPreferenceId
      NewApplicants
      NewRecommendations
      TotalApplicants
      companyPersonName
      companyMailId
      companyName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteJobApplicantionRecommendationKPIs = /* GraphQL */ `
  mutation DeleteJobApplicantionRecommendationKPIs(
    $input: DeleteJobApplicantionRecommendationKPIsInput!
    $condition: ModelJobApplicantionRecommendationKPIsConditionInput
  ) {
    deleteJobApplicantionRecommendationKPIs(
      input: $input
      condition: $condition
    ) {
      id
      JobPostId
      CompanyProfileId
      CompanyPreferenceId
      NewApplicants
      NewRecommendations
      TotalApplicants
      companyPersonName
      companyMailId
      companyName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createJobApplicationRecommednationsMailSent = /* GraphQL */ `
  mutation CreateJobApplicationRecommednationsMailSent(
    $input: CreateJobApplicationRecommednationsMailSentInput!
    $condition: ModelJobApplicationRecommednationsMailSentConditionInput
  ) {
    createJobApplicationRecommednationsMailSent(
      input: $input
      condition: $condition
    ) {
      id
      JobPostIds
      CompanyUser
      companyPersonName
      companyMailId
      companyName
      mailMessageId
      MailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateJobApplicationRecommednationsMailSent = /* GraphQL */ `
  mutation UpdateJobApplicationRecommednationsMailSent(
    $input: UpdateJobApplicationRecommednationsMailSentInput!
    $condition: ModelJobApplicationRecommednationsMailSentConditionInput
  ) {
    updateJobApplicationRecommednationsMailSent(
      input: $input
      condition: $condition
    ) {
      id
      JobPostIds
      CompanyUser
      companyPersonName
      companyMailId
      companyName
      mailMessageId
      MailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteJobApplicationRecommednationsMailSent = /* GraphQL */ `
  mutation DeleteJobApplicationRecommednationsMailSent(
    $input: DeleteJobApplicationRecommednationsMailSentInput!
    $condition: ModelJobApplicationRecommednationsMailSentConditionInput
  ) {
    deleteJobApplicationRecommednationsMailSent(
      input: $input
      condition: $condition
    ) {
      id
      JobPostIds
      CompanyUser
      companyPersonName
      companyMailId
      companyName
      mailMessageId
      MailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createEventTable = /* GraphQL */ `
  mutation CreateEventTable(
    $input: CreateEventTableInput!
    $condition: ModelEventTableConditionInput
  ) {
    createEventTable(input: $input, condition: $condition) {
      id
      EventNameTableKeyId
      EventName
      EventAttributes
      EventLogs
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateEventTable = /* GraphQL */ `
  mutation UpdateEventTable(
    $input: UpdateEventTableInput!
    $condition: ModelEventTableConditionInput
  ) {
    updateEventTable(input: $input, condition: $condition) {
      id
      EventNameTableKeyId
      EventName
      EventAttributes
      EventLogs
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteEventTable = /* GraphQL */ `
  mutation DeleteEventTable(
    $input: DeleteEventTableInput!
    $condition: ModelEventTableConditionInput
  ) {
    deleteEventTable(input: $input, condition: $condition) {
      id
      EventNameTableKeyId
      EventName
      EventAttributes
      EventLogs
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createContractEventTable = /* GraphQL */ `
  mutation CreateContractEventTable(
    $input: CreateContractEventTableInput!
    $condition: ModelContractEventTableConditionInput
  ) {
    createContractEventTable(input: $input, condition: $condition) {
      id
      EventNameTableKeyId
      EventName
      EventAttributes
      EventCreatedbyUserCognitoId
      EventLogs
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateContractEventTable = /* GraphQL */ `
  mutation UpdateContractEventTable(
    $input: UpdateContractEventTableInput!
    $condition: ModelContractEventTableConditionInput
  ) {
    updateContractEventTable(input: $input, condition: $condition) {
      id
      EventNameTableKeyId
      EventName
      EventAttributes
      EventCreatedbyUserCognitoId
      EventLogs
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteContractEventTable = /* GraphQL */ `
  mutation DeleteContractEventTable(
    $input: DeleteContractEventTableInput!
    $condition: ModelContractEventTableConditionInput
  ) {
    deleteContractEventTable(input: $input, condition: $condition) {
      id
      EventNameTableKeyId
      EventName
      EventAttributes
      EventCreatedbyUserCognitoId
      EventLogs
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createJobPost = /* GraphQL */ `
  mutation CreateJobPost(
    $input: CreateJobPostInput!
    $condition: ModelJobPostConditionInput
  ) {
    createJobPost(input: $input, condition: $condition) {
      id
      CompanyPreferenceId
      companyQrewId
      CompanyPreference {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      companyName
      JobApplications {
        items {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      shortlistedCandidates {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      jobPostQuestions {
        items {
          id
          JobPostId
          preferencesID
          Question
          TypeOfQuesiton
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      jobPostLink
      logoUrl
      htmldescription
      CompanyDescription
      AvailablityStatus
      experienceRequired
      jobType
      Location
      skills
      domain
      description
      ShortDesc
      RolesandResponsibility
      title
      CapabilitiesRequired {
        items {
          id
          jobPostID
          GlobalCapabilityConfigID
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      FeedsConnected {
        items {
          id
          UserSegmentId
          HidiId
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          CapabilityConfigId
          jobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          tags
          domain
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          Description
          Payload
          FeedType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CapabilitiesRequiredJSON
      createdAt
      updatedAt
      SavedJobPosts {
        items {
          id
          JobPostId
          ProfileId
          UserId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateJobPost = /* GraphQL */ `
  mutation UpdateJobPost(
    $input: UpdateJobPostInput!
    $condition: ModelJobPostConditionInput
  ) {
    updateJobPost(input: $input, condition: $condition) {
      id
      CompanyPreferenceId
      companyQrewId
      CompanyPreference {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      companyName
      JobApplications {
        items {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      shortlistedCandidates {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      jobPostQuestions {
        items {
          id
          JobPostId
          preferencesID
          Question
          TypeOfQuesiton
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      jobPostLink
      logoUrl
      htmldescription
      CompanyDescription
      AvailablityStatus
      experienceRequired
      jobType
      Location
      skills
      domain
      description
      ShortDesc
      RolesandResponsibility
      title
      CapabilitiesRequired {
        items {
          id
          jobPostID
          GlobalCapabilityConfigID
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      FeedsConnected {
        items {
          id
          UserSegmentId
          HidiId
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          CapabilityConfigId
          jobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          tags
          domain
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          Description
          Payload
          FeedType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CapabilitiesRequiredJSON
      createdAt
      updatedAt
      SavedJobPosts {
        items {
          id
          JobPostId
          ProfileId
          UserId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteJobPost = /* GraphQL */ `
  mutation DeleteJobPost(
    $input: DeleteJobPostInput!
    $condition: ModelJobPostConditionInput
  ) {
    deleteJobPost(input: $input, condition: $condition) {
      id
      CompanyPreferenceId
      companyQrewId
      CompanyPreference {
        createdAt
        id
        rolename
        businessUserId
        businessProfileId
        companyQrewId
        companyQrew {
          id
          qrewname
          aboutqrew
          businessProfileId
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          Invoices {
            items {
              id
              CtcInvoiceNumber
              PlatformInvoiceNumber
              CompanyQrewId
              businessProfileId
              companyName
              CtcInvoiceS3Key
              PlarformInvoiceS3Key
              CtcTxnNumber
              PlatformTxnNumber
              CtcTxnNumberApproved
              PlatformTxnNumberApproved
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          qrewroles {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          objectives {
            items {
              id
              createdAt
              updatedAt
              title
              creator
              companyProfileId
              companyUserId
              deployedQrewId
              companyQrewId
              owner
              __typename
            }
            nextToken
            __typename
          }
          initiatives {
            items {
              id
              createdAt
              updatedAt
              initiative
              initiativeDocumentation
              keyResultsAcheived
              deployedQrewId
              companyProfileId
              candidateProfileId
              companyQrewId
              objectiveKeyResultId
              objectiveId
              approvalStatus
              PercentageContributionOnkeyResult
              TimeRequiredToComplete
              owner
              __typename
            }
            nextToken
            __typename
          }
          durationInmonths
          qrewLive
          createdAt
          updatedAt
          owner
          __typename
        }
        companyprofile {
          createdAt
          businessUserId
          name
          companyMailId
          id
          preferences
          about
          websiteUrl
          LogoImageKey
          IndustryType
          Shortlists {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          CompanyQrew {
            items {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          prefferedprofile {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          preferenceslist {
            items {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejected {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          companyusers {
            items {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          ConvoOnHidi {
            items {
              id
              Question
              Answer
              profileId
              UserId
              PreferenceId
              companyProfileId
              companyUserProfileId
              HidiId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          owner
          __typename
        }
        recommendedprofiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            candidateprofileId
            acceptanceStatus
            ReasonofRecommendation
            ReasonofShortlist
            Reasonofrejection
            acceptanceStatus_by_CompanyUser_ID
            acceptanceStatus_by_CompanyUser_name
            recommendedbyId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            preferencesId
            companyQrewId
            JobApplicationsId
            JobApplication {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            JobPostId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            new
            id
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        rejectedProfiles {
          items {
            createdAt
            businessProfileId
            businessUserId
            shortlistedbyId
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            UserId
            about
            candidateprofileId
            Reasonofrejection
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobPosts {
          items {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        deployedQrew {
          items {
            createdAt
            updatedAt
            companyQrewId
            businessProfileId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            finalCTCQrew
            candidateprofileId
            qrewPosition
            deployedUserProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            preferencesId
            JobPostPreferences {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            deployedQrewHistory {
              nextToken
              __typename
            }
            recommededId
            joiningDate
            endingDate
            NDAkey
            Contractkey
            KYCdocumentkey
            WorkingOnit
            EndContract
            ContractEndingDate
            ExtensionDurationInMonths
            ExtensionDate
            ExtendingContract
            CandidateAcceptanceStatus
            CandidateContractRejectionReason
            CandidateContractNegotiatingReason
            NewContractCTC
            ContractEndedBy
            ContractExtendedBy
            ContractPausedBy
            JobApplicationsId
            initiatives {
              nextToken
              __typename
            }
            editObjectiveHistory {
              nextToken
              __typename
            }
            id
            owner
            __typename
          }
          nextToken
          __typename
        }
        JobTraits {
          items {
            id
            TraitName
            createdAt
            updatedAt
            preferencesID
            owner
            __typename
          }
          nextToken
          __typename
        }
        package
        certifications
        noticeperiod
        maxExperienceRequired
        minExperienceRequired
        modeofwork
        JobDomain
        other
        previousrole
        combinedrole
        currencyCountry
        JobType
        Location
        JobPublished
        numberOfCandidateRequired
        updatedAt
        owner
        __typename
      }
      companyName
      JobApplications {
        items {
          id
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          CompanyProfileId
          ProfileId
          CompanyPreferenceId
          companyQrewId
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          ApplyingStatus
          Level
          CandidateProfileAnalysis
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      shortlistedCandidates {
        items {
          createdAt
          businessProfileId
          businessUserId
          UserId
          about
          candidateprofileId
          shortlistedbyId
          JobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          shortlistedByUser {
            createdAt
            username
            designation
            ProfilePictureKey
            businessProfileId
            id
            cognitoId
            ConvoOnHidi {
              nextToken
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Shortlists {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            UserRole
            editObjectiveHistory {
              nextToken
              __typename
            }
            email
            updatedAt
            owner
            __typename
          }
          recommededId
          preferencesId
          prferenceType {
            createdAt
            id
            rolename
            businessUserId
            businessProfileId
            companyQrewId
            companyQrew {
              id
              qrewname
              aboutqrew
              businessProfileId
              durationInmonths
              qrewLive
              createdAt
              updatedAt
              owner
              __typename
            }
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            recommendedprofiles {
              nextToken
              __typename
            }
            shortlistedProfiles {
              nextToken
              __typename
            }
            rejectedProfiles {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            JobPosts {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            JobTraits {
              nextToken
              __typename
            }
            package
            certifications
            noticeperiod
            maxExperienceRequired
            minExperienceRequired
            modeofwork
            JobDomain
            other
            previousrole
            combinedrole
            currencyCountry
            JobType
            Location
            JobPublished
            numberOfCandidateRequired
            updatedAt
            owner
            __typename
          }
          profile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          id
          candidateprofile {
            createdAt
            name
            UserId
            Email
            id
            phone
            about
            hidis {
              nextToken
              __typename
            }
            CandidateEducation {
              nextToken
              __typename
            }
            deployedforqrews {
              nextToken
              __typename
            }
            CandidateExperience {
              nextToken
              __typename
            }
            comapanyshortlists {
              nextToken
              __typename
            }
            comapanyrejected {
              nextToken
              __typename
            }
            inpreference {
              nextToken
              __typename
            }
            Skills {
              nextToken
              __typename
            }
            profileFeedback {
              nextToken
              __typename
            }
            certifications {
              nextToken
              __typename
            }
            Capabilities {
              nextToken
              __typename
            }
            CandidatePreferences {
              nextToken
              __typename
            }
            JobApplications {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            ActivatedCandisatePreferencesID
            activeCandidatePreferences {
              createdAt
              id
              rolename
              jobType
              workType
              rolelevel
              benefits
              priority
              expectation
              Salaryexpectation
              ContractCompensation
              currentSalary
              companyType
              workStyle
              minimumContractPeriod
              servingNotice
              noticePeriod
              workSchedule
              Activelylooking
              contractType
              compensationStructure
              AvailableDate
              userDomain
              roletype
              location1
              location2
              location3
              Country
              CurrencyCountry
              ActivatePreference
              prevContractCount
              profileId
              updatedAt
              owner
              __typename
            }
            ConnectedUserSegments {
              nextToken
              __typename
            }
            salarycurrent
            salaryexpectation
            certification
            noticeperiod
            currentoffer
            education
            experience
            expertise
            graduation
            image
            resume
            location
            imageicon1
            link1
            imageicon2
            link2
            imageicon3
            link3
            profileviews
            relevant
            connected
            imagekey
            typeofwork
            resumekey
            ConsentToShareInfo
            IntroVideo
            updatedAt
            owner
            __typename
          }
          businessProfile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      jobPostQuestions {
        items {
          id
          JobPostId
          preferencesID
          Question
          TypeOfQuesiton
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      jobPostLink
      logoUrl
      htmldescription
      CompanyDescription
      AvailablityStatus
      experienceRequired
      jobType
      Location
      skills
      domain
      description
      ShortDesc
      RolesandResponsibility
      title
      CapabilitiesRequired {
        items {
          id
          jobPostID
          GlobalCapabilityConfigID
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      FeedsConnected {
        items {
          id
          UserSegmentId
          HidiId
          Hidi {
            createdAt
            Email
            id
            profileId
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            name
            UserId
            challenges {
              nextToken
              __typename
            }
            outcomes {
              nextToken
              __typename
            }
            ConnectedCapability {
              nextToken
              __typename
            }
            connectedFeeds {
              nextToken
              __typename
            }
            summary
            description
            title
            Verified
            likes
            views
            steps
            jobs
            outcomelink
            impact
            impactOverall
            imagelink
            outcome
            pinnedFlag
            tag1
            tag2
            htmlheading
            htmlsummary
            htmloutput
            peerEmails
            helped
            problem
            Hack
            experience
            projectLink
            whereitwasdone
            whereitwasdonecompany
            SkillUsed
            ExperinceInSkill
            outcomejson
            ConvoOnHidi {
              nextToken
              __typename
            }
            AiGenerated
            ProbabilityFake
            ProbabilityReal
            hidiTopic
            updatedAt
            owner
            __typename
          }
          CapabilityConfigId
          jobPostId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          tags
          domain
          CapabilityConfig {
            id
            name
            tag
            domain
            createdAt
            weeklyActiveOpportunity
            capabilitiesClaimedCount
            CapabilityPoints
            capabilitiesClaimed {
              nextToken
              __typename
            }
            feeds {
              nextToken
              __typename
            }
            connectedJobPosts {
              nextToken
              __typename
            }
            ActivityStatus
            updatedAt
            owner
            __typename
          }
          Description
          Payload
          FeedType
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      CapabilitiesRequiredJSON
      createdAt
      updatedAt
      SavedJobPosts {
        items {
          id
          JobPostId
          ProfileId
          UserId
          jobPost {
            id
            CompanyPreferenceId
            companyQrewId
            CompanyPreference {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            companyName
            JobApplications {
              nextToken
              __typename
            }
            shortlistedCandidates {
              nextToken
              __typename
            }
            jobPostQuestions {
              nextToken
              __typename
            }
            jobPostLink
            logoUrl
            htmldescription
            CompanyDescription
            AvailablityStatus
            experienceRequired
            jobType
            Location
            skills
            domain
            description
            ShortDesc
            RolesandResponsibility
            title
            CapabilitiesRequired {
              nextToken
              __typename
            }
            FeedsConnected {
              nextToken
              __typename
            }
            CapabilitiesRequiredJSON
            createdAt
            updatedAt
            SavedJobPosts {
              nextToken
              __typename
            }
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createJoinGlobalCapabilityconfigAndJobPost = /* GraphQL */ `
  mutation CreateJoinGlobalCapabilityconfigAndJobPost(
    $input: CreateJoinGlobalCapabilityconfigAndJobPostInput!
    $condition: ModelJoinGlobalCapabilityconfigAndJobPostConditionInput
  ) {
    createJoinGlobalCapabilityconfigAndJobPost(
      input: $input
      condition: $condition
    ) {
      id
      jobPostID
      GlobalCapabilityConfigID
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateJoinGlobalCapabilityconfigAndJobPost = /* GraphQL */ `
  mutation UpdateJoinGlobalCapabilityconfigAndJobPost(
    $input: UpdateJoinGlobalCapabilityconfigAndJobPostInput!
    $condition: ModelJoinGlobalCapabilityconfigAndJobPostConditionInput
  ) {
    updateJoinGlobalCapabilityconfigAndJobPost(
      input: $input
      condition: $condition
    ) {
      id
      jobPostID
      GlobalCapabilityConfigID
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteJoinGlobalCapabilityconfigAndJobPost = /* GraphQL */ `
  mutation DeleteJoinGlobalCapabilityconfigAndJobPost(
    $input: DeleteJoinGlobalCapabilityconfigAndJobPostInput!
    $condition: ModelJoinGlobalCapabilityconfigAndJobPostConditionInput
  ) {
    deleteJoinGlobalCapabilityconfigAndJobPost(
      input: $input
      condition: $condition
    ) {
      id
      jobPostID
      GlobalCapabilityConfigID
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      jobPost {
        id
        CompanyPreferenceId
        companyQrewId
        CompanyPreference {
          createdAt
          id
          rolename
          businessUserId
          businessProfileId
          companyQrewId
          companyQrew {
            id
            qrewname
            aboutqrew
            businessProfileId
            companyprofile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            Invoices {
              nextToken
              __typename
            }
            qrewroles {
              nextToken
              __typename
            }
            deployedQrew {
              nextToken
              __typename
            }
            objectives {
              nextToken
              __typename
            }
            initiatives {
              nextToken
              __typename
            }
            durationInmonths
            qrewLive
            createdAt
            updatedAt
            owner
            __typename
          }
          companyprofile {
            createdAt
            businessUserId
            name
            companyMailId
            id
            preferences
            about
            websiteUrl
            LogoImageKey
            IndustryType
            Shortlists {
              nextToken
              __typename
            }
            CompanyQrew {
              nextToken
              __typename
            }
            prefferedprofile {
              nextToken
              __typename
            }
            preferenceslist {
              nextToken
              __typename
            }
            rejected {
              nextToken
              __typename
            }
            companyusers {
              nextToken
              __typename
            }
            ConvoOnHidi {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          recommendedprofiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              candidateprofileId
              acceptanceStatus
              ReasonofRecommendation
              ReasonofShortlist
              Reasonofrejection
              acceptanceStatus_by_CompanyUser_ID
              acceptanceStatus_by_CompanyUser_name
              recommendedbyId
              preferencesId
              companyQrewId
              JobApplicationsId
              JobPostId
              new
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          shortlistedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              UserId
              about
              candidateprofileId
              shortlistedbyId
              JobPostId
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          rejectedProfiles {
            items {
              createdAt
              businessProfileId
              businessUserId
              shortlistedbyId
              UserId
              about
              candidateprofileId
              Reasonofrejection
              recommededId
              preferencesId
              id
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobApplications {
            items {
              id
              JobPostId
              CompanyProfileId
              ProfileId
              CompanyPreferenceId
              companyQrewId
              ApplyingStatus
              Level
              CandidateProfileAnalysis
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobPosts {
            items {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          deployedQrew {
            items {
              createdAt
              updatedAt
              companyQrewId
              businessProfileId
              finalCTCQrew
              candidateprofileId
              qrewPosition
              preferencesId
              recommededId
              joiningDate
              endingDate
              NDAkey
              Contractkey
              KYCdocumentkey
              WorkingOnit
              EndContract
              ContractEndingDate
              ExtensionDurationInMonths
              ExtensionDate
              ExtendingContract
              CandidateAcceptanceStatus
              CandidateContractRejectionReason
              CandidateContractNegotiatingReason
              NewContractCTC
              ContractEndedBy
              ContractExtendedBy
              ContractPausedBy
              JobApplicationsId
              id
              owner
              __typename
            }
            nextToken
            __typename
          }
          JobTraits {
            items {
              id
              TraitName
              createdAt
              updatedAt
              preferencesID
              owner
              __typename
            }
            nextToken
            __typename
          }
          package
          certifications
          noticeperiod
          maxExperienceRequired
          minExperienceRequired
          modeofwork
          JobDomain
          other
          previousrole
          combinedrole
          currencyCountry
          JobType
          Location
          JobPublished
          numberOfCandidateRequired
          updatedAt
          owner
          __typename
        }
        companyName
        JobApplications {
          items {
            id
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            CompanyProfileId
            ProfileId
            CompanyPreferenceId
            companyQrewId
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ApplyingStatus
            Level
            CandidateProfileAnalysis
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        shortlistedCandidates {
          items {
            createdAt
            businessProfileId
            businessUserId
            UserId
            about
            candidateprofileId
            shortlistedbyId
            JobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            shortlistedByUser {
              createdAt
              username
              designation
              ProfilePictureKey
              businessProfileId
              id
              cognitoId
              UserRole
              email
              updatedAt
              owner
              __typename
            }
            recommededId
            preferencesId
            prferenceType {
              createdAt
              id
              rolename
              businessUserId
              businessProfileId
              companyQrewId
              package
              certifications
              noticeperiod
              maxExperienceRequired
              minExperienceRequired
              modeofwork
              JobDomain
              other
              previousrole
              combinedrole
              currencyCountry
              JobType
              Location
              JobPublished
              numberOfCandidateRequired
              updatedAt
              owner
              __typename
            }
            profile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            id
            candidateprofile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            businessProfile {
              createdAt
              businessUserId
              name
              companyMailId
              id
              preferences
              about
              websiteUrl
              LogoImageKey
              IndustryType
              updatedAt
              owner
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostQuestions {
          items {
            id
            JobPostId
            preferencesID
            Question
            TypeOfQuesiton
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        jobPostLink
        logoUrl
        htmldescription
        CompanyDescription
        AvailablityStatus
        experienceRequired
        jobType
        Location
        skills
        domain
        description
        ShortDesc
        RolesandResponsibility
        title
        CapabilitiesRequired {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        FeedsConnected {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        CapabilitiesRequiredJSON
        createdAt
        updatedAt
        SavedJobPosts {
          items {
            id
            JobPostId
            ProfileId
            UserId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createTrending = /* GraphQL */ `
  mutation CreateTrending(
    $input: CreateTrendingInput!
    $condition: ModelTrendingConditionInput
  ) {
    createTrending(input: $input, condition: $condition) {
      id
      Domain
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      trendingCapabilityConfigId
      owner
      __typename
    }
  }
`;
export const updateTrending = /* GraphQL */ `
  mutation UpdateTrending(
    $input: UpdateTrendingInput!
    $condition: ModelTrendingConditionInput
  ) {
    updateTrending(input: $input, condition: $condition) {
      id
      Domain
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      trendingCapabilityConfigId
      owner
      __typename
    }
  }
`;
export const deleteTrending = /* GraphQL */ `
  mutation DeleteTrending(
    $input: DeleteTrendingInput!
    $condition: ModelTrendingConditionInput
  ) {
    deleteTrending(input: $input, condition: $condition) {
      id
      Domain
      CapabilityConfig {
        id
        name
        tag
        domain
        createdAt
        weeklyActiveOpportunity
        capabilitiesClaimedCount
        CapabilityPoints
        capabilitiesClaimed {
          items {
            createdAt
            ProfileId
            id
            PointsEarned {
              nextToken
              __typename
            }
            userProfile {
              createdAt
              name
              UserId
              Email
              id
              phone
              about
              ActivatedCandisatePreferencesID
              salarycurrent
              salaryexpectation
              certification
              noticeperiod
              currentoffer
              education
              experience
              expertise
              graduation
              image
              resume
              location
              imageicon1
              link1
              imageicon2
              link2
              imageicon3
              link3
              profileviews
              relevant
              connected
              imagekey
              typeofwork
              resumekey
              ConsentToShareInfo
              IntroVideo
              updatedAt
              owner
              __typename
            }
            ConnectedHidis {
              nextToken
              __typename
            }
            capabilitiesCapabilityConfigId
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            JoinedSkills {
              nextToken
              __typename
            }
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        feeds {
          items {
            id
            UserSegmentId
            HidiId
            Hidi {
              createdAt
              Email
              id
              profileId
              name
              UserId
              summary
              description
              title
              Verified
              likes
              views
              steps
              jobs
              outcomelink
              impact
              impactOverall
              imagelink
              outcome
              pinnedFlag
              tag1
              tag2
              htmlheading
              htmlsummary
              htmloutput
              peerEmails
              helped
              problem
              Hack
              experience
              projectLink
              whereitwasdone
              whereitwasdonecompany
              SkillUsed
              ExperinceInSkill
              outcomejson
              AiGenerated
              ProbabilityFake
              ProbabilityReal
              hidiTopic
              updatedAt
              owner
              __typename
            }
            CapabilityConfigId
            jobPostId
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            tags
            domain
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            Description
            Payload
            FeedType
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connectedJobPosts {
          items {
            id
            jobPostID
            GlobalCapabilityConfigID
            CapabilityConfig {
              id
              name
              tag
              domain
              createdAt
              weeklyActiveOpportunity
              capabilitiesClaimedCount
              CapabilityPoints
              ActivityStatus
              updatedAt
              owner
              __typename
            }
            jobPost {
              id
              CompanyPreferenceId
              companyQrewId
              companyName
              jobPostLink
              logoUrl
              htmldescription
              CompanyDescription
              AvailablityStatus
              experienceRequired
              jobType
              Location
              skills
              domain
              description
              ShortDesc
              RolesandResponsibility
              title
              CapabilitiesRequiredJSON
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        ActivityStatus
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      trendingCapabilityConfigId
      owner
      __typename
    }
  }
`;
