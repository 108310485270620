import {
	Modal,
	ModalBackdrop,
	ModalBody,
	ModalContent,
	ModalCloseButton,
	ModalFooter,
	ModalHeader,
	Heading,
	Text,
	Box,
	VStack,
	HStack,
	Icon,
	CloseIcon,
} from '@gluestack-ui/themed'

import React from 'react'
import { Dimensions } from 'react-native'
import ForYou from '../../newScreens/opportunities/ForYou'
import StatCounts from './StatCounts'
import MyOpportunities from '../../newScreens/opportunities/MyOpportunities'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const ApplicationsModal = ({
	isOpen,
	onClose,
	navigation,
	completedApplications,
	totalApplication,
	profileId,
}) => {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			position="fixed"
			minHeight={windowHeight}
		>
			<ModalBackdrop />
			<ModalContent
				minWidth={
					windowWidth > 480 ? windowWidth * 0.7 : windowWidth * 0.9
				}
				maxHeight={windowHeight * 0.9}
				overflow={false}
				showHorizontalScrollIndicator={false}
			>
				<ModalHeader bg={'$primary0'}>
					<VStack>
						<Heading
							fontFamily="$fontHeading"
							fontSize={
								windowWidth > 480
									? '$fontHeading'
									: '$fontHeadingMobile'
							}
						>
							My applications
						</Heading>
						<Text
							fontFamily="$fontContent"
							color="$fontGray"
							fontSize={windowWidth > 480 ? 11 : 9}
							lineHeight={15}
						>
							Track you progress
						</Text>
					</VStack>
					<ModalCloseButton>
						<Icon as={CloseIcon} />
					</ModalCloseButton>
				</ModalHeader>
				<ModalBody
					bg={'$primary0'}
					showsHorizontalScrollIndicator={false}
				>
					<StatCounts
						counts={[completedApplications, totalApplication]}
						labels={[
							'Completed Applications',
							'Total Applications',
						]}
					/>
					<MyOpportunities
						navigation={navigation}
						profileId={profileId}
						closeModal={onClose}
					/>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default ApplicationsModal
