import {
	Box,
	Divider,
	HStack,
	Image,
	Pressable,
	Text,
	VStack,
} from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'
import React, { useEffect, useState } from 'react'
import { API, Storage } from 'aws-amplify'
import { Document, Page, pdfjs } from 'react-pdf'
import UploadResume from '../newScreens/onboardingFlow/UploadResume'
import ModalComponent from './ModalComponent'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const ResumeView = ({ userId, screen, navigation }) => {
	const [resumeUploaded, setResumeUploaded] = useState(false)
	const [signedDownloadUrl, setsignedDownloadUrl] = useState('')
	const [numPages, setNumPages] = useState(0)
	const [pageNumber, setPageNumber] = useState(1)
	const [resumeModal, setResumeModal] = useState(false)

	const checkStorage = async () => {
		await Storage.get(userId, {
			validateObjectExistence: true,
		})
			.then((res) => {
				setResumeUploaded(true)
			})
			.catch((err) => {
				setResumeUploaded(false)
			})
	}

	async function getDownloadUrl() {
		const signedURL = await Storage.get(userId, {
			contentType: 'application/pdf',
		})
		setsignedDownloadUrl(signedURL)
	}

	const downloadFile = () => {
		window.open(signedDownloadUrl, '_blank')
	}

	useEffect(() => {
		checkStorage()
		getDownloadUrl()
	}, [userId])

	function onDocumentLoadSuccess({ numPages: nextNumPages }) {
		setNumPages(nextNumPages)
	}

	function goToNextPage() {
		if (numPages == pageNumber) {
			return null
		} else {
			setPageNumber((prevPageNumber) => prevPageNumber + 1)
		}
	}
	function goToPreviousPage() {
		if (pageNumber == 1) {
			return null
		} else {
			setPageNumber((prevPageNumber) => prevPageNumber - 1)
		}
	}

	const uploadResumeRender = () => {
		return (
			<UploadResume
				navigation={navigation}
				setModal={setResumeModal}
				screen={'ResumeView'}
				inModal={true}
			/>
		)
	}

	return (
		<Box>
			{resumeUploaded ? (
				<Box
					borderWidth={1}
					borderColor="$borderDark0"
					borderRadius={6}
					padding={windowWidth > 480 ? 25 : 10}
					alignItems="center"
					marginTop={30}
					bg="white"
				>
					<HStack
						width={'100%'}
						justifyContent="space-between"
						alignItems="flex-start"
					>
						<ModalComponent
							heading={'Edit Resume'}
							isOpen={resumeModal}
							onClose={() => {
								setResumeModal(false)
							}}
							renderBody={uploadResumeRender}
							minWidth={
								windowWidth > 480
									? windowWidth * 0.7
									: windowWidth * 0.9
							}
							hideCloseButton={true}
						/>
						<VStack gap={10}>
							<Text
								fontFamily="$fontHeading"
								fontSize={windowWidth > 480 ? 18 : 15}
								color="$primary950"
								alignSelf="flex-start"
								fontWeight={700}
							>
								Resume
							</Text>
							{screen === 'private' && (
								<Pressable
									paddingHorizontal={
										windowWidth > 480 ? 20 : 10
									}
									paddingVertical={windowWidth > 480 ? 5 : 2}
									bg="$primary500"
									borderRadius={6}
									onPress={() => {
										setResumeModal(true)
									}}
								>
									<Text
										color="white"
										fontFamily="$fontHeading"
										fontWeight="600"
										fontSize={windowWidth > 480 ? 14 : 12}
									>
										Edit Resume
									</Text>
								</Pressable>
							)}
						</VStack>
						<Pressable
							onPress={() => {
								downloadFile()
							}}
						>
							<Image
								width={16}
								height={16}
								source={require('../assets/Images/download.png')}
							/>
						</Pressable>
					</HStack>

					<Divider marginVertical={20} />

					<Box
						w={'auto'}
						borderWidth={1}
						borderColor="$borderDark0"
						borderRadius={6}
					>
						<Document
							file={signedDownloadUrl}
							onLoadSuccess={onDocumentLoadSuccess}
						>
							<Page
								width={
									windowWidth > 480
										? windowWidth * 0.55
										: windowWidth * 0.9
								}
								key={pageNumber}
								pageNumber={pageNumber}
								renderAnnotationLayer={false}
								renderTextLayer={false}
							/>
						</Document>
					</Box>

					<HStack marginTop={10}>
						<Pressable onPress={goToPreviousPage}>
							<Image
								marginRight={40}
								width={20}
								height={20}
								source={require('../assets/Images/Arrow-Down3.png')}
							/>
						</Pressable>
						<Text fontFamily="$fontHeading">
							{pageNumber}/{numPages}
						</Text>

						<Pressable onPress={goToNextPage}>
							<Image
								marginLeft={40}
								width={20}
								height={20}
								source={require('../assets/Images/ArrowDown2.png')}
							/>
						</Pressable>
					</HStack>
				</Box>
			) : (
				screen === 'private' && (
					<Box
						padding={windowWidth > 480 ? 25 : 10}
						bg="white"
						borderColor="$borderDark0"
						borderRadius={6}
						borderWidth={1}
					>
						<HStack
							width={'100%'}
							justifyContent="space-between"
							alignItems="center"
						>
							<Text
								fontFamily="$fontHeading"
								fontSize={windowWidth > 480 ? 18 : 15}
								color="$primary950"
								alignSelf="flex-start"
								fontWeight={700}
							>
								Resume
							</Text>
						</HStack>
						<Divider marginVertical={20} bgColor="$borderDark0" />
						<UploadResume
							profileScreen={true}
							screen={'ResumeView'}
						/>
					</Box>
				)
			)}
		</Box>
	)
}

export default ResumeView
