import React, { useState, useEffect } from 'react'
import { Dimensions } from 'react-native'
import ClaimedCapability from './ClaimedCapability'
import UnclaimedCapability from './UnclaimedCapability'
import UnlockedCapability from './UnlockedCapability'
import useUserStore from '../../Userstore/UserStore'
import { showToast } from '../../components/ErrorToast'
import { createJobApplications } from '../../graphql/mutations'
import { list_JobApplications_by_ProfileId_filter_byJobPostId } from '../../graphql/queries'
import { API } from 'aws-amplify'
import { createCapabilities } from '../../graphql/mutations'
import {
	Box,
	Modal,
	ModalBackdrop,
	ModalContent,
	ModalHeader,
	Heading,
	ModalCloseButton,
	CloseIcon,
	Icon,
	ModalBody,
	HStack,
	Image,
	Text,
	Divider,
	ModalFooter,
} from '@gluestack-ui/themed'
import SpinnerComponent from '../../newComponents/SpinnerComponent'
import ButtonComponent from '../../newComponents/ButtonComponent'
import { ToastContainer } from 'react-toastify'
import JobDetails from '../../newScreens/opportunities/JobDetails'

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

const EligibilityModal = ({
	isOpen,
	onClose,
	jobPosts,
	jobPostsId,
	navigation,
	closeModal,
}) => {
	const jobPost = jobPosts?.jobPost
	const [jobPostFromId, setJobPostFromId] = useState([])
	// const setCheckScreen = useUserStore((state) => state.setCheckScreen)

	// useEffect(() => {
	// 	setCheckScreen('Onboarding')
	// })

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			minHeight={windowHeight}
			position="fixed"
		>
			<ToastContainer />
			<ModalBackdrop />
			<ModalContent
				maxHeight={windowHeight * 0.9}
				minWidth={
					windowWidth > 480 ? windowWidth * 0.7 : windowWidth * 0.9
				}
				minHeight={windowHeight * 0.3}
				overflow={false}
				bg="white"
			>
				<ModalHeader bg="white">
					<Text
						fontFamily="$fontHeading"
						fontSize={windowWidth > 480 ? '$fontSize12' : 10}
						lineHeight={15}
					>
						Eligibility check for{' '}
						<Heading
							fontFamily="$fontHeading"
							fontSize={windowWidth > 480 ? '$fontSize12' : 10}
							color={'$primary500'}
							lineHeight={15}
						>
							{jobPost
								? jobPost?.companyName
								: jobPostFromId?.companyName}
						</Heading>
					</Text>
					<ModalCloseButton>
						<Icon
							as={CloseIcon}
							size={windowWidth > 480 ? 'md' : 'xs'}
						/>
					</ModalCloseButton>
				</ModalHeader>
				<ModalBody showsHorizontlScrollIndicator={false}>
					<Divider h={0.5} />
					<Box>
						<JobDetails
							jobPostIdModal={
								jobPostsId ? jobPostsId : jobPost?.id
							}
							onClose={onClose}
							setJobPostFromId={setJobPostFromId}
							closeModal={closeModal}
							navigation={navigation}
						/>
					</Box>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default EligibilityModal
