import React from 'react'
import { useEffect } from 'react'
import {
	View,
	Dimensions,
	StyleSheet,
	TouchableOpacity,
	TextInput,
	ScrollView,
	Image,
	FlatList,
	Text,
} from 'react-native'
import { HStack, Divider } from 'native-base'
// import { Container, Header, Content, Picker, Form } from 'native-base';
import { Icon, Select } from 'native-base'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
// import { createNewhidi } from "../graphql/mutations";
import { API } from 'aws-amplify'
import { useRef, useState } from 'react'
import { Input, Button, Pressable } from 'native-base'
// load theme styles with webpack
import 'medium-editor/dist/css/medium-editor.css'
import Hidicontainer from '../components/HidiContainer'
import 'medium-editor/dist/css/themes/default.css'
import useUserStore from '../Userstore/UserStore'
// ES module
import Editor from 'react-medium-editor'
import { createNewhidi } from '../graphql/mutations'
import { createOutcome } from '../graphql/custom-mutations'
// import { TouchableOpacity } from "react-native-web";
import { listNewprofiles } from '../graphql/queries'
import { HidiValidator } from '../components/HidiValidator'
import { ActivityIndicator } from 'react-native-web'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { title } from 'process'
import { formatValidationMessages } from '../HeplerFunctions/Validationhelpers'
import HidiErrorPopup from '../components/HidiErrorPopup'
import { showToast } from '../components/ErrorToast'
import ExampleHidi from '../components/ExampleHidi'

const CreateHidiScreen = ({ navigation, route }) => {
	const { profile } = route.params
	const notify = (errorMessages) =>
		toast.warn(<HidiErrorPopup text={errorMessages} />, {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,

			theme: 'light',
		})

	const [heading, setHeading] = useState('')
	const [summary, setSummary] = useState('')
	const [background, setBackground] = useState('')
	const [output, setOutput] = useState('')
	const [loading, setloading] = useState(false)
	const [screenstate, setScreenState] = useState('edit')
	const userId = useUserStore((state) => state.userId)
	const profileId = useUserStore((state) => state.profileId)
	const [selectedValue, setSelectedValue] = useState('')
	// this will be attached with each input onChangeText
	const [textValue, setTextValue] = useState('')
	const [profiles, setProfiles] = useState([])
	// our number of inputs, we can add the length or decrease the length
	const [numInputs, setNumInputs] = useState(1)
	const numInputsRef = useRef(1)

	// all our input fields are tracked with this array
	const refInputs = useRef([textValue])

	const hasValues = () => {
		return !!heading.trim()
	}

	const saveDraft = () => {
		const draftData = {
			heading,
			summary,
			background,
			output: refInputs.current,
			numInputs: numInputsRef.current,
			refInputs: refInputs.current,
		}

		localStorage.setItem('draftData', JSON.stringify(draftData))
	}

	const loadDraft = () => {
		const storedDraftData = localStorage.getItem('draftData')
		if (storedDraftData) {
			const draftData = JSON.parse(storedDraftData)
			setHeading(draftData.heading || '')
			setSummary(draftData.summary || '')
			setBackground(draftData.background || '')
			setOutput(draftData.output || [''])
			numInputsRef.current = draftData.numInputs || 1
			refInputs.current = draftData.refInputs || [textValue]
		}
	}

	useEffect(() => {
		loadDraft()
	}, [])

	useEffect(() => {
		saveDraft()
	}, [heading, summary, background, output, numInputsRef.current])

	async function createHidi() {
		setloading(true)
		const updateProp = ''
		const validationErrors = HidiValidator(
			heading,
			summary,
			numInputs,
			updateProp
		)

		if (validationErrors?.length > 0) {
			// Display validation errors using alert
			const errorMessages = formatValidationMessages(validationErrors)

			notify(errorMessages)
			setloading(false)
			return null
		} else {
			const CreateUserInput = {
				UserId: userId,
				profileId: profileId,
				htmlheading: heading,
				htmlsummary: summary,
				summary: background,
				// htmloutput: output,
			}
			API.graphql({
				query: createNewhidi,
				// authMode: 'API_KEY',
				variables: { input: CreateUserInput },
			}).then((res) => {
				createOutcomes(res.data.createNewhidi.id)
				// navigation.navigate("NewHIDI", {hidiId: res.data.createNewhidi.id})
			})
		}
	}
	async function createOutcomes(prop) {
		{
			refInputs.current.map((value, i) => {
				if (value) {
					const CreateUserInput = {
						HidiId: prop,
						Outcome: value,
						Verified: 'notverified',
					}
					API.graphql({
						query: createOutcome,
						// authMode: 'API_KEY',
						variables: { input: CreateUserInput },
					}).then((res) => {})
				}
			})

			navigation.push('NewHIDI', { hidiId: prop })
		}
	}

	const setInputValue = (index, value) => {
		
		const inputs = refInputs.current
		inputs[index] = value
		setTextValue(value)
		refInputs.current[index] = value
		setOutput(...inputs)
	}
	const addInput = () => {
		if (textValue.length < 40) {
			const message =
				'Outcome Length (Less than 40 characters):"Hold on! What you did needs to be at least 40 characters long for a captivating Outcome." '
			return notify(message)
		}
		// add a new element in our refInputs array
		refInputs.current.push('')
		setNumInputs((value) => value + 1)
		numInputsRef.current += 1
	}
	const removeInput = (i) => {
		// remove from the array by index value
		refInputs.current.splice(i, 1)[0]
		setNumInputs((value) => value - 1)
		numInputsRef.current -= 1
	}
	const inputs = []
	for (let i = 0; i < numInputs; i++) {
		inputs.push(
			<View
				key={i}
				style={{ flexDirection: 'row', alignItems: 'center' }}
			>
				<TextInput
					style={{ ...styles.inputs, marginRight: '1.5%' }}
					onChangeText={(value) => setInputValue(i, value)}
					value={refInputs.current[i]}
					placeholder="Share the results and impact of the solution implemented."
					placeholderTextColor={'#AAA6B9'}
					// placeholder="placeholder"
				/>
				{/* To remove the input */}
				{i >= 1 && (
					<Pressable
						onPress={() => removeInput(i)}
						style={{
							marginLeft: 5,
							backgroundColor: '#FFF2F2',
							borderRadius: 200,
							padding: '1%',
							alignItems: 'center',
						}}
					>
						<Image
							style={{ height: 16, width: 16 }}
							source={require('../assets/Images/trash.png')}
						/>
					</Pressable>
				)}
				{i == numInputs - 1 && (
					<Pressable
						onPress={() => addInput()}
						style={{
							marginLeft: 5,
							backgroundColor: '#DDFFDC',
							borderRadius: 200,
							padding: '1%',
							alignItems: 'center',
						}}
					>
						<Image
							style={{ height: 16, width: 16 }}
							source={require('../assets/Images/addbuttonimage.png')}
						/>
					</Pressable>
				)}
			</View>
		)
	}

	return (
		<>
			{!loading ? (
				<View style={styles.container}>
					<View
						style={{
							flexDirection: windowWidth < 480 ? 'column' : 'row',
							width: windowWidth < 480 ? '100%' : '77%',
							alignSelf: 'center',
							justifyContent: 'center',
						}}
					>
						<View style={{}}>
							{screenstate == 'edit' && (
								<View
									style={{
										flex: 1,
										width: '100%',
										flexDirection: 'column',
										paddingVertical: '3%',
									}}
								>
									<View style={styles.containerheading}>
										<Text style={styles.title}>
											What did you do?
										</Text>
										<TextInput
											onChangeText={(text) =>
												setHeading(text)
											}
											placeholder="Please provide a title"
											style={{
												...styles.inputs,
												height:
													windowWidth < 480
														? windowHeight * 0.07
														: 'auto ',
											}}
											multiline={true}
											value={heading}
											outlineWidth={0}
											placeholderTextColor={'#AAA6B9'}
										/>
										{/* <Editor
                  
                  text={heading}
                  className="editor"
                  style={{ outline: 1, padding: 10, lineheight: "0%", borderRadius: 15 }}
                  onChange={(text) => setHeading(text)}
                  options={{ 
                    toolbar: { buttons: ['bold', 'italic', 
                    'underline','quote', "orderedlist", "unorderedlist", 'h1', 'h2', 'h3', 'h4'] },
                    placeholder : {
                        text : 'Add',
                    }
                 }}
                /> */}
									</View>
									<View style={styles.containeSummary}>
										<Text style={styles.title}>
											Give a background for it
										</Text>
										<TextInput
											onChangeText={(text) =>
												setBackground(text)
											}
											placeholder="Please provide a detailed description of the issue, problem, or situation you encountered."
											style={{
												...styles.inputs,
												height: windowHeight * 0.15,
											}}
											multiline={true}
											value={background}
											placeholderTextColor={'#AAA6B9'}
										/>
									</View>
									<View style={styles.containeSummary}>
										<Text style={styles.title}>
											How did you do it?
										</Text>
										<View
											style={{
												width:
													windowWidth < 480
														? windowWidth * 0.92
														: windowWidth * 0.5,
												fontSize: 14,
												marginTop: 4,
												marginBottom: 8,
												backgroundColor: '#FAFAFA',
												paddingHorizontal: 22,
												paddingVertical: 12,
												borderRadius: 10,
											}}
										>
											<Editor
												text={summary}
												style={{
													lineheight: '1.2rem',
													color: '#424242',
													fontWeight: '400',
													placeholderTextColor:
														'#AAA6B9',
													borderRadius: 10,
													fontSize: 14,
													fontStyle: 'normal',
													outlineStyle: 'none',
													alignItems: 'center',
													justifyContent: 'left',
													fontFamily: 'Open Sans',
													minHeight:
														windowHeight * 0.2,
												}}
												onChange={(text) =>
													setSummary(text)
												}
												options={{
													toolbar: {
														buttons: [
															'bold',
															'italic',
															'underline',
															'orderedlist',
															'unorderedlist',
															'qoute',
															'ancor',
															'h3',
															'h4',
															'h5',
															'h6',
														],
													},
													// toolbar: { buttons: ['bold', 'italic', 'underline'] },
													placeholder: {
														text: 'Describe',
													},
												}}
											/>
										</View>
									</View>
									<View style={styles.containeroutput}>
										<Text style={styles.title}>
											What were the outcomes?
										</Text>
										<View
											contentContainerStyle={{
												flexDirection: 'row',
												flex: 1,
												marginVertical: 12,
												borderRadius: 24,
												padding: 20,
												marginLeft: '1%',
												marginRight: '1%',
												borderWidth: 1,
												borderColor: '#EEEEEE',
												marginTop: 12,
												flex: 1,
												alignSelf: 'center',
											}}
										>
											{inputs}
										</View>
									</View>
								</View>
							)}
							{screenstate == 'view' && (
								<View
									style={{
										flex: 1,
										width: '100%',
										alignItems: 'center',
										paddingVertical: '3%',
									}}
								>
									<Hidicontainer
										heading={heading}
										refInputs={refInputs}
										background={background}
										profile={profile}
										summary={summary}
									/>

									{/* <Editor
                style={{ padding: 10, lineheight: "0%", width: (windowWidth) < 480 ? windowWidth * 0.86 : windowWidth * 0.75, alignSelf: "center" }}
                text={summary}

                // onChange={(text) => setOutput(text)}
                options={{
                  toolbar: false,
                  // toolbar: { buttons: ['bold', 'italic', 'underline'] },
                  disableEditing: true,


                }}
              /> */}
								</View>
							)}
						</View>
						{windowWidth <= 480 ? (
							<Divider
								thickness="1"
								bg="#eeeeee"
								orientation="horizontal"
								style={{ width: '100%', marginVertical: '3%' }}
							/>
						) : (
							<Divider
								thickness="1"
								bg="#eeeeee"
								orientation="vertical"
								style={{
									minHeight: windowHeight,
									marginHorizontal: '3%',
								}}
							/>
						)}
						<View
							style={{
								paddingVertical: '3%',
								paddingHorizontal:
									windowWidth <= 480 ? '3%' : '0%',
								alignSelf: 'flex-start',
								width: windowWidth <= 480 ? '100%' : '20%',
								marginBottom: windowWidth <= 480 ? '3%' : '0%',
							}}
						>
							<HStack
								style={{
									gap: windowWidth <= 480 ? '3%' : '4%',
									flex: 1,
									alignItems: 'center',
									width: '100%',
								}}
							>
								<TouchableOpacity
									style={[
										styles.otherTab,
										!hasValues() && {
											backgroundColor: '#AAA6B9',
											borderColor: '#AAA6B9',
										},
									]}
									onPress={() => {
										if (hasValues()) {
											screenstate === 'view'
												? setScreenState('edit')
												: setScreenState('view')
										} else {
											showToast({
												message:
													'Please provide a title to view the draft!',
											})
										}
									}}
								>
									<Text
										style={[
											styles.otherTabText,
											!hasValues() && {
												color: 'white',
											},
										]}
									>
										{screenstate == 'view'
											? 'Edit Draft'
											: 'View Draft'}
									</Text>
								</TouchableOpacity>
								<TouchableOpacity
									style={styles.activeTab}
									onPress={() => createHidi()}
								>
									<Text style={styles.activeTabText}>
										Publish
									</Text>
								</TouchableOpacity>
							</HStack>
							<View>
								<ExampleHidi />
							</View>
						</View>
					</View>
				</View>
			) : (
				<ActivityIndicator />
			)}
		</>
	)
}

const styles = StyleSheet.create({
	container: {
		width: windowWidth,
		height: windowHeight,
	},
	containerheading: {
		width: windowWidth < 480 ? windowWidth * 0.92 : windowWidth * 0.5,

		// borderWidth: 1,
		alignSelf: 'center',
		marginVertical: 10,
	},
	containeSummary: {
		width: windowWidth < 480 ? windowWidth * 0.92 : windowWidth * 0.5,
		// height: windowHeight*0.65,
		// borderWidth: 1,
		alignSelf: 'center',
		marginVertical: 10,
	},
	containeroutput: {
		width: windowWidth < 480 ? windowWidth * 0.92 : windowWidth * 0.5,
		// height: windowHeight*0.15,
		// Height: windowHeight,
		// borderWidth: 1,
		marginVertical: 20,

		alignSelf: 'center',
	},

	title: {
		fontSize: 17,
		fontWeight: 'bold',
	},
	title1: {
		fontSize: 17,
		paddingBottom: 20,
		fontWeight: 'bold',
	},
	title2: {
		fontSize: 17,
		paddingBottom: 20,
		fontWeight: 'bold',
	},
	input: {
		width: '100%',
		height: 40,
		borderWidth: 1,
		borderColor: '#cccccc',
		borderRadius: 8,
		marginBottom: 10,
	},
	addButton: {
		backgroundColor: 'green',
		width: 180,
		height: 40,
		padding: 10,
		alignSelf: 'center',

		justifyContent: 'center',
		borderRadius: 10,
		marginVertical: 10,
	},
	Hidititle: {
		padding: 10,
		fontSize: 18,
		fontWeight: 'bold',
		fontFamily: 'Lexend',
		paddingHorizontal: '3%',
	},

	abouttext: {
		padding: 10,
		// lineHeight: 10,
		fontSize: 14,
		color: 'rgba(91, 91, 91, 0.7)',
	},
	introtext: {
		padding: 10,
		fontWeight: '800',
		fontSize: 16,
		color: '#5B5B5B',
		fontFamily: 'Lexend',
	},
	detailscontainer: {
		width: windowWidth <= 480 ? '95%' : '60%',
		backgroundColor: '#fff',
		alignSelf: 'center',
		marginTop: 20,
		borderRadius: 15,
		borderWidth: 0.5,
		borderColor: 'lightgrey',
		// shadowColor: '#171717',
		// shadowOffset: {width: -2, height: 4},
		// shadowOpacity: 0.2,
		// shadowRadius: 3,
		marginBottom: 40,
	},
	imagedetails: {
		width: windowWidth <= 480 ? '95%' : '70%',
		height: windowWidth <= 480 ? windowWidth * 0.6 : windowWidth * 0.35,
		// padding: 10,
		alignSelf: 'center',
	},
	backgroundcontainer: {
		borderWidth: 1,
		width: '95%',
		alignSelf: 'center',
		marginVertical: 10,
		borderRadius: 15,
		borderColor: '#F1F1F1',
	},
	outcomecontainer: {
		borderWidth: 1,
		width: '95%',
		alignSelf: 'center',
		marginVertical: 10,
		borderRadius: 15,
		borderColor: '#F1F1F1',
	},
	hidiBox: {
		width: windowWidth < 480 ? windowWidth * 0.92 : windowWidth * 0.5,
		marginVertical: 12,
		flexDirection: 'column',
		borderRadius: 24,
		padding: 20,

		borderWidth: 1,
		borderColor: '#EEEEEE',
	},
	innerBox: {
		marginVertical: 12,
		flexDirection: 'column',
		borderRadius: 24,
		padding: 20,
		marginHorizontal: '3%',
		borderWidth: 1,
		borderColor: '#EEEEEE',
		marginTop: 12,
		width: '100%',
		alignSelf: 'center',
	},
	hidicontainer1: {
		// width: (windowWidth <= 480) ? "95%" : "95%",
		// height: windowHeight*0.22,
		// alignSelf: "center",
		// justifyContent: "center",
		// alignItems: "center",
		marginLeft: -1,
		paddingVertical: 20,
	},
	hidicontainer2: {
		width: '60%',
		// height: "80%",
		borderWidth: 2,
		borderRadius: 15,
		borderColor: '#F1F1F1',
	},
	title: {
		fontWeight: '900',
		fontSize: 14,
		marginTop: 4,
		marginBottom: 8,
		fontFamily: 'Urbanist',
		color: '#272727',
		// borderWidth: 1
		// marginLeft: 20,
		// padding: 10
	},
	inputs: {
		fontWeight: '400',
		fontSize: 14,
		marginTop: 4,
		marginBottom: 8,
		fontFamily: 'Open Sans',
		color: '#424242',
		backgroundColor: '#FAFAFA',
		outlineStyle: 'none',
		paddingHorizontal: 22,
		paddingVertical: 12,
		borderRadius: 10,
		width: '100%',
		lineHeight: '1.2rem',
	},

	hashtagtext: {
		fontWeight: '300',
		fontSize: 14,
		fontFamily: 'Open Sans',
		color: 'rgba(91, 91, 91, 0.7)',
		// paddingVertical: 5
		// padding: 10
	},
	impacttext: {
		fontWeight: '900',
		fontFamily: 'Open Sans Bold 700',
		fontSize: 16,
		lineHeight: 18,
		// marginVerti/cal: 2
	},
	impactcontainer: {
		marginTop: 10,
	},
	impactgreytext: {
		fontFamily: 'Open Sans',
		color: 'rgba(91, 91, 91, 0.7)',
		marginVertical: 10,
	},
	secondheader: {
		width: windowWidth <= 480 ? '90%' : '65%',
		height: 50,
		borderWidth: 1,
		alignSelf: 'center',
		borderRadius: 5,
		alignContent: 'center',
		alignItems: 'center',
		marginTop: 20,
		marginBottom: 20,
		justifyContent: 'space-evenly',
	},
	activeTab: {
		width: '47%',
		paddingVertical: '3%',
		paddingHorizontal: '4%',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 100,
		backgroundColor: '#246bfe',
		borderWidth: 2,
		borderColor: '#246bfe',
	},
	activeTabText: {
		color: '#fff',
		fontSize: 11,
		fontWeight: 400,
		fontFamily: 'Lexend',
		alignContent: 'center',
	},
	otherTab: {
		width: '47%',
		paddingVertical: '3%',
		paddingHorizontal: '4%',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 100,
		borderWidth: 2,
		borderColor: '#246bfe',
	},
	otherTabText: {
		color: '#246bfe',
		fontFamily: 'Lexend',
		fontSize: 11,
		fontWeight: 400,
		alignContent: 'center',
	},
	outcomeText: {
		fontFamily: 'Lexend',
		fontSize: 14,
		color: '#272727',
		fontWeight: 600,
	},
	outcomeBox: {
		flexDirection: 'column',
		paddingVertical: 1,
		gap: 1,
	},
})

export default CreateHidiScreen
