import {
	Box,
	Image,
	Modal,
	ModalBackdrop,
	ModalContent,
	Pressable,
	Text,
} from '@gluestack-ui/themed'
import React from 'react'
import { Dimensions } from 'react-native'
import TotalPointsOneCapability from './TotalPointsOneCapability'
import { ModalBody } from '@gluestack-ui/themed'

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

const CongratsOnboarding = ({ points, isOpen, onClose, onPressContinue }) => {

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			position="fixed"
			maxHeight={windowHeight}
		>
			<ModalBackdrop />
			<ModalContent
				p={0}
				w={'fit-content'}
				h={'fit-content'}
				borderRadius={30}
			>
				<ModalBody
					p={0}
					w={'fit-content'}
					h={'fit-content'}
					borderRadius={30}
				>
					<Box
						p={40}
						borderRadius={30}
						w={'fit-content'}
						bg={'rgba(255, 143, 162, 1)'}
						gap={10}
						alignItems="center"
					>
						<Box marginVertical={10}>
							<Image
								source={require('../../assets/Images/congrats.png')}
								h={110}
								w={110}
								alignSelf="center"
							/>
						</Box>
						<Box>
							<Text
								textAlign="center"
								fontFamily="$fontHeading"
								color="$primary0"
								fontSize={20}
								fontWeight="bold"
							>
								Congratulations!!
							</Text>
						</Box>
						<Box>
							<TotalPointsOneCapability
								points={points}
								text={'Added to your profile'}
							/>
						</Box>
						<Pressable
							bg="rgba(255, 233, 233, 1)"
							paddingVertical={10}
							borderRadius={20}
							paddingHorizontal={30}
							marginTop={10}
							w={'fit-content'}
							onPress={onPressContinue}
						>
							<Text
								textAlign="center"
								fontFamily="$fontHeading"
								fontSize={15}
								color={'rgba(255, 143, 162, 1)'}
								fontWeight="bold"
							>
								Continue
							</Text>
						</Pressable>
					</Box>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default CongratsOnboarding
