import { Box } from '@gluestack-ui/themed'
import React from 'react'
import { Dimensions } from 'react-native'
import useUserStore from '../../../Userstore/UserStore'
import CandidateCareerViewComponent from '../../../newComponents/editCareer/CandidateCareerViewComponent'
import HidiViewProfile from './HidiViewProfile'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const WhatIDidView = ({ data, navigation, profile, screen, PreferenceId }) => {
	const loggedInProfile = useUserStore((state) => state.profile)
	const renderItemMethod = ({ item }) => {
		return (
			<Box>
				<HidiViewProfile
					item={item}
					navigation={navigation}
					view={screen}
					PreferenceId={PreferenceId ? PreferenceId : null}
				/>
			</Box>
		)
	}

	return (
		<Box
			bg="$primary0"
			marginBottom={12}
			p={windowWidth > 480 ? 20 : 10}
			borderWidth={1}
			borderColor="$borderDark0"
			borderRadius={6}
			marginTop={10}
		>
			<CandidateCareerViewComponent
				data={data}
				renderItem={renderItemMethod}
				title={'Experiences'}
				onPress={() => {
					navigation.push('CapCreateHidi')
				}}
				hidiCount={loggedInProfile?.hidis?.items?.length}
				description={'No experiences added'}
				buttonText={'Add Your Experiences'}
				addMoreImage={require('../../../assets/Images/addhidi.png')}
				component={'whatIDid'}
				onPressViewAll={() => {
					navigation.push('Hidilist', {
						UserId: profile
							? profile?.UserId
							: loggedInProfile?.UserId,
						screen: screen,
					})
				}}
				screen={screen}
			/>
		</Box>
	)
}

export default WhatIDidView
