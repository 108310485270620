import {
	StyleSheet,
	Text,
	View,
	ActivityIndicator,
	Dimensions,
} from 'react-native'
import { FlatList, ScrollView, Spinner } from 'native-base'
import React from 'react'
import JobPostFeed from '../../FeedComponents/JobPostFeed'
import PeersProfiles from '../../FeedComponents/PeersProfiles'
import CompanyShortlists from '../../FeedComponents/CompanyShortlist'
import CapabilityUnlock from '../../FeedComponents/CapabilityUnlockComponent'
import PeersProfileComponent from '../../FeedComponents/PeersProfileComponent'
import { list_Feed_by_UserSegmentId } from '../../graphql/custom-queries'
import { list_Feed_by_Domain } from '../../graphql/queries'
import { getNewprofileByUserId } from '../../graphql/custom-queries'
import { listTrendings } from '../../graphql/queries'
import HidiFeed from '../../FeedComponents/HIdiFeedComponent'
import { useState, useEffect, useCallback } from 'react'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
import TrendingComponent from '../../FeedComponents/TrendingComponent'
import ProfileStats from '../../FeedComponents/ProfileStats'
import { API } from 'aws-amplify'
import useUserStore from '../../Userstore/UserStore'
import ModalSelectCapabilities from '../../FeedComponents/ModalSelectCapabilities'
import useGlobalCapabilityConfig from '../../customhook/GetGlobalCapability'
import SideNavBar from '../../newComponents/sideNavigationBar/SideNavBar'
import SelectCapabilitiesModal from '../../newComponents/keyCapabilities/SelectCapabilitiesModal'
import { Box } from '@gluestack-ui/themed'
// import { useLinkTo } from '@react-navigation/native';
import EligibilityModal from '../../FeedComponents/CheckEligibility/EligibilityModal'
import SpinnerComponent from '../../newComponents/SpinnerComponent'
import GetOpportunities from '../../customhook/GetOpportunities'
import MyOpportunities from '../opportunities/MyOpportunities'

export default function Feed({ navigation }) {
	// const linkTo = useLinkTo();
	const userId = useUserStore((state) => state.userId)
	const domain = useUserStore((state) => state.domain)
	
	const getJobs = GetOpportunities()
	const profileId = useUserStore((state) => state.profileId)
	const setProfileId = useUserStore((state) => state.setProfileId)
	const setHidisState = useUserStore((state) => state.setHidis)
	const setFullName = useUserStore((state) => state.setName)
	const setImageProfile = useUserStore((state) => state.setImage)
	const setImagekeyProfile = useUserStore((state) => state.setImagekey)
	const setUserProfile = useUserStore((state) => state.setUserProfile)
	const setDomain = useUserStore((state) => state.setDomain)
	const savedurl = useUserStore((state) => state.savedurl)
	const setSavedUrl = useUserStore((state) => state.setSavedUrl)
	const setCountCapabailities = useUserStore(
		(state) => state.setCountCapabailities
	)
	const setClaimedCapabailities = useUserStore(
		(state) => state.setClaimedCapabailities
	)
	const [profile, setProfile] = useState('')
	const profileState = useUserStore((state) => state.profile)
	const setCheckScreen = useUserStore((state) => state.setCheckScreen)
	const [nextToken, setNextToken] = useState(null)
	const [feedData, setFeedData] = useState([])
	const [trending, setTrending] = useState([])
	const [cohortdata, setcohortData] = useState([])
	const [generaldata, setgeneralData] = useState([])
	const [prevFeedData, setPrevFeedData] = useState([])

	const [isRefreshing, setIsRefreshing] = useState(false)
	const [loading, setLoading] = useState(true)
	const [openModalFirstTime, setOpenModalFirstTime] = useState(false)
	const [Trendsloading, setTrendsLoading] = useState(true)
	const [modalShow, setModalShow] = useState(false)
	const Globalcapabilities = useGlobalCapabilityConfig()
	const candidateCapabilities = profile?.Capabilities?.items
	const [modalShowEligibility, setModalShowEligibility] = useState(false)

	const [firstTimeLogin, setFirstTimeLogin] = useState(false)

	useEffect(() => {
		const checkFirstTimeLogin = () => {
			try {
				console.log('in check')
				const hasLoggedInBefore =
					localStorage.getItem('hasLoggedInBefore')
				if (!hasLoggedInBefore && openModalFirstTime) {
					setFirstTimeLogin(true)
					localStorage.setItem('hasLoggedInBefore', 'true')
				} else {
					setFirstTimeLogin(false)
				}
			} catch (error) {
				console.error(error)
			}
		}

		checkFirstTimeLogin()
	}, [openModalFirstTime])

	const gotoJobPage = () => {
		
		const jobId = savedurl.match(/jobPostId=([^&]+)/)
		
		if(savedurl.includes('JobDetails')){
			navigation.navigate('JobDetails', {
				jobPostId: jobId[1],
			})
		}
		else{
			navigation.navigate('JobApply', {
				jobPostId: jobId[1],
			})
		}
		
		setSavedUrl('')
	}

	const getprofile = useCallback(async () => {
		try {
			const response = await API.graphql({
				query: getNewprofileByUserId,
				variables: { UserId: userId },
			})
			
			const profileData = response.data.getNewprofileByUserId?.items[0]
			
			setProfile(profileData)
		
			setDomain(profileData?.CandidatePreferences?.items[0]?.userDomain)
			setUserProfile(profileData)
			setProfileId(profileData?.id)
			setFullName(profileData?.name)
			setImageProfile(profileData?.image)
			setImagekeyProfile(profileData?.imagekey)
			setHidisState(profileData?.hidis.items)
			setClaimedCapabailities(profileData?.Capabilities?.items)

			if (!profileData) {
				navigation.push('Onboarding', { stepState: 0 })
				return
			} else if (profileData?.CandidatePreferences?.items?.length === 0) {
				navigation.push('Onboarding', { stepState: 1 })
				return
			} else if (savedurl.includes('JobDetails')) {
				gotoJobPage()
			}
			else if (savedurl.includes('JobApply')) {
				gotoJobPage()
			}

			if (savedurl.includes('opportunities')) {
				gotoLink()
			}
			 else {
				setLoading(false)
				// if (!domain) {
				// 	getPrevFeedList(
				// 		profileData?.CandidatePreferences?.items[0]?.userDomain
				// 	)
				// }
			}
		} catch (error) {
			console.log(error)
			if (profile?.length !== 0) {
				console.error(error)
			} else {
				setProfileId(null)
				console.error(error)
				if (savedurl.includes('JobDetails')) {
					gotoJobPage()
				} else {
					navigation.push('Onboarding', { stepState: 0 })
					return
				}
			}
		}
	}, [])

	const gotoLink = () => {
		setModalShowEligibility(true)
		// getFeedData()
	}

	useEffect(() => {
		setCheckScreen('Feed')

		getprofile()
		// getFeedData()
	}, [ navigation])

	// useEffect(() => {
	// 	const mergedData = [...cohortdata, ...generaldata]

	// 	mergedData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

	// 	if (mergedData.length > 0) {
	// 		setFeedData(mergedData)
	// 		setLoading(false)
	// 	}
	// }, [cohortdata, generaldata])

	// const getFeedData = useCallback(() => {
	// 	if (Array.isArray(feedData) && feedData.length === 0) {
	// 		domain && getPrevFeedList()
	// 		getPrevFeedListGeneral()
	// 		// getTrendings();
	// 	}
	// }, [])

	async function getFeedOnRefresh() {
		await API.graphql({
			query: list_Feed_by_UserSegmentId,
			variables: {
				type: 'Global',
				limit: 5,
				sortDirection: 'DESC',
			},
		}).then((res) => {
			setNextToken(res.data.FeedByDate.nextToken)
			setFeedData(res.data.FeedByDate.items)
			setIsRefreshing(false)
		})
	}
	async function getTrendings() {
		await API.graphql({
			query: listTrendings,
			variables: {
				type: 'Global',
				limit: 25,
				sortDirection: 'DESC',
			},
		}).then((res) => {
			setTrending(res.data.listTrendings.items)
			setTrendsLoading(false)
		})
	}
	async function getPrevFeedList(props) {
		await API.graphql({
			query: list_Feed_by_Domain,
			variables: {
				type: 'Global',
				limit: 25,
				sortDirection: 'DESC',
				domain: domain ? domain : props,
			},
		})
			.then((res) => {
				setcohortData(res.data?.list_Feed_by_Domain?.items)
				// setNextToken(res.data.list_Feed_by_Domain.nextToken)

				// setLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}
	async function getPrevFeedListGeneral() {
		await API.graphql({
			query: list_Feed_by_Domain,
			variables: {
				type: 'Global',
				limit: 25,
				sortDirection: 'DESC',
				domain: 'general',
			},
		}).then((res) => {
			// setNextToken(res.data.list_Feed_by_Domain.nextToken)
			setgeneralData(res.data.list_Feed_by_Domain.items)

			// setLoading(false)
		})
	}

	useEffect(() => {
		// Check if feedData is not empty
		if (feedData.length > 0) {
			setOpenModalFirstTime(true)
		}
	}, [feedData])

	// 	const renderItem = (data) => {
	// 		const item = data.item
	// 		return (
	// 			<>
	// 				{item !== undefined && (
	// 					<Box overflow="hidden">
	// 						{item?.FeedType == 'jobPost' && (
	// 							<JobPostFeed
	// 								modalShow={modalShow}
	// 								item={item}
	// 								candidateCapabilities={candidateCapabilities}
	// 								navigation={navigation}
	// 							/>
	// 						)}
	// 						{item?.FeedType == 'HidiType' && (
	// 							<HidiFeed
	// 								modalShow={modalShow}
	// 								setModalShow={setModalShow}
	// 								item={item}
	// 								navigation={navigation}
	// 							/>
	// 						)}

	// 						{/* {index == 1 ? (
	// 	<>
	// 		{Trendsloading ? (
	// 			<SpinnerComponent button={true} />
	// 		) : (
	// 			<TrendingComponent
	// 				modalShow={modalShow}
	// 				setModalShow={setModalShow}
	// 				TrendsData={trending}
	// 			/>
	// 		)}
	// 	</>
	// ) : null} */}

	// 						{item?.FeedType == 'GlobalCapbilityType' && (
	// 							<CapabilityUnlock
	// 								modalShow={modalShow}
	// 								setModalShow={setModalShow}
	// 								item={item}
	// 								navigation={navigation}
	// 							/>
	// 						)}
	// 					</Box>
	// 				)}
	// 			</>
	// 		)
	// 	}
	return (
		<Box
			bg="$backgroundGray"
			minHeight={windowHeight - 62}
			marginTop={62}
			marginBottom={windowWidth > 480 ? null : 50}
			paddingHorizontal={windowWidth > 480 ? 100 : 5}
			paddingVertical={windowWidth > 480 ? 20 : 5}
			paddingBottom={windowWidth > 480 ? null : 50}
		>
			<EligibilityModal
				isOpen={modalShowEligibility}
				setModalShow={setModalShowEligibility}
				onClose={() => {
					setModalShowEligibility(false)
				}}
				jobPostsId={
					savedurl.includes('opportunities') &&
					savedurl.split('/opportunities/')[1]
				}
				navigation={navigation}
			/>
			{loading ? (
				<Box w={'100%'}>
					<SpinnerComponent button={true} />
				</Box>
			) : (
				<Box>
					<SelectCapabilitiesModal
						isOpen={modalShow}
						setModalShow={setModalShow}
						onClose={() => setModalShow(false)}
					/>

					{loading ? (
						<Box w={'100%'}>
							<SpinnerComponent button={true} />
						</Box>
					) : (
						<Box w={'100%'}>
							<Box w={'100%'}>
								<ProfileStats
									navigation={navigation}
									firstTimeLogin={firstTimeLogin}
								/>
								{/* <FlatList
									data={feedData}
									renderItem={renderItem}
									keyExtractor={(item) => item.id.toString()}
									onEndReachedThreshold={0.5}
								/> */}
								<Box>
									<MyOpportunities
										navigation={navigation}
										profileId={profileId}
										closeModal={null}
									/>
								</Box>
							</Box>
						</Box>
					)}
				</Box>
			)}
		</Box>
	)
}
