
import { Box, View, Text } from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'
import React, { useEffect, useState } from 'react'

import EditProfile from '../../newComponents/editProfile/EditProfile'

import useUserStore from '../../Userstore/UserStore'
import ProgressBar from '../../newComponents/ProgressBar'
import { onCreateUserParsedResumeByUserId } from '../../graphql/subscriptions'
import ProgressBubbles from '../onboardingFlow/ProgressBubbles'
import CandidatePreferences from '../../newComponents/CandidatePreferences'
import VideoRecordingScreen from '../../newComponents/videoComponent/VideoRecordingScreen'
import CreateUpdatePreferences from './components/CreateUpdatePreferences'
import CreateUpdateComponentJobPost from './components/CreateUpdateComponentJobPost'
import CreateUpdateSkillTrait from './components/CreateUpdateSkillTrait'
import CreateJobPostView from './components/CreateJobPostView'
import CreateCapabilityforJobPost from './components/CreateCapabilityforJobPost'
import { MobileHeader } from '../../newComponents/header/Header'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

export default function CreateJobPost( {
	navigation,
	route
   
}) {
    
  const {companyProfileId} = route.params

	const [activeSection, setActiveSection] = useState(
		0
	)
	const [totalScreens, setTotalScreens] = useState(5)

	const setCheckScreen = useUserStore((state) => state.setCheckScreen)
    const [progress, setProgress] = useState(0)
	const [preferenceId, setpreferenceId ] = useState(route?.params?.preferenceId)
	const [responseLambda, setResponseLambda] = useState(false)
	const [mapData, setMapData] = useState([])

	const skipState = () => {
		setResponseLambda(false)
		setMapData([])
	}

	const handleProceed = () => {
		if (activeSection < totalScreens) {
			
			const newStepState = activeSection + 1
			setActiveSection(newStepState)
			
		}
	}

	const handleGoBack = () => {
		if (activeSection > 0) {
			const newStepState = activeSection - 1
			setActiveSection(newStepState)

			
		}
	}
   
	useEffect(() => {
		if(activeSection == 0) {
			setProgress(0)
		}
		if(activeSection == 1) {
			setProgress(25)
		}
		if(activeSection == 2) {
			setProgress(50)
		}
		if(activeSection == 3) {
			setProgress(75)
		}
		if(activeSection == 4) {
			setProgress(100)
		}
	}, [activeSection])

	return (
		<View
			overflowX="hidden"
			bg="$backgroundGray"
			minHeight={windowHeight - 50}
			marginTop={50}
			w={'100%'}
		>
				<Box zIndex={1}>
				<MobileHeader props={'Create or Update Job Post'} navigation={navigation} />
			</Box>
			{/* <ProgressBubbles
				activeSection={activeSection}
				setTotalScreen={setTotalScre or Updateens}
				setActiveSection={setActiveSection}
			/> */}
           
			<Box w={windowWidth > 480 ? '70%' : '75%'} alignSelf='center' marginBottom={20} marginTop={20}>
			<Text
			fontFamily="$fontContent"
			fontSize={
				windowWidth > 480
					? '$fontContent'
					: '$fontContentMobile'
			}
			fontWeight="700"
			color="$primary950">
                {`${progress}% Complete`}
            </Text>
			<ProgressBar
                width={"100%"}
				
                
								progress={progress}
								bgColor={'red'}
								borderColor={'lightgrey'}
								animationType={'timing'}
							/>
			</Box>
           
            
          

			{activeSection === 0 && (
				<Box
					w={
						windowWidth > 480
							? windowWidth * 0.7
							: windowWidth * 0.95
					}
					alignSelf="center"
					mb={windowWidth < 480 && 20}
				>
					{/* <EditProfile
						handleProceed={handleProceed}
						handleGoBack={handleGoBack}
						responseLambda={responseLambda}
						mapData={mapData}
						setMapData={setMapData}
						screen={'onboarding'}
						navigation={navigation}
						jobType={jobType}
					/> */}
                    <CreateUpdatePreferences
                    preferenceId = {preferenceId}
					setpreferenceId = {setpreferenceId}
                    handleProceed={handleProceed}
                    handleGoBack={handleGoBack}
                    navigation={navigation}
					companyProfileId={companyProfileId}
                    // jobType={jobType}
                />
				</Box>
			)}
			{activeSection === 1 && (
				<Box
				w={
					windowWidth > 480
						? windowWidth * 0.7
						: windowWidth * 0.95
				}
				alignSelf="center"
				mb={windowWidth < 480 && 20}
				>
					 <CreateUpdateComponentJobPost
                       preferenceId = {preferenceId}
                    handleProceed={handleProceed}
                    handleGoBack={handleGoBack}
                    navigation={navigation}
					companyProfileId={companyProfileId}
                   
                />
				</Box>
			)}
			{activeSection === 2 && (
				<Box
				w={
					windowWidth > 480
						? windowWidth * 0.7
						: windowWidth * 0.95
				}
				alignSelf="center"
				mb={windowWidth < 480 && 20}
				>
					  <CreateUpdateSkillTrait
                        preferenceId = {preferenceId}
                    handleProceed={handleProceed}
                    handleGoBack={handleGoBack}
					navigation={navigation}
					companyProfileId={companyProfileId}
                    
					
                />
				</Box>
			)}
             {activeSection === 3 && (
				<Box
				w={
					windowWidth > 480
						? windowWidth * 0.7
						: windowWidth * 0.95
				}
				alignSelf="center"
				mb={windowWidth < 480 && 20}
				>
					  <CreateCapabilityforJobPost
					   preferenceId = {preferenceId}
                    handleProceed={handleProceed}
                    handleGoBack={handleGoBack}
                    navigation={navigation}
						companyProfileId={companyProfileId}
                
                />
				</Box>
			)}
            {activeSection === 4 && (
				<Box
				w={
					windowWidth > 480
						? windowWidth * 0.7
						: windowWidth * 0.95
				}
				alignSelf="center"
				mb={windowWidth < 480 && 20}
				>
					  <CreateJobPostView
                    handleProceed={handleProceed}
                    handleGoBack={handleGoBack}
                    navigation={navigation}
					preferenceId = {preferenceId}
					companyProfileId={companyProfileId}
                />
				</Box>
			)}
			{/* {activeSection === 2 && (
				<Box
					w={
						windowWidth > 480
							? windowWidth * 0.7
							: windowWidth * 0.95
					}
					alignSelf="center"
				>
					<SelectCapabilities
						handleGoBack={handleGoBack}
						navigation={navigation}
						inModal={inModal}
						setJobOnboardModal={setJobOnboardModal}
					/>
				</Box>
			)} */}
		</View>
	)
}
