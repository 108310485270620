import React, { useState } from 'react'
import { Dimensions } from 'react-native'
import { Box } from '@gluestack-ui/themed'
import EditCandidateCareerModal from '../EditCandidateCareerModal'

const windowWidth = Dimensions.get('window').width

const EditExperience = ({
	onSave,
	setShowModal,
	modalVisibility,
	setFields,
	fields,
	toast,
	ref,
}) => {
	const [loading, setLoading] = useState(false)

	const toggleSwitch = () =>
		setFields({ ...fields, isPresent: !fields.isPresent })

	const saveFields = async () => {
		setLoading(true)
		if (validateFields(fields)) {
			await onSave(fields)
			setLoading(false)
			setShowModal(false)
			setFields({
				id: '',
				designation: '',
				company: '',
				StartDate: '',
				EndDate: '',
				isPresent: false,
			})
		} else {
			setLoading(false)
			toast()
		}
	}

	const closeModal = () => {
		setShowModal(false)
		setFields({
			id: '',
			designation: '',
			company: '',
			StartDate: '',
			EndDate: '',
			isPresent: false,
		})
	}

	const validateFields = () => {
		if (
			fields.designation === '' ||
			fields.company === '' ||
			fields.StartDate === '' ||
			(!fields.isPresent && fields.EndDate === '')
		) {
			return false
		}
		return true
	}

	const setDesignationChange = (value) =>
		setFields({ ...fields, designation: value })

	const setCompanyChange = (value) => setFields({ ...fields, company: value })

	const setStartDateChange = (pickedDate) =>
		setFields({ ...fields, StartDate: pickedDate })
	const setEndDateChange = (pickedDate) =>
		setFields({ ...fields, EndDate: pickedDate })

	return (
		<Box>
			<EditCandidateCareerModal
				heading={'Employment'}
				isOpen={modalVisibility}
				onClose={closeModal}
				ref={ref}
				labels={['Job Title', 'Company']}
				importants={[true, true]}
				placeholders={['Full Stack Developer', 'Qrusible Pvt. Ltd.']}
				values={[fields?.designation, fields?.company]}
				setValueChanges={[setDesignationChange, setCompanyChange]}
				handleSave={saveFields}
				date={true}
				startDate={fields?.StartDate}
				onStartDateChange={setStartDateChange}
				endDate={fields?.EndDate}
				onEndDateChange={setEndDateChange}
				isPresent={fields?.isPresent}
				toggleSwitch={toggleSwitch}
				loading={loading}
			/>
		</Box>
	)
}

export default EditExperience
