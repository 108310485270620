import {
	Box,
	FlatList,
	Pressable,
	Text,
	HStack,
	VStack,
	Image,
	Divider,
	CheckIcon,
	CloseIcon,
	Icon,
	Input,
	InputField,
} from '@gluestack-ui/themed'
import React, { useEffect, useState } from 'react'
import { Dimensions } from 'react-native'
import { claimCapability, getSkillWithId } from './HelperFunctions'
import { OpenHidiPublicView } from '../../../HeplerFunctions/OpenHidiView'
import SpinnerComponent from '../../../newComponents/SpinnerComponent'
import ButtonComponent from '../../../newComponents/ButtonComponent'
import { updateSkills } from '../../../FeedComponents/CheckEligibility/ShareExperience/helperFunctions'
import useUserStore from '../../../Userstore/UserStore'
import CapabilityTemplate from '../../../FeedComponents/CheckEligibility/CapabilityTemplate'

const windowWidth = Dimensions.get('window').width

const SkillInfoModalBody = ({
	skillSelected,
	navigation,
	setSelectedSkills,
	screen,
}) => {
	const [skillInfo, setSkillInfo] = useState(null)
	const [showEditYears, setShowEditYears] = useState(false)
	const [skillYears, setSkillsYears] = useState(null)
	const capabilitiesByDomain = useUserStore(
		(state) => state.GlobalCapabilities
	)

	const typeOfUser = useUserStore((state) => state.group)
	const claimedCapabilities = useUserStore(
		(state) => state.claimedCapabilities
	)
	const setClaimedCapabilities = useUserStore(
		(state) => state.setClaimedCapabailities
	)
	const profileId = useUserStore((state) => state.profileId)
	const userId = useUserStore((state) => state.userId)
	const [combinedCapabilities, setCombinedCapabilities] = useState([])
	const [claimLoading, setClaimLoading] = useState('')

	const filterCapabilities = () => {
		const claimedCapabilitiesWithNoHidis =
			claimedCapabilities &&
			claimedCapabilities?.filter(
				(item) => item?.ConnectedHidis?.items?.length === 0
			)

		const unclaimedCapabilities = capabilitiesByDomain?.filter(
			(item) =>
				claimedCapabilities &&
				!claimedCapabilities?.some(
					(capability) =>
						capability?.CapabilityConfig?.id === item?.id
				)
		)

		const newCombinedCapabilities = [
			...claimedCapabilitiesWithNoHidis,
			...unclaimedCapabilities,
		]

		setCombinedCapabilities(newCombinedCapabilities)
	}

	useEffect(() => {
		typeOfUser === 'candidates' &&
			screen !== 'public' &&
			filterCapabilities()
	}, [claimedCapabilities])

	const fetchSkillInfo = async () => {
		try {
			const resolvedSkillInfo = await getSkillWithId(skillSelected)
			setSkillInfo(resolvedSkillInfo)
			setSkillsYears(resolvedSkillInfo?.YearsOfExperience)
		} catch (error) {
			console.error('Error fetching skill info:', error)
		}
	}

	useEffect(() => {
		fetchSkillInfo()
	}, [skillSelected])

	const claimUserCapability = async (globalConfigId) => {
		setClaimLoading(globalConfigId)
		const userInput = {
			ProfileId: profileId,
			capabilitiesCapabilityConfigId: globalConfigId,
		}
		try {
			const result = await claimCapability(userInput)
			setClaimedCapabilities([...claimedCapabilities, result])
		} catch (err) {
			console.error('Error claiming capability:', err)
		}
		setClaimLoading('')
	}

	const DataDisplay = ({ text, title }) => {
		return (
			<Box marginVertical={5}>
				<Text color="#A0AEC0" fontSize={12} fontFamily="$fontHeading">
					{title}
				</Text>
				<Text fontFamily="$fontContent" fontSize={13}>
					{text}
				</Text>
			</Box>
		)
	}

	const renderHidis = ({ item }) => {
		const capabilityName =
			item?.Capabilities?.ConnectedHidis?.items[0]?.Capability
				?.CapabilityConfig?.name

		const relatedHidi = item?.Capabilities?.ConnectedHidis?.items[0]?.Hidi
		const skillsFound = item?.Capabilities?.JoinedSkills?.items

		const splitString = relatedHidi?.whereitwasdone?.split(/\(([^)]+)\)/)

		const textOutside = splitString ? splitString[0]?.trim() : null
		const textInside = splitString ? splitString[1]?.trim() : null

		return (
			<>
				{capabilityName && (
					<Pressable
						onPress={() => {
							OpenHidiPublicView(relatedHidi?.id)
						}}
						marginVertical={windowWidth > 480 ? 15 : 5}
					>
						<HStack marginVertical={windowWidth > 480 ? 5 : 3}>
							<Box flex={1}>
								<Text
									fontWeight="$bold"
									fontFamily="$fontHeading"
									color={'$primary400'}
									fontSize={'$fontSize16'}
								>
									{capabilityName}
								</Text>

								<Text
									fontFamily="$fontHeading"
									color={'$textDark1000'}
									fontSize={'$fontSize14'}
									fontWeight="$bold"
								>
									{relatedHidi?.problem}
								</Text>
							</Box>
						</HStack>
						{relatedHidi?.whereitwasdone && (
							<>
								<DataDisplay title={'While working as:'} />

								<Text
									fontFamily="$fontHeading"
									color={'$textDark1000'}
									fontSize={'$fontSize14'}
									fontWeight="$bold"
								>
									{textOutside}{' '}
									<Text color={'$fontGray'}>@</Text>{' '}
									<Text
										fontFamily="$fontHeading"
										color={'$textDark1000'}
										fontSize={'$fontSize14'}
									>
										{textInside}
									</Text>
								</Text>
							</>
						)}

						{skillsFound?.length > 0 && (
							<Box mt={10}>
								<Text
									fontFamily="$fontHeading"
									fontSize={windowWidth > 480 ? 12 : 11}
									color={'$fontGray'}
									fontWeight="500"
								>
									Skills used:
								</Text>
								<HStack gap={10} flexWrap={'wrap'} my={10}>
									{skillsFound?.map((skill) => {
										return (
											<Box
												borderWidth={1}
												px={6}
												borderRadius={10}
												borderColor={'$coolGray300'}
												display={
													skill.Skill?.Skill
														? 'flex'
														: 'none'
												}
												// bg="#D3D3D3"
											>
												<Text
													fontFamily="$fontHeading"
													color={'$coolGray500'}
												>
													{skill?.Skill?.Skill}
												</Text>
											</Box>
										)
									})}
								</HStack>
							</Box>
						)}

						{relatedHidi?.experience && (
							<DataDisplay
								text={relatedHidi?.experience}
								title={'The Process of How i Solved It:'}
							/>
						)}
						<Box>
							<Text
								color="$primary500"
								fontFamily="$fontHeading"
								fontWeight="bold"
							>
								Read More ...
							</Text>
						</Box>
						<Box
							flexDirection={windowWidth > 480 ? 'row' : 'column'}
							marginVertical={10}
						>
							<HStack
								alignItems="center"
								marginVertical={5}
								justifyContent="space-between"
							>
								<HStack>
									<Image
										alignSelf="center"
										height={10}
										width={10}
										marginHorizontal={5}
										source={require('../../../assets/Images/peerIcon.png')}
										alt="peers"
									/>
									<Text
										color="#A0A0A0"
										fontFamily="$fontHeading"
									>
										Peer Vouching{' '}
										{relatedHidi?.peerEmails ? 1 : 0}
									</Text>
								</HStack>
								<HStack>
									<Image
										alignSelf="center"
										height={10}
										marginHorizontal={5}
										width={10}
										source={require('../../../assets/Images/questionIcon.png')}
										alt="questions"
									/>
									<Text
										color="#A0A0A0"
										fontFamily="$fontHeading"
									>
										Questions{' '}
										{
											relatedHidi?.ConvoOnHidi?.items
												?.length
										}
									</Text>
								</HStack>
								<HStack>
									<Image
										alignSelf="center"
										height={10}
										width={10}
										marginHorizontal={5}
										source={require('../../../assets/Images/linkiconprofile.png')}
										alt="links"
									/>
									<Text
										color="#A0A0A0"
										fontFamily="$fontHeading"
									>
										Links {relatedHidi?.projectLink ? 1 : 0}
									</Text>
								</HStack>
							</HStack>
						</Box>
					</Pressable>
				)}
			</>
		)
	}

	const isVerified = skillInfo?.JoinedCapabilities?.items.some(
		(item) =>
			item?.Capabilities?.ConnectedHidis?.items[0]?.Capability
				?.CapabilityConfig?.name
	)

	const filterItems = (data) => {
		return data?.filter(
			(item) =>
				item?.Capabilities?.ConnectedHidis?.items[0]?.Capability
					?.CapabilityConfig?.name
		)
	}

	const filteredItems = filterItems(skillInfo?.JoinedCapabilities?.items)
	const filteredItemCount = filteredItems?.length

	const updateCandidateSkill = () => {
		const userInput = {
			UserId: userId,
			id: skillInfo?.id,
			YearsOfExperience: skillYears === '' ? 0 : skillYears,
		}

		if (skillInfo?.YearsOfExperience !== skillYears) {
			const result = updateSkills(userInput)
		}
	}

	const RenderStatBoxes = ({ num, text, edit, editable }) => {
		return (
			<VStack
				alignItems="center"
				justifyContent="center"
				borderWidth={1}
				flex={windowWidth > 480 && 1}
				w={windowWidth < 480 && '100%'}
				height={150}
				gap={10}
				borderRadius={10}
				borderColor={'$borderDark200'}
				shadowColor="black"
				shadowRadius={7}
				shadowOpacity={0.15}
			>
				{editable &&
					(!edit ? (
						<Pressable
							onPress={() => {
								setShowEditYears(true)
							}}
							position="absolute"
							top={10}
							right={10}
						>
							<Image
								source={require('../../../assets/Images/edit.png')}
								h={15}
								w={15}
								alt="edit icon"
							/>
						</Pressable>
					) : (
						<Box position="absolute" top={10} right={10}>
							<ButtonComponent
								text={'Done'}
								height={20}
								width={30}
								customFontSize={12}
								onPress={() => {
									updateCandidateSkill()
									setShowEditYears(false)
								}}
							/>
						</Box>
					))}

				{edit ? (
					<Box>
						<Input w={80} p={2} h={80}>
							<InputField
								type="text"
								fontFamily="$fontContent"
								color="$primary950"
								fontWeight="500"
								fontSize={40}
								lineHeight={80}
								onChangeText={(text) =>
									setSkillsYears(
										text.replace(/[^0-9]/g, '').slice(0, 2)
									)
								}
								value={skillYears}
								autoFocus={true}
							/>
						</Input>
					</Box>
				) : (
					<Box>
						{num === 0 || num ? (
							<Text
								fontSize={80}
								lineHeight={80}
								color={
									isVerified ? '$green500' : '$secondary500'
								}
								fontFamily="$fontHeading"
							>
								{num}
							</Text>
						) : isVerified ? (
							<Icon
								as={CheckIcon}
								height={80}
								width={100}
								color={'$green500'}
							/>
						) : (
							<Icon
								as={CloseIcon}
								height={80}
								width={100}
								color={'$secondary500'}
							/>
						)}
					</Box>
				)}
				<Box>
					<Text
						fontFamily="$fontHeading"
						color={isVerified ? '$green500' : '$secondary500'}
						fontSize={14}
						fontWeight="600"
					>
						{text}
					</Text>
				</Box>
			</VStack>
		)
	}

	const renderLockedCapabilities = ({ item }) => {
		return (
			<Box w={'31%'} m={10} key={item?.id}>
				<CapabilityTemplate
					capabilityText={
						item?.name ? item?.name : item.CapabilityConfig?.name
					}
					text={item?.name ? 'Claim' : 'Unlock'}
					textColor={item?.name ? '#E96025' : null}
					borderColor={item?.name ? '$secondary500' : '#4DAE19'}
					bgColor={item?.name ? '$secondary100' : '#F2FDF1'}
					claimed={item?.name ? false : true}
					onPress={() => {
						if (item?.name) {
							claimUserCapability(item?.id)
						} else {
							setSelectedSkills([])
							navigation.push('CapCreateHidi', {
								capabilityId: item?.id,
								capabilityName: item?.CapabilityConfig?.name,
								capability: item,
							})
						}
					}}
					key={item?.id}
					loading={item?.name ? item?.id === claimLoading : false}
				/>
			</Box>
		)
	}

	return (
		<Box px={20}>
			<Divider h={0.5} />
			{skillInfo ? (
				<Box flex={1}>
					<HStack my={10} gap={10} flexWrap="wrap">
						<RenderStatBoxes
							text={isVerified ? 'Verified' : 'Not Verified'}
						/>
						<RenderStatBoxes
							num={filteredItemCount ? filteredItemCount : 0}
							text={'Evidences Shared'}
						/>
						<RenderStatBoxes
							num={skillYears ? skillYears : 0}
							text={'Years of Experience'}
							editable={
								userId === skillInfo.UserId ? true : false
							}
							edit={showEditYears}
						/>
					</HStack>

					<Box my={10}>
						<Text
							fontFamily="$fontHeading"
							fontWeight="600"
							underline
							fontSize={14}
						>
							Evidences Shared:
						</Text>
						{isVerified ? (
							<FlatList
								data={skillInfo?.JoinedCapabilities?.items}
								renderItem={renderHidis}
								keyExtractor={(item) => item?.id?.toString()}
							/>
						) : (
							<Box
								h={200}
								w={'100%'}
								alignItems="center"
								justifyContent="center"
							>
								<Text
									textAlign="center"
									fontFamily="$fontHeading"
									fontSize={12}
								>
									No Evidence Shared Yet
								</Text>
							</Box>
						)}
					</Box>

					{typeOfUser === 'candidates' && screen !== 'public' && (
						<Box my={10} gap={20}>
							<Box>
								<Text
									fontFamily="$fontHeading"
									fontWeight="600"
									fontSize={14}
									underline
								>
									More capabilities for you:
								</Text>
								<Text fontFamily="$fontHeading" fontSize={12}>
									Claim and share more experiences to verify
									your skill and get more related
									opportunities!
								</Text>
							</Box>
							<Box
								alignItems="center"
								justifyContent="center"
								flex={1}
							>
								<FlatList
									data={combinedCapabilities}
									renderItem={renderLockedCapabilities}
									keyExtractor={(item) =>
										item?.id?.toString()
									}
									numColumns={3}
								/>
							</Box>
						</Box>
					)}
				</Box>
			) : (
				<Box
					h={300}
					w={'100%'}
					alignItems="center"
					justifyContent="center"
				>
					<SpinnerComponent smallText={'Loading...'} />
				</Box>
			)}
		</Box>
	)
}

export default SkillInfoModalBody
