const fetchCurrenciesInfo = async (apiUrl) => {
	try {
		const response = await fetch(apiUrl)
		const data = await response.json()
		const countriesInfo = data.map((country) => {
			const countryName = country.name.common
			const currencies = country.currencies
				? Object.values(country.currencies)
				: [{ code: 'N/A' }]
			const name = currencies
				.map((currency) => currency.name || 'N/A')
				.join(', ')

			const currencySymbol = currencies
				.map((currency) => currency.symbol || 'N/A')
				.join(', ')

			const flag = country.flags ? Object.values(country.flags) : 'N/A'

			return { countryName, name, currencySymbol, flag }
		})
		const sortedCountriesInfo = countriesInfo.sort((a, b) =>
			a.countryName.localeCompare(b.countryName)
		)

		return sortedCountriesInfo
	} catch (error) {
		console.error('Error fetching countries information:', error)
		throw error
	}
}

export default fetchCurrenciesInfo
