import {
	Box,
	Text,
	Pressable,
	Image,
	HStack,
	Badge,
	BadgeText,
} from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'
import React, { useEffect, useState } from 'react'
import SpinnerComponent from '../../newComponents/SpinnerComponent'
import AiBadge from '../../newComponents/AiBadge'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const CapabilityTemplate = ({
	text,
	onPress,
	capabilityText,
	loading,
	borderColor,
	bgColor,
	peersCount,
	textColor,
	claimed,
	unlocked,
	showTag,
	isGptGenerated,
	skillsList,
}) => {
	const [randomNum, setRandomNum] = useState(null)
	const [randomCapCount, setRandomCapCount] = useState(null)

	const generateRandomNumber = () => {
		const newRandomNum = Math.floor(Math.random() * 21) + 20
		setRandomNum(newRandomNum)
	}

	const generateRandomCapCount = () => {
		const newRandomNum = Math.floor(Math.random() * 10) + 10
		setRandomCapCount(newRandomNum)
	}

	const capabilitiesClaimCount = () => {
		if (peersCount < 10 || peersCount == null || peersCount == undefined) {
			return randomCapCount
		} else {
			return peersCount + 10
		}
	}
	useEffect(() => {
		generateRandomNumber()
		generateRandomCapCount()
	}, [])

	return (
		<Box
			w={'100%'}
			h={'100%'}
			flex={1}
			borderWidth={1}
			borderRadius={15}
			borderColor="$borderDark0"
			shadowColor="#FDF0EB"
			shadowOffset={{ width: 0, height: 2 }}
			shadowOpacity={0.1}
			shadowRadius={12}
			elevation={25}
			justifyContent="space-between"
			marginBottom={10}
			backgroundColor="#fff"
		>
			<HStack
				p={10}
				alignItems="center"
				borderTopLeftRadius={15}
				borderTopRightRadius={15}
				gap={10}
				paddingHorizontal={'5%'}
				bg={'#F2F2F2'}
				borderBottomWidth={1}
				borderColor="$borderDark0"
			>
				<Text
					marginVertical={5}
					fontSize={
						windowWidth > 480 ? '$fontContent' : '$fontSizeMobile'
					}
					fontFamily="$fontHeading"
					fontWeight="600"
				>
					{capabilityText}
				</Text>
			</HStack>

			<Box
				alignItems="center"
				justifyContent="center"
				paddingVertical={10}
			>
				{skillsList && skillsList.length > 0 && (
					<HStack
						gap={10}
						alignItems="flex-start"
						w={'90%'}
						my={10}
						flexWrap="wrap"
					>
						{skillsList?.map((skill, index) => {
							return (
								<>
									{skill?.Skill?.Skill && (
										<Badge
											size="md"
											variant="solid"
											borderRadius={6}
											action="success"
											borderWidth={1}
										>
											<BadgeText
												fontFamily="$fontHeading"
												fontSize={12}
											>
												{skill?.Skill?.Skill}
											</BadgeText>
										</Badge>
									)}
								</>
							)
						})}
					</HStack>
				)}
				<HStack
					alignItems="center"
					paddingHorizontal={15}
					bottom={0}
					w={'$full'}
					gap={10}
				>
					<HStack gap={3} alignItems="center">
						{claimed || unlocked ? (
							<Image
								source={require('../../assets/Images/unlocked.png')}
								h={15}
								w={15}
							/>
						) : (
							<Image
								source={require('../../assets/Images/cancel-new.png')}
								h={15}
								w={15}
							/>
						)}
						<Text fontFamily="$fontHeading">Claimed</Text>
					</HStack>
					<HStack gap={3} alignItems="center">
						{unlocked ? (
							<Image
								source={require('../../assets/Images/unlocked.png')}
								h={15}
								w={15}
							/>
						) : (
							<Image
								source={require('../../assets/Images/cancel-new.png')}
								h={15}
								w={15}
							/>
						)}
						<Text fontFamily="$fontHeading">Experience Shared</Text>
					</HStack>
				</HStack>

				{showTag &&
					isGptGenerated !== null &&
					isGptGenerated !== undefined && (
						<AiBadge
							isGptGenerated={isGptGenerated}
							paddingHorizontal={15}
						/>
					)}
				<Pressable
					borderRadius={10}
					w={'90%'}
					paddingHorizontal={10}
					paddingVertical={12}
					marginVertical={10}
					onPress={onPress}
					bgColor={bgColor}
					borderWidth={2}
					borderColor={textColor ? textColor : '#53B54E'}
				>
					{loading ? (
						<SpinnerComponent button={true} />
					) : (
						<HStack
							alignItems="center"
							gap={3}
							justifyContent="center"
						>
							<Text
								textAlign="center"
								fontFamily="$fontHeading"
								fontWeight="bold"
								fontSize={
									windowWidth > 480
										? '$fontContent'
										: '$fontSizeMobile'
								}
								color={textColor ? textColor : '#53B54E'}
							>
								{text}
							</Text>
						</HStack>
					)}
				</Pressable>
			</Box>
		</Box>
	)
}

export default CapabilityTemplate
