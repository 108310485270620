import { Dimensions } from 'react-native'
import {
	Box,
	Divider,
	FlatList,
	HStack,
	Pressable,
	ScrollView,
	Text,
} from '@gluestack-ui/themed'
import Editor from 'react-medium-editor'
import React, { useState } from 'react' // Import useState
import ButtonComponent from '../../../newComponents/ButtonComponent'
import SpinnerComponent from '../../../newComponents/SpinnerComponent'
import { SendEmailtoCandidates } from '../../../graphql/queries'
import RecommendedContainer from './RecommendedContainer'
import { showToast } from '../../../components/ErrorToast'
import { API, graphqlOperation } from 'aws-amplify'
import NewProfile from '../NewProfile'
import {
	CheckboxGroup,
	Checkbox,
	CheckboxIndicator,
	CheckboxIcon,
	CheckIcon,
	CheckboxLabel,
} from '@gluestack-ui/themed'
import ModalComponent from '../../../newComponents/ModalComponent'
import LabelWithInputComponent from '../../../newComponents/LabelWithInputComponent'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

function CandidateViewComponent({
	loading,
	headingText,
	data,
	companyname,
	rolename,
	navigation,
	onPressBtn,
	listEmptyText,
	btnText,
	fetchMoreData,
	PreferenceId,
	JobPostData,
	prefferedSalary,
	minExperience,
	maxExpeirence,
	companyQrewId, 
	SelectedTab 
}) {
	const sortedData = [...data].sort((a, b) => {
		const dateA = new Date(a.createdAt)
		const dateB = new Date(b.createdAt)
		return dateB - dateA
	})
	
	const [selectedItemIndex, setSelectedItemIndex] = useState(0) // Add state to store the selected item index
	const [EmailCandidates, setEmailCandidates] = useState(false)
	const [emailloading, setEmailLoading] = useState(false)
	const [values, setValues] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [bodytext, setBodyText] = useState('')
	const [subject, setSubject] = useState('')
	const [selectedTab, setselectedTab] = useState('Shortlisted')
	const renderItem = ({ item, index }) => {
	
		const ClaimedCapabilities = item.candidateprofile?.Capabilities?.items
		const requiredCapabilities = JobPostData?.CapabilitiesRequired?.items

			
		const matchedClaimedCapabilities = ClaimedCapabilities?.filter(
			(data) => {
				return requiredCapabilities?.some(
					(cap) =>
						data.capabilitiesCapabilityConfigId ==
						cap.CapabilityConfig.id
				)
			}
		)
		const ExperienceSharedOnMatching = matchedClaimedCapabilities?.filter(
			(data) => {
				return data.ConnectedHidis?.items?.length > 0
			}
		)

		return (
			<Box>
				{EmailCandidates && (
					<Checkbox
						key={item?.candidateprofile?.Email}
						value={item?.candidateprofile?.Email}
						w={'fit-content'}
						gap={5}
						size={windowWidth > 480 ? 'md' : 'sm'}
					>
						<CheckboxIndicator>
							<CheckboxIcon
								as={CheckIcon}
								size={windowWidth > 480 ? 'md' : 'sm'}
								key={item?.id}
							/>
						</CheckboxIndicator>
						<CheckboxLabel
							key={item.candidateprofile?.Email}
							fontFamily="$fontContent"
							fontSize={
								windowWidth > 480
									? '$fontContent'
									: '$fontContentMobile'
							}
						>
							{item?.candidateprofile?.name}
						</CheckboxLabel>
					</Checkbox>
				)}

				<RecommendedContainer
					headingText={headingText}
					companyname={companyname}
					props={item}
					rolename={rolename}
					navigation={navigation}
					text={btnText}
					JobPostData={JobPostData}
					companyQrewId ={companyQrewId }
					onPressBtn={() => {
						if (onPressBtn) {
							onPressBtn(item.id)
						}
					}}
					selectedItemIndex={selectedItemIndex}
					setSelectedIndex={setSelectedItemIndex}
					index={index}
					JobApplicantId={sortedData[selectedItemIndex]?.id}
					matchingClaimedCapabilites={
						matchedClaimedCapabilities?.length
					}
					ExperienceSharedOnMatching={
						ExperienceSharedOnMatching?.length
					}
					SelectedTab ={SelectedTab}
					requiredCapabilities={requiredCapabilities?.length}
				/>
				
			</Box>
		)
	}
	const CallEmailLambda = async () => {
		setEmailLoading(true)

		try {
			await API.graphql({
				query: SendEmailtoCandidates,
				variables: {
					EmailBody: bodytext,
					Emails: values,
					EmailSubject: subject,
					TypeOfMailenum : 'NormalMail'
				},
			}).then((response) => {
				setEmailCandidates(false)

				setValues([])
				setEmailLoading(false)
				console.log('succeeded')
			})
			return showToast({
				message: 'Emails Sent Successfully',
				type: 'success',
			})
		} catch (error) {
			setEmailCandidates(false)

			setValues([])
			setEmailLoading(false)

			return console.log(error)
		}
	}
	const listEmpty = () => {
		return (
			<Box marginVertical={20}>
				<Text
					fontFamily="$fontHeading"
					fontSize={
						windowWidth > 480
							? '$fontHeading'
							: '$fontHeadingContent'
					}
					fontWeight="600"
					textAlign="center"
				>
					{listEmptyText}
				</Text>
			</Box>
		)
	}

	const renderModalBody = () => {
		return (
			<Box>
				<LabelWithInputComponent
					placeholder={'Enter the Subject of mail'}
					value={subject}
					setValueChange={(text) => setSubject(text)}
				/>
				<Box borderWidth={1} borderColor="#E5E5E5" borderRadius={6}>
					<Editor
						text={bodytext}
						style={{
							lineheight: '1.2rem',
							backgroundColor: '#fff',
							color: '#424242',
							fontWeight: '400',
							placeholderTextColor: '#AAA6B9',
							borderRadius: 6,
							fontSize: windowWidth > 480 ? 12 : 11,
							fontStyle: 'normal',
							alignItems: 'center',
							padding: windowWidth > 480 ? 10 : 5,
							justifyContent: 'left',
							fontFamily: 'Open Sans',
							minHeight: windowHeight * 0.2,
						}}
						onChange={(text) => setBodyText(text)}
						options={{
							toolbar: {
								buttons: [
									'bold',
									'italic',
									'underline',
									'orderedlist',
									'unorderedlist',
									'qoute',
									'ancor',
									'h3',
									'h4',
									'h5',
									'h6',
								],
							},
							placeholder: {
								text: 'Describe! (Select to customize text)',
							},
						}}
					/>
				</Box>
				<Box alignSelf="center" padding={5} marginTop={10}>
					<ButtonComponent
						text={'Send Mail'}
						onPress={() => {
							setShowModal(false)
							CallEmailLambda()
						}}
						width={windowWidth > 480 ? 200 : 100}
					/>
				</Box>
			</Box>
		)
	}

	return (
		<HStack>
			<Box
				bg="$primary0"
				p={windowWidth > 480 ? 20 : 5}
				// borderRadius={6}
				borderWidth={1}
				borderColor="$borderDark0"
				w={(sortedData?.length === 0 || windowWidth < 480) && '$full'}
				minWidth={windowWidth > 480 ? 300 : '$full'}
				maxHeight={windowHeight}
				// flexDirection=''
			>
				<ModalComponent
					isOpen={showModal}
					onClose={() => {
						setShowModal(false)
					}}
					heading={'Email Candidates'}
					renderBody={renderModalBody}
				/>
				<Text
					fontFamily="$fontHeading"
					fontSize={windowWidth > 480 ? 11 : 9}
					fontWeight="600"
					color="$fontGray100"
				>
					{headingText}
				</Text>
				{EmailCandidates ? (
					<ButtonComponent
						loading={emailloading}
						text={'Send Mail'}
						onPress={() => {
							setEmailCandidates(true)
							setShowModal(true)
						}}
						width={windowWidth > 480 ? 200 : 100}
					/>
				) : (
					<ButtonComponent
						loading={emailloading}
						text={'Mail'}
						onPress={() => {
							setEmailCandidates(true)
						}}
						width={windowWidth > 480 ? 100 : 50}
					/>
				)}

				<Divider
					marginVertical={windowWidth > 480 ? 10 : 5}
					color={'$borderDark0'}
					h={0.5}
				/>
				{loading ? (
					<Box marginVertical={windowWidth > 480 ? 20 : 10}>
						<SpinnerComponent smallText={'Loading...'} />
					</Box>
				) : (
					<ScrollView
						maxHeight={windowHeight - 70}
						showsHorizontalScrollIndicator={false}
					>
						<CheckboxGroup
							value={values}
							onChange={(keys) => {
								setValues(keys)
							}}
						>
							<FlatList
								data={sortedData}
								renderItem={renderItem}
								keyExtractor={(item) => item.id}
								ListEmptyComponent={listEmpty}
								onEndReached={() =>
									sortedData?.length > 24
										? fetchMoreData()
										: null
								}
								onEndReachedThreshold={0.5}
							/>
						</CheckboxGroup>
					</ScrollView>
				)}
			</Box>
			<Box
				flex={sortedData.length > 0 && 1}
				borderWidth={sortedData.length > 0 && 1}
				borderColor="$borderDark0"
				paddingHorizontal={windowWidth > 480 ? 20 : 5}
				bg="white"
			>
				{!loading && windowWidth > 480 && sortedData?.length > 0 ? (
					<Box>
						<NewProfile
							UserId={
								sortedData[selectedItemIndex]?.candidateprofile
									?.UserId
							}
							PreferenceId={
								sortedData[selectedItemIndex]?.preferencesId
									? sortedData[selectedItemIndex]
											?.preferencesId
									: sortedData[selectedItemIndex]
											.CompanyPreferenceId
							}
							companyQrewId ={companyQrewId }
							prefferedSalary={prefferedSalary}
							minExperience={minExperience}
							maxExpeirence={maxExpeirence}
							JobPostData={JobPostData}
							RecommendationId={sortedData[selectedItemIndex]?.id}
							acceptancestatus={
								sortedData[selectedItemIndex]?.acceptanceStatus
							}
							ReasonofRecommendation={
								sortedData[selectedItemIndex]
									?.ReasonofRecommendation
							}
							navigation={navigation}
							JobApplicantId={sortedData[selectedItemIndex]?.id}
							headingText={headingText}
							profileAnalysisProp =  {sortedData[1]?.__typename == 'recommendedprofile' ? sortedData[selectedItemIndex]?.JobApplication.CandidateProfileAnalysis : sortedData[selectedItemIndex]?.CandidateProfileAnalysis}
							SelectedTab ={SelectedTab}
							// profileAnalysis =  {sortedData[selectedItemIndex]?.CandidateProfileAnalysis} 
						/>
					</Box>
				) : null}
			</Box>
		</HStack>
	)
}

export default CandidateViewComponent
