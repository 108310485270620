import { Box, FlatList, HStack, Image, Text } from '@gluestack-ui/themed'
import React from 'react'
import TopBoxJD from './TopBoxJD'
import ProgressBar from '../../../newComponents/ProgressBar'
import ButtonComponent from '../../../newComponents/ButtonComponent'
import ImproveApplication from './ImproveApplication'
import { Dimensions } from 'react-native'
import AddProfilePic from './AddProfilePic'
import AddVideo from './AddVideo'
import useUserStore from '../../../Userstore/UserStore'
import QuestionsAsked from './QuestionsAsked'
const windowWidth = Dimensions.get('window').width

const AppliedScreen = ({
	jobPost,
	aboutCurrentJob,
	applicationProgress,
	setSwitchAppliedView,
	navigation,
	macthedHidis,
	companyName,
}) => {
	const profile = useUserStore((state) => state.profile)
	const currentJob =
		aboutCurrentJob?.data
			?.list_JobApplications_by_ProfileId_filter_byJobPostId?.items[0]

	let logoUrl =
		'https://logo.clearbit.com/' +
		jobPost?.CompanyPreference?.companyprofile?.name?.split(' ')[0].trim() +
		'.com'

	const formatDate = (dateString) => {
		if (!dateString) return 'No date provided'

		const date = new Date(dateString)
		return new Intl.DateTimeFormat('en-US', {
			month: 'short',
			day: 'numeric',
			year: 'numeric',
		}).format(date)
	}

	const ApplicationStatusData = [
		{
			id: 1,
			title: 'Recieved',
			time: formatDate(currentJob?.createdAt),
			imageSource: require('../../../assets/Images/applicationRecieved.png'),
		},
		{
			id: 2,
			title: 'Reviewed',
			time: null,
			imageSource: null,
		},
	].filter((item) => item.time !== null)

	const renderStatus = ({ item }) => {
		return (
			<HStack px={6} py={10} gap={10} my={10}>
				<Box
					bg="white"
					h={25}
					alignItems="center"
					justifyContent="center"
				>
					<Image source={item.imageSource} h={20} w={20} />
				</Box>
				<Box>
					<Text
						fontFamily="$fontHeading"
						fontSize={14}
						color="$primary950"
						fontWeight="600"
					>
						{item?.title}
					</Text>
					<Text
						fontFamily="$fontHeading"
						fontSize={14}
						color="#4A789C"
					>
						{item?.time}
					</Text>
				</Box>
			</HStack>
		)
	}

	return (
		<Box w={'100%'}>
			<TopBoxJD
				jobTitle={jobPost?.CompanyPreference?.rolename}
				onPressTtitle={() => {
					if (jobPost?.jobPostLink) {
						window.open(jobPost?.jobPostLink)
					}
				}}
				companyName={jobPost?.CompanyPreference?.companyprofile?.name}
				jobType={jobPost?.CompanyPreference?.JobType}
				logoUrl={logoUrl}
				modeOfWork={jobPost?.CompanyPreference?.modeofwork}
			/>
			<Box gap={10} my={15}>
				<HStack justifyContent="space-between">
					<Text
						fontFamily="$fontHeading"
						fontSize={14}
						color="$primary950"
						fontWeight="600"
					>
						Application Completion
					</Text>
					<Text fontFamily="$fontHeading" fontSize={14}>
						{applicationProgress}%
					</Text>
				</HStack>
				<ProgressBar
					progress={applicationProgress}
					bgColor={'#E16449'}
					bgPlaceHolder={'#EEEEEE'}
					animationType={'timing'}
				/>
				<Box>
					<Text
						color="#4A789C"
						fontFamily="$fontHeading"
						fontSize={windowWidth > 480 ? 13 : 11}
						lineHeight={windowWidth > 480 ? 15 : 13}
					>
						Complete your Application to improve your chances
					</Text>
				</Box>
			</Box>
			<Box gap={15} my={15}>
				<Text
					fontFamily="$fontHeading"
					color="$primary950"
					fontSize={16}
					fontWeight="600"
				>
					What's next
				</Text>
				<ImproveApplication
					applicationProgress={applicationProgress}
					onPressBtn={() => {
						setSwitchAppliedView(true)
					}}
				/>
				<Box
					display={
						profile.imagekey && profile.IntroVideo ? 'none' : 'flex'
					}
					flexDirection={windowWidth > 480 ? 'row' : 'column'}
					gap={15}
					my={15}
				>
					{profile && !profile?.imagekey && (
						<AddProfilePic profile={profile} />
					)}
					{profile && !profile?.IntroVideo && (
						<AddVideo navigation={navigation} />
					)}
				</Box>
			</Box>
			<QuestionsAsked
				macthedHidis={macthedHidis}
				companyName={companyName}
			/>
			{profile && (
				<Box my={15}>
					<Text
						fontFamily="$fontHeading"
						color="$primary950"
						fontSize={16}
						fontWeight="600"
					>
						Application Status
					</Text>
					<Box position="relative">
						<Box
							borderWidth={1}
							w={1}
							borderColor="#CFDEE8"
							position="absolute"
							left={15}
							height={'100%'}
						/>
						<FlatList
							data={ApplicationStatusData}
							renderItem={renderStatus}
							keyExtractor={(item) => item.id.toString()}
						/>
					</Box>
				</Box>
			)}
		</Box>
	)
}

export default AppliedScreen
