import React, { useState } from 'react'
import { Dimensions } from 'react-native'
import {
	Box,
	Text,
	Image,
	HStack,
	Pressable,
	Icon,
	InfoIcon,
	CheckIcon,
} from '@gluestack-ui/themed'

import { useNavigation } from '@react-navigation/native'
import useUserStore from '../../Userstore/UserStore'
import ButtonComponent from '../../newComponents/ButtonComponent'
import CapabilityTemplate from './CapabilityTemplate'
import ShareExperienceModal from './ShareExperience/ShareExperienceModal'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const ClaimedCapability = ({
	data,
	onClose,
	navigation,
	onPress,
	getCapability,
	jobDomain,
	closeTooltip,
}) => {
	const profile = useUserStore((state) => state.profile)
	const [modalOpen, setModalOpen] = useState(false)
	const [tab, setTab] = useState('Outcomes')

	const points = data?.CapabilityConfig?.CapabilityPoints
		? data?.CapabilityConfig?.CapabilityPoints
		: 100

	return (
		<Box h={'100%'}>
			<ShareExperienceModal
				isOpen={modalOpen}
				onClose={() => {
					setModalOpen(false)
				}}
				capabilityName={data?.CapabilityConfig?.name}
				tab={tab}
				setTab={setTab}
				capability={data}
				getCapability={() => {
					getCapability()
				}}
				jobDomain={jobDomain}
				navigation={navigation}
				closeTooltip={closeTooltip}
			/>

			<CapabilityTemplate
				capabilityText={data?.CapabilityConfig?.name}
				onPress={() => {
					setModalOpen(true)
					setTab('Problem')
				}}
				text={`Add Expereince`}
				borderColor={'#4DAE19'}
				bgColor={'#F2FDF1'}
				peersCount={
					data?.CapabilityConfig?.capabilitiesClaimed?.items?.length
				}
				skillsList={data?.JoinedSkills?.items}
				claimed={true}
			/>
		</Box>
	)
}

export default ClaimedCapability
