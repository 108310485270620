import { Dimensions } from 'react-native'
import React from 'react'
import { Box, Divider, FlatList, Text } from '@gluestack-ui/themed'
import { API, graphqlOperation, Auth, Storage } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { listshortlistsByPreferencesByDate } from '../../graphql/queries'
import { deleteShortlist } from '../../graphql/mutations'
import { onDeleteShortlist } from '../../graphql/subscriptions'
import SpinnerComponent from '../../newComponents/SpinnerComponent'
import RecommendedContainer from './components/RecommendedContainer'
import CandidateViewComponent from './components/CandidateViewComponent'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

export default function ShortlistScreen({
	navigation,
	preferenceId,
	rolename,
	companyname,
	JobPostData,
	shorlistedData
	
}) {
	
	const [shortlistedProfiles, setShortlistedproiles] = useState('')
	const [loading, setLoading] = useState(true)

	const initSubscriptions = async () => {
		let _subscriptions = []
		const subscription = API.graphql(
			graphqlOperation(onDeleteShortlist)
		).subscribe({
			next: ({ provider, value }) => {
				getshortlistedprofiles()

				// setIsParticipant(true);
			},
			error: (error) => {
				// setConnectionStatus(false);
				console.warn(error.error)
			},
		})

		return
	}

	async function removecandidate(props) {
		setLoading(true)
		const CreateUserInput = {
			// Timelinestring: Data1,
			id: props,
		}
		await API.graphql({
			query: deleteShortlist,
			// authMode: 'API_KEY',
			variables: { input: CreateUserInput },
		}).then((res) => {
			setLoading(false)
			getshortlistedprofiles()
		})
	}

	async function getshortlistedprofiles() {
		setLoading(true)
		await API.graphql({
			query: listshortlistsByPreferencesByDate,
			// authMode: 'API_KEY',
			variables: {
				preferencesId: preferenceId,
				limit: 10,
				sortDirection: 'DESC',
			},
		})
			.then((res) => {
				console.log(res, "shortlisted")
				setShortlistedproiles(
					res.data.listshortlistsByPreferencesByDate.items
				)
				setLoading(false)
				// setProfilelist(res.data.listNewprofiles.items)
				// setloading(false)
			})
			.catch((err) => {
				console.log(err)
				setLoading(false)
			})
	}

	useEffect(() => {
		getshortlistedprofiles()
	}, [preferenceId])
	useEffect(() => {
		initSubscriptions()
	}, [])

	return (
		<Box>
			<CandidateViewComponent
				loading={loading}
				headingText={'Shortlisted'}
				companyname={companyname}
				navigation={navigation}
				data={shortlistedProfiles}
				rolename={rolename}
				onPressBtn={removecandidate}
				preferenceId = {preferenceId}
				btnText={'Remove'}
				listEmptyText={'No shortlisted candidates yet!'}
				JobPostData={JobPostData}
			/>
		</Box>
	)
}
