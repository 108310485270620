import React from 'react'
import { Dimensions } from 'react-native'
import {
	Box,
	HStack,
	Icon,
	Pressable,
	ScrollView,
	SettingsIcon,
	Text,
} from '@gluestack-ui/themed'
import { useState } from 'react'
const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

const JourneyTabs = ({
	labels,
	stateName,
	tabState,
	setTabState,
	screen,
	viewSettings,
	navigation,
}) => {
	return (
		<HStack
			alignItems="center"
			borderBottomWidth={2}
			borderColor="$borderDark0"
			justifyContent="space-between"
			paddingHorizontal={windowWidth > 480 ? 5 : 0}
			w={'$full'}
		>
			<ScrollView
				horizontal={true}
				flexDirection="row"
				showsHorizontalScrollIndicator={false}
				w={'$full'}
				flex={1}
				alignItems="center"
				gap={5}
			>
				{labels.map((label, i) => {
					return label ? (
						<Pressable
							key={i}
							onPress={() => {
								setTabState(stateName[i])
							}}
						>
							<Text
								color={
									tabState === stateName[i]
										? '$primary500'
										: '#737373'
								}
								fontFamily="$fontHeading"
								fontSize={
									windowWidth > 480
										? '$fontHeading'
										: '$fontSize13'
								}
								fontWeight={
									tabState === stateName[i] ? 600 : 400
								}
								flex={1}
								w={'fit-content'}
								paddingHorizontal={windowWidth > 480 ? 20 : 5}
								paddingVertical={windowWidth > 480 ? 10 : 5}
								borderBottomWidth={
									tabState === stateName[i] && 3
								}
								borderColor="$primary500"
							>
								{label}
							</Text>
						</Pressable>
					) : null
				})}
			</ScrollView>
			{viewSettings ? (
				<Pressable
					onPress={() => {
						navigation.push('CandidatePreferences')
					}}
					w={30}
					alignItems="center"
					justifyContent="center"
					h={30}
					borderColor="$borderDark0"
					bg="white"
					// borderWidth={1}
				>
					<Icon
						as={SettingsIcon}
						size={windowWidth > 480 ? 'md' : 'md'}
					/>
				</Pressable>
			) : null}
		</HStack>
	)
}

export default JourneyTabs
