import { Box, HStack, Image, Pressable, Text } from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'
import React, { useState, useRef, useEffect } from 'react'
import ButtonComponent from '../ButtonComponent'
import { API, Storage } from 'aws-amplify'
import useUserStore from '../../Userstore/UserStore'
import SpinnerComponent from '../SpinnerComponent'
import { updateNewprofile } from '../../graphql/mutations'
import { showToast } from '../../components/ErrorToast'
import ModalComponent from '../ModalComponent'
import ProgressBar from '../ProgressBar'
import { VStack } from '@gluestack-ui/themed'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const VideoRecorderComponent = ({
	keyParam,
	actionOnUpload,
	screen,
	handleGoBack,
	handleSkip,
}) => {
	const [permission, setPermission] = useState(false)
	const mediaRecorder = useRef(null)
	const [mediaBlob, setMediaBlob] = useState(null)
	const liveVideoFeed = useRef(null)
	const [recordingStatus, setRecordingStatus] = useState('recording')
	const [stream, setStream] = useState(null)
	const [videoChunks, setVideoChunks] = useState([])
	const [recordedVideo, setRecordedVideo] = useState(null)
	const recordedVideoRef = useRef(null)
	const [uploadLoading, setUploadLoading] = useState(false)
	const [uploadProgress, setUploadProgress] = useState(0)

	const profileId = useUserStore((state) => state.profileId)
	const setProfile = useUserStore((state) => state.setUserProfile)

	const [timer, setTimer] = useState(0)
	const [timerId, setTimerId] = useState(null)

	const formatTime = (seconds) => {
		const mins = Math.floor(seconds / 60)
		const secs = seconds % 60
		return `${mins.toString().padStart(2, '0')}:${secs
			.toString()
			.padStart(2, '0')}`
	}

	const mimeType = 'video/webm'

	const getCameraPermission = async () => {
		setRecordedVideo(null)

		if (
			'MediaRecorder' in window &&
			navigator.mediaDevices &&
			navigator.mediaDevices.getUserMedia
		) {
			try {
				const videoConstraints = {
					audio: true,
					video: true,
					width: { exact: 640 },
					height: { exact: 360 },
					frameRate: { exact: 25 },
				}

				const stream = await navigator.mediaDevices.getUserMedia(
					videoConstraints
				)

				setPermission(true)

				const combinedStream = new MediaStream([
					...stream.getVideoTracks(),
					...stream.getAudioTracks(),
				])

				setStream(combinedStream)
				liveVideoFeed.current.srcObject = combinedStream
			} catch (err) {
				console.error('Error accessing camera:', err)
				alert(`Error accessing camera: ${err.message}`)
			}
		} else {
			console.error(
				'MediaRecorder API or getUserMedia not supported on this device.'
			)
			alert(
				'MediaRecorder API or getUserMedia not supported on this device.'
			)
		}
	}

	const startRecording = async () => {
		setRecordingStatus('inactive')

		const media = new MediaRecorder(stream, {
			mimeType,
			audioBitsPerSecond: 64000,
			videoBitsPerSecond: 350000,
		})
		mediaRecorder.current = media
		mediaRecorder.current.start()
		let localVideoChunks = []
		mediaRecorder.current.ondataavailable = (event) => {
			if (typeof event.data === 'undefined') return
			if (event.data.size === 0) return
			localVideoChunks.push(event.data)
		}
		setVideoChunks(localVideoChunks)

		setTimer(0)
		const id = setInterval(() => {
			setTimer((prevTimer) => prevTimer + 1)
		}, 1000)
		setTimerId(id)
	}

	const stopRecording = () => {
		setRecordingStatus('recording')
		mediaRecorder.current.stop()

		clearInterval(timerId)

		mediaRecorder.current.onstop = () => {
			const videoBlob = new Blob(videoChunks, { type: mimeType })
			setMediaBlob(videoBlob)
			const videoUrl = URL.createObjectURL(videoBlob)
			setRecordedVideo(videoUrl)
			setVideoChunks([])
		}
		if (timer < 60) {
			showToast({
				type: 'error',
				message: 'Minimum required time is 1 min',
			})
		}
	}

	const stopCamera = () => {
		if (stream) {
			const tracks = stream.getTracks()
			tracks.forEach((track) => track.stop())
			setStream(null)
			setRecordedVideo(null)
			setMediaBlob(null)
			setTimer(0)
			clearInterval(timerId)
			setPermission(false)
		}
	}

	const restartRecording = () => {
		if (recordedVideoRef.current) {
			recordedVideoRef.current.srcObject = stream
			setRecordedVideo(null)
			setMediaBlob(null)
			stopCamera()
			getCameraPermission()
		}
	}

	const updateProfile = () => {
		const userInput = {
			id: profileId,
			IntroVideo: true,
		}
		API.graphql({
			query: updateNewprofile,
			variables: {
				input: userInput,
			},
		})
			.then((res) => {
				setProfile(res?.data?.updateNewprofile)
			})
			.catch((err) => {})
	}

	const uploadToS3 = async () => {
		try {
			setUploadLoading(true)

			await Storage.put(keyParam, mediaBlob, {
				level: 'public',
				contentType: mimeType,
				progressCallback: (progress) => {
					const percentage = (progress.loaded / progress.total) * 100
					setUploadProgress(percentage)
				},
			})
			updateProfile()
			console.log('File uploaded successfully')

			showToast({
				type: 'success',
				message: 'Video uploaded successfully',
			})
			actionOnUpload && actionOnUpload()
			stopCamera()
			setUploadLoading(false)
		} catch (error) {
			console.error('Error uploading file:', error)
			setUploadLoading(false)
		}
	}

	return (
		<Box borderColor="$borderDark0" position="relative">
			<Box marginVertical={windowWidth > 480 ? 20 : 10}>
				<Box position="relative">
					{!recordedVideo && (
						<HStack
							alignItems="center"
							gap={10}
							position="absolute"
							bottom={windowWidth > 480 ? 20 : 5}
							w={'$full'}
							justifyContent="center"
							zIndex={1000}
						>
							<Box>
								{!permission ? (
									<Pressable
										onPress={getCameraPermission}
										disabled={
											recordingStatus === 'inactive'
										}
										bg="black"
										paddingHorizontal={
											windowWidth > 480 ? 5 : 3
										}
										borderWidth={2}
										borderColor="$primary500"
										borderRadius={6}
									>
										<Image
											source={require('../../assets/Images/camOff.png')}
											h={windowWidth > 480 ? 30 : 20}
											w={windowWidth > 480 ? 30 : 20}
											alt="camera off"
										/>
									</Pressable>
								) : (
									<Pressable
										onPress={stopCamera}
										disabled={
											recordingStatus === 'inactive'
										}
										bg="black"
										paddingHorizontal={
											windowWidth > 480 ? 5 : 3
										}
										borderWidth={2}
										borderColor="$primary500"
										borderRadius={6}
									>
										<Image
											source={require('../../assets/Images/camOn.png')}
											h={windowWidth > 480 ? 30 : 20}
											w={windowWidth > 480 ? 30 : 20}
											alt="camera on"
										/>
									</Pressable>
								)}
							</Box>
							<Box alignItems="center">
								{permission && (
									<Pressable
										onPress={
											recordingStatus === 'recording'
												? startRecording
												: stopRecording
										}
										disabled={!permission}
										alignItems="center"
										flexDirection="row"
										gap={windowWidth > 480 ? 5 : 2}
										p={windowWidth > 480 ? 5 : 3}
										bg={
											recordingStatus === 'recording'
												? '$blue400'
												: '$secondary500'
										}
										borderRadius={6}
										w={windowWidth > 480 ? 150 : 120}
										justifyContent="center"
										borderWidth={2}
										borderColor={
											recordingStatus === 'recording'
												? '$blue400'
												: '$secondary500'
										}
									>
										<Text
											fontWeight="600"
											fontFamily="$fontHeading"
											color="white"
											fontSize={
												windowWidth > 480 ? 13 : 11
											}
										>
											{recordingStatus === 'recording'
												? 'Start Recording'
												: 'Stop'}
										</Text>
										<Image
											source={
												recordingStatus === 'recording'
													? require('../../assets/Images/startRecord.png')
													: require('../../assets/Images/stopRecord.png')
											}
											h={20}
											w={20}
											alt="start recording"
										/>
									</Pressable>
								)}
							</Box>
							{permission && (
								<Box>
									<Text
										color="$primary0"
										fontFamily="$fontHeading"
										fontSize={windowWidth > 480 ? 13 : 11}
										fontWeight="600"
									>
										{formatTime(timer)}
									</Text>
								</Box>
							)}
						</HStack>
					)}

					{permission ? (
						<Box
							flexDirection={windowWidth > 480 ? 'row' : 'column'}
							bg="black"
							p={windowWidth > 480 ? 10 : 0}
							alignItems="center"
							gap={10}
						>
							{recordedVideo ? (
								<Box
									flex={windowWidth > 480 ? 1 : 1}
									borderWidth={2}
									borderColor="white"
									p={windowWidth > 480 ? 10 : 0}
									alignItems={
										windowWidth > 480 ? null : 'center'
									}
								>
									<Box position="relative">
										<video
											ref={recordedVideoRef}
											src={recordedVideo}
											loop
											controls
											controlsList="nodownload"
											style={{
												width:
													windowWidth > 480
														? '100%'
														: windowWidth * 0.9,
												height:
													windowWidth > 480
														? screen ===
														  'JobDetails'
															? 400
															: 600
														: '100%',
											}}
										/>
									</Box>
								</Box>
							) : (
								<Box
									flex={1}
									borderWidth={2}
									borderColor="white"
									p={windowWidth > 480 ? 10 : 0}
								>
									<video
										ref={liveVideoFeed}
										autoPlay
										muted
										style={{
											width:
												windowWidth > 480
													? '100%'
													: windowWidth * 0.9,
											height:
												windowWidth > 480
													? screen === 'JobDetails'
														? 400
														: 600
													: '100%',
										}}
									/>
								</Box>
							)}
						</Box>
					) : (
						<Box
							w={'auto'}
							h={
								windowWidth > 480
									? screen === 'JobDetails'
										? 445
										: 645
									: 210
							}
							bg="black"
							alignItems="center"
							justifyContent="center"
						>
							<Pressable
								onPress={getCameraPermission}
								disabled={recordingStatus === 'inactive'}
							>
								<Text
									fontFamily="$fontHeading"
									color="$primary0"
									fontSize={windowWidth > 480 ? 13 : 10}
									fontWeight="500"
								>
									Give camera and audio permissions
								</Text>
							</Pressable>
						</Box>
					)}
				</Box>
				{permission && (
					<Box alignItems="center">
						<Text
							color="$secondary500"
							fontFamily="$fontHeading"
							italic
							fontWeight="600"
							fontSize={windowWidth > 480 ? 12 : 10}
						>
							*min required time is 1min
						</Text>
					</Box>
				)}
			</Box>

			<Box
				flexDirection={windowWidth > 480 ? 'row' : 'column'}
				gap={20}
				justifyContent={
					screen === 'Onboarding' ? 'space-between' : 'center'
				}
				w={'$full'}
				marginVertical={windowWidth > 650 ? 25 : 5}
				alignItems="flex-start"
			>
				{screen === 'Onboarding' && (
					<ButtonComponent
						onPress={() => handleGoBack()}
						text={'Go Back'}
						width={windowWidth > 650 ? 210 : '100%'}
						color={'$white'}
						textColor={'$primary500'}
					/>
				)}
				<VStack alignItems="flex-end" w={windowWidth < 480 && '$full'}>
					<HStack
						justifyContent={'space-between'}
						alignItems="center"
						gap={10}
						w={windowWidth < 480 && '$full'}
					>
						<Pressable
							bg={recordedVideo ? '$secondary500' : 'gray'}
							p={windowWidth > 480 ? 10 : 5}
							borderRadius={6}
							onPress={() => {
								restartRecording()
							}}
							disabled={!recordedVideo}
						>
							<Text
								fontFamily="$fontHeading"
								color="white"
								fontWeight="600"
								fontSize={windowWidth > 480 ? 13 : 11}
							>
								Delete and Restart
							</Text>
						</Pressable>

						<Pressable
							bg={
								mediaBlob && timer >= 60
									? '$primary500'
									: 'gray'
							}
							p={windowWidth > 480 ? 10 : 5}
							borderRadius={6}
							disabled={!mediaBlob || timer < 60}
							onPress={() => {
								uploadToS3()
							}}
							w={140}
						>
							<Text
								fontFamily="$fontHeading"
								color="white"
								fontWeight="600"
								textAlign="center"
								fontSize={windowWidth > 480 ? 13 : 11}
							>
								Upload Recording
							</Text>
						</Pressable>
					</HStack>
					{screen === 'Onboarding' && (
						<Box>
							<Pressable onPress={() => handleSkip()}>
								<Text
									fontFamily="$fontHeading"
									underline
									color="$primary500"
									fontWeight="700"
									fontSize={windowWidth > 480 ? 14 : 12}
								>
									{'skip>'}
								</Text>
							</Pressable>
						</Box>
					)}
				</VStack>
			</Box>
			<ModalComponent
				isOpen={uploadLoading}
				onClose={() => {
					setUploadLoading
				}}
				closeOnOverlayClick={false}
				height={'auto'}
				renderBody={() => {
					return (
						<Box p={20} gap={20} alignItems="center">
							<Text textAlign="center" fontFamily="$fontHeading">
								Uploading: {Math.floor(uploadProgress)}%
							</Text>
							<ProgressBar
								progress={Math.ceil(uploadProgress)}
								borderColor={'red'}
								bgColor={'orange'}
								animationType={'timing'}
								width={
									windowWidth > 480 ? 400 : windowWidth * 0.7
								}
								normalAnimation={true}
							/>
						</Box>
					)
				}}
				background={'white'}
			/>
		</Box>
	)
}

export default VideoRecorderComponent
