import React from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import { HStack } from 'native-base'
import { useNavigation } from '@react-navigation/native'

function BreadCrumbs({ screen, name, subScreen, userId }) {
	const navigation = useNavigation()

	const backToDashboard = () => {
		navigation.push('Dashboard')
	}
	const allHidisPage = () => {
		navigation.push('Hidilist', { UserId: userId })
	}

	return (
		<View>
			{screen === 'capability' && (
				<HStack style={styles.crumbsContainer}>
					<TouchableOpacity onPress={backToDashboard()}>
						<Text style={styles.crumbsText}>{name} / </Text>
					</TouchableOpacity>
					<TouchableOpacity></TouchableOpacity>
					{subScreen === 'hidiView' ? (
						<TouchableOpacity>
							<Text style={styles.crumbsText}>Experience</Text>
						</TouchableOpacity>
					) : (
						<TouchableOpacity>
							<Text style={styles.crumbsText}>
								{' '}
								Capability Details
							</Text>
						</TouchableOpacity>
					)}
				</HStack>
			)}
			{screen === 'hidis' && (
				<HStack style={styles.crumbsContainer}>
					<TouchableOpacity onPress={backToDashboard()}>
						<Text style={styles.crumbsText}>{name} /</Text>
					</TouchableOpacity>
					<TouchableOpacity
						onPress={
							subScreen === 'hidiView' ? allHidisPage : () => {}
						}
					>
						<Text style={styles.crumbsText}> All Experiences </Text>
					</TouchableOpacity>
					{subScreen === 'hidiView' && (
						<TouchableOpacity>
							<Text style={styles.crumbsText}>/ Experience</Text>
						</TouchableOpacity>
					)}
				</HStack>
			)}
		</View>
	)
}

const styles = StyleSheet.create({
	crumbsContainer: {
		// borderWidth: 1,
		paddingHorizontal: 5,
		paddingVertical: 15,
		// borderColor: '#EEEEEE',
		borderRadius: 20,
	},
	crumbsText: {
		color: '#246BFD',
		fontWeight: '600',
	},
})

export default BreadCrumbs
