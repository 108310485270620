import { Dimensions } from 'react-native'
import React, { useRef, useState, useEffect } from 'react'
import { Auth, Amplify } from 'aws-amplify'
import { showToast } from '../../../components/ErrorToast'
import {
	ModalContent,
	Modal,
	ModalBackdrop,
	ModalHeader,
	VStack,
	Heading,
	Text,
	HStack,
	ModalBody,
	Input,
	InputField,
	Box,
	ModalCloseButton,
	Icon,
	ModalFooter,
	Button,
	ButtonText,
	CloseIcon,
} from '@gluestack-ui/themed'
import SpinnerComponent from '../../../newComponents/SpinnerComponent'
Amplify.configure(Auth)
import { useCallback } from 'react'
import { useNavigation } from '@react-navigation/native'
import { ToastContainer } from 'react-toastify'
import useUserStore from '../../../Userstore/UserStore'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const ValidateOtpNew = ({
	modalClose,
	modalVisibilty,
	userData,
	ref,
	inModal,
	setTab,
	navigation,
	emailparam,
	passwordparam,
	loading,
	setLoading
}) => {
	
	const otpLength = 6
	
	const [loadingResend, setLoadingResend] = useState(false)
	const [showResend, setShowResend] = useState(false)
	const [otp, setOtp] = useState('')
	const updateUser = useUserStore((state) => state.updateUser)
	const signIn = useUserStore((state) => state.signIn)
	const [refreshing, setRefreshing] = useState(false)
	// const [UserData, setUserData] = useState({
	// 	email: emailparam,
	// 	password: passwordparam,
	// })
	const composeOnLogin = (data, methods) => async () => {
		const { email, password } = data || {}
		const { updateUser, signIn, setLoading } = methods || {}
		if (!password || !email) {
			showToast({ message: 'Please fill in your Email and Password' })
			return
		}
		setLoading(true)
		try {
			updateUser({ email, password })
			await signIn()
			if (inModal) {
				window.location.href = '/Feed'
				setModal(false)
			}
			setLoading(false)
		} catch (err) {
			setLoading(false)
			console.log(err)
		}
	}
	const onSubmit = useCallback(
		composeOnLogin({ ...userData }, { updateUser, signIn, setLoading }),
		[userData, updateUser, signIn, setLoading]
	)
	// useEffect(() => {
	// 	if (!loading && refreshing) {
	// 		setRefreshing(false)
	// 		if (inModal) {
	// 			setTab('Login')
	// 		} else {
	// 			navigation.navigate('Login')
	// 		}
	// 	}
	// }, [loading, refreshing, navigation])


	const handleValidation = async () => {
		setLoading(true)
		try {
			if (otp.length === otpLength) {
				const confirmSignUp = await Auth.confirmSignUp(
					userData.email,
					otp
				)
				if (confirmSignUp === 'SUCCESS') {
					setRefreshing(true)
					showToast({
						message:
							'OTP validated successfully!. Logging You In.',
						type: 'success',
					})
					modalClose()
					onSubmit()
				}
			} else {
				showToast({ message: 'Invalid OTP!' })
				setShowResend(true)
			}
		} catch (error) {
			console.log(error)
			setLoading(false)
			setShowResend(true)
		} finally {
			
		}
	}

	const handleResendCode = async () => {
		setLoadingResend(true)
		try {
			await Auth.resendSignUp(userData.email)
			setLoadingResend(false)
			showToast({ message: 'Code Resent!', type: 'success' })
		} catch (err) {
			setLoadingResend(false)
		} finally {
			setLoadingResend(false)
		}
	}

	return (
		<Modal
			isOpen={modalVisibilty}
			onClose={modalClose}
			minHeight={windowHeight}
			finalFocusRef={ref}
			position="fixed"
		>
			<ModalBackdrop />
			<ToastContainer />
			<ModalContent
				minWidth={windowWidth > 480 ? null : windowWidth * 0.9}
				overflow={false}
			>
				<ModalHeader>
					<Heading
						size="lg"
						fontFamily="$fontHeading"
						fontSize={
							windowWidth > 480
								? '$fontHeading'
								: '$fontHeadingMobile'
						}
					>
						Enter OTP
					</Heading>
					<ModalCloseButton>
						<Icon as={CloseIcon} />
					</ModalCloseButton>
				</ModalHeader>
				<ModalBody>
					<VStack gap={15}>
						<Text size="sm" fontFamily="$fontContent" fontSize={12}>
							An OTP has been to {userData.email}. Enter the code
							below.
						</Text>
						<Input>
							<InputField
								placeholder="Enter verification code"
								onChangeText={(text) => {
									setOtp(text)
								}}
							/>
						</Input>
					</VStack>
				</ModalBody>
				<ModalFooter justifyContent="space-between">
					{showResend ? (
						<Button
							variant="outline"
							size="sm"
							action="secondary"
							mr="$3"
							w={windowWidth > 480 ? 100 : 70}
							onPress={() => {
								handleResendCode()
							}}
						>
							{loadingResend ? (
								<SpinnerComponent
									color={'$primary950'}
									button={true}
								/>
							) : (
								<ButtonText
									fontSize={windowWidth > 480 ? 12 : 11}
									fontFamily="$fontContent"
								>
									Resend
								</ButtonText>
							)}
						</Button>
					) : (
						<Box></Box>
					)}

					<Button
						size="sm"
						action="primary"
						borderWidth="$0"
						w={windowWidth > 480 ? 100 : 70}
						onPress={handleValidation}
						sx={{
							bg: '$primary500',
							':hover': {
								bg: '$primary400',
							},
							':active': {
								bg: '$primary500',
							},
						}}
					>
						{loading ? (
							<SpinnerComponent
								color={'$primary0'}
								button={true}
							/>
						) : (
							<ButtonText
								fontSize={windowWidth > 480 ? 12 : 11}
								fontFamily="$fontContent"
							>
								Validate
							</ButtonText>
						)}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ValidateOtpNew
