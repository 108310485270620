import React from 'react'
import { useState, useEffect } from 'react'
import {
	Box,
	HStack,
	Image,
	Pressable,
	Text,
	FlatList,
	Divider,
} from '@gluestack-ui/themed'
import SpinnerComponent from '../../newComponents/SpinnerComponent'
import { Dimensions } from 'react-native'
import EligibilityModal from '../../FeedComponents/CheckEligibility/EligibilityModal'
const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width
import { API } from 'aws-amplify'
import { list_JobApplications_by_ProfileId_filter_byJobPostIdforFeed } from '../../graphql/custom-queries'
import MyOppRenderComponent from '../../newComponents/MyOppRenderComponent'

const MyOpportunities = ({ navigation, profileId, closeModal }) => {
	const [loading, setLoading] = useState(true)
	const [data, setJobsAppliedDetails] = useState([])

	let numColumns = windowWidth > 480 ? 4 : 1

	const listJobsApplied = async () => {
		try {
			const res = await API.graphql({
				query: list_JobApplications_by_ProfileId_filter_byJobPostIdforFeed,
				variables: {
					ProfileId: profileId,
				},
			})
		
			setJobsAppliedDetails(
				res.data.list_JobApplications_by_ProfileId_filter_byJobPostId
					.items
			)
			
			setLoading(false)
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		if (profileId) {
			listJobsApplied()
		}
	}, [profileId])

	const renderItem = ({ item }) => {
		let logoUrl =
			'https://logo.clearbit.com/' +
			item?.jobPost?.companyName?.split(' ')[0].trim() +
			'.com'

		return (
			<Box
				borderWidth={1}
				w={`24%`}
				borderRadius={10}
				borderColor="$borderDark50"
			>
				<MyOppRenderComponent
					applyingStatus={item?.ApplyingStatus}
					companyName={item?.jobPost?.companyName ? item?.jobPost?.companyName : item.jobPost?.CompanyPreference?.companyprofile.name}
					jobTitle={item?.jobPost?.title ? item?.jobPost?.title : item.jobPost?.CompanyPreference.rolename}
					modeofwork={item?.jobPost?.CompanyPreference?.modeofwork}
					onPress={() => {
						
						navigation.push('JobDetails', {
							jobPostId: item?.JobPostId,
						})
						closeModal && closeModal()
					}}
					colFD={true}
				/>
			</Box>
		)
	}

	return (
		<>
			{!loading && (
				<Box
					my={20}
					borderRadius={6}
					borderWidth={1}
					borderColor="$borderDark0"
					p={20}
					bg="white"
				>
					<Text
						fontFamily="$fontHeading"
						color="$primary950"
						fontSize={14}
						fontWeight="600"
					>
						Your Applications
					</Text>
					<HStack
						justifyContent="space-between"
						flexWrap="wrap"
						marginVertical={15}
						bg="$primary0"
						borderRadius={10}
						borderColor="$borderDark0"
						gap={5}
					>
						<Box gap={15} w={'100%'} borderRadius={10}>
							<FlatList
								data={data}
								renderItem={renderItem}
								numColumns={numColumns}
								contentContainerStyle={{
									borderRadius: 10,
									gap: 10,
								}}
								columnWrapperStyle={
									windowWidth > 480 ? { gap: 10 } : null
								}
								ListEmptyComponent={() => {
									return (
										<Box
											alignContent="center"
											alignItems="center"
											p={windowWidth > 480 ? 20 : 5}
										>
											<Image
												alignSelf="center"
												height={300}
												width={200}
												resizeMode="contain"
												source={require('../../assets/Images/addhidi.png')}
											/>
											<Text
												fontFamily="$fontHeading"
												color="$fontGray"
												fontSize={'$fontSize13'}
												fontWeight="500"
												lineHeight={17}
											>
												Opportunities you have applied
												to are visible here
											</Text>
										</Box>
									)
								}}
							/>
						</Box>
					</HStack>
				</Box>
			)}
		</>
	)
}

export default MyOpportunities
