import { ArrowLeftIcon, Box, TrashIcon } from '@gluestack-ui/themed'
import React from 'react'
import { points } from '../../../newComponents/pointsComponents/pointsconfig'
import ShareSkeleton from './ShareSkeleton'

const ShareHelped = ({
	setTab,
	helped,
	setHelped,
	totalCapabilityPoints,
	prevData,
	capEditView,
	textAreaHeight,
}) => {
	const wordCount = helped
		?.split(/\s+/)
		?.filter((word) => word !== '')?.length

	return (
		<Box>
			<ShareSkeleton
				prevFieldData={prevData}
				titleNormal1={'How did your '}
				titleColored1={''}
				titleNormal2={''}
				titleColored2={'solution help'}
				titleNormal3={'?'}
				subtitle={
					'The impact and value your solution had on the team, product, or client.'
				}
				valueInput={helped}
				setInputValueChange={setHelped}
				textArea={true}
				infoStripText={
					'Employers highly value candidates who share real-world problem-solving experiences, providing concrete examples that demonstrate practical skills, critical thinking, and a results-oriented approach while offering insights into behavior and cultural alignment'
				}
				buttonText={helped === '' || helped === null ? 'Skip' : 'Next'}
				buttonOnPress={() => {
					setTab('ProjectLink')

					if (helped === '' || helped === null) {
						setHelped('')
					}
				}}
				backButtonOnPress={() => {
					setTab('Hacks')
					if (helped === '' || helped === null) {
						setHelped('')
					}
				}}
				backIcon={ArrowLeftIcon}
				backText={'Back'}
				totalCapabilityPoints={totalCapabilityPoints}
				pointsAssigned={points.helped}
				capEditView={capEditView}
				textAreaHeight={textAreaHeight}
				disableCopyPaste={true}
				disableRightClick={true}
			/>
		</Box>
	)
}

export default ShareHelped
