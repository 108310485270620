import { ArrowLeftIcon, Box, TrashIcon } from '@gluestack-ui/themed'
import React from 'react'
import { points } from '../../../newComponents/pointsComponents/pointsconfig'
import ShareSkeleton from './ShareSkeleton'

const ShareProjectLink = ({
	setTab,
	projectLink,
	setProjectLink,
	totalCapabilityPoints,
	prevData,
	capEditView,
}) => {
	return (
		<Box>
			<ShareSkeleton
				prevFieldData={prevData}
				titleNormal1={'Can you give us any project '}
				titleColored1={'links '}
				titleNormal2={'or references to this outcome'}
				titleColored2={''}
				titleNormal3={'?'}
				subtitle={
					'Others who have worked on this with you or helped you'
				}
				valueInput={projectLink}
				setInputValueChange={setProjectLink}
				infoStripText={
					'Your project link is a visual testament to your skills. 🎨 Employers love a good show-and-tell! Sharing tangible examples of your work is like giving them a front-row seat to the awesome things you can do.'
				}
				buttonOnPress={() => {
					setTab('WorkPlace')
				}}
				buttonText={
					projectLink === '' || projectLink === null ? 'Skip' : 'Next'
				}
				backButtonOnPress={() => {
					setTab('Helped')
				}}
				backIcon={ArrowLeftIcon}
				disabled={
					projectLink !== '' &&
					(!projectLink.includes('http' || 'https') ||
						!projectLink.includes('.'))
				}
				backText={'Back'}
				totalCapabilityPoints={totalCapabilityPoints}
				pointsAssigned={points.projectLink}
				capEditView={capEditView}
				disableCopyPaste={false}
				disableRightClick={false}
				infoText={
					projectLink === ''
						? ' '
						: '*must include http/https in your link'
				}
			/>
		</Box>
	)
}

export default ShareProjectLink
