import {
	Box,
	Text,
	Icon,
	LockIcon,
	HStack,
	VStack,
	Heading,
	Divider,
	Pressable,
	TrashIcon,
	AddIcon,
	FlatList,
} from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'

// import { Container, Header, Content, Picker, Form } from 'native-base';
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
const screenHeight = Dimensions.get('screen').height
// import { createNewhidi } from "../graphql/mutations";
import { API } from 'aws-amplify'
import React, { useRef, useState, useEffect } from 'react'
// load theme styles with webpack
import 'medium-editor/dist/css/medium-editor.css'
import 'medium-editor/dist/css/themes/default.css'
import useUserStore from '../Userstore/UserStore'
// ES module
import Editor from 'react-medium-editor'
// import { createNewhidi } from '../graphql/mutations'
import {
	createChallenges,
	createNewhidi,
	updateChallenges,
} from '../graphql/custom-mutations'
import { createOutcome } from '../graphql/custom-mutations'
import { createJoinHidisAndCapabilities } from '../graphql/custom-mutations'
// import { createJoinHidisAndCapabilities } from '../graphql/mutations'
import { getNewhidi } from '../graphql/custom-queries'
import { deleteOutcome } from '../graphql/mutations'
import { updateNewhidi } from '../graphql/mutations'
// import { TouchableOpacity } from "react-native-web";
import { listNewprofiles } from '../graphql/queries'
import { ActivityIndicator } from 'react-native-web'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { formatValidationMessages } from '../HeplerFunctions/Validationhelpers'
import { HidiValidator } from '../components/HidiValidator'
import HidiErrorPopup from '../components/HidiErrorPopup'
import { title } from 'process'
import ExampleHidi from '../components/ExampleHidi'
import Hidicontainer from '../components/HidiContainer'
import { showToast } from '../components/ErrorToast'
import SpinnerComponent from '../newComponents/SpinnerComponent'
import LabelWithInputComponent from '../newComponents/LabelWithInputComponent'
import ButtonComponent from '../newComponents/ButtonComponent'
import OutcomeComponent from '../newComponents/OutcomeComponent'
import HIdidetails from '../newComponents/HIdidetails'
import ShareProblem from '../FeedComponents/CheckEligibility/ShareExperience/ShareProblem'
import ShareExperience from '../FeedComponents/CheckEligibility/ShareExperience/ShareExperience'
import ShareChallenge from '../FeedComponents/CheckEligibility/ShareExperience/ShareChallenge'
import ShareHack from '../FeedComponents/CheckEligibility/ShareExperience/ShareHack'
import ShareHelped from '../FeedComponents/CheckEligibility/ShareExperience/ShareHelped'
import ShareProjectLink from '../FeedComponents/CheckEligibility/ShareExperience/ShareProjectLink'
import SharePeers from '../FeedComponents/CheckEligibility/ShareExperience/SharePeers'
import SelectSkillModalBody from './opportunities/components/SelectSkillModalBody'
import {
	deleteSkillandCapability,
	joinSkillsAndCapability,
	listSkillsAndCapabilitiesByUserId,
} from './opportunities/components/HelperFunctions'
const CapCreateHidi = ({ route, navigation }) => {
	const notify = (errorMessages) =>
		toast.warn(<HidiErrorPopup text={errorMessages} />, {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light',
		})

	const { capabilityName, capabilityId, hidiId, capability } =
		route.params || {}

	const [heading, setHeading] = useState('')
	const [summary, setSummary] = useState('')
	const [background, setBackground] = useState('')
	const [output, setOutput] = useState('')
	const [loading, setloading] = useState(false)
	const [screenstate, setScreenState] = useState('edit')
	const userId = useUserStore((state) => state.userId)
	const profileId = useUserStore((state) => state.profileId)
	const [selectedValue, setSelectedValue] = useState('')
	const [outcomes, setOutcomes] = useState('')
	const [loadingOutcomeId, setloadingOutcomeId] = useState('')
	const existingSkills = useUserStore((state) => state.userSkills)
	const setExistingSkills = useUserStore((state) => state.setUserSkills)
	const [joiningCpabilitySkills, setJoiningCpabilitySkills] = useState(false)

	// this will be attached with each input onChangeText
	const [textValue, setTextValue] = useState('')
	// our number of inputs, we can add the length or decrease the length
	const [numInputs, setNumInputs] = useState(1)
	const numInputsRef = useRef(1)
	const [wordCount, setWordCount] = useState(0)
	const setClaimedCapabailities = useUserStore(
		(state) => state.setClaimedCapabailities
	)
	// all our input fields are tracked with this array
	const refInputs = useRef([textValue])

	const getLocalStorageKey = (capabilityId) => `draftData_${capabilityId}`

	// new fields for the new HIDI

	const [hack, setHack] = useState('')
	const [projectLink, setProjectLink] = useState('')
	const [experience, setExperience] = useState('')
	const [problem, setProblem] = useState('')
	const [helped, setHelped] = useState('')
	const [peers, setPeers] = useState('')
	const [challenge, setChallenge] = useState('')
	const [challengeId, setChallengeId] = useState('')
	const countWords = (text) => {
		const words = text?.trim()?.split(/\s+/)
		return words?.filter((word) => word !== '').length
	}
	const [oldSelectedSkills, setOldSelectedSkills] = useState([])
	const [selectedSkillsItem, setSelectedSkillsItem] = useState([])
	const [untouchedSkills, setUntouchedSkills] = useState([])
	const [deletableSkills, setDeletableSkills] = useState([])
	const [newSkills, setNewSkills] = useState([])
	const [linkedSkills, setLinkedSkills] = useState([])

	const hackWordCount = countWords(hack)
	const experienceWordCount = countWords(experience)

	const userProfile = useUserStore((state) => state.profile)

	const saveDraft = () => {
		const draftData = {
			heading,
			summary,
			background,
			output: refInputs.current,
			numInputs: numInputsRef.current,
			refInputs: refInputs.current,
		}

		localStorage.setItem(
			getLocalStorageKey(capabilityId),
			JSON.stringify(draftData)
		)
	}

	async function removeoutcome(props) {
		setloadingOutcomeId(props)
		const CreateUserInput = {
			// Timelinestring: Data1,
			id: props,
		}
		await API.graphql({
			query: deleteOutcome,
			// authMode: 'API_KEY',
			variables: { input: CreateUserInput },
		}).then((res) => {
			setOutcomes(res.data.deleteOutcome.Hidi.outcomes)
			setloadingOutcomeId('')
			// setNumInputs(res.data.deleteOutcome.Hidi.outcomes.items.length)
		})
	}

	async function getnewhididata() {
		setloading(true)
		await API.graphql({
			query: getNewhidi,
			variables: {
				id: hidiId,
			},
		})
			.then((res) => {
				setHeading(res.data.getNewhidi.htmlheading)
				setSummary(res.data.getNewhidi.htmlsummary)
				setBackground(res.data.getNewhidi.summary)
				setOutcomes(res.data.getNewhidi.outcomes)
				// new HIDI
				setProblem(res.data.getNewhidi.problem)
				setHack(res.data.getNewhidi.Hack)
				setHelped(res.data.getNewhidi.helped)
				setPeers(res.data.getNewhidi.peerEmails)
				setProjectLink(res.data.getNewhidi.projectLink)
				setExperience(res.data.getNewhidi.experience)
				setChallenge(
					res.data.getNewhidi?.challenges?.items[0]?.challenge
				)
				setChallengeId(res.data.getNewhidi.challenges?.items[0]?.id)

				setloading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	useEffect(() => {
		hidiId && getnewhididata()
	}, [])

	const loadDraft = () => {
		const storedDraftData = localStorage.getItem(
			getLocalStorageKey(capabilityId)
		)
		if (!hidiId && storedDraftData) {
			const draftData = JSON.parse(storedDraftData)
			setHeading(draftData.heading || '')
			setSummary(draftData.summary || '')
			setBackground(draftData.background || '')
			setOutput(draftData.output || [''])
			numInputsRef.current = draftData.numInputs || 1
			refInputs.current = draftData.refInputs || [textValue]
		}
	}

	useEffect(() => {
		loadDraft()
	}, [capabilityId])

	useEffect(() => {
		saveDraft()
	}, [heading, summary, background, output, numInputsRef.current])

	const createChallengeHidi = async ({ HidiIdProp }) => {
		const CreateUserInput = {
			HidiId: HidiIdProp,
			challenge: challenge,
		}

		await API.graphql({
			query: createChallenges,
			variables: { input: CreateUserInput },
		})
			.then((res) => {})
			.catch((err) => {})
	}

	const updateChallengeHidi = async () => {
		const CreateUserInput = {
			id: challengeId,
			HidiId: hidiId,
			challenge: challenge,
		}

		await API.graphql({
			query: updateChallenges,
			variables: { input: CreateUserInput },
		})
			.then((res) => {})
			.catch((err) => {})
	}

	async function createHidi() {
		setloading(true)

		const CreateUserInput = {
			UserId: userId,
			profileId: profileId,
			htmlheading: heading,
			htmlsummary: summary,
			summary: background,
			experience: experienceWordCount < 20 ? '' : experience,
			Hack: hackWordCount < 10 ? '' : hack,
			peerEmails: peers,
			helped: helped,
			problem: problem,
			projectLink: projectLink,
		}
		API.graphql({
			query: createNewhidi,
			// authMode: 'API_KEY',
			variables: { input: CreateUserInput },
		}).then(async (res) => {
			await createChallengeHidi({
				HidiIdProp: res?.data?.createNewhidi?.id,
			})
			createOutcomes(res.data.createNewhidi.id)
			showToast({
				message: 'Your capability is under review!',
				type: 'success',
			})
		})
	}

	async function updateHidi() {
		setloading(true)

		const CreateUserInput = {
			id: hidiId,
			htmlheading: heading,
			htmlsummary: summary,
			summary: background,
			experience: experienceWordCount < 20 ? '' : experience,
			Hack: hackWordCount < 10 ? '' : hack,
			peerEmails: peers,
			helped: helped,
			problem: problem,
			projectLink: projectLink,
			// htmloutput: output,
		}
		API.graphql({
			query: updateNewhidi,
			// authMode: 'API_KEY',
			variables: { input: CreateUserInput },
		}).then(async (res) => {
			if (challengeId) {
				await updateChallengeHidi()
			} else {
				await createChallengeHidi({
					HidiIdProp: res?.data?.updateNewhidi?.id,
				})
			}
			createOutcomes()
			// navigation.navigate("NewHIDI", {hidiId: res.data.createNewhidi.id})
		})
	}
	async function createOutcomes(prop) {
		{
			refInputs.current.map(async (value) => {
				if (value) {
					const CreateUserInput = {
						HidiId: hidiId ? hidiId : prop,
						Outcome: value,
						Verified: 'notverified',
					}
					await API.graphql({
						query: createOutcome,
						// authMode: 'API_KEY',
						variables: { input: CreateUserInput },
					}).then((res) => {})
				}
			})
			setloading(false)
			hidiId
				? navigation.push('NewHIDI', { hidiId: hidiId })
				: capabilityName
				? createJoinHidiandCapabilityTable(prop)
				: navigation.push('NewHIDI', { hidiId: prop })
		}
	}

	async function createJoinHidiandCapabilityTable(prop) {
		setloading(true)

		const CreateUserInput = {
			CapabilityId: capabilityId,
			HidiId: prop,

			// htmloutput: output,
		}
		API.graphql({
			query: createJoinHidisAndCapabilities,
			// authMode: 'API_KEY',
			variables: { input: CreateUserInput },
		}).then((res) => {
			setClaimedCapabailities(
				res.data.createJoinHidisAndCapabilities.Capability?.userProfile
					.Capabilities?.items
			)
			navigation.push('NewHIDI', { hidiId: prop })
			// navigation.navigate("NewHIDI", {hidiId: res.data.createNewhidi.id})
		})
	}

	const setInputValue = (index, value) => {
		const inputs = refInputs.current
		inputs[index] = value
		setTextValue(value)
		refInputs.current[index] = value
		setOutput(...inputs)
	}
	const addInput = () => {
		if (textValue.length < 45) {
			const message =
				'Outcome Length (Less than 40 characters):"Hold on! What you did needs to be at least 40 characters long for a captivating Outcome." '
			return notify(message)
		}
		// add a new element in our refInputs array
		refInputs.current.push('')
		setNumInputs((value) => value + 1)
		numInputsRef.current += 1
	}
	const removeInput = (i) => {
		// remove from the array by index value
		refInputs.current.splice(i, 1)[0]
		setNumInputs((value) => value - 1)
		numInputsRef.current -= 1
	}
	const inputs = []
	for (let i = 0; i < numInputs; i++) {
		inputs.push(
			<Box key={i} flexDirection="row" alignItems="center">
				<Box flex={1}>
					<LabelWithInputComponent
						setValueChange={(value) => setInputValue(i, value)}
						value={refInputs.current[i]}
						placeholder={
							'Share the results and impact of the solution implemented.'
						}
					/>
				</Box>
				<Box flexDirection="row">
					{i >= 1 && (
						<Pressable
							marginHorizontal={10}
							bg="$secondary200"
							p={7}
							borderRadius={6}
							onPress={() => removeInput(i)}
						>
							<Icon
								h={20}
								w={20}
								as={TrashIcon}
								color={'$secondary500'}
							/>
						</Pressable>
					)}
					{i == numInputs - 1 && (
						<Pressable
							onPress={() => addInput()}
							marginHorizontal={10}
							bg="$primary200"
							p={7}
							borderRadius={6}
						>
							<Icon
								h={20}
								w={20}
								as={AddIcon}
								color={'$primary600'}
							/>
						</Pressable>
					)}
				</Box>
			</Box>
		)
	}

	// skills and capability

	const getSkillsByUserId = async () => {
		const skillsResponse = await listSkillsAndCapabilitiesByUserId(userId)
		if (skillsResponse) {
			setLinkedSkills(skillsResponse)
		}
	}

	useEffect(() => {
		getSkillsByUserId()
	}, [])

	useEffect(() => {
		if (
			capability?.JoinedSkills?.items?.length > 0 &&
			existingSkills?.length > 0
		) {
			const existingSkillsSet = new Set(
				existingSkills.map((skill) => skill.id)
			)

			const matchedSkills = capability.JoinedSkills.items
				.filter((item) => existingSkillsSet.has(item.SkillId))
				.map((item) => item.Skill)

			setSelectedSkillsItem(matchedSkills)
			setOldSelectedSkills(matchedSkills)
		}
	}, [capability, existingSkills])

	useEffect(() => {
		if (selectedSkillsItem.length > 0) {
			const oldSelectedSkillsSet = new Set(
				oldSelectedSkills.map((skill) => skill.id)
			)
			const deletable = oldSelectedSkills.filter(
				(skill) =>
					!selectedSkillsItem.some((item) => item.id === skill.id)
			)
			const untouched = selectedSkillsItem.filter((skill) =>
				oldSelectedSkillsSet.has(skill.id)
			)
			const newSkills = selectedSkillsItem.filter(
				(skill) => !oldSelectedSkillsSet.has(skill.id)
			)

			setUntouchedSkills(untouched)
			setDeletableSkills(deletable)
			setNewSkills(newSkills)
		}
	}, [capability, existingSkills, oldSelectedSkills, selectedSkillsItem])

	const deleteJoinSkillsBySkillId = async (skillId) => {
		try {
			const itemsToDelete = linkedSkills.filter(
				(item) => item.SkillId === skillId
			)

			for (const item of itemsToDelete) {
				const deleteInput = {
					id: item.id,
				}
				const itemToDelete = await deleteSkillandCapability(deleteInput)
			}
			getSkillsByUserId()
		} catch (e) {
			console.log('error', e)
		}
	}

	const joinCapabilityAndSkills = async () => {
		const joiningSkillsCapability = newSkills?.map(async (skill) => {
			const input = {
				UserId: userId,
				SkillId: skill.id,
				CapabilityId: capabilityId,
				GlobalCapabilityConfigId: capability?.CapabilityConfig?.id,
				GlobalCapabilityConfigName: capabilityName,
			}
			return joinSkillsAndCapability(input)
		})

		const joinSkillsCapabilityResponse = await Promise.all(
			joiningSkillsCapability
		)
	}

	const mergeSkillsAndCapability = async () => {
		setJoiningCpabilitySkills(true)
		if (newSkills.length > 0) {
			await joinCapabilityAndSkills()
		}

		if (deletableSkills.length > 0) {
			const deletionOfSkills = deletableSkills.map(async (skill) => {
				return deleteJoinSkillsBySkillId(skill.id)
			})

			const deletionOfSkillsResponse = await Promise.all(deletionOfSkills)
		}

		setJoiningCpabilitySkills(false)
	}

	const checkDisablePubishButtonProblem = () => {
		return problem === ''
	}

	const checkDisablePubishButtonHack = () => {
		if (hackWordCount === 0) {
			return false
		} else if (hackWordCount < 10) {
			return true
		} else if (hackWordCount >= 10) {
			return false
		}
	}

	const checkDisablePubishButtonExperience = () => {
		if (experienceWordCount === 0) {
			return false
		} else if (experienceWordCount < 20) {
			return true
		} else if (experienceWordCount >= 20) {
			return false
		}
	}

	const publishBtnDisabled = checkDisablePubishButtonProblem()

	const hackWordIssue = checkDisablePubishButtonHack()
	const experienceWordIssue = checkDisablePubishButtonExperience()

	return (
		<Box
			bg="$backgroundGray"
			minHeight={windowHeight - 62}
			marginTop={62}
			paddingHorizontal={windowWidth > 480 ? 100 : 5}
			paddingVertical={windowWidth > 480 ? 20 : 5}
		>
			{loading ? (
				<Box>
					<SpinnerComponent />
				</Box>
			) : (
				<Box bg="$primary0" p={20}>
					<Box>
						<Box>
							{capabilityName ? (
								<Box>
									<Text
										fontFamily="$fontHeading"
										fontSize={
											windowWidth > 480
												? '$fontSize13'
												: 10
										}
									>
										For the capability:
									</Text>
									<HStack
										justifyContent="space-between"
										borderRadius={6}
										alignItems="center"
									>
										<Text
											fontFamily="$fontHeading"
											fontSize={
												windowWidth > 480
													? '$fontHeading'
													: '$fontHeadingMobile'
											}
											fontWeight="600"
											color="$primary500"
										>
											{capabilityName?.replace(
												/^"|"$/g,
												''
											)}
										</Text>
									</HStack>
									<Divider
										h={0.5}
										marginTop={windowWidth > 480 ? 10 : 5}
									/>
								</Box>
							) : (
								<Box>
									<Heading
										fontFamily="$fontHeading"
										fontSize={
											windowWidth > 480
												? '$fontHeading'
												: '$fontHeadingMobile'
										}
									>
										{screenstate === 'edit' ? (
											hidiId ? (
												<Heading
													fontFamily="$fontHeading"
													fontSize={
														windowWidth > 480
															? '$fontHeading'
															: '$fontHeadingMobile'
													}
												>
													Edit{' '}
													<Heading
														fontFamily="$fontHeading"
														fontSize={
															windowWidth > 480
																? '$fontHeading'
																: '$fontHeadingMobile'
														}
														color="$primary500"
													>
														Experience
													</Heading>
												</Heading>
											) : (
												<Heading
													fontFamily="$fontHeading"
													fontSize={
														windowWidth > 480
															? '$fontHeading'
															: '$fontHeadingMobile'
													}
												>
													Add{' '}
													<Heading
														fontFamily="$fontHeading"
														fontSize={
															windowWidth > 480
																? '$fontHeading'
																: '$fontHeadingMobile'
														}
														color="$primary500"
													>
														Experience
													</Heading>
												</Heading>
											)
										) : (
											<Heading
												fontFamily="$fontHeading"
												fontSize={
													windowWidth > 480
														? '$fontHeading'
														: '$fontHeadingMobile'
												}
											>
												<Heading
													fontFamily="$fontHeading"
													fontSize={
														windowWidth > 480
															? '$fontHeading'
															: '$fontHeadingMobile'
													}
													color="$primary500"
												>
													Experience{' '}
												</Heading>
												View
											</Heading>
										)}
									</Heading>
									<Divider
										h={0.5}
										marginTop={windowWidth > 480 ? 10 : 5}
									/>
								</Box>
							)}
							<Box
								flexDirection={
									windowWidth > 480 ? 'row' : 'column'
								}
								flex={windowWidth > 480 ? 1 : null}
							>
								{screenstate === 'edit' ? (
									heading ? (
										<Box
											flex={windowWidth > 480 ? 1 : null}
										>
											<LabelWithInputComponent
												label={'What did you do?'}
												setValueChange={(text) =>
													setHeading(text)
												}
												important={true}
												value={heading}
												placeholder={
													'Please provide a title'
												}
											/>
											<LabelWithInputComponent
												label={
													'Give a background for it'
												}
												setValueChange={(text) => {
													if (
														countWords(text) <= 100
													) {
														setBackground(text)
														setWordCount(
															countWords(text)
														)
													}
												}}
												value={background}
												important={true}
												placeholder={
													'Please provide a detailed description of the issue, problem, or situation you encountered.'
												}
												textArea={true}
											/>
											<Box
												marginVertical={
													windowWidth > 480 ? 10 : 5
												}
											>
												<HStack>
													<Text
														fontFamily="$fontContent"
														fontSize={
															windowWidth > 480
																? '$fontContent'
																: '$fontContentMobile'
														}
														fontWeight="800"
														color="$primary950"
													>
														How did you do it?
													</Text>
													<Text color="$secondary500">
														*
													</Text>
												</HStack>
												<Box
													borderWidth={1}
													borderColor="$backgroundDark200"
													bg="$backgroundGray"
													marginTop={10}
													borderRadius={6}
												>
													<Editor
														text={summary}
														style={{
															lineheight:
																'1.2rem',
															color: '#424242',
															fontWeight: '400',
															placeholderTextColor:
																'#AAA6B9',
															borderRadius: 6,
															fontSize:
																windowWidth >
																480
																	? 15
																	: 11,
															fontStyle: 'normal',
															outlineStyle:
																'none',
															alignItems:
																'center',
															// background: 'white',
															padding:
																windowWidth >
																480
																	? 10
																	: 5,
															justifyContent:
																'left',
															fontFamily:
																'Open Sans',
															minHeight:
																windowHeight *
																0.2,
														}}
														onChange={(text) =>
															setSummary(text)
														}
														options={{
															toolbar: {
																buttons: [
																	'bold',
																	'italic',
																	'underline',
																	'orderedlist',
																	'unorderedlist',
																	'qoute',
																	'ancor',
																	'h3',
																	'h4',
																	'h5',
																	'h6',
																],
															},
															placeholder: {
																text: 'Describe! (Select to customize text)',
															},
														}}
													/>
												</Box>
											</Box>
											<Box
												marginVertical={
													windowWidth > 480 ? 10 : 5
												}
											>
												<HStack>
													<Text
														fontFamily="$fontContent"
														fontSize={
															windowWidth > 480
																? '$fontContent'
																: '$fontContentMobile'
														}
														fontWeight="800"
														color="$primary950"
													>
														What were the outcomes?
													</Text>
													<Text color="$secondary500">
														*
													</Text>
												</HStack>
												{outcomes ? (
													<Box marginVertical={10}>
														<OutcomeComponent
															props={outcomes}
															screen={
																hidiId && 'edit'
															}
															deleteOutcome={
																removeoutcome
															}
															loading={
																loadingOutcomeId
															}
															setLoading={
																setloadingOutcomeId
															}
														/>
													</Box>
												) : null}
												<Box>{inputs}</Box>
											</Box>
										</Box>
									) : (
										<Box
											flex={windowWidth > 480 ? 1 : null}
										>
											<Box mt={20} gap={15}>
												<Text
													lineHeight={
														windowWidth > 480
															? 22
															: 17
													}
													fontSize={
														windowWidth > 480
															? 18
															: 14
													}
													fontFamily="$fontHeading"
													fontWeight={700}
													color="$primary950"
												>
													Select{' '}
													<Text
														fontFamily="$fontHeading"
														fontWeight={700}
														color="$secondary500"
													>
														Skills
													</Text>{' '}
													used in this capability
												</Text>
												<SelectSkillModalBody
													screen={'CapCreateHidi'}
													inModal={true}
													userId={userId}
													existingSkills={
														existingSkills
													}
													setExistingSkills={
														setExistingSkills
													}
													setSelectedSkillsItem={
														setSelectedSkillsItem
													}
													selectedSkillsItem={
														selectedSkillsItem
													}
												/>
											</Box>

											<ShareProblem
												problem={problem}
												setProblem={setProblem}
												capEditView={true}
												textAreaHeight={100}
											/>
											<ShareExperience
												experience={experience}
												setExperience={setExperience}
												capEditView={true}
												textAreaHeight={100}
											/>
											<ShareChallenge
												challenge={challenge}
												setChallenge={setChallenge}
												capEditView={true}
												textAreaHeight={100}
											/>
											<ShareHack
												hack={hack}
												setHack={setHack}
												capEditView={true}
												textAreaHeight={100}
											/>
											<ShareHelped
												helped={helped}
												setHelped={setHelped}
												capEditView={true}
												textAreaHeight={100}
											/>
											<ShareProjectLink
												projectLink={projectLink}
												setProjectLink={setProjectLink}
												capEditView={true}
											/>
											<SharePeers
												peers={peers}
												setPeers={setPeers}
												capEditView={true}
											/>
										</Box>
									)
								) : (
									<Box>
										<HIdidetails
											heading={heading}
											refInputs={refInputs}
											background={background}
											profile={userProfile}
											summary={summary}
											outcomes={outcomes}
											screen={'edit'}
											navigation={navigation}
											capabilityName={capabilityName}
											newHidiViewProps={[
												problem,
												experience,
												challenge,
												hack,
												helped,
												projectLink,
												peers,
											]}
											hidiSkills={selectedSkillsItem}
											view={'CapCreateHidi'}
										/>
									</Box>
								)}

								<Box
									flexDirection="row"
									gap={15}
									justifyContent={
										windowWidth > 480
											? 'flex-end'
											: 'center'
									}
									flex={windowWidth > 480 ? 0.3 : null}
									// borderWidth={1}
									marginTop={20}
									marginBottom={70}
									h={'fit-content'}
									flexWrap="wrap"
								>
									<ButtonComponent
										width={100}
										text={
											screenstate == 'view'
												? 'Edit'
												: 'View'
										}
										color={
											heading
												? heading === ''
													? '$gray'
													: '$primary500'
												: problem === ''
												? '$gray'
												: '$primary500'
										}
										borderColor={
											heading
												? heading === ''
													? '$gray'
													: '$primary600'
												: problem === ''
												? '$gray'
												: '$primary600'
										}
										onPress={() => {
											if (
												heading
													? heading === ''
													: problem === ''
											) {
												showToast({
													message: heading
														? 'Please fill the heading to see the edit view'
														: 'Please share the problem faced to see the edit view',
													type: 'error',
												})
											} else {
												screenstate === 'view'
													? setScreenState('edit')
													: setScreenState('view')
											}
										}}
									/>
									<ButtonComponent
										width={100}
										onPress={async () => {
											if (hackWordIssue) {
												showToast({
													message:
														'Share an outcome in more than 10 words!',
													type: 'error',
												})
												return
											}
											if (experienceWordIssue) {
												showToast({
													message:
														'Explain how you solved the problem in more than 20 words!',
													type: 'error',
												})
												return
											}

											await mergeSkillsAndCapability()

											if (hidiId) {
												updateHidi()
											} else {
												createHidi()
											}
										}}
										loading={joiningCpabilitySkills}
										disabled={publishBtnDisabled}
										text={'Publish'}
									/>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			)}
		</Box>
	)
}

export default CapCreateHidi
