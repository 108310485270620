import { Dimensions } from 'react-native'
import React from 'react'
import FederatedLogin from '../FederatedLogin'
import AlreadyUser from '../Options'
import useUserStore from '../../../Userstore/UserStore'
import { Box, HStack, Image, Text, Heading } from '@gluestack-ui/themed'
import SpinnerComponent from '../../../newComponents/SpinnerComponent'
import AboutQrusible from '../AboutQrusible'
import WavyBg from '../../../newComponents/WavyBg'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const SignUpOptions = ({ navigation }) => {
	const isLoading = useUserStore((state) => state.isLoading)

	return (
		<Box minHeight={'100vh'} alignItems="center" justifyContent="center">
			<WavyBg />
			{isLoading ? (
				<Box
					justifyContent="center"
					alignItems="center"
					w={200}
					h={200}
					borderRadius={10}
					borderWidth={0.2}
					borderColor="#d1d1e8"
					bg="white"
				>
					<SpinnerComponent size={'large'} text={'Please Wait'} />
				</Box>
			) : (
				<HStack
					borderWidth={1}
					p={30}
					borderRadius={20}
					shadowRadius={30}
					shadowColor="black"
					shadowOpacity={0.2}
					borderColor="#d1d1e8"
					bg="white"
				>
					<Box
						alignItems="center"
						justifyContent="center"
						flexDirection="column"
						alignSelf={'center'}
					>
						<Image
							h={'8rem'}
							w={'8rem'}
							alt="Qrusible logo"
							source={require('../../../assets/Images/QrusibleNewBlack.png')}
						/>
						<Heading
							fontSize={
								windowWidth > 1500
									? '$fontSize25'
									: '$fontSize20'
							}
							fontWeight="$bold"
							fontFamily="$fontContent"
							color="$primary500"
							marginVertical={40}
							textAlign="center"
						>
							Your Career Compass
						</Heading>
						<Box w={windowWidth > 500 ? '18rem' : '95%'}>
							<FederatedLogin loginScreen={false} />
						</Box>
						<HStack
							alignItems="center"
							justifyContent="center"
							gap={12}
							mt={20}
						>
							<Box
								borderWidth={1}
								borderColor="#EEEEEE"
								h={1}
								w={windowWidth > 500 ? '8rem' : '7rem'}
							></Box>
							<Text fontFamily="$fontHeading">or</Text>
							<Box
								borderWidth={1}
								borderColor="#EEEEEE"
								h={1}
								w={windowWidth > 500 ? '8rem' : '7rem'}
							></Box>
						</HStack>
						<Box w={windowWidth > 500 ? '18rem' : '95%'}>
							<AlreadyUser
								signInOption={true}
								loginScreen={true}
								navigation={navigation}
							/>
						</Box>
					</Box>
				</HStack>
			)}
		</Box>
	)
}

export default SignUpOptions
