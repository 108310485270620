import {
	Box,
	Input,
	InputField,
	VStack,
	Text,
	Textarea,
	TextareaInput,
	HStack,
	InputIcon,
	SearchIcon,
	CloseIcon,
	Pressable,
} from '@gluestack-ui/themed'
import React from 'react'
import { Dimensions } from 'react-native'
import { showToast } from '../components/ErrorToast'
const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

const LabelWithInputComponent = ({
	type,
	value,
	setValueChange,
	label,
	placeholder,
	editable,
	selectTextOnFocus,
	textArea,
	important,
	marginVertical,
	disabled,
	width,
	minWordCount,
	maxWordCount,
	wordCount,
	height,
	heightTextArea,
	onBlur,
	onFocus,
	numberOfLines,
	autoFocus,
	numberInput,
	disableRightClick, 
	disableCopyPaste
}) => {
	const handleTextChange = (text) => {
		if (maxWordCount && text) {
			const words = text.trim().split(/\s+/)
			if (words.length <= maxWordCount) {
				setValueChange(text)
			} else {
				const truncatedText = words.slice(0, maxWordCount).join(' ')
				setValueChange(truncatedText)
			}
		} else {
			setValueChange(text)
		}
	}

	return (
		<>
			<VStack
				w={width ? width : '100%'}
				marginVertical={
					marginVertical ? marginVertical : windowWidth > 480 ? 10 : 5
				}
			>
				{label ? (
					<HStack>
						<Text
							fontFamily="$fontContent"
							fontSize={
								windowWidth > 480
									? '$fontContent'
									: '$fontContentMobile'
							}
							fontWeight="800"
							color="$primary950"
						>
							{label}
						</Text>
						{important && <Text color="$secondary500">*</Text>}
					</HStack>
				) : null}

				<Box marginTop={label && windowWidth > 480 ? 10 : 2}>
					{textArea ? (
						<Textarea height={heightTextArea} borderRadius={12}>
							<TextareaInput
								placeholder={placeholder}
								bg="#f7f7fc"
								fontFamily="$fontContent"
								color="$primary950"
								padding={windowWidth > 480 ? 10 : 5}
								fontWeight="500"
								
								onContextMenu={(e) => {
									if (disableRightClick) {
										e.preventDefault(); 
										showToast({
											message : 'Copy/Paste Not Allowed',
											type : 'error'

										})
										// Prevent right-click context menu
									}
								}}
								onKeyPress={(e) => {
									if (disableCopyPaste) {
										if (e.nativeEvent.key === 'c' && (e.nativeEvent.ctrlKey || e.nativeEvent.metaKey)) {
											showToast({
												message : 'Copy/Paste Not Allowed',
												type : 'error'
											})
											// 
											e.preventDefault(); // Prevent copy
										}
										if (e.nativeEvent.key === 'v' && (e.nativeEvent.ctrlKey || e.nativeEvent.metaKey)) {
											
											// 
											e.preventDefault(); // Prevent paste
											showToast({
												message : 'Copy/Paste Not Allowed',
												type : 'error'
											})
										}
									}
								}}
								fontSize={
									windowWidth > 480
										? 12
										: '$fontContentMobile'
								}
								value={value}
								onChangeText={
									maxWordCount
										? handleTextChange
										: setValueChange
								}
								borderColor="#d1d1e8"
								borderRadius={12}
							/>
						</Textarea>
					) : (
						<Input
							height={height ? height + 20 : 45}
							isDisabled={disabled}
							bg="#f7f7fc"
							borderRadius={12}
							borderColor="#d1d1e8"
							alignItems="center"
							padding={windowWidth > 480 ? 5 : 3}
						>
							{type === 'search' && <InputIcon as={SearchIcon} />}
							<InputField
								placeholder={placeholder}
								type="text"
								
								onPaste={(e) => e.preventDefault()} // Prevent paste
								onCopy={(e) => e.preventDefault()}
								onContextMenu={(e) => {
									if (disableRightClick) {
										e.preventDefault(); 
										
										showToast({
											message : 'Copy/Paste Not Allowed',
											type : 'error'

										})// Prevent right-click context menu
									}
								}}
								// Conditionally disable copy and paste
								onKeyPress={(e) => {
									if (disableCopyPaste) {
										if (e.nativeEvent.key === 'c' && (e.nativeEvent.ctrlKey || e.nativeEvent.metaKey)) {
											e.preventDefault(); // Prevent copy
											showToast({
												message : 'Copy/Paste Not Allowed',
												type : 'error'
	
											})
										}
										if (e.nativeEvent.key === 'v' && (e.nativeEvent.ctrlKey || e.nativeEvent.metaKey)) {
											e.preventDefault(); // Prevent paste
											showToast({
												message : 'Copy/Paste Not Allowed',
												type : 'error'
	
											})
										}
									}
								}}
								fontFamily="$fontContent"
								color="$primary950"
								fontWeight="500"
								fontSize={
									windowWidth > 480
										? 12
										: '$fontContentMobile'
								}
								lineHeight={windowWidth > 480 ? 20 : 15}
								value={value}
								height={height}
								onFocus={onFocus}
								autoFocus={autoFocus}
								onBlur={onBlur}
								selectTextOnFocus={selectTextOnFocus}
								editable={editable}
								numberOfLines={numberOfLines}
								onChangeText={
									maxWordCount
										? (text) => handleTextChange(text)
										: setValueChange
								}
							/>
							{type === 'search' && value?.length > 0 && (
								<Pressable
									onPress={() => {
										setValueChange('')
									}}
									marginRight={5}
								>
									<InputIcon as={CloseIcon} />
								</Pressable>
							)}
						</Input>
					)}

					{maxWordCount !== null &&
						maxWordCount !== undefined &&
						maxWordCount > 0 && (
							<Box>
								<Text
									fontFamily="$fontHeading"
									fontStyle="italic"
									fontSize={10}
									color="$secondary300"
									fontWeight="600"
								>
									*max word count left:{' '}
									{maxWordCount - wordCount}
								</Text>
							</Box>
						)}

					{minWordCount !== null &&
						minWordCount !== undefined &&
						minWordCount > 0 && (
							<Box>
								<Text
									fontFamily="$fontHeading"
									fontStyle="italic"
									fontSize={10}
									color="$secondary300"
									fontWeight="600"
								>
									{numberInput
										? '*min digits left: '
										: '*min word count left: '}
									{minWordCount}
								</Text>
							</Box>
						)}
				</Box>
			</VStack>
		</>
	)
}

export default LabelWithInputComponent
