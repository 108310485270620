import React from 'react'
import { Dimensions } from 'react-native'
import { Box, Divider, Heading, Link, Pressable, Text, HStack } from '@gluestack-ui/themed'
import AvatarComponent from './AvatarComponent'
import ButtonComponent from './ButtonComponent'
import Moment from 'react-moment'
import useUserStore from '../Userstore/UserStore'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
import Hidiheader from '../components/Hidiheader'
const ConvoOnhidi = ({
    Props, 
    onPress,
    onPressEdit,
    routeParams,
    screen,
    navigation,
    inModal,
    props

}) => {
    const UserId  = useUserStore((state) => state.userId)
   
    let logoUrl =
		'https://logo.clearbit.com/' +
		Props?.CompanyProfile?.name.split(' ')[0].trim() +
		'.com'
  return (
    <Box
    w={'100%'}
    bg="white"
    borderWidth={2}
    borderColor='$borderDark0'
    marginVertical={10}
    p={windowWidth > 480 ? 20 : 10}
    borderRadius={8}
    >
        <HStack alignItems={'center'} gap={5}>
			
					<AvatarComponent
						imgSource={logoUrl }
						height={ 45}
						width={ 45 }
						name={props?.name}
            borderRadius = {10}
					/>
			
				<Box justifyContent="center">
					<Pressable
					>
						<Text
							lineHeight={18}
							fontSize={
								windowWidth > 480
									? '$fontContent'
									: '$fontContentMobile'
							}
							fontFamily="$fontHeading"
							color="$primary500"
							fontWeight="700"
						>
							{Props?.CompanyProfile?.name}
						</Text>
					</Pressable>
          <Text 
           color = {'#D1D1D1'}
          >
					{''}
					<Moment 
                     color = {'#D1D1D1'}
                    fromNow ago interval={30000} element={Text}>
						{Props?.createdAt}
					</Moment>{' '}
					ago
				</Text>
				</Box>
			</HStack>
      <Heading paddingTop={10}
      fontFamily="$fontHeading"
				fontSize={windowWidth > 480 ? '$fontSize16' : '$fontSize16'}
				fontWeight="700">
                    {Props.Question}</Heading>
    
    
      {!Props.Answer && 
      Props.UserId == UserId 
      && 
       <ButtonComponent
       marginVertical={10}
       disabled={false}
       text={'Answer'}
       onPress={onPress}
       width={
           windowWidth > 480
               ? 200
               : 100
       }
   />
      }
      { Props?.Answer &&
      <Box
        bg='$backgroundGray'
        borderRadius={6}
        padding={15}
        marginTop={10}
        borderWidth={2}
        direction='column'
        borderColor='$borderDark0'>
            
          
            

    <Box flexDirection='row' justifyContent='space-between'>
        <Box>
        <Hidiheader
                        params={routeParams}
                        navigation={navigation}
                        props={
                            inModal
                                ? profile
                                : screen === 'edit'
                                ? profile
                                : props?.profile
                        }
                    />
            <Text 
            color = {'#D1D1D1'}
            marginLeft={40}>
                        <Moment 
                        color = {'#D1D1D1'}
                        fromNow ago interval={30000} element={Text}>
                            {Props?.updatedAt}
                        </Moment>{' '}
                        ago
                    </Text>
                    
        
        </Box>
        {
        Props.UserId == UserId && 
            <Pressable
      width={60}
      height={35}
      alignContent='center'
      alignItems='center'
      justifyContent='center'
      bg ={'D1D1D1'}
      borderRadius={6}
      borderWidth={2}
      padding={5}
      borderColor='lightgrey'
      zIndex = {10}
      // paddingHorizontal={10}
      onPress={onPressEdit}
      // position='absolute'
      right={10}>

            <Text
            // paddingTop={10}
            fontFamily="$fontHeading"
            fontSize={windowWidth > 480 ? '$fontSize14' : '$fontSize14'}
            fontWeight="700">
            Edit
            </Text>
            </Pressable>    
        }
       
    </Box>   
    
     
     
    <Text
        paddingTop={10}
        fontFamily="$fontHeading"
        fontSize={windowWidth > 480 ? '$fontSize14' : '$fontSize14'}
        fontWeight="500">
        {Props.Answer}
    </Text>
      </Box>
      
     
      }
     
    </Box>
  )
}

export default ConvoOnhidi