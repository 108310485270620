import { Dimensions } from 'react-native'
import React from 'react'
import { Box } from '@gluestack-ui/themed'
import { useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
// import { listrecommendedprofieByPreferencesByAcceptance } from '../../graphql/queries'
import { listrecommendedprofieByPreferencesByAcceptance } from '../../graphql/custom-queries'
import {
	onDeleteShortlist,
	onDeleteRejected,
} from '../../graphql/subscriptions'
import CandidateViewComponent from './components/CandidateViewComponent'

export default function RecomendedScreen({
	preferenceId,
	rolename,
	companyName,
	navigation,
	JobPostData,
	SelectedTab,
	recommendedData,
	prefferedSalary,
	minExperience,
	maxExpeirence,
	companyQrewId 
}) {
	
	const [selectedtab, setselectedtab] = useState('Recommendations')
	
	const [loading, setloading] = useState(false)

	// const initSubscriptions = async () => {
	// 	let _subscriptions = []
	// 	const subscription = API.graphql(
	// 		graphqlOperation(onDeleteShortlist, onDeleteRejected)
	// 	).subscribe({
	// 		next: ({ provider, value }) => {
	// 			getprofiles()

	// 			// setIsParticipant(true);
	// 		},
	// 		error: (error) => {
	// 			// setConnectionStatus(false);
	// 			console.warn(error.error)
	// 		},
	// 	})

	// 	return
	// }
	// async function getprofiles() {
	// 	await API.graphql({
	// 		query: listrecommendedprofieByPreferencesByAcceptance,
	// 		variables: {
	// 			preferencesId: preferenceId,
	// 			sortDirection: 'DESC',
	// 			// acceptanceStatus: { eq: 'recommend' },
	// 		},
	// 	})
	// 		.then((res) => {
	// 			// getJobApplications()
	// 			setProfilelist(
	// 				res.data.listrecommendedprofieByPreferencesByAcceptance
	// 					.items
	// 			)

	// 			setloading(false)
	// 		})
	// 		.catch((err) => {
	// 			console.log(err)
	// 			setloading(false)
	// 		})
	// }

	useEffect(() => {
		// getprofiles()
	}, [])
	// useEffect(() => {
	// 	initSubscriptions()
	// })

	return (
		<Box>
			<CandidateViewComponent
				loading={loading}
				headingText={'Recommended'}
				companyname={companyName}
				navigation={navigation}
				data={recommendedData}
				rolename={rolename}
				onPressBtn={null}
				PreferenceId={preferenceId}
				JobPostData={JobPostData}
				prefferedSalary = {prefferedSalary}
				minExperience = {minExperience}
				maxExpeirence  ={maxExpeirence}
				companyQrewId ={companyQrewId }
				// btnText={'Shortlist'}
				listEmptyText={'No recommended candidates yet!'}
				fetchMoreData={ () => console.log('fetchmoew')}
			/>
		</Box>
	)
}
