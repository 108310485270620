import React, { useState, useRef } from 'react'
import { Dimensions } from 'react-native'
import {
	Box,
	Divider,
	FlatList,
	HStack,
	Text,
	Pressable,
	EditIcon,
	CloseIcon,
	CheckIcon,
	ClockIcon,
} from '@gluestack-ui/themed'


import { createObjectiveEdit, listQrewObjectivesByQrewId,
	updateQrewObjectives, } from '../../HeplerFunctions/CandidateQrewHelperQuery'
import { useEffect } from 'react'
import ListEmptyComponent from '../../qrewComponents/ListEmptyComponent'

import SpinnerComponent from '../SpinnerComponent'
import LabelWithInputComponent from '../LabelWithInputComponent'
import ModalComponent from '../ModalComponent'
import ButtonComponent from '../ButtonComponent'
import useUserStore from '../../Userstore/UserStore'
// import SideViewOpenerAnimated from '../../Contractors/components/SideViewOpenerAnimated'

import CandidateQrewObjectiveKeyResultsList from './CandidateQrewObjectiveKeyResultsList'


const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const CandidateQrewObjectivesList = ({ qrewId }) => {
	const [objectiveDATA, setObjectiveDATA] = useState([])
	const companyProfileId = useUserStore((state) => state.companyProfileId)
	const [loadingObjectives, setLoadingObjectives] = useState(false)
	const [showEditButton, setShowEditButton] = useState('')
	const [showEditInput, setShowEditInput] = useState('')
	const [editObjectiveModal, setEditObjectiveModal] = useState(false)
	const [editInput, setEditInput] = useState('')
	const [loadingConfirmEdit, setLoadingConfirmEdit] = useState(false)
	const [showSideView, setShowSideView] = useState(null)
	const [selectedObjective, setSelectedObjective] = useState(null)
	const [selectedObjectiveTitle, setSelectedObjectiveTitle] = useState('')
	
	const listObjectives = async () => {
		setLoadingObjectives(true)
		const response = await listQrewObjectivesByQrewId(qrewId)
		setObjectiveDATA(response)
		setLoadingObjectives(false)
	}
	
	const updateObjective = async (item) => {
		try {
			setLoadingConfirmEdit(true)
			const objectiveInput = {
				id: item.id,
				title: editInput,
			}

			const historyInput = {
				objectiveId: item.id,
				prevTitle: item.title,
				createdAt: item.updatedAt,
				editedbyCompanyUserProfileId: companyProfileId,
			}

			const resEdit = await createObjectiveEdit(historyInput)

			const res = await updateQrewObjectives(objectiveInput)
		} catch (err) {
			console.log('Error updating objective', err)
		} finally {
			setEditObjectiveModal(false)
			setLoadingConfirmEdit(false)
			setShowEditInput('')
			listObjectives()
		}
	}

	useEffect(() => {
		listObjectives()
	}, [qrewId])

	const renderObjectivesAccordion = ({ item }) => {
		
		const renderTopOfAccordion = () => {
			return (
				<HStack mt={10} gap={15} alignItems="center">
					<Box gap={5}>
						<Text
							fontFamily="$fontHeading"
							fontSize={15}
							fontWeight="700"
							color="black"
						>
						Objective Key Results Desired 
						</Text>
						<Text
							fontFamily="$fontHeading"
							fontSize={14}
							fontWeight="500"
							color="black"
						>
							{item?.ObjectivekeyResults?.items?.length}
						</Text>
					</Box>
					
				</HStack>
			)
		}
		const renderAccordionItem = () => {
			return (
				<Box>
					{/* <InitiativesList
						initiativesDATA={item?.initiatives?.items}
					/> */}
					<CandidateQrewObjectiveKeyResultsList 
				objectiveId={item.id}
				/>
				</Box>
			)
		}

		const renderConfirmationModal = () => {
			return (
				<Box p={windowWidth > 480 ? 30 : 15} gap={50}>
					<Text
						fontSize={18}
						fontWeight="600"
						fontFamily="$fontHeading"
					>
						Are you sure you want to edit this objective?
					</Text>
					<HStack justifyContent="flex-end" gap={10}>
						<ButtonComponent
							text={'Cancel'}
							color={'$secondary500'}
							borderColor={'$secondary500'}
							onPress={() => {
								setEditObjectiveModal(false)
								setShowEditInput('')
							}}
							width={120}
						/>
						<ButtonComponent
							text={'Confirm'}
							onPress={() => {
								updateObjective(item)
							}}
							width={120}
							loading={loadingConfirmEdit}
						/>
					</HStack>
				</Box>
			)
		}

		const renderTitle = () => {
			return (
				<Box
					flex={1}
					mb={5}
					borderColor="#E5E8EB"
					borderBottomWidth={1}
				>
					{showEditInput === item.id ? (
						<HStack alignItems="center" gap={15} mr={20}>
							<ModalComponent
								isOpen={editObjectiveModal}
								onClose={() => {
									setEditObjectiveModal(false)
								}}
								renderBody={renderConfirmationModal}
							/>
							<Box flex={1}>
								<LabelWithInputComponent
									value={editInput}
									setValueChange={setEditInput}
								/>
							</Box>
							<Pressable
								p={5}
								bg="$secondary500"
								borderRadius={30}
								onPress={() => setShowEditInput('')}
							>
								<CloseIcon color={'white'} size={'lg'} />
							</Pressable>
							<Pressable
								p={5}
								bg="$green400"
								borderRadius={30}
								onPress={() => {
									setEditObjectiveModal(true)
								}}
							>
								<CheckIcon color={'white'} size={'lg'} />
							</Pressable>
						</HStack>
					) : (
						<div
							onMouseEnter={() => {
								setShowEditButton(item.id)
							}}
							onMouseLeave={() => {
								setShowEditButton('')
							}}
						>
							<HStack alignItems="center" gap={20}>
								
								{showEditButton === item.id && (
									<>
										<Pressable
											onPress={() => {
												setEditInput(item.title)
												setShowEditInput(item.id)
											}}
										>
											<EditIcon />
										</Pressable>
										<Pressable
											onPress={() => {
												setShowSideView(
													'ObjectiveHistory'
												)
												setSelectedObjective(item)

												setSelectedObjectiveTitle(
													item.title
												)
											}}
										>
											<ClockIcon />
										</Pressable>
									</>
								)}
							</HStack>
						</div>
					)}
				</Box>
			)
		}

		return (
			
			<Box my={5} bg="white">
				
				{/* <AccordionComponent
					renderItem={renderAccordionItem}
					renderTop={renderTopOfAccordion}
					renderTitle={renderTitle}
					toggleActive={!showEditInput}
				/> */}
				<Box
					flex={1}
				
					borderColor='#BCDAE0'
					borderBottomWidth={1}
					// bgColor='#BCDAE0'

									>
				
						
							<HStack alignItems="center" gap={20} marginLeft={10}>
							<Box bgColor='#62B0BA' padding={5} paddingHorizontal={20} borderRadius={15}>
								<Text fontFamily="$fontHeading"
									fontSize={12}
									// lineHeight={60}
									alignSelf='center'
									fontWeight="800"
									
									color={'white'} >
									OBJECTIVE
								</Text>
								</Box>
								
								<Text
									fontFamily="$fontHeading"
									fontSize={15}
									lineHeight={60}
									fontWeight="800"
									color={'black'}
								>
									{item.title}
								</Text>
								{showEditButton === item.id && (
									<>
										<Pressable
											onPress={() => {
												setEditInput(item.title)
												setShowEditInput(item.id)
											}}
										>
											<EditIcon />
										</Pressable>
										<Pressable
											onPress={() => {
												setShowSideView(
													'ObjectiveHistory'
												)
												setSelectedObjective(item)

												setSelectedObjectiveTitle(
													item.title
												)
											}}
										>
											<ClockIcon />
										</Pressable>
									</>
								)}
							</HStack>
						
				
				</Box>
			
					<CandidateQrewObjectiveKeyResultsList  
				objectiveId={item.id}
				/>
				
			</Box>
		
			
		)
	}

	return (
		<Box >
			{showSideView && (
				<Box
					position="fixed"
					bg="black"
					top={0}
					bottom={0}
					opacity={0.4}
					right={0}
					h={windowHeight}
					w={windowWidth}
					zIndex={10000}
				/>
			)}
			{/* <SideViewOpenerAnimated
				showSideView={showSideView}
				setShowSideView={setShowSideView}
				objectiveHistory={selectedObjective}
				setObjectiveHistory={setSelectedObjective}
				objectiveTitle={selectedObjectiveTitle}
			/> */}
			{loadingObjectives ? (
				<Box
					h={200}
					alignItems="center"
					justifyContent="center"
					bg="white"
				>
					<SpinnerComponent />
				</Box>
			) : (
				<FlatList
					data={objectiveDATA}
					flex={1}
					flexShrink={true}
					
					renderItem={renderObjectivesAccordion}
					keyExtractor={(item) => item.id.toString()}
					// showsVerticalScrollIndicator = {false}
					// ListFooterComponent={() => {
					// 	return(
					// 		<Box
								
								
					// 			alignItems="center"
					// 			justifyContent="center"
					// 			marginTop={'12%'}
					// 		>
					// 			<ListEmptyComponent
					// 				text={'No Other Objectives Yet'}
					// 				subText={
					// 					'If there are any Objectives, they will appear here.'
					// 				}
					// 			/>
								
					// 		</Box>
					// 	)
					// }}
					ListEmptyComponent={() => {
						return (
							<Box
								bg="white"
								h={200}
								alignItems="center"
								justifyContent="center"
							>
								<ListEmptyComponent
									text={'No Objectives Yet'}
									subText={
										'If there are any Objectives, they will appear here.'
									}
								/>
								
							</Box>
						)
					}}
				/>
			)}
		</Box>
	)
}

export default CandidateQrewObjectivesList
