import { Dimensions } from 'react-native'
import { Box, Heading, Image, Text } from '@gluestack-ui/themed'

import React, { useEffect, useState } from 'react'

import AsyncStorage from '@react-native-async-storage/async-storage'
import { DekstopHeader } from '../header/Header'
import UserAvatar from 'react-native-user-avatar'
import useUserStore from '../../Userstore/UserStore'
import { getNewprofile } from '../../graphql/queries'
import {
	createCandidatePreferences,
	createProfile,
	updateCandidatePreferences,
	updateNewprofile,
} from '../../graphql/mutations'
import { API, Storage, Auth } from 'aws-amplify'
import { CreateEvents } from '../../HeplerFunctions/EventTableMutations'
import { createNewprofile } from '../../graphql/mutations'
import DropdownPreference from '../../components/DropdownPreference'
import useImageFileLink from '../../customhook/useImageLinkhook'
import { isBrowser, isMobile, browserName} from 'react-device-detect';
import { useDropzone } from 'react-dropzone'
import { showToast } from '../../components/ErrorToast'
import UploadComponent from '../UploadComponent'
import AvatarComponent from '../AvatarComponent'
import LabelWithInputComponent from '../LabelWithInputComponent'
import DropdownComponent from '../DropdownComponent'
import ButtonComponent from '../ButtonComponent'
import SpinnerComponent from '../SpinnerComponent'
import UploadResume from '../../newScreens/onboardingFlow/UploadResume'
import { getJobTypeFromJobPostId } from '../../graphql/custom-queries'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

export default function EditProfile({
	handleProceed,
	handleGoBack,
	responseLambda,
	mapData,
	screen,
	hideModal,
	hiddenHeading,
	navigation,
}) {
	// const [user, setUser] = useState('');

	const [loading, setLoading] = useState(false)
	const [loadingPage, setLoadingPage] = useState(false)
	const setUserProfile = useUserStore((state) => state.setUserProfile)
	const setDomain = useUserStore((state) => state.setDomain)

	const savedUrl = useUserStore((state) => state.savedurl)
	const [fullName, setFullName] = useState('')
	const [email, setEmail] = useState('')
	const [bio, setBio] = useState('')
	const [expertise, setExpertise] = useState('')
	// const [location, setLocation] = useState('')
	const [updatedAttributes, setUpdatedAttributes] = useState({})
	const ProfileId = useUserStore((state) => state.profileId)
	const userId = useUserStore((state) => state.userId)
	const [file, setFile] = useState()
	const [key, setKey] = useState('')
	const [filelocal, setFilelocal] = useState(null)
	const [image, setImage] = useState(null)
	const [fileKey, setfilekey] = useState('')
	const setImagekeyProfile = useUserStore((state) => state.setImagekey)
	const fileLink = useImageFileLink(fileKey)
	const [phone, setPhone] = useState('')
	const [typeofwork, setTypeofwork] = useState('')
	const [experience, setExperience] = useState('')
	const [filledPercentage, setFilledPercentage] = useState(40)
	const [displayText, setDisplayText] = useState(
		'Your resume is being processed'
	)
	const [resumeUploaded, setResumeUploaded] = useState(false)

	const checkScreen = useUserStore((state) => state.checkScreen)
	const signout = useUserStore((state) => state.signOut)

	const textOptions = [
		'Your resume is being processed',
		'Extracting your education, experiences and certifications',
		'Crunching data!',
		`Getting your profile ready`,
		'Almost there!',
		'Sit back and relax!',
		'Almost there!',
	]

	const [wordCount, setWordCount] = useState(0)

	const [userpreferences, setUserpreferences] = useState({
		experienceList: '',
		domain: '',
		userpreferenceId: '',
	})

	const [fileName, setFileName] = useState('')

	const setProfileId = useUserStore((state) => state.setProfileId)
	const user = useUserStore((state) => state.user)
	const userEmail = useUserStore((state) => state.userEmail)

	const domain = [
		{ label: 'Full Stack', value: 'fullstack' },
		{ label: 'Cyber Security', value: 'cybersecurity' },
		{ label: 'HR', value: 'hr' },
		{ label: 'Content', value: 'content' },
		{ label: 'Ai and Blockchain', value: 'ai' },
		{ label: 'Digital Marketing', value: 'digitalmarketing' },

	]

	const DropdownExperienceList = [
		{ label: 'Fresher', value: 'Fresher' },
		{ label: '1 Year', value: '1 Year' },
		{ label: '2 Years', value: '2 Years' },
		{ label: '3 Years', value: '3 Years' },
		{ label: '4 Years', value: '4 Years' },
		{ label: '5 Years', value: '5 Years' },
		{ label: '6 Years', value: '6 Years' },
		{ label: '7 Years', value: '7 Years' },
		{ label: '8 Years', value: '8 Years' },
		{ label: '9 Years', value: '9 Years' },
		{ label: '10 Years', value: '10 Years' },
		{ label: '10+ Years', value: '10+ Years' },
	]

	const isOpportunities = savedUrl.includes('opportunities')
	const isJobLink = savedUrl.includes('JobDetails')

	const [jobType, setJobType] = useState(null)

	function getJobPostIdFromUrl(url) {
		const params = url?.split('?')[1]?.split('&')
		if (params?.length > 0) {
			for (const param of params) {
				const [key, value] = param.split('=')
				if (key === 'jobPostId') {
					return value
				}
			}
		}
		return null
	}
	const jobPostId = getJobPostIdFromUrl(savedUrl)

	const fetchJobType = async () => {
		const res = await API.graphql({
			query: getJobTypeFromJobPostId,
			variables: { id: jobPostId },
		})
		setJobType(res?.data?.getJobPost?.CompanyPreference?.JobType)
	}

	useEffect(() => {
		if (savedUrl) {
			fetchJobType()
		}
	}, [savedUrl])

	const navToFeed = () => {
		navigation.push('Feed')
	}
	const CreateEventProfileCreation = async (item) => {
		
	
		const EventAttributesJSOn = {
			"UserId" :  userId,
			"UserProfileId" : item,
			"IsMobile" : isMobile
		  };
		try {
			
			

			const Input = {
				EventNameTableKeyId: item,
				EventName : "CreateProfileStep1",
				EventAttributes : JSON.stringify(EventAttributesJSOn)

	
			}

			const res = await CreateEvents(Input)
			console.log(res)

		
		} catch (err) {
			console.log('Error updating objective', err)
		} 
		
	}
	// const Createpreferences = ({ id }) => {
	// 	const CreateUserInput = {
	// 		profileId: id,
	// 		jobType: isJobLink && jobType ? jobType : 'FullTime',
	// 		userDomain: userpreferences.domain,
	// 	}
	// 	API.graphql({
	// 		query: createCandidatePreferences,
	// 		variables: { input: CreateUserInput },
	// 	}).catch((err) => {
	// 		console.log('Createpreferences', err)
	// 	})
	// }

	// const UpdateCandidatePreferences = ({ id }) => {
	// 	const CreateUserInput = {
	// 		id: id,
	// 		jobType: isJobLink && jobType ? jobType : 'FullTime',
	// 		userDomain: userpreferences.domain,
	// 	}
	// 	API.graphql({
	// 		query: updateCandidatePreferences,
	// 		variables: { input: CreateUserInput },
	// 	}).catch((err) => {
	// 		console.log('UpdateCandidatePreferences', err)
	// 	})
	// }

	async function pathToImageFile() {
		setLoading(true)
		try {
			await Storage.put(key, file, {
				level: 'public',
				contentType: 'image/jpeg',
			}).then((res) => {
				getimage(res)
				setImagekeyProfile(res.key)
			})
			{
				screen !== 'onboarding' && hideModal()
			}
		} catch (err) {
			{
				screen !== 'onboarding' && hideModal()
			}
			console.log('pathToImageFile', err)
		}
	}

	async function getimage(res) {
		await Storage.get(key).then((uri) => {
			updateProfileandImage(uri)
			setImage(uri)
		})
	}

	function handleChange(e) {
		const selectedFile =
			e?.target?.files[0] == undefined ? e[0] : e?.target?.files[0]
		setFilelocal(URL.createObjectURL(selectedFile))
		setFile(selectedFile)
		setKey(selectedFile.lastModified)
		setFileName(selectedFile.name)
	}

	async function getProfile() {
		setLoadingPage(true)
		await API.graphql({
			query: getNewprofile,
			// authMode: 'API_KEY',
			variables: {
				id: ProfileId,
			},
		})
			.then((res) => {
				setUserProfile(res.data.getNewprofile)
				setFullName(res.data.getNewprofile?.name)
				setBio(res.data.getNewprofile?.about)
				setExpertise(res.data.getNewprofile?.expertise)
				setPhone(res.data.getNewprofile?.phone)
				setImage(res.data.getNewprofile?.image)
				setfilekey(res.data.getNewprofile?.imagekey)

				// setLocation(res.data.getNewprofile?.location)
				setUserpreferences({
					// workTypeList: res.data.getNewprofile?.typeofwork,
					experienceList: res.data.getNewprofile?.experience,
					userpreferenceId:
						res?.data?.getNewprofile?.CandidatePreferences?.items[0]
							?.id,
					domain: res?.data?.getNewprofile?.CandidatePreferences
						?.items[0]?.userDomain,
				})

				setLoading(false)
				setLoadingPage(false)
			})
			.catch((err) => {
				if (ProfileId) {
					console.log(err)
					window.alert(
						'pls check yout internet connection or url is not correct'
					)
				}
			})
	}

	async function CreateProfile() {
		setLoading(true)

		const CreateUserInput = {
			name: fullName,
			about: bio,
			expertise: expertise,
			UserId: userId,
			Email: userEmail,
			// location: location,
			// typeofwork: userpreferences.workTypeList,
			phone: phone,
			experience: userpreferences.experienceList,
		}
		API.graphql({
			query: createNewprofile,
			variables: { input: CreateUserInput },
		})
			.then((res) => {
				setProfileId(res.data.createNewprofile.id)
				// !userpreferences.userpreferenceId
				// 	? userpreferences.domain &&
				// 	  Createpreferences({ id: res.data.createNewprofile.id })
				// 	: UpdateCandidatePreferences({
				// 			id: userpreferences.userpreferenceId,
				// 	  })
				handleProceed && handleProceed()
				setLoading(false)
				CreateEventProfileCreation(res.data.createNewprofile.id)
			})
			.catch((err) => {
				console.log('CreateProfile', err)
			})
	}

	async function updateProfile() {
		if (ProfileId) {
			setLoading(true)
			//const _updatedAttributes = JSON.parse(JSON.stringify(updatedAttributes));
			// console.log(_updatedAttributes);
			const CreateUserInput = {
				id: ProfileId,
				name: fullName,
				about: bio,
				expertise: expertise,
				// location: location,
				// typeofwork: userpreferences.workTypeList,
				phone: phone,
				experience: userpreferences.experienceList,

				// htmloutput: output,
			}
			API.graphql({
				query: updateNewprofile,
				variables: { input: CreateUserInput },
			})
				.then((res) => {
					setProfileId(res.data.updateNewprofile.id)
					// !userpreferences.userpreferenceId
					// 	? userpreferences.domain &&
					// 	  Createpreferences({
					// 			id: res.data.updateNewprofile.id,
					// 	  })
					// 	: UpdateCandidatePreferences({
					// 			id: userpreferences.userpreferenceId,
					// 	  })
					setUserProfile(res.data.updateNewprofile)
					setLoading(false)
					{
						screen !== 'onboarding' && hideModal()
					}
					{
						screen === 'onboarding' && handleProceed()
					}
				})
				.catch((err) => {
					console.log('updateProfile', err)
				})
		} else {
			CreateProfile()
		}
	}

	async function CreateProfileandImage(res) {
		setLoading(true)

		const CreateUserInput = {
			UserId: userId,
			name: fullName,
			about: bio,
			expertise: expertise,
			Email: userEmail,
			// location: location,
			imagekey: key,
			phone: phone,
			// typeofwork: userpreferences.workTypeList,
			experience: userpreferences.experienceList,
			// htmloutput: output,
		}
		API.graphql({
			query: createNewprofile,
			variables: { input: CreateUserInput },
		})
			.then((res) => {
				setProfileId(res.data.createNewprofile.id)
				// !userpreferences.userpreferenceId
				// 	? userpreferences.domain &&
				// 	  Createpreferences({ id: res.data.createNewprofile.id })
				// 	: UpdateCandidatePreferences({
				// 			id: userpreferences.userpreferenceId,
				// 	  })
				handleProceed && handleProceed()
				setLoading(false)
				setTimeout(() => {
					CreateEventProfileCreation(res.data.createNewprofile.id)
							}, 3000)
			})
			.catch((err) => {
				console.log('CreateProfileandImage', err)
			})
	}

	async function updateProfileandImage(res) {
		if (ProfileId) {
			setLoading(true)
			const CreateUserInput = {
				id: ProfileId,
				name: fullName,
				about: bio,
				expertise: expertise,
				// location: location,
				imagekey: key,
				phone: phone,
				// typeofwork: userpreferences.workTypeList,
				experience: userpreferences.experienceList,
				// htmloutput: output,
			}
			API.graphql({
				query: updateNewprofile,
				variables: { input: CreateUserInput },
			})
				.then((res) => {
					setLoading(false)
					setUserProfile(res.data.updateNewprofile)
					// !userpreferences.userpreferenceId
					// 	? userpreferences.domain &&
					// 	  Createpreferences({
					// 			id: res.data.updateNewprofile.id,
					// 	  })
					// 	: UpdateCandidatePreferences({
					// 			id: userpreferences.userpreferenceId,
					// 	  })
					// navigation.navigate("NewHIDI", {hidiId: res.data.createNewhidi.id})
				})
				.catch((err) => {
					console.log('updateProfileandImage', err)
				})
		} else {
			CreateProfileandImage()
		}
	}

	async function uploadimageandprofile() {
		// setDomain(userpreferences.domain)
		if (
			fullName &&
			phone &&
			expertise &&
			userpreferences.experienceList 
			// userpreferences.domain
		) {
			if (key) {
				await pathToImageFile()
			} else {
				console.log('in Update Profile')
				await updateProfile()
			}
		} else {
			if (!fullName) {
				return showToast({
					message: 'You must fill your Full Name correctly',
				})
			}
			if (!expertise) {
				return showToast({
					message: 'You must fill you Designation correctly',
				})
			}
			if (!phone) {
				return showToast({
					message: 'You must fill your Phone Number correctly',
				})
			}
			if (!userpreferences.experienceList) {
				return showToast({
					message: 'You must choose your Experience in your domain',
				})
			}
			// if (!userpreferences.domain) {
			// 	return showToast({
			// 		message: 'You must choose your domain',
			// 	})
			// }
		}
	}
	const getSession = async () => {
		try {
			await Auth.currentSession()
		} catch (error) {
			console.log(error)
			signout()
		}
	}
	useEffect(() => {
		getProfile()
		if (mapData?.length !== 0) {
			setFullName(mapData?.name)
		}
		if (mapData?.length !== 0) {
			setBio(mapData?.about)
		}
		if (mapData?.length !== 0) {
			setExpertise(mapData?.experiences[0]?.designation)
		}
		if (mapData?.length !== 0) {
			setPhone(mapData?.phoneNo)
		}
		getSession()
	}, [mapData])

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: handleChange,
	})

	useEffect(() => {
		const timer = setInterval(() => {
			const newPercentage = filledPercentage + 20
			const newTextIndex =
				(textOptions.indexOf(displayText) + 1) % textOptions.length
			setDisplayText(textOptions[newTextIndex])

			if (newPercentage >= 95) {
				setFilledPercentage(95)
			} else {
				setFilledPercentage(newPercentage)
			}
		}, 8000)

		return () => clearInterval(timer)
	}, [filledPercentage, displayText])

	const countWords = (text) => {
		const words = text.trim().split(/\s+/)
		return words?.filter((word) => word !== '').length
	}

	return (
		<>
			<Box
				borderWidth={hiddenHeading ? null : 1}
				borderColor="$borderDark0"
				borderRadius={6}
				alignSelf="center"
				justifyContent="center"
				alignItems="center"
				w={'100%'}
				paddingVertical={windowWidth > 480 ? 25 : 10}
				paddingHorizontal={windowWidth > 480 ? 10 : 5}
				marginBottom={30}
				bg="$primary0"
			>
				{responseLambda ? (
					<Box
						w={'100%'}
						alignSelf="center"
						justifyContent="center"
						alignItems="center"
					>
						<Box
							h={7}
							w={'70%'}
							borderWidth={1}
							borderColor="$trueGray800"
							borderRadius={2}
							marginVertical={10}
						>
							<Box
								h={'100%'}
								bg="$primary500"
								w={`${filledPercentage}%`}
							></Box>
						</Box>
						<SpinnerComponent
							size={'small'}
							smallText={`${filledPercentage}% Progress`}
							text={displayText}
						/>
					</Box>
				) : (
					<Box
						w={'100%'}
						alignSelf="center"
						justifyContent="center"
						alignItems="center"
					>
						{hiddenHeading ? null : (
							<Box marginBottom={windowWidth > 480 ? 20 : 10}>
								<Heading
									textAlign="center"
									fontFamily="$fontHeading"
									fontSize={
										windowWidth > 480
											? '$fontHeading'
											: '$fontHeadingMobile'
									}
									fontWeight="700"
								>
									Tell us about{' '}
									<Text
										fontFamily="$fontHeading"
										fontSize={
											windowWidth > 480
												? '$fontHeading'
												: '$fontHeadingMobile'
										}
										fontWeight="700"
										color={'$primary500'}
									>
										yourself
									</Text>
								</Heading>
							</Box>
						)}

						<Box>
							{filelocal ? (
								<Box>
									<Image
										h={windowWidth > 480 ? 100 : 70}
										w={windowWidth > 480 ? 100 : 70}
										borderRadius={'$full'}
										source={{ uri: filelocal }}
										alt="profile image"
									/>
								</Box>
							) : (
								<>
									{fileLink ? (
										<Image
											h={windowWidth > 480 ? 100 : 70}
											w={windowWidth > 480 ? 100 : 70}
											borderRadius={'$full'}
											source={{ uri: fileLink }}
											alt="profile image"
										/>
									) : (
										<AvatarComponent
											name={fullName}
											imgSource={fileLink}
										/>
									)}
								</>
							)}
						</Box>
						<Box marginVertical={10}>
							<UploadComponent
								flexDirection={'row'}
								text={'Select an image'}
								height={windowWidth > 480 ? 40 : 35}
								width={windowWidth > 480 ? 170 : 160}
								handleChange={handleChange}
							/>
						</Box>
						<Box w={windowWidth > 480 ? '80%' : '95%'}>
							<LabelWithInputComponent
								label={'Name'}
								placeholder={'Full Name'}
								setValueChange={setFullName}
								important={true}
								value={fullName}
							/>
							<LabelWithInputComponent
								label={'Designation'}
								placeholder={'Designation'}
								important={true}
								setValueChange={setExpertise}
								value={expertise}
							/>
							{/* <DropdownComponent
								label={'Domain:'}
								placeholder={'Select your preferred domain'}
								userpreferences={userpreferences}
								setUserPreferences={setUserpreferences}
								property={'domain'}
								data={domain}
								important={true}
							/> */}
							<DropdownComponent
								label={'Experience'}
								placeholder={'Select Experience'}
								data={DropdownExperienceList}
								property={'experienceList'}
								userpreferences={userpreferences}
								setUserPreferences={setUserpreferences}
								important={true}
							/>
							{/* <LabelWithInputComponent
								label={'Location'}
								placeholder={'Current Location'}
								setValueChange={setLocation}
								value={location}
							/> */}
							{/* <DropdownComponent
								label={'Mode of Work'}
								placeholder={'Current Mode of Work'}
								property={'workTypeList'}
								data={DropdownworkTypeListCommon}
								userpreferences={userpreferences}
								setUserPreferences={setUserpreferences}
							/> */}
							<LabelWithInputComponent
								label={'Phone'}
								important={true}
								placeholder={'Phone Number'}
								setValueChange={setPhone}
								value={phone}
							/>
							<LabelWithInputComponent
								label={'Email'}
								placeholder={'Email Address'}
								value={userEmail}
								selectTextOnFocus={false}
								editable={false}
							/>
							{/* <LabelWithInputComponent
								textArea={true}
								label={'About'}
								placeholder={
									'Write a short paragraph for employers to see.'
								}
								heightTextArea={100}
								value={bio}
								setValueChange={(text) => {
									if (countWords(text) <= 40) {
										setBio(text)
										setWordCount(countWords(text))
									}
								}}
							/> */}

							{checkScreen === 'Onboarding' && (
								<Box
									marginVertical={10}
									borderWidth={1}
									py={20}
									borderColor="$borderDark0"
									borderRadius={6}
								>
									<UploadResume
										profileScreen={true}
										screen={'ResumeView'}
										setResumeUploaded={setResumeUploaded}
									/>
								</Box>
							)}
						</Box>
						<Box
							flexDirection={
								windowWidth > 650 ? 'row' : 'column-reverse'
							}
							justifyContent={'space-between'}
							w={windowWidth > 480 ? '80%' : '95%'}
						>
							{screen === 'onboarding' ? (
								<Box> </Box>
							) : (
								<Box> </Box>
							)}

							<ButtonComponent
								loading={loading}
								disabled={
									checkScreen === 'Onboarding'
										? !resumeUploaded
										: false
								}
								onPress={() => {
									uploadimageandprofile()
								}}
								text={
									screen === 'onboarding'
										? 'Save and Proceed'
										: 'Save'
								}
								marginVertical={windowWidth > 650 ? 25 : 5}
								width={
									windowWidth > 650
										? screen === 'onboarding'
											? 210
											: 100
										: '100%'
								}
							/>
						</Box>
					</Box>
				)}
			</Box>
		</>
	)
}
