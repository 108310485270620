import React, { useEffect, useState } from 'react'
import {
	Box,
	Modal,
	ModalBackdrop,
	ModalHeader,
	ModalBody,
	Text,
	ModalContent,
	Divider,
	HStack,
	Progress,
	ProgressFilledTrack,
} from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'
import SharePeers from './SharePeers'
import ShareHack from './ShareHack'
import ShareProjectLink from './ShareProjectLink'
import ShareExperience from './ShareExperience'
import useUserStore from '../../../Userstore/UserStore'
import { API } from 'aws-amplify'
import {
	createCapabilityPoints,
	updateCapabilityPoints,
	createChallenges,
	updateChallenges,
	createNewhidi,
	updateNewhidi,
	createJoinHidisAndCapabilities,
} from '../../../graphql/custom-mutations'
import { getNewhidi } from '../../../graphql/custom-queries'
import { showToast } from '../../../components/ErrorToast'
import TotalPointsOneCapability from '../../../newComponents/pointsComponents/TotalPointsOneCapability'
import CongratsCapabilityUnlock from '../../../newComponents/pointsComponents/CongratsCapabilityUnlock'
import { points } from '../../../newComponents/pointsComponents/pointsconfig'
import { ToastContainer } from 'react-toastify'
import PreviewHidi from './PreviewHidi'
import ShareChallenge from './ShareChallenge'
import ShareHelped from './ShareHelped'
import ShareProblem from './ShareProblem'
import ProgressBar from '../../../newComponents/ProgressBar'
import { checkAiText } from '../../../customhook/CheckAiText'
import { listWorkExperiences } from './helperFunctions'
import ShareWorklPlace from './ShareWorklPlace'

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

const ShareExperienceModal = ({
	isOpen,
	onClose,
	capabilityName,
	tab,
	setTab,
	capability,
	getCapability,
	navigation,
	jobDomain,
	closeTooltip,
}) => {
	const [hack, setHack] = useState('')
	const [projectLink, setProjectLink] = useState('')
	const [experience, setExperience] = useState('')
	const [problem, setProblem] = useState('')
	const [helped, setHelped] = useState('')
	const [peers, setPeers] = useState('')
	const [challenge, setChallenge] = useState('')
	const [congratsModal, setCongratsModal] = useState(false)
	const userId = useUserStore((state) => state.userId)
	const profileId = useUserStore((state) => state.profileId)
	const setClaimedCapabailities = useUserStore(
		(state) => state.setClaimedCapabailities
	)
	const profile = useUserStore((state) => state.profile)
	const [loadingHidi, setLoadingHidi] = useState(false)
	const totalCapabilityPoints = capability?.CapabilityConfig?.CapabilityPoints
		? capability?.CapabilityConfig?.CapabilityPoints
		: 100

	// work experience start

	const [existingWorkExperience, setExistingWorkExperience] = useState(null)
	const [workExperienceData, setWorkExperienceData] = useState(
		existingWorkExperience
	)

	const [newWorkExperience, setNewWorkExperience] = useState(null)
	const [selectedWorkExperience, setSelectedWorkExperience] = useState({})
	const [workExperienceHidi, setWorkExperienceHidi] = useState(null)

	const selectedItemsWorkExp = workExperienceData?.filter(
		(skill) => selectedWorkExperience[skill.id]
	)

	useEffect(() => {
		const fetchData = async () => {
			try {
				const listWorkExperience = await listWorkExperiences(userId)
				setExistingWorkExperience(listWorkExperience)
			} catch (error) {
				console.error('Error fetching work experience', error)
			}
		}

		fetchData()
	}, [userId])

	useEffect(() => {
		setWorkExperienceData(existingWorkExperience)
	}, [existingWorkExperience])

	useEffect(() => {
		const settingSelectedWorkExperience = () => {
			const comapnyDesignationPairs = workExperienceHidi?.split(', ')

			const result = comapnyDesignationPairs?.map((pair, index) => {
				const [designation, company] = pair?.split(' (')
				return {
					id: index + 1,
					designation: designation,
					company: company?.replace(')', ''),
				}
			})

			const workExperienceSelected = existingWorkExperience?.filter(
				(item) =>
					result?.some(
						(workExperienceInHidi) =>
							workExperienceInHidi.company === item.company &&
							workExperienceInHidi.designation ===
								item.designation
					)
			)

			workExperienceSelected?.forEach((item) => {
				setSelectedWorkExperience((prevSelectedItems) => ({
					...prevSelectedItems,
					[item.id]: true,
				}))
			})
		}

		settingSelectedWorkExperience()
	}, [workExperienceHidi, existingWorkExperience, setSelectedWorkExperience])

	useEffect(() => {
		const newWorkExperienceFound = () => {
			const newWorkExperiences = workExperienceData?.filter(
				(item) =>
					!existingWorkExperience?.some(
						(existingWorkExperience) =>
							existingWorkExperience.company === item.company &&
							existingWorkExperience.designation ===
								item.designation
					)
			)
			setNewWorkExperience(newWorkExperiences)
		}

		newWorkExperienceFound()
	}, [workExperienceData, existingWorkExperience])

	const selectedWorkExperienceString = selectedItemsWorkExp
		?.map((item) => `${item.designation} (${item.company})`)
		?.join(', ')

	// work expoeience end

	let totalPointsEarned =
		(hack === '' || hack === null ? 0 : points.hack) +
		(projectLink === '' || projectLink === null ? 0 : points.projectLink) +
		(challenge === '' || challenge === null ? 0 : points.challenge) +
		(helped === '' || helped === null ? 0 : points.helped) +
		(experience === '' || experience === null ? 0 : points.experience) +
		(problem === '' || problem === null ? 0 : points.problem) +
		(peers === '' || peers === null ? 0 : points.peers)

	// const checkAiTextAndUpdate = async (combinedText) => {
	// 	if (combinedText) {
	// 		const result = await checkAiText(combinedText)
	// 		return result
	// 	}
	// }

	const createPoints = async () => {
		const CreateUserInput = {
			UserId: userId,
			GlobalCapabilityConfigId: capability.CapabilityConfig.id,
			capabilityPointsCapabilityClaimedId: capability.id,
			Hack: hack === '' || hack === null ? 'No' : 'Yes',
			ProjectLink:
				projectLink === '' || projectLink === null ? 'No' : 'Yes',
			experience: experience === '' || experience === null ? 'No' : 'Yes',
			PeersVouch: peers === '' || peers === null ? 'No' : 'Yes',
			Challenges: challenge === '' || challenge === null ? 'No' : 'Yes',
			Problem: problem === '' || problem === null ? 'No' : 'Yes',
			Helped: helped === '' || helped === null ? 'No' : 'Yes',
			Video: 'No',
			Photo: 'No',
			Presentation: 'No',
			Outcomes: 'No',
			Claim: 'Yes',
			Project: 'No',
			CapabilityPoints: totalCapabilityPoints,
		}

		await API.graphql({
			query: createCapabilityPoints,
			variables: { input: CreateUserInput },
		})
			.then((res) => {})
			.catch((err) => {})
	}

	const updatePoints = async () => {
		console.log('update points')
		const CreateUserInput = {
			id: capability?.PointsEarned?.items[0]?.id,
			UserId: userId,
			GlobalCapabilityConfigId: capability.CapabilityConfig.id,
			capabilityPointsCapabilityClaimedId: capability.id,
			Hack: hack === '' || hack === null ? 'No' : 'Yes',
			ProjectLink:
				projectLink === '' || projectLink === null ? 'No' : 'Yes',
			Challenges: challenge === '' || challenge === null ? 'No' : 'Yes',
			Helped: helped === '' || helped === null ? 'No' : 'Yes',
			experience: experience === '' || experience === null ? 'No' : 'Yes',
			Problem: problem === '' || problem === null ? 'No' : 'Yes',
			PeersVouch: peers === '' ? 'No' : 'Yes',
			Video: 'No',
			Photo: 'No',
			Presentation: 'No',
			Claim: 'Yes',
			Outcomes: 'No',
			Project: 'No',
			CapabilityPoints: totalCapabilityPoints,
		}

		await API.graphql({
			query: updateCapabilityPoints,
			variables: { input: CreateUserInput },
		})
			.then((res) => {})
			.catch((err) => {})
	}

	const updateChallengeHidi = async ({ id, HidiIdProp }) => {
		const CreateUserInput = {
			id: id,
			HidiId: HidiIdProp,
			challenge: challenge,
		}

		await API.graphql({
			query: updateChallenges,
			variables: { input: CreateUserInput },
		})
			.then(async (res) => {
				setLoadingHidi(false)
				await getCapability()
			})
			.catch((err) => {})
	}

	async function getnewhididata() {
		const hidiId = capability?.ConnectedHidis?.items[0]?.Hidi?.id

		await API.graphql({
			query: getNewhidi,
			variables: {
				id: hidiId,
			},
		})
			.then((res) => {
				if (res?.data?.getNewhidi?.problem) {
					setHack(res?.data?.getNewhidi?.Hack)
					setProjectLink(res?.data?.getNewhidi?.projectLink)
					setHelped(res?.data?.getNewhidi?.helped)
					setExperience(res?.data?.getNewhidi?.experience)
					setProblem(res?.data?.getNewhidi?.problem)
					setChallenge(
						res?.data?.getNewhidi?.challenges?.items[0].challenge
					)
					setPeers(res?.data?.getNewhidi?.peerEmails)
					setWorkExperienceHidi(res?.data?.getNewhidi?.whereitwasdone)
				} else {
					setHack(res?.data?.getNewhidi?.summary)
					setProjectLink(res?.data?.getNewhidi?.projectLink)
					setExperience(res?.data?.getNewhidi?.htmlheading)
					setProblem(res?.data?.getNewhidi?.problem)
					setPeers(res?.data?.getNewhidi?.peerEmails)
				}
			})
			.catch((err) => {})
	}

	useEffect(() => {
		capability?.ConnectedHidis?.items.length > 0 && getnewhididata()
	}, [capability])

	const createJoinHidiandCapabilityTable = async (hidiIdProp) => {
		const CreateUserInput = {
			CapabilityId: capability.id,
			HidiId: hidiIdProp,
		}

		await API.graphql({
			query: createJoinHidisAndCapabilities,
			// authMode: 'API_KEY',
			variables: { input: CreateUserInput },
		}).then(async (res) => {
			await getCapability()
			setClaimedCapabailities(
				res.data.createJoinHidisAndCapabilities.Capability?.userProfile
					.Capabilities?.items
			)
			setLoadingHidi(false)
			listWorkExperiences(userId)
			showToast({
				message: 'Your proofs are submitted successfully!',
				type: 'success',
			})
			onClose()
		})
	}

	const createChallengeHidi = async ({ HidiIdProp }) => {
		const CreateUserInput = {
			HidiId: HidiIdProp,
			challenge: challenge,
		}

		await API.graphql({
			query: createChallenges,
			variables: { input: CreateUserInput },
		})
	}

	const createHIDI = async () => {
		setLoadingHidi(true)

		const combinedText = `${experience}`

		// const responseAI = await checkAiTextAndUpdate(combinedText)

		const CreateUserInput = {
			UserId: userId,
			profileId: profileId,
			experience: experience,
			Hack: hack,
			peerEmails: peers,
			helped: helped,
			problem: problem,
			projectLink: projectLink,
			// AiGenerated: responseAI.isGPTGenerated,
			// ProbabilityFake: responseAI.fakeProbability,
			// ProbabilityReal: responseAI.realProbability,
			whereitwasdone: selectedWorkExperienceString,
		}
		await API.graphql({
			query: createNewhidi,
			// authMode: 'API_KEY',
			variables: { input: CreateUserInput },
		})
			.then(async (res) => {
				createPoints()
				await createChallengeHidi({
					HidiIdProp: res?.data?.createNewhidi?.id,
				})
				await createJoinHidiandCapabilityTable(
					res?.data?.createNewhidi?.id
				)
				setLoadingHidi(false)
			})
			.catch((err) => {
				setLoadingHidi(false)
			})
	}

	const updateHIDI = async () => {
		setLoadingHidi(true)

		const combinedText = `${experience}`

		// const responseAI = await checkAiTextAndUpdate(combinedText)

		const hidiId = capability?.ConnectedHidis?.items[0]?.HidiId
		const pointsId = capability?.PointsEarned?.items[0]?.id
		const challengeId =
			capability?.ConnectedHidis?.items[0]?.Hidi?.challenges?.items[0]?.id

		const CreateUserInput = {
			id: hidiId,
			experience: experience,
			Hack: hack,
			peerEmails: peers,
			helped: helped,
			problem: problem,
			projectLink: projectLink,
			// AiGenerated: responseAI.isGPTGenerated,
			// ProbabilityFake: responseAI.fakeProbability,
			// ProbabilityReal: responseAI.realProbability,
			whereitwasdone: selectedWorkExperienceString,
		}

		await API.graphql({
			query: updateNewhidi,
			// authMode: 'API_KEY',
			variables: { input: CreateUserInput },
		})
			.then(async (res) => {
				if (pointsId) {
					updatePoints()
				} else {
					createPoints()
				}
				if (challengeId) {
					await updateChallengeHidi({
						id: challengeId,
						HidiIdProp: hidiId,
					})
					onClose()
				} else {
					await createChallengeHidi({ HidiIdProp: hidiId })
					onClose()
				}
				listWorkExperiences(userId)
				setLoadingHidi(false)
			})
			.catch((err) => {
				setLoadingHidi(false)
			})
	}

	const saveDetails = async () => {
		if (capability.ConnectedHidis.items.length > 0) {
			await updateHIDI()
		} else {
			if (
				(hack === '' || hack === null) &&
				(projectLink === '' || projectLink === null) &&
				(experience === '' || experience === null) &&
				(problem === '' || problem === null) &&
				(peers === '' || peers === null) &&
				(challenge === '' || challenge === null) &&
				(helped === '' || helped === null)
			) {
				onClose()
			} else {
				await createHIDI()
				await getCapability()
			}
		}
		closeTooltip && closeTooltip()
	}

	const progressBarModal =
		tab === 'Problem'
			? 0
			: tab === 'Experience'
			? 15
			: tab === 'Challenge'
			? 30
			: tab === 'Hacks'
			? 45
			: tab === 'Helped'
			? 60
			: tab === 'ProjectLink'
			? 75
			: tab === 'WorkPlace'
			? 90
			: tab === 'Peers'
			? 100
			: 100

	return (
		<Box>
			<CongratsCapabilityUnlock
				isOpen={congratsModal}
				onClose={() => {
					setCongratsModal(false)
				}}
				onPressContinue={async () => {
					await saveDetails()
					setCongratsModal(false)
				}}
				loading={loadingHidi}
				points={totalPointsEarned}
			/>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				minHeight={windowHeight}
				position="fixed"
			>
				<ToastContainer />
				<ModalBackdrop />
				<ModalContent
					maxHeight={windowHeight * 0.95}
					minWidth={windowWidth * 0.95}
					bg={'$primary0'}
					overflow={false}
					p={0}
				>
					<Divider bgColor="$borderGrey100" />
					<ModalBody
						paddingVertical={windowWidth > 480 ? 35 : 0}
						paddingHorizontal={0}
					>
						<HStack>
							<Box
								w={windowWidth > 480 ? '40%' : '100%'}
								gap={10}
								h={'fit-content'}
								borderRadius={6}
								borderWidth={1}
								borderColor="$borderGrey100"
								shadowColor="black"
								shadowOpacity={0.15}
								shadowRadius={20}
								elevation={15}
								paddingTop={25}
								marginLeft={windowWidth > 480 && 15}
								position="relative"
							>
								<Box
									position="absolute"
									zIndex={100}
									h={10}
									w={'$full'}
									top={0.5}
								>
									<ProgressBar
										progress={progressBarModal}
										bgColor={'red'}
										animationType={'spring'}
									/>
								</Box>

								{tab === 'Problem' && (
									<ShareProblem
										setTab={setTab}
										problem={problem}
										setProblem={setProblem}
										totalCapabilityPoints={
											totalCapabilityPoints
										}
										capabilityName={capabilityName}
									/>
								)}
								{tab === 'Experience' && (
									<ShareExperience
										prevData={problem}
										setTab={setTab}
										experience={experience}
										setExperience={setExperience}
										totalCapabilityPoints={
											totalCapabilityPoints
										}
									/>
								)}
								{tab === 'Challenge' && (
									<ShareChallenge
										setTab={setTab}
										prevData={problem}
										challenge={challenge}
										setChallenge={setChallenge}
										totalCapabilityPoints={
											totalCapabilityPoints
										}
									/>
								)}
								{tab === 'Hacks' && (
									<ShareHack
										setTab={setTab}
										prevData={problem}
										hack={hack}
										setHack={setHack}
										totalCapabilityPoints={
											totalCapabilityPoints
										}
									/>
								)}
								{tab === 'Helped' && (
									<ShareHelped
										prevData={problem}
										setTab={setTab}
										helped={helped}
										setHelped={setHelped}
										totalCapabilityPoints={
											totalCapabilityPoints
										}
									/>
								)}
								{tab === 'ProjectLink' && (
									<ShareProjectLink
										prevData={problem}
										setTab={setTab}
										projectLink={projectLink}
										setProjectLink={setProjectLink}
										totalCapabilityPoints={
											totalCapabilityPoints
										}
									/>
								)}
								{tab === 'WorkPlace' && (
									<ShareWorklPlace
										setTab={setTab}
										workExperienceData={workExperienceData}
										setWorkExperienceData={
											setWorkExperienceData
										}
										setSelectedWorkExperience={
											setSelectedWorkExperience
										}
										selectedWorkExperience={
											selectedWorkExperience
										}
										newWorkExperience={newWorkExperience}
										skillsClaimed={
											capability?.JoinedSkills?.items
										}
									/>
								)}
								{tab === 'Peers' && (
									<SharePeers
										setTab={setTab}
										peers={peers}
										prevData={problem}
										setPeers={setPeers}
										totalCapabilityPoints={
											totalCapabilityPoints
										}
										loadingBtn={loadingHidi}
										saveDetails={saveDetails}
									/>
								)}
								{tab === 'PreviewHidi' && (
									<PreviewHidi
										hack={hack}
										experience={experience}
										challenge={challenge}
										projectLink={projectLink}
										peers={peers}
										setTab={setTab}
										navigation={navigation}
										profile={profile}
										helped={helped}
										problem={problem}
										capabilityName={capabilityName}
										loadingHidi={loadingHidi}
										saveDetails={saveDetails}
										setExperience={setExperience}
										setHack={setHack}
										jobDomain={jobDomain}
									/>
								)}
							</Box>
							{windowWidth > 480 && (
								<Box w={'60%'}>
									<PreviewHidi
										hack={hack}
										challenge={challenge}
										experience={experience}
										projectLink={projectLink}
										peers={peers}
										helped={helped}
										setTab={setTab}
										navigation={navigation}
										profile={profile}
										problem={problem}
										capabilityName={capabilityName}
										loadingHidi={loadingHidi}
										saveDetails={saveDetails}
										setExperience={setExperience}
										setHack={setHack}
										jobDomain={jobDomain}
									/>
								</Box>
							)}
						</HStack>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Box>
	)
}

export default ShareExperienceModal
