import React from 'react'
import { Dimensions } from 'react-native'
import { Box, Divider, HStack, Image, Text, VStack } from '@gluestack-ui/themed'

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

function AboutQrusible() {
	return (
		<Box
			alignItems="center"
			justifyContent="space-evenly"
			w={'$full'}
			minHeight={windowHeight}
		>
			<Box
				w={'fit-content'}
				alignSelf="center"
				alignItems="center"
				p={20}
				// gap={5}
			>
				<HStack alignItems="center" justifyContent="center">
					<Text
						fontWeight="bold"
						fontFamily="$fontHeading"
						fontSize={windowWidth > 1250 ? 40 : 35}
						lineHeight={windowWidth > 1250 ? 40 : 35}
						textAlign="center"
					>
						Up your career game on{' '}
					</Text>
					<Image
						source={require('../../assets/Images/qrusbileOrangebg.png')}
						h={windowWidth > 1250 ? 50 : 40}
						w={windowWidth > 1250 ? 150 : 130}
						alignSelf="center"
					/>
				</HStack>
				<HStack alignItems="center" justifyContent="center">
					<Text
						fontWeight="500"
						fontFamily="$fontHeading"
						fontSize={windowWidth > 1250 ? 24 : 20}
						lineHeight={40}
						textAlign="center"
						color="$fontGray100"
					>
						Discover key{' '}
						<Text
							fontStyle="italic"
							fontWeight="500"
							fontFamily="$fontHeading"
							fontSize={windowWidth > 1250 ? 24 : 20}
							lineHeight={40}
							textAlign="center"
							color="$fontGray100"
							// underline
						>
							insights & analysis{' '}
						</Text>
						<Text
							fontWeight="500"
							fontFamily="$fontHeading"
							fontSize={windowWidth > 1250 ? 24 : 20}
							lineHeight={40}
							textAlign="center"
							color="$primary500"
						>
							<Text
								fontWeight="500"
								fontFamily="$fontHeading"
								fontSize={windowWidth > 1250 ? 24 : 20}
								lineHeight={40}
								textAlign="center"
								color="$fontGray100"
							>
								to
							</Text>{' '}
							find the way forward{' '}
						</Text>
					</Text>
					<Image
						source={require('../../assets/Images/insightSymbol.png')}
						h={25}
						w={25}
					/>
				</HStack>
			</Box>
			{/* <Divider bg={'$fontGray'} h={1} w={'20%'} alignSelf="flex-start" /> */}
			{/* <Box w={'fit-content'} alignSelf="center" p={20}>
				<Box alignItems="flex-start">
					<Text
						fontWeight="600"
						fontStyle="italic"
						fontSize={windowWidth > 1250 ? 30 : 20}
						lineHeight={windowWidth > 1250 ? 40 : 30}
						textAlign="left"
						fontFamily="$fontHeading"
						color="$fontGray100"
					>
						<Image
							source={require('../../assets/Images/addSymbol.png')}
							h={30}
							w={30}
						/>{' '}
						Step 1:{' '}
						<Text fontWeight="600" fontFamily="$fontHeading">
							Claim your{' '}
							<Text
								fontWeight="600"
								fontFamily="$fontHeading"
								bg="$primary500"
								color="$primary0"
								lineHeight={windowWidth > 1250 ? 40 : 30}
								p={5}
							>
								capabilities
							</Text>
						</Text>
					</Text>
				</Box>
				<Box marginTop={windowWidth > 1250 ? 30 : 23}>
					<Text
						fontWeight="600"
						fontStyle="italic"
						fontSize={windowWidth > 1250 ? 30 : 20}
						lineHeight={windowWidth > 1250 ? 40 : 30}
						textAlign="left"
						fontFamily="$fontHeading"
						color="$fontGray100"
					>
						<Image
							source={require('../../assets/Images/jobMatches.png')}
							h={30}
							w={30}
						/>{' '}
						Step 2:{' '}
						<Text fontWeight="600" fontFamily="$fontHeading">
							Get{' '}
							<Text
								fontWeight="600"
								fontFamily="$fontHeading"
								bg="$primary500"
								color="$primary0"
								lineHeight={windowWidth > 1250 ? 40 : 30}
								p={5}
							>
								matching
							</Text>{' '}
							opportunities
						</Text>
					</Text>
				</Box>
				<Box marginTop={windowWidth > 1250 ? 30 : 23}>
					<Text
						fontWeight="600"
						fontStyle="italic"
						fontSize={windowWidth > 1250 ? 30 : 20}
						lineHeight={windowWidth > 1250 ? 40 : 30}
						textAlign="left"
						fontFamily="$fontHeading"
						color="$fontGray100"
					>
						<Image
							source={require('../../assets/Images/eligibilitySymbol.png')}
							h={30}
							w={30}
						/>{' '}
						Step 3:{' '}
						<Text fontWeight="600" fontFamily="$fontHeading">
							Check your{' '}
							<Text
								fontWeight="600"
								fontFamily="$fontHeading"
								bg="$primary500"
								color="$primary0"
								lineHeight={windowWidth > 1250 ? 40 : 30}
								p={5}
							>
								eligibility
							</Text>
						</Text>
					</Text>
				</Box>
			</Box>{' '}
			<Divider
				bg={'$fontGray'}
				h={0.5}
				w={'20%'}
				alignSelf="flex-start"
			/>
			<Box w={'fit-content'} alignSelf="center" p={20}>
				<Text
					fontFamily="$fontHeading"
					fontSize={windowWidth > 1250 ? 30 : 25}
					color="$fontGray100"
					fontWeight={700}
				>
					300+ full-stack jobs{' '}
					<Text
						bg="$primary500"
						fontFamily="$fontHeading"
						paddingHorizontal={5}
						paddingVertical={3}
						borderRadius={6}
						color="$primary0"
						fontWeight={700}
					>
						• LIVE
					</Text>
				</Text>
			</Box> */}
			<HStack gap={8}>
				<Box
					justifyContent="flex-end"
					w={'fit-content'}
					gap={30}
					paddingVertical={5}
				>
					<Text
						color="$primary500"
						fontFamily="$fontHeading"
						fontWeight="bold"
					>
						coming soon
					</Text>
					<Text
						color="$primary500"
						fontFamily="$fontHeading"
						fontWeight="bold"
					>
						coming soon
					</Text>
				</Box>
				<Box w={'fit-content'} alignSelf="center" gap={20}>
					<HStack alignItems="center" gap={10}>
						<Image
							source={require('../../assets/Images/tickOrange.png')}
							h={32}
							w={32}
						/>
						<Text
							fontFamily="$fontHeading"
							fontSize={windowWidth > 1250 ? 25 : 22}
							fontWeight="bold"
						>
							Find job success probability
						</Text>
					</HStack>
					<HStack alignItems="center" gap={10}>
						<Image
							source={require('../../assets/Images/tickOrange.png')}
							h={32}
							w={32}
						/>
						<Text
							fontFamily="$fontHeading"
							fontSize={windowWidth > 1250 ? 25 : 22}
							fontWeight="bold"
						>
							Find gaps in your profile
						</Text>
					</HStack>
					<HStack alignItems="center" gap={10}>
						<Image
							source={require('../../assets/Images/tickOrange.png')}
							h={32}
							w={32}
						/>
						<Text
							fontFamily="$fontHeading"
							fontSize={windowWidth > 1250 ? 25 : 22}
							fontWeight="bold"
						>
							Find in-demand industry skills
						</Text>
					</HStack>
					<HStack alignItems="center" gap={10}>
						<Image
							source={require('../../assets/Images/tickOrange.png')}
							h={32}
							w={32}
							opacity={0.7}
						/>
						<Text
							fontFamily="$fontHeading"
							fontSize={windowWidth > 1250 ? 25 : 22}
							fontWeight="bold"
							opacity={0.7}
							strikeThrough
						>
							Learn industry-best capabilities
						</Text>
					</HStack>
					<HStack alignItems="center" gap={10}>
						<Image
							source={require('../../assets/Images/tickOrange.png')}
							h={32}
							w={32}
							opacity={0.7}
						/>
						<Text
							fontFamily="$fontHeading"
							fontSize={windowWidth > 1250 ? 25 : 22}
							opacity={0.7}
							fontWeight="bold"
							strikeThrough
						>
							1 dashboard to track all job applications
						</Text>
					</HStack>
				</Box>
			</HStack>
		</Box>
	)
}

export default AboutQrusible
