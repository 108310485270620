import { Box, HStack, Image, Text } from '@gluestack-ui/themed'
import React from 'react'
import BreathingImage from '../AnimatedXPImg'
import { Dimensions } from 'react-native'

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

const TotalPointsOneCapability = ({ points, text, pH, pV, bg }) => {
	return (
		<Box
			alignItems="center"
			w={'fit-content'}
			paddingHorizontal={pH ? pH : 20}
			paddingVertical={pV ? pV : 7}
			bg={bg ? bg : '$backgroundGray'}
			borderRadius={6}
		>
			<HStack alignItems="center" gap={5}>
				<Text
					fontFamily="$fontHeading"
					fontSize={windowWidth > 480 ? 14 : 12}
					fontWeight="700"
				>
					{points}
				</Text>
				<BreathingImage
					imageSource={require('../../assets/Images/xp.png')}
					width={15}
					height={15}
				/>
			</HStack>
			{text && (
				<Box>
					<Text
						fontFamily="$fontHeading"
						fontSize={12}
						color="$fontGray100"
					>
						{text}
					</Text>
				</Box>
			)}
		</Box>
	)
}

export default TotalPointsOneCapability
