import {
	StyleSheet,
	Text,
	View,
	TextInput,
	Button,
	TouchableHighlight,
	Image,
	Alert,
	ActivityIndicator,
	Dimensions,
} from 'react-native'
import React, { useEffect, useState } from 'react'
import { Auth, auth0SignInButton } from 'aws-amplify'
import AsyncStorage from '@react-native-async-storage/async-storage'
import useUserStore from '../Userstore/UserStore'
import { getNewprofile } from '../graphql/queries'
import { updateNewprofile } from '../graphql/mutations'
import { API, Storage } from 'aws-amplify'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

export default function EditPreferences({ navigation }) {
	// console.log(e.target.files)
	// const [user, setUser] = useState('');
	const [loading, setLoading] = useState(false)
	const [fullName, setFullName] = useState('')
	const [email, setEmail] = useState('')
	const [bio, setBio] = useState('')
	const [expertise, setExpertise] = useState('')
	const [location, setLocation] = useState('')
	const [updatedAttributes, setUpdatedAttributes] = useState({})
	const ProfileId = useUserStore((state) => state.profileId)

	async function getProfile() {
		await API.graphql({
			query: getNewprofile,
			// authMode: 'API_KEY',
			variables: {
				id: ProfileId,
			},
		})
			.then((res) => {
				setFullName(res.data.getNewprofile.name)
				setBio(res.data.getNewprofile.about)
				setExpertise(res.data.getNewprofile.expertise)
				setEmail(res.data.getNewprofile.Email)
				setLocation(res.data.getNewprofile.location)
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
				
			})
	}

	async function updateProfile() {
		setLoading(true)
		//const _updatedAttributes = JSON.parse(JSON.stringify(updatedAttributes));
		// console.log(_updatedAttributes);
		const CreateUserInput = {
			id: ProfileId,
			name: fullName,
			about: bio,
			expertise: expertise,
			Email: email,
			location: location,
			// htmloutput: output,
		}
		API.graphql({
			query: updateNewprofile,
			variables: { input: CreateUserInput },
		}).then((res) => {
			setLoading(false)
			// navigation.navigate("NewHIDI", {hidiId: res.data.createNewhidi.id})
		})
	}

	// useEffect(() => {
	//   getProfile()
	// }, []);
	return (
		<View style={{ justifyContent: 'center', alignItems: 'center' }}>
			<input type="file" onChange={handleChange} />
			<View
				style={{
					flexDirection: 'row',
					alignItems: 'center',
					marginBottom: 20,
				}}
			>
				{/* <Text style ={{fontSize: 20, fontWeight: 'bold', marginTop: 10, fontFamily: "Lexend"}}>
                Edit Profile
            </Text> */}
			</View>
			<View style={{ ...styles.inputContainer }}>
				<Image
					style={styles.inputIcon}
					source={{
						uri: 'https://cdn-icons-png.flaticon.com/512/1828/1828439.png',
					}}
				/>
				<TextInput
					style={styles.inputs}
					placeholder="Current Salary"
					placeholderTextColor={'grey'}
					keyboardType="email-address"
					underlineColorAndroid="transparent"
					value={fullName}
					onChangeText={setFullName}
				/>
			</View>
			<View style={styles.inputContainer}>
				<Image
					style={styles.inputIcon}
					source={{
						uri: 'https://cdn-icons-png.flaticon.com/512/923/923481.png',
					}}
				/>
				<TextInput
					style={styles.inputs}
					placeholder="Expected Salary"
					placeholderTextColor={'grey'}
					underlineColorAndroid="transparent"
					value={expertise}
					onChangeText={setExpertise}
				/>
			</View>
			<View style={styles.inputContainer}>
				<Image
					style={styles.inputIcon}
					source={{
						uri: 'https://cdn-icons-png.flaticon.com/512/923/923481.png',
					}}
				/>
				<TextInput
					style={styles.inputs}
					placeholder="Area Of Intereset"
					placeholderTextColor={'grey'}
					underlineColorAndroid="transparent"
					value={location}
					onChangeText={setLocation}
				/>
			</View>
			<View style={styles.inputContainer}>
				<Image
					style={styles.inputIcon}
					source={{
						uri: 'https://cdn-icons-png.flaticon.com/512/591/591777.png',
					}}
				/>
				<TextInput
					style={styles.inputs}
					placeholder="Work type Preference"
					placeholderTextColor={'grey'}
					underlineColorAndroid="transparent"
					value={email}
					onChangeText={setEmail}
				/>
			</View>
			<ActivityIndicator
				animating={loading}
				size="small"
				color="#002DE3"
			/>
			<TouchableHighlight
				style={[styles.buttonContainer, styles.signupButton]}
				onPress={() => getimage()}
			>
				<Text style={styles.signUpText}>Update</Text>
			</TouchableHighlight>
		</View>
	)
}
const styles = StyleSheet.create({
	container: {
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#F7F7FC',
	},
	seperator: {
		height: 0.5,
		backgroundColor: '#ADB5BD',
		opacity: 0.3,
		width: '80%',
		marginTop: 5,
		marginBottom: 10,
	},
	image: {
		width: 191,
		height: 47,
		marginBottom: 50,
	},
	inputContainer: {
		borderBottomColor: '#F5FCFF',
		backgroundColor: '#FFFFFF',
		borderRadius: 15,
		borderBottomWidth: 1,
		width: '80%',
		height: 65,
		marginBottom: 20,
		flexDirection: 'row',
		alignItems: 'center',
	},
	inputs: {
		height: 45,
		marginLeft: 16,
		borderBottomColor: '#FFFFFF',
		flex: 1,
	},
	inputs2: {
		height: 90,
		marginLeft: 16,
		borderBottomColor: '#FFFFFF',
		flex: 1,
	},
	inputIcon: {
		width: 30,
		height: 30,
		marginLeft: 15,
		justifyContent: 'center',
		opacity: 0.2,
	},
	buttonContainer: {
		height: 45,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: 20,
		width: 250,
		borderRadius: 15,
	},
	signupButton: {
		backgroundColor: '#002DE3',
	},
	signUpText: {
		color: 'white',
	},
})
