import { ArrowLeftIcon, Box, TrashIcon } from '@gluestack-ui/themed'
import React from 'react'
import { points } from '../../../newComponents/pointsComponents/pointsconfig'
import ShareSkeleton from './ShareSkeleton'

const ShareExperience = ({
	setTab,
	experience,
	setExperience,
	totalCapabilityPoints,
	prevData,
	capEditView,
	textAreaHeight,
}) => {
	const wordCount = experience
		?.split(/\s+/)
		?.filter((word) => word !== '')?.length

	return (
		<Box>
			<ShareSkeleton
				prevFieldData={prevData}
				titleNormal1={'Explain briefly, '}
				titleColored1={'how '}
				titleNormal2={'you went about '}
				titleColored2={'solving '}
				titleNormal3={'the problem.'}
				subtitle={'Elaborate on the process you took'}
				valueInput={experience}
				setInputValueChange={setExperience}
				textArea={true}
				minWordCount={20 - wordCount}
				infoStripText={`Each experience shapes you. 🌈 Employers are curious about how your past adventures contribute to your abilities—it’s like puzzle pieces coming together to create the perfect picture for their team.`}
				buttonText={'Next'}
				buttonOnPress={() => {
					setTab('Challenge')

					if (wordCount < 20 || experience === null) {
						setExperience('')
					}
				}}
				backButtonOnPress={() => {
					setTab('Problem')
					// if (wordCount < 20 || experience === null) {
					// setExperience('')
					// }
				}}
				disabled={wordCount < 20 || experience === null}
				backIcon={ArrowLeftIcon}
				backText={'Back'}
				totalCapabilityPoints={totalCapabilityPoints}
				pointsAssigned={points.experience}
				capEditView={capEditView}
				textAreaHeight={textAreaHeight}
				disableCopyPaste={true}
				disableRightClick={true}
			/>
		</Box>
	)
}

export default ShareExperience
