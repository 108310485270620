import React, { useState } from 'react'
import { Box, Heading, Image, Tabs, Text } from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'
import { useEffect } from 'react'
import useUserStore from '../../Userstore/UserStore'
import JourneyTabs from '../journey/JourneyTabs'
import ForYou from './ForYou'
import MyOpportunities from './MyOpportunities'
import JobDetails from './JobDetails'
import { listJobPosts } from '../../graphql/queries'
import { API } from 'aws-amplify'
import SpinnerComponent from '../../newComponents/SpinnerComponent'
import EligibilityModal from '../../FeedComponents/CheckEligibility/EligibilityModal'

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

const Opportunities = ({ navigation, route }) => {
	const setSavedUrl = useUserStore((state) => state.setSavedUrl)
	const [tabState, setTabState] = useState('opportunities')

	const [showDetailsModal, setShowDetailsModal] = useState(false)
	const profileId = useUserStore((state) => state.profileId)

	useEffect(() => {
		if (
			route?.params?.JobPostId !== undefined &&
			route?.params?.JobPostId !== 'undefined'
		) {
			setShowDetailsModal(true)
		}
	}, [])

	return (
		<Box
			bg="$backgroundGray"
			minHeight={windowHeight - 62}
			marginTop={62}
			marginBottom={windowWidth > 480 ? null : 50}
			paddingHorizontal={windowWidth > 480 ? 100 : 5}
			paddingVertical={windowWidth > 480 ? 20 : 5}
			paddingBottom={windowWidth > 480 ? null : 50}
		>
			<Box>
				<JourneyTabs
					tabState={tabState}
					setTabState={setTabState}
					labels={['My Applications', 'Opportunities']}
					stateName={['myApplications','opportunities' ]}
					viewSettings={true}
					navigation={navigation}
				/>
			</Box>
			{tabState === 'opportunities' && (
				<Box gap={10}>
					<Box>
						<Heading
							fontFamily="$fontHeading"
							fontSize={
								windowWidth > 480
									? '$fontHeading'
									: '$fontHeadingMobile'
							}
							marginTop={20}
							marginLeft={10}
							lineHeight={windowWidth < 480 && 20}
							color={'#737373'}
							fontWeight={400}
						>
							Recommended for you based on your capabilities
						</Heading>
						<ForYou navigation={navigation} potentialOpp={true} />
					</Box>
					<Box>
						<Heading
							fontFamily="$fontHeading"
							fontSize={
								windowWidth > 480
									? '$fontHeading'
									: '$fontHeadingMobile'
							}
							marginLeft={10}
							marginTop={20}
							fontWeight={400}
							color={'#737373'}
						>
							All Opportunities
						</Heading>
						<ForYou navigation={navigation} potentialOpp={false} />
					</Box>
				</Box>
			)}
			{tabState === 'myApplications' && (
				<Box>
					<MyOpportunities
						profileId={profileId}
						navigation={navigation}
					/>
				</Box>
			)}
			<EligibilityModal
				isOpen={showDetailsModal}
				jobPostsId={route?.params?.JobPostId}
				onClose={() => {
					setShowDetailsModal(false)
				}}
				navigation={navigation}
				setModalShow={() => setShowDetailsModal}
			/>
		</Box>
	)
}

export default Opportunities
