import * as React from 'react'
import { Dimensions } from 'react-native'
import useUserStore from '../Userstore/UserStore'
import useImageFileLink from '../customhook/useImageLinkhook'
import { useState, useEffect } from 'react'
import {
	Box,
	Divider,
	HStack,
	Heading,
	Text,
	VStack,
} from '@gluestack-ui/themed'
import SelectCapabilitiesModal from '../newComponents/keyCapabilities/SelectCapabilitiesModal'
import AvatarComponent from '../newComponents/AvatarComponent'
import ButtonComponent from '../newComponents/ButtonComponent'
import CountBox from '../newComponents/profileStatsComponents/Countbox'
import { list_JobApplications_by_ProfileId_filter_byJobPostIdforFeed } from '../graphql/custom-queries'
import { API } from 'aws-amplify'
import PotentialOppModal from '../newComponents/profileStatsComponents/PotentialOppModal'
import ApplicationsModal from '../newComponents/profileStatsComponents/ApplicationsModal'
import MyOppRenderComponent from '../newComponents/MyOppRenderComponent'
import EligibilityModal from './CheckEligibility/EligibilityModal'
import { listCapabilitiesByProfileAndJobConnected } from '../graphql/custom-queries'
import GetOpportunities from '../customhook/GetOpportunities'
import PopUpOnFeed from './PopUpOnFeed'

const windowWidth = Dimensions.get('window').width

const ProfileStats = ({ screen, navigation, firstTimeLogin }) => {
	const profile = useUserStore((state) => state.profile)

	const [pickupModal, setPickupModal] = useState(false)
	const jobsAppliedDetails = useUserStore((state) => state.jobsAppliedDetails)
	const setJobsAppliedDetails = useUserStore(
		(state) => state.setJobsAppliedDetails
	)

	const listJobsApplied = async () => {
		try {
			const res = await API.graphql({
				query: list_JobApplications_by_ProfileId_filter_byJobPostIdforFeed,
				variables: {
					ProfileId: profile?.id,
				},
			})
			
			setJobsAppliedDetails(
				res.data.list_JobApplications_by_ProfileId_filter_byJobPostId
					.items
			)
		} catch (error) {}
	}

	useEffect(() => {
		if (profile?.id) {
			listJobsApplied()
		}
	}, [profile?.id])

	const getLatestApplyingJobApplication = () => {
		if (jobsAppliedDetails?.length > 0) {
			let latestApplyingJobApplication = null

			for (const application of jobsAppliedDetails) {
				if (application.Level < 4) {
					if (!latestApplyingJobApplication) {
						latestApplyingJobApplication = application
					} else {
						const dateLatest = new Date(
							latestApplyingJobApplication.updatedAt
						)
						const dateCurrent = new Date(application.updatedAt)
						if (dateCurrent > dateLatest) {
							latestApplyingJobApplication = application
						}
					}
				}
			}

			return latestApplyingJobApplication
		} else {
			return null
		}
	}

	const latestApplyingJobApplication = getLatestApplyingJobApplication()
	
	return (
		<>
			{latestApplyingJobApplication ? (
				<Box
					bg="$primary0"
					w={'100%'}
					borderRadius={6}
					padding={windowWidth > 480 ? 20 : 10}
					borderWidth={2}
					softShadow="5"
					borderColor="$borderOrange100"
				>
					<>
						<Box>
							<Text
								fontFamily="$fontContent"
								color="$fontGray"
								fontSize={11}
								fontWeight="600"
							>
								Pickup where you left off
							</Text>
							<Box>
								<MyOppRenderComponent
									applyingStatus={
										latestApplyingJobApplication?.ApplyingStatus
									}
									companyName={
										latestApplyingJobApplication?.jobPost
											?.companyName ? latestApplyingJobApplication?.jobPost
											?.companyName : latestApplyingJobApplication.jobPost.CompanyPreference.companyprofile.name
									}
									jobTitle={
										latestApplyingJobApplication?.jobPost
											?.title ? latestApplyingJobApplication?.jobPost
											?.title : latestApplyingJobApplication.jobPost.CompanyPreference.rolename
									}
									modeofwork={
										latestApplyingJobApplication?.jobPost
											?.CompanyPreference?.modeofwork
									}
									onPress={() => {
										
										navigation.push('JobDetails', {
											jobPostId:
												latestApplyingJobApplication
													?.JobPostId,
										})
									}}
									level={latestApplyingJobApplication.Level}
								/>
							</Box>
						</Box>``
						<EligibilityModal
							navigation={navigation}
							isOpen={pickupModal}
							onClose={() => setPickupModal(false)}
							jobPostsId={
								latestApplyingJobApplication?.jobPost?.id
							}
						/>
					</>
				</Box>
			) : null}
		</>
	)
}

export default ProfileStats
