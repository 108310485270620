import {
	Box,
	EditIcon,
	Icon,
	Image,
	Pressable,
	HStack,
	Text,
	TrashIcon,
} from '@gluestack-ui/themed'
import React from 'react'
import { useState } from 'react'
import { Dimensions } from 'react-native'
import DeleteConfirmationModal from '../DeleteConfirmationModal'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const CandidateCareerRenderComponent = ({
	imgSource,
	defaultImgSource,
	title,
	institution,
	startDate,
	endDate,
	present,
	duration,
	grades,
	onEdit,
	onDelete,
	screen,
}) => {
	const [deleteModal, setDeleteModal] = useState(false)

	return (
		<HStack w={'100%'} flex={1}>
			<DeleteConfirmationModal
				isOpen={deleteModal}
				onClose={() => setDeleteModal(false)}
				onDelete={onDelete}
			/>
			<Box
				borderWidth={1}
				padding={10}
				alignItems="center"
				justifyContent="center"
				w={windowWidth > 480 ? 50 : 40}
				h={windowWidth > 480 ? 50 : 40}
				borderRadius={6}
				borderColor="$borderDark0"
			>
				<Image
					h={windowWidth > 480 ? 30 : 20}
					w={windowWidth > 480 ? 30 : 20}
					source={{ uri: imgSource }}
					defaultSource={defaultImgSource}
				/>
			</Box>
			<Box flex={1} paddingHorizontal={windowWidth > 480 ? 10 : 5}>
				{title ? (
					<Text
						fontFamily="$fontContent"
						fontSize={
							windowWidth > 480
								? '$fontContent'
								: '$fontContentMobile'
						}
						fontWeight="700"
						color="$primary950"
					>
						{title}
					</Text>
				) : null}
				{institution ? (
					<Text
						fontFamily="$fontContent"
						fontSize={windowWidth > 480 ? '$fontSize12' : 10}
						fontWeight="500"
						color="$primary950"
					>
						{institution}
					</Text>
				) : null}
				{startDate ? (
					<Text
						fontFamily="$fontContent"
						fontSize={windowWidth > 480 ? '$fontSize12' : 10}
						fontWeight="500"
						color="$primary950"
					>
						{startDate} - {present ? 'Present' : endDate}
					</Text>
				) : duration ? (
					<Text
						fontFamily="$fontContent"
						fontSize={windowWidth > 480 ? '$fontSize12' : 10}
						fontWeight="500"
						color="$primary950"
					>
						{duration}
					</Text>
				) : null}
				{grades ? (
					<Text
						fontFamily="$fontContent"
						fontSize={windowWidth > 480 ? '$fontSize12' : 10}
						fontWeight="500"
						color="$primary950"
					>
						{grades}
					</Text>
				) : null}
			</Box>
			{screen !== 'public' && (
				<Box
					flexDirection={windowWidth > 480 ? 'row' : 'column'}
					paddingVertical={2}
					gap={20}
				>
					<Pressable onPress={onEdit} h={'fit-content'}>
						<Icon
							size={windowWidth > 480 ? 'sm' : 'xs'}
							as={EditIcon}
							color={'$primary500'}
						/>
					</Pressable>
					<Pressable
						onPress={() => {
							setDeleteModal(true)
						}}
						h={'fit-content'}
					>
						<Icon
							size={windowWidth > 480 ? 'sm' : 'xs'}
							as={TrashIcon}
							color={'$secondary500'}
						/>
					</Pressable>
				</Box>
			)}
		</HStack>
	)
}

export default CandidateCareerRenderComponent
