import {
	Modal,
	ModalBackdrop,
	ModalBody,
	ModalContent,
	ModalCloseButton,
	ModalFooter,
	ModalHeader,
	Heading,
	Text,
	Box,
	VStack,
	HStack,
	Icon,
	CloseIcon,
} from '@gluestack-ui/themed'

import React from 'react'
import { Dimensions } from 'react-native'
import ButtonComponent from './ButtonComponent'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const DeleteConfirmationModal = ({ isOpen, onClose, onDelete, loading }) => {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			position="fixed"
			minHeight={windowHeight}
		>
			<ModalBackdrop />
			<ModalContent
				minWidth={windowWidth > 480 ? null : windowWidth * 0.9}
				overflow={false}
			>
				<ModalHeader>
					<Heading
						fontFamily="$fontHeading"
						fontSize={
							windowWidth > 480
								? '$fontHeading'
								: '$fontHeadingMobile'
						}
					>
						Deleting Item
					</Heading>
					<ModalCloseButton>
						<Icon as={CloseIcon} />
					</ModalCloseButton>
				</ModalHeader>
				<ModalBody>
					<Text
						fontFamily="$fontContent"
						fontSize={
							windowWidth > 480
								? '$fontContent'
								: '$fontContentMobile'
						}
					>
						Are you sure you want to delete this item? This action
						cannot be undone.
					</Text>
				</ModalBody>
				<ModalFooter>
					<ButtonComponent
						text={'Delete'}
						color={'$secondary500'}
						borderColor={'$secondary500'}
						onPress={() => onDelete()}
						loading={loading}
						spinnerColor={'white'}
						width={windowWidth > 480 ? 100 : 70}
					/>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default DeleteConfirmationModal
