import {
	Box,
	HStack,
	Heading,
	Text,
	Pressable,
	VStack,
} from '@gluestack-ui/themed'
import React, { useState } from 'react'
import { Dimensions } from 'react-native'
import Editor from 'react-medium-editor'
import useUserStore from '../../Userstore/UserStore'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const AboutCompany = ({
	companyName,
	companyDescription,
	opportunity,
	jobType,
	location,
	modeOfWork,
	postedOn,
	externalLocation,
	externalAbout,
	shortDesc,
	skills,
	experienceRequired,
}) => {
	const locationCompanyExternal = externalLocation
		? JSON.parse(externalLocation)
		: null

	const profile = useUserStore((state) => state.profile)

	// const candidatePreferences = profile?.CandidatePreferences?.items[0]
	const candidateExperience = profile?.experience
	const candidateExpYears = parseInt(candidateExperience)

	function convertSkills(skillsString) {
		try {
			const parsedSkills = JSON.parse(skillsString)
			if (Array.isArray(parsedSkills)) {
				return parsedSkills.map((skillObject) => skillObject.S)
			} else {
				return skillsString
			}
		} catch (error) {
			console.error('Error parsing skills:', error)
			return skillsString
		}
	}
	const requiredSkills = skills ? convertSkills(skills) : null

	const [expanded, setExpanded] = useState(false)

	const toggleExpanded = () => {
		setExpanded(!expanded)
	}
	function LimitText({ text }) {
		const [showAll, setShowAll] = useState(false)
		const words = text.split(' ')

		const truncatedText = showAll ? text : words.slice(0, 60).join(' ')

		const toggleShowAll = () => {
			setShowAll(!showAll)
		}

		return (
			<Box>
				{externalAbout ? (
					<Editor
						text={truncatedText}
						style={{
							fontFamily: 'Open Sans',
							fontSize: windowWidth > 480 ? 12 : 11,
							fontStyle: 'normal',
							color: '#828282',
						}}
						options={{
							toolbar: false,
							disableEditing: true,
						}}
					/>
				) : (
					<Text
						fontFamily="$fontContent"
						color={'$primary950'}
						fontSize={windowWidth > 480 ? '$fontSize12' : 11}
						lineHeight={windowWidth > 480 ? 18 : 15}
					>
						{truncatedText}
					</Text>
				)}

				{words.length > 100 && (
					<Pressable
						marginTop={windowWidth > 480 ? 5 : 3}
						onPress={toggleShowAll}
					>
						<Text
							color="$primary500"
							fontFamily="$fontHeading"
							fontWeight="600"
							fontSize={12}
						>
							{showAll ? 'Read Less' : 'Read More...'}
						</Text>
					</Pressable>
				)}
			</Box>
		)
	}

	const companyLocation = location
		? location
		: locationCompanyExternal
		? locationCompanyExternal[0]?.S
		: null

	return (
		<>
			<VStack
				flexWrap="wrap"
				marginTop={windowWidth > 480 ? 11 : 5}
				gap={11}
			>
				<Box gap={11}>
					{(companyDescription || externalAbout) && (
						<Box>
							<Heading
								fontFamily="$fontHeading"
								fontSize={
									windowWidth > 480 ? 17 : '$fontSize12'
								}
								fontWeight="900"
								color={'$primary950'}
							>
								About {companyName}
							</Heading>

							{externalAbout ? (
								<LimitText
									text={externalAbout?.replace(/"/g, '')}
								/>
							) : (
								<LimitText
									text={companyDescription.replace(/"/g, '')}
								/>
							)}
						</Box>
					)}

					{(opportunity || shortDesc) && (
						<Box>
							<Heading
								fontFamily="$fontHeading"
								fontSize={
									windowWidth > 480 ? 17 : '$fontSize12'
								}
								fontWeight="900"
								color={'$primary950'}
							>
								{opportunity
									? 'Roles & Responsibilities'
									: 'Description'}
							</Heading>
							<Text
								fontFamily="$fontContent"
								color={'$primary950'}
								fontSize={
									windowWidth > 480 ? '$fontSize12' : 11
								}
								lineHeight={windowWidth > 480 ? 18 : 15}
							>
								{opportunity
									? opportunity?.replace(/"/g, '')
									: shortDesc?.replace(/"/g, '')}
							</Text>
						</Box>
					)}
					{requiredSkills?.length > 0 && (
						<Box>
							<Heading
								fontFamily="$fontHeading"
								fontSize={
									windowWidth > 480 ? 17 : '$fontSize12'
								}
								fontWeight="900"
								color={'$primary950'}
							>
								Required Skills
							</Heading>
							<HStack flexWrap="wrap">
								{requiredSkills.map((skill, index) => {
									return (
										<Text
											fontFamily="$fontContent"
											color={'$primary950'}
											fontSize={
												windowWidth > 480
													? '$fontSize12'
													: 11
											}
											lineHeight={
												windowWidth > 480 ? 18 : 15
											}
											marginRight={5}
										>
											{/* {skill?.S}
											 */}
											{skill}
											{index !==
												requiredSkills?.length - 1 &&
												','}
										</Text>
									)
								})}
							</HStack>
						</Box>
					)}
				</Box>
			</VStack>
		</>
	)
}

export default AboutCompany
