import React from 'react'
import { Dimensions } from 'react-native'
import {
	AddIcon,
	CheckIcon,
	Box,
	FlatList,
	HStack,
	Icon,
	Pressable,
	Text,
	VStack,
	ArrowLeftIcon,
	TrashIcon,
	ScrollView,
	Switch,
} from '@gluestack-ui/themed'
import ButtonComponent from '../../../newComponents/ButtonComponent'
import { Motion } from '@legendapp/motion'
import { useState } from 'react'
import LabelWithInputComponent from '../../../newComponents/LabelWithInputComponent'
import CandidateCareerRenderComponent from '../../../newComponents/editCareer/CandidateCareerRenderComponent'
import CustomDatePicker from '../../../newComponents/DatePickerComponent'

const windowWidth = Dimensions.get('window').width

const ShareSelectSkeleton = ({
	onBackPress,
	titleNormal1,
	titleNormal2,
	titleNormal3,
	titleNormal4,
	titleColored1,
	titleColored2,
	subtitle,
	data,
	setData,
	selectedItem,
	setSelectedItem,
	onPressAddButton,
	inputOne,
	setInputOne,
	inputTwo,
	setInputTwo,
	onNextPress,
	type,
	labelInputOne,
	labelInputTwo,
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	present,
	setPresent,
}) => {
	const [addMore, setAddMore] = useState(false)

	const renderFlatList = ({ item }) => {
		const isSelected = selectedItem[item.id]

		const startDate = item.startDate ? new Date(item.startDate) : ''
		const endDate = item?.EndDate ? new Date(item.EndDate) : ''

		const formattedStartDate = startDate.toLocaleString('en-US', {
			month: 'short',
			year: 'numeric',
		})

		const formattedEndDate = endDate.toLocaleString('en-US', {
			month: 'short',
			year: 'numeric',
		})

		let logoUrl =
			'https://logo.clearbit.com/' +
			item?.company?.split(' ')[0].trim() +
			'.com'

		return (
			<Pressable
				onPress={() => {
					setSelectedItem(() => ({
						[item.id]: !isSelected,
					}))
				}}
				p={10}
				borderRadius={6}
				borderColor="$fontGray100"
			>
				<HStack alignItems="center" gap={10}>
					{isSelected ? (
						<Icon
							as={CheckIcon}
							h={15}
							w={15}
							p={3}
							bg={'$green500'}
							color={'white'}
							borderRadius={50}
						/>
					) : (
						<Box
							borderWidth={1}
							h={15}
							w={15}
							p={9.8}
							borderRadius={50}
						/>
					)}
					<CandidateCareerRenderComponent
						defaultImgSource={require('../../../assets/Images/experience.png')}
						imgSource={logoUrl}
						title={item?.designation}
						institution={item?.company}
						startDate={item?.startDate ? formattedStartDate : null}
						endDate={item?.EndDate ? formattedEndDate : null}
						present={item?.Present}
						duration={item?.duration}
						screen={'public'}
					/>
					{!item.UserId && (
						<Pressable
							onPress={() => {
								const newData = data.filter(
									(dataItem) => dataItem.id !== item.id
								)
								setData(newData)
								setSelectedItem(() => {
									delete selectedItem[item.id]
									return selectedItem
								})
							}}
						>
							<Icon
								as={TrashIcon}
								size={'sm'}
								color={'$primary500'}
							/>
						</Pressable>
					)}
				</HStack>
			</Pressable>
		)
	}

	return (
		<Motion.View
			style={{ width: '100%', marginVertical: windowWidth < 480 && 20 }}
			initial={{
				opacity: 0,
				scale: 0.9,
			}}
			animate={{
				opacity: 1,
				scale: 1,
			}}
			transition={{
				default: {
					type: 'timing',
					duration: 350,
					damping: 30,
					stiffness: 1000,
				},
			}}
		>
			<HStack
				paddingHorizontal={windowWidth > 480 ? 25 : 15}
				marginVertical={windowWidth > 480 ? 5 : 15}
				alignItems="center"
			>
				<Box gap={3} flex={1}>
					<Text
						lineHeight={windowWidth > 480 ? 22 : 17}
						fontSize={windowWidth > 480 ? 18 : 14}
					>
						<Text
							fontFamily="$fontHeading"
							fontWeight={700}
							color="$primary950"
						>
							{titleNormal1}
						</Text>
						<Text
							fontFamily="$fontHeading"
							fontWeight={700}
							color="$secondary500"
						>
							{titleColored1}
						</Text>
						<Text
							fontFamily="$fontHeading"
							color="$primary950"
							fontWeight={700}
						>
							{titleNormal2}
						</Text>
						<Text
							fontFamily="$fontHeading"
							fontWeight={700}
							color="$secondary500"
						>
							{titleColored2}
						</Text>
						<Text
							fontFamily="$fontHeading"
							color="$primary950"
							fontWeight={700}
						>
							{titleNormal3}
						</Text>
					</Text>
					{titleNormal4 && (
						<Text
							fontFamily="$fontHeading"
							color="$fontGray100"
							fontWeight={700}
							fontSize={windowWidth > 480 ? 18 : 14}
						>
							{titleNormal4}
						</Text>
					)}
					{subtitle && (
						<Text
							fontFamily="$fontHeading"
							fontSize={windowWidth > 480 ? 12 : 11}
							lineHeight={14}
							color={'#999999'}
						>
							{subtitle}
						</Text>
					)}
				</Box>
			</HStack>

			{addMore ? (
				<Box>
					<VStack
						paddingHorizontal={windowWidth > 480 ? 25 : 15}
						marginVertical={windowWidth > 480 ? 5 : 15}
						alignItems="center"
					>
						<LabelWithInputComponent
							label={labelInputOne}
							value={inputOne}
							setValueChange={(text) => {
								setInputOne(text)
							}}
						/>
						<LabelWithInputComponent
							label={labelInputTwo}
							value={inputTwo}
							setValueChange={(text) => {
								const sanitizedValue = text.replace(
									/[^0-9]/g,
									''
								)
								type === 'workplace'
									? setInputTwo(text)
									: setInputTwo(sanitizedValue)
							}}
						/>
						<Box
							flexDirection={windowWidth > 480 ? 'row' : 'column'}
							justifyContent="space-between"
							w={'100%'}
							zIndex={100000}
						>
							<CustomDatePicker
								label={'Start Date'}
								selectedDate={startDate}
								onDateChange={setStartDate}
							/>

							{!present && (
								<CustomDatePicker
									label={'End Date'}
									selectedDate={endDate}
									onDateChange={setEndDate}
								/>
							)}
						</Box>
						<HStack
							gap={5}
							alignItems={'center'}
							w={'100%'}
							mt={10}
						>
							<Switch
								size="sm"
								value={present}
								onValueChange={setPresent}
							/>
							<Text
								fontFamily="$fontHeading"
								italic
								fontSize={12}
								color={'$secondary500'}
							>
								I am working here
							</Text>
						</HStack>
					</VStack>
					<HStack
						paddingHorizontal={windowWidth > 480 ? 25 : 15}
						marginVertical={windowWidth > 480 ? 5 : 15}
						alignItems="center"
						gap={10}
						zIndex={-10}
					>
						<Pressable
							onPress={() => {
								setInputOne(null)
								setInputTwo(null)
								setEndDate(null)
								setStartDate(null)
								setAddMore(false)
							}}
							borderWidth={1}
							px={10}
							py={5}
							w={80}
							borderColor="$fontGray100"
							borderRadius={6}
						>
							<Text textAlign="center" fontFamily="$fontHeading">
								Cancel
							</Text>
						</Pressable>
						<Pressable
							onPress={() => {
								onPressAddButton()
								setInputOne('')
								setInputTwo('')
								setEndDate(null)
								setStartDate(null)
								setAddMore(false)
							}}
							borderWidth={1}
							px={10}
							py={5}
							w={80}
							borderColor={
								inputOne === '' ||
								inputTwo === '' ||
								inputOne === null ||
								inputTwo === null ||
								(endDate === null && present !== true) ||
								startDate === null
									? '$grey100'
									: '$primary500'
							}
							marginVertical={25}
							disabled={
								inputOne === '' ||
								inputTwo === '' ||
								inputOne === null ||
								inputTwo === null ||
								(endDate === null && present !== true) ||
								startDate === null
							}
							borderRadius={6}
							bg={
								inputOne === '' ||
								inputTwo === '' ||
								inputOne === null ||
								inputTwo === null ||
								(endDate === null && present !== true) ||
								startDate === null
									? '$grey100'
									: '$primary500'
							}
						>
							<Text
								textAlign="center"
								color="$primary0"
								fontFamily="$fontHeading"
							>
								Add
							</Text>
						</Pressable>
					</HStack>
				</Box>
			) : (
				<ScrollView
					paddingHorizontal={windowWidth > 480 ? 25 : 15}
					marginVertical={windowWidth > 480 ? 5 : 15}
					height={windowWidth > 480 ? 300 : 200}
				>
					<FlatList
						data={data}
						renderItem={renderFlatList}
						keyExtractor={(item) => item.id}
					/>
				</ScrollView>
			)}
			{!addMore && (
				<>
					<Box
						paddingHorizontal={windowWidth > 480 ? 25 : 15}
						marginVertical={windowWidth > 480 ? 5 : 15}
						alignItems="flex-start"
					>
						<Pressable
							flexDirection="row"
							alignItems="center"
							borderRadius={6}
							gap={5}
							px={20}
							py={10}
							bg={'$primary500'}
							onPress={() => {
								setAddMore(true)
								setInputOne(null)
								setInputTwo(null)
							}}
						>
							<Icon
								as={AddIcon}
								color={'$primary0'}
								size={'sm'}
							/>
							<Text
								fontFamily="$fontHeading"
								color={'$primary0'}
								fontSize={15}
								fontWeight="500"
							>
								Add more
							</Text>
						</Pressable>
					</Box>
					<Box
						flexDirection={
							windowWidth > 480 ? 'row' : 'column-reverse'
						}
						alignItems={'center'}
						paddingHorizontal={windowWidth > 480 ? 25 : 15}
						gap={windowWidth > 480 ? 20 : 10}
						marginVertical={25}
					>
						<Pressable
							flexDirection="row"
							alignItems="center"
							gap={5}
							onPress={onBackPress}
						>
							<Icon
								as={ArrowLeftIcon}
								size={'xs'}
								color={'$primary500'}
							/>
							<Text
								fontFamily="$fontHeading"
								fontSize={12}
								color={'$primary500'}
								fontWeight="bold"
							>
								Back
							</Text>
						</Pressable>
						<ButtonComponent
							text={'Next'}
							width={windowWidth > 480 ? 200 : '80%'}
							onPress={() => {
								onNextPress()
							}}
						/>
					</Box>
				</>
			)}
		</Motion.View>
	)
}

export default ShareSelectSkeleton
