import React from 'react'
import { Dimensions } from 'react-native'
import EditCertifications from './EditCertifications'
import { useState, useEffect } from 'react'
import useUserStore from '../../../Userstore/UserStore'
import {
	createCertifications,
	deleteCertifications,
	updateCertifications,
} from '../../../graphql/mutations'
import { API } from 'aws-amplify'
import { listsCerticationsByUserId } from '../../../graphql/queries'
import { showToast } from '../../../components/ErrorToast'
import CandidateCareerViewComponent from '../CandidateCareerViewComponent'
import CandidateCareerRenderComponent from '../CandidateCareerRenderComponent'
import { Box } from '@gluestack-ui/themed'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const CertificateComponent = ({ ref, userId, screen }) => {
	const userIdPrivate = useUserStore((state) => state.userId)
	const [DATA, setDATA] = useState([])
	const [loading, setLoading] = useState(true)
	const [showModal, setShowModal] = useState(false)
	// const [arrangedData, setArrangedData] = useState([])
	const [viewHeight, setViewHeight] = useState('')

	const [fields, setFields] = useState({
		id: '',
		Certificatename: '',
		certificateId: '',
		// validity: '',
		Source: '',
	})
	useEffect(() => {}, [])
	const onMainViewLayout = (event) => {
		const { height } = event.nativeEvent.layout
		setViewHeight(height)
	}

	const addCertification = async (newData) => {
		if (!newData.id) {
			const updatedData = {
				Certificatename: newData.Certificatename,
				certificateId: newData.certificateId,
				// validity: newData.validity,
				Source: newData.Source,
				UserId: userId ? userId : userIdPrivate,
			}
			try {
				const result = await API.graphql({
					query: createCertifications,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
					variables: {
						input: updatedData,
					},
				})
				const createdCertification = result.data.createCertifications
				setDATA([...DATA, createdCertification])
			} catch (error) {
				console.error('Error creating certification details:', error)
			}
		} else {
			const updatedData = {
				id: newData.id,
				Certificatename: newData.Certificatename,
				certificateId: newData.certificateId,
				Source: newData.Source,
				// validity: newData.validity,
				UserId: userId ? userId : userIdPrivate,
			}
			try {
				const result = await API.graphql({
					query: updateCertifications,
					authMode: 'AMAZON_COGNITO_USER_POOLS',
					variables: {
						input: updatedData,
					},
				})
				const updatedCertification = result.data.updateCertifications
				UpdateDataObject(updatedCertification)
			} catch (error) {
				console.error('Error updating certification details:', error)
			}
		}
	}

	const UpdateDataObject = (updatedObject) => {
		const RemainingData = DATA.filter((obj) => obj.id !== updatedObject.id)
		setDATA([...RemainingData, updatedObject])
	}

	const handleDelete = async (itemId) => {
		try {
			const updatedData = DATA.filter((item) => item.id !== itemId)
			setDATA(updatedData)

			await API.graphql({
				query: deleteCertifications,
				authMode: 'AMAZON_COGNITO_USER_POOLS',
				variables: {
					input: { id: itemId },
				},
			})
		} catch (error) {
			console.error('Error deleting certification details:', error)
		}
	}

	useEffect(() => {
		getCertificationsData()
	}, [userId, userIdPrivate])

	// useEffect(() => {
	// 	const sortedData = [...DATA].sort((a, b) => {
	// 		const validityA = new Date(a.validity)
	// 		const validityB = new Date(b.validity)

	// 		return validityB.getTime() - validityA.getTime()
	// 	})

	// 	setArrangedData(sortedData)
	// }, [DATA])

	async function getCertificationsData() {
		try {
			const response = await API.graphql({
				query: listsCerticationsByUserId,
				authMode: 'API_KEY',
				variables: {
					UserId: userId ? userId : userIdPrivate,
				},
			})

			const certificationData =
				response.data.listsCerticationsByUserId.items

			setDATA(certificationData)
			setLoading(false)
		} catch (error) {}
	}

	const toastShow = () => {
		showToast({ message: 'Please fill the all the necessary fields.' })
	}

	const renderItemMethod = ({ item }) => {
		// const validity = item.validity ? new Date(item.validity) : ''

		// const formattedValidityDate = validity.toLocaleString('en-US', {
		// 	month: 'short',
		// 	year: 'numeric',
		// })

		let logoUrl =
			'https://logo.clearbit.com/' +
			item?.Source?.split(' ')[0].trim() +
			'.com'

		return (
			<Box w={'100%'} marginVertical={10}>
				<CandidateCareerRenderComponent
					defaultImgSource={require('../../../assets/Images/experience.png')}
					imgSource={logoUrl}
					title={item?.Certificatename}
					institution={item?.Source}
					grades={item?.certificateId}
					onEdit={() => {
						setFields({
							id: item.id,
							Certificatename: item.Certificatename,
							certificateId: item.certificateId,
							// validity: item.validity
							// 	? new Date(item.validity)
							// 	: '',
							Source: item.Source,
						})
						setShowModal(true)
					}}
					onDelete={() => {
						handleDelete(item.id)
					}}
					screen={screen}
				/>
			</Box>
		)
	}

	return (
		<>
		{
				screen == 'public' && DATA.length ==0 ? (<></>) :(
					<Box
			id="main-view"
			onLayout={onMainViewLayout}
			bg="$primary0"
			marginBottom={12}
			p={windowWidth > 480 ? 20 : 10}
			borderWidth={1}
			borderColor="$borderDark0"
			borderRadius={6}
		>
			<CandidateCareerViewComponent
				title={'Certificates'}
				description={'No certificate details added'}
				buttonText={'Add Your Earned Certificates'}
				loading={loading}
				data={DATA}
				renderItem={renderItemMethod}
				addMoreImage={require('../../../assets/Images/certificate.png')}
				onPress={() => setShowModal(true)}
				ref={ref}
				screen={screen}
			/>
			<Box>
				<EditCertifications
					setFields={setFields}
					fields={fields}
					onSave={addCertification}
					setShowModal={setShowModal}
					toast={toastShow}
					modalVisibility={showModal}
					ref={ref}
				/>
			</Box>
		</Box>
				)
		}
		</>
		
	)
}

export default CertificateComponent
