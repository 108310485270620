import { useState } from 'react'
import React from 'react'
import UploadComponent from '../../../newComponents/UploadComponent'
import { Box, Image, Text } from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'
import AvatarComponent from '../../../newComponents/AvatarComponent'
import ButtonComponent from '../../../newComponents/ButtonComponent'
import useUserStore from '../../../Userstore/UserStore'
import { API, Storage } from 'aws-amplify'
import { updatePofilePicture } from '../../../graphql/custom-mutations'

const windowWidth = Dimensions.get('window').width

const AddProfilePic = ({ profile }) => {
	const [fileLocal, setFileLocal] = useState(null)
	const [fileKey, setFileKey] = useState(null)
	const [imageFile, setImageFile] = useState(null)
	const [uploadLoading, setUploadLoading] = useState(false)
	const setImagekeyProfile = useUserStore((state) => state.setImagekey)
	const setUserProfile = useUserStore((state) => state.setUserProfile)

	function handleChange(e) {
		const selectedFile =
			e?.target?.files[0] == undefined ? e[0] : e?.target?.files[0]
		setFileLocal(URL.createObjectURL(selectedFile))
		setImageFile(selectedFile)
		setFileKey(selectedFile.lastModified)
	}

	async function pathToImageFile() {
		try {
			await Storage.put(fileKey, imageFile, {
				level: 'public',
				contentType: 'image/jpeg',
			}).then((res) => {
				console.log('res', res)
				setImagekeyProfile(res.key)
			})
		} catch (err) {
			console.log(err)
		}
	}

	async function updateProfile() {
		const updateProfileInput = {
			id: profile?.id,
			imagekey: fileKey,
		}

		await API.graphql({
			query: updatePofilePicture,
			variables: { input: updateProfileInput },
		})
			.then((res) => {
				setUserProfile({ imagekey: res.data.updateNewprofile })
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const handleUpload = async () => {
		setUploadLoading(true)
		updateProfile()
		await pathToImageFile()
		setUploadLoading(false)
	}

	return (
		<Box
			alignItems="center"
			gap={15}
			borderWidth={1}
			borderColor="$borderDark50"
			p={20}
			borderRadius={15}
		>
			<Text
				fontFamily="$fontHeading"
				fontSize={windowWidth > 480 ? 14 : 12}
				color="$primary950"
				fontWeight="600"
			>
				Add a profile picture
			</Text>
			{fileLocal ? (
				<AvatarComponent name={profile.name} imgSource={fileLocal} />
			) : (
				<Image
					source={require('../../../assets/Images/userImg.png')}
					h={windowWidth > 480 ? 100 : 70}
					w={windowWidth > 480 ? 100 : 70}
					borderRadius={200}
				/>
			)}
			<UploadComponent
				flexDirection={'row'}
				text={'Select an image'}
				height={windowWidth > 480 ? 40 : 35}
				width={windowWidth > 480 ? 170 : 160}
				handleChange={handleChange}
			/>
			<ButtonComponent
				text={'Upload'}
				height={35}
				onPress={handleUpload}
				loading={uploadLoading}
				disabled={fileLocal ? false : true}
			/>
		</Box>
	)
}

export default AddProfilePic
