export const onUpdateRecommendedprofile = /* GraphQL */ `
  subscription OnUpdateRecommendedprofile(
    $filter: ModelSubscriptionRecommendedprofileFilterInput
  ) {
    onUpdateRecommendedprofile(filter: $filter) {
      createdAt
      businessProfileId
      businessUserId
      UserId
      candidateprofileId
      acceptanceStatus
      ReasonofShortlist
      Reasonofrejection
      acceptanceStatus_by_CompanyUser_ID
      acceptanceStatus_by_CompanyUser_name
      recommendedbyId
     
      preferencesId
      JobApplicationsId
      JobPostId
      candidateprofile {
        createdAt
        name
        UserId
        Email
        id
        phone
        about
        IntroVideo 
        Capabilities {
          items {
            createdAt
            ProfileId
            id
            capabilitiesCapabilityConfigId
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
       
        salarycurrent
        salaryexpectation
        certification
        noticeperiod
        currentoffer
        education
        experience
        expertise
        graduation
        image
        resume
        location
        imageicon1
        link1
        imageicon2
        link2
        imageicon3
        link3
        profileviews
        relevant
        connected
        imagekey
        typeofwork
        resumekey
        updatedAt
        owner
        __typename
      }
     
      new
      id
      updatedAt
      owner
      __typename
    }
  }`

export const onDeleteInitiative = /* GraphQL */ `
subscription OnDeleteInitiative(
  $filter: ModelSubscriptionInitiativeFilterInput
) {
  onDeleteInitiative(filter: $filter) {
    id
    owner
    __typename
  }
}
`;
export const onUpdateObjectiveKeyResult = /* GraphQL */ `
  subscription OnUpdateObjectiveKeyResult(
    $filter: ModelSubscriptionObjectiveKeyResultFilterInput
  ) {
    onUpdateObjectiveKeyResult(filter: $filter) {
      id
      progresssPercentage
      owner
      __typename
    }
  }
`;

export const onCreateInitiative = /* GraphQL */ `
  subscription OnCreateInitiative(
    $filter: ModelSubscriptionInitiativeFilterInput
  ) {
    onCreateInitiative(filter: $filter) {
      id
      createdAt
      __typename
    }
  }
`;
export const onUpdateJobApplications = /* GraphQL */ `
  subscription OnUpdateJobApplications(
    $filter: ModelSubscriptionJobApplicationsFilterInput
  ) {
    onUpdateJobApplications(filter: $filter) {
      id
      JobPostId
    
      __typename
    }
  }
`;