import {
	Box,
	Pressable,
	Text,
	VStack,
	Image,
	Icon,
	EditIcon,
	HStack,
} from '@gluestack-ui/themed'
import React from 'react'
import { Dimensions } from 'react-native'
import OutcomeComponent from '../../../newComponents/OutcomeComponent'
import useUserStore from '../../../Userstore/UserStore'
import { OpenHidiPublicView } from '../../../HeplerFunctions/OpenHidiView'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
import { Divider, Heading } from '@gluestack-ui/themed'
const HidiViewProfile = ({ item, navigation, view, screen, PreferenceId }) => {
	const profile = useUserStore((state) => state.profile)
	var impactarr = item?.impact
	if (item.impact) {
		impactarr = impactarr?.split('///')
	}

	const countWords = (str) => str?.split(' ').filter(Boolean).length

	const wordCount = countWords(item?.summary)

	const renderBackgroundText = () => {
		const backgroundText = item?.summary?.split(' ')
		const truncatedBackground = backgroundText?.slice(0, 50)?.join(' ')

		if (wordCount <= 50) {
			return (
				<Text
					fontFamily="$fontContent"
					fontSize={
						windowWidth > 480
							? '$fontContent'
							: '$fontContentMobile'
					}
					lineHeight={windowWidth > 480 ? 20 : 15}
				>
					{item?.summary}
				</Text>
			)
		} else {
			return (
				<Text
					fontFamily="$fontContent"
					fontSize={
						windowWidth > 480
							? '$fontContent'
							: '$fontContentMobile'
					}
					lineHeight={windowWidth > 480 ? 20 : 15}
				>
					{truncatedBackground}...
				</Text>
			)
		}
	}

	return (
		<Box
			marginVertical={screen === 'feed' ? 5 : windowWidth > 480 ? 10 : 5}
			borderBottomWidth={screen == 'feed' ? null : 1}
			borderColor="$borderDark0"
			paddingVertical={
				screen == 'feed' ? null : windowWidth > 480 ? 20 : 10
			}
			paddingHorizontal={
				screen == 'feed' ? null : windowWidth > 480 ? 10 : 5
			}
			bg="$primary0"
		>
			<Pressable
				onPress={() =>
					view == 'public'
						? OpenHidiPublicView(item?.id, PreferenceId)
						: navigation.push('NewHIDI', { hidiId: item?.id })
				}
			>
				{item?.pinnedFlag && (
					<Image
						resizeMode="contain"
						w={80}
						h={30}
						source={require('../../../assets/Images/pinned.png')}
					/>
				)}
				<Box>
					<HStack
						justifyContent="space-between"
						marginBottom={5}
						alignItems="center"
					>
						{item?.htmlheading
							? item?.htmlheading && (
									<Text
										fontFamily="$fontHeading"
										fontSize={
											windowWidth > 480
												? '$fontHeading'
												: '$fontHeadingMobile'
										}
										lineHeight={windowWidth > 480 ? 15 : 10}
										fontWeight="bold"
										paddingBottom={10}
									>
										{item?.htmlheading}
									</Text>
							  )
							: item?.problem
							? item?.problem && (
									<Text
										fontFamily="$fontHeading"
										fontSize={
											windowWidth > 480
												? '$fontHeading'
												: '$fontHeadingMobile'
										}
										lineHeight={windowWidth > 480 ? 15 : 10}
										fontWeight="bold"
										paddingBottom={10}
									>
										{item?.problem}
									</Text>
							  )
							: item?.outcomes.items[0]?.Outcome && (
									<Text
										fontFamily="$fontHeading"
										fontSize={
											windowWidth > 480
												? '$fontHeading'
												: '$fontHeadingMobile'
										}
										lineHeight={windowWidth > 480 ? 15 : 10}
										fontWeight="bold"
										paddingBottom={10}
									>
										{item?.outcomes.items[0]?.Outcome}
									</Text>
							  )}

						{view === 'public' ? null : (
							<Pressable
								onPress={() =>
									navigation.navigate('CapCreateHidi', {
										hidiId: item?.id,
										profile: profile,
										capabilityName:
											item.ConnectedCapability.items[0]
												.Capability?.CapabilityConfig
												?.name,
									})
								}
							>
								<Icon
									as={EditIcon}
									size={windowWidth > 480 ? 'md' : 'xs'}
								/>
							</Pressable>
						)}
					</HStack>
					<Box marginBottom={5}>
						{(item?.tag1 || item?.tag2) && (
							<HStack gap={5}>
								{item?.tag1 && (
									<Box>
										<Text
											color="$primary500"
											paddingVertical={1}
											paddingHorizontal={7}
											bg="$primary200"
											fontWeight="700"
											borderRadius={4}
											fontFamily="$fontContent"
											fontSize={
												windowWidth > 480
													? '$fontSize11'
													: 10
											}
										>
											{item?.tag1}
										</Text>
									</Box>
								)}
								{item?.tag2 && (
									<Box>
										<Text
											color="$primary500"
											paddingVertical={1}
											paddingHorizontal={7}
											bg="$primary200"
											fontWeight="700"
											borderRadius={4}
											fontFamily="$fontContent"
											fontSize={
												windowWidth > 480
													? '$fontSize11'
													: 10
											}
										>
											{item?.tag2}
										</Text>
									</Box>
								)}
							</HStack>
						)}
					</Box>
					{item.htmlheading && (
						<Box w={'96%'} marginVertical={5}>
							{/* <Text lineHeight={15}>{renderBackgroundText()}</Text> */}
						</Box>
					)}

					{item.htmlheading && (
						<>
							{screen === 'feed'
								? null
								: item?.outcomes?.items.length > 0 && (
										<Box marginVertical={5}>
											{/* <OutcomeComponent
												limit={3}
												screen="about"
												props={item?.outcomes}
											/> */}
										</Box>
								  )}
						</>
					)}

					{item?.ConnectedCapability?.items?.length == 1 && (
						<Box
							bg="$primary50"
							paddingVertical={10}
							paddingHorizontal={10}
						>
							<Text
								color="$grey100"
								paddingHorizontal={5}
								fontWeight="700"
								borderRadius={4}
								fontFamily="$fontContent"
								fontSize={
									windowWidth > 480 ? '$fontSize13' : 14
								}
							>
								For the capability
							</Text>
							<Heading
								fontFamily="$fontHeading"
								fontSize={
									windowWidth > 480
										? '$fontHeading'
										: '$fontHeadingMobile'
								}
								bg="$primary50"
								// paddingVertical={windowWidth > 480 ? 10 : 5}
								paddingHorizontal={5}
								borderRadius={6}
								color="$primary500"
								lineHeight={windowWidth > 480 ? 30 : 20}
							>
								{
									item.ConnectedCapability.items[0].Capability
										?.CapabilityConfig?.name
								}
							</Heading>
						</Box>
					)}

					{item?.impact && (
						<Box>
							{impactarr.map((item) => {
								const arr = item?.split('~//~')
								return (
									<>
										{arr[1] == '1' && (
											<Box>
												<Text>{arr[0]}</Text>
												<Image
													source={require('../../../assets/Images/greycheck.png')}
												/>
											</Box>
										)}
										{arr[1] == '2' && (
											<Box>
												<Text>{arr[0]}</Text>
												<Image
													source={require('../../../assets/Images/yellowcheck.png')}
												/>
											</Box>
										)}
										{arr[1] == '3' && (
											<Box>
												<Text>{arr[0]}</Text>
												<Image
													source={require('../../../assets/Images/greencheck.png')}
												/>
											</Box>
										)}
									</>
								)
							})}
							{item.impactOverall && (
								<Text>{item.impactOverall}</Text>
							)}
						</Box>
					)}
				</Box>
				<Box>
					{item?.steps && (
						<HStack>
							<Text>{item.steps} steps to Outcome</Text>
						</HStack>
					)}
				</Box>
			</Pressable>
		</Box>
	)
}

export default HidiViewProfile
