import { TouchableOpacity, StyleSheet, Dimensions } from 'react-native'
import {
	Box,
	VStack,
	View,
	Text,
	Image,
	HStack,
	Pressable,
	Icon,
	EditIcon,
	ExternalLinkIcon,
} from '@gluestack-ui/themed'
import React, { useState, useEffect, useRef } from 'react'
import useImageFileLink from '../../customhook/useImageLinkhook'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
import useUserStore from '../../Userstore/UserStore'
import OutlineView from '../../assets/Images/outlineview.png'
import CircularProgress from './CircularProgress'
import BreadCrumbs from '../../components/BreadCrumbs'
import AvatarComponent from '../AvatarComponent'
import EditProfileModal from './EditProfileModal'
import { showPublicView } from '../../HeplerFunctions/ShowPublicProfile'

export default function AboutBox({ props, publicState }) {
	const profile = useUserStore((state) => state.profile)
	const imgKey = useUserStore((state) => state.imagekeyprofile)
	const fileKey = imgKey

	const fileLink = useImageFileLink(fileKey)
	const publicFileLink = useImageFileLink(props?.imagekey)
	const userId = useUserStore((state) => state.userId)
	const [filledPercentage, setFilledPercentage] = useState(70)
	const [expanded, setExpanded] = useState(false)
	const [showModal, setShowModal] = useState(false)

	const hideModal = () => {
		setShowModal(false)
	}
	const showEditProfileModal = () => {
		setShowModal(true)
	}

	const ref = useRef(null)

	const connectedCapabilitiesCount =
		profile?.Capabilities?.items?.filter(
			(item) => item?.ConnectedHidis?.items.length > 0
		).length ?? 0

	useEffect(() => {
		const newFilledPercentage = Math.min(
			100,
			70 + connectedCapabilitiesCount * 5
		)
		setFilledPercentage(newFilledPercentage)
	}, [connectedCapabilitiesCount])

	const showPublicView = () => {
		const url = `https://app.qrusible.com/Q-profile/${userId}`
		window.open(url, '_blank')
	}

	const countWords = (str) => str?.split(' ')?.filter(Boolean).length

	const wordCount = countWords(profile?.about)

	const toggleExpanded = () => {
		setExpanded(!expanded)
	}

	// const renderAboutText = () => {
	// 	const aboutWords = profile?.about.split(' ')
	// 	const truncatedAbout = aboutWords.slice(0, 20).join(' ')

	// 	if (wordCount <= 20) {
	// 		return <Text>{profile?.about}</Text>
	// 	}

	// 	if (expanded) {
	// 		return (
	// 			<VStack>
	// 				<Text>{profile?.about}</Text>
	// 				<Pressable onPress={toggleExpanded}>
	// 					<Text>Read Less</Text>
	// 				</Pressable>
	// 			</VStack>
	// 		)
	// 	} else {
	// 		return (
	// 			<>
	// 				<Text>{truncatedAbout}...</Text>
	// 				<Pressable onPress={toggleExpanded}>
	// 					<Text>Read More</Text>
	// 				</Pressable>
	// 			</>
	// 		)
	// 	}
	// }

	return (
		<>
			{(props?.name || profile?.name) && (
				<Box
					w={'100%'}
					bg="$primary0"
					borderRadius={6}
					paddingVertical={windowWidth > 480 ? 20 : 10}
					paddingLeft={windowWidth > 480 ? 20 : 10}
					paddingRight={windowWidth > 480 ? 11 : 5}
					marginBottom={10}
					borderWidth={1}
					borderColor="$borderDark0"
				>
					<Box w={'100%'}>
						<HStack w={'100%'} gap={10} alignItems="center">
							<Box>
								{publicState === 'public' ? (
									<AvatarComponent
										name={
											props ? props?.name : profile?.name
										}
										height={windowWidth > 480 ? 70 : 65}
										width={windowWidth > 480 ? 70 : 65}
										imgSource={publicFileLink}
									/>
								) : (
									<CircularProgress
										fileLink={fileLink}
										percent={filledPercentage}
									/>
								)}
							</Box>
							<Box w={'auto'} flex={1}>
								<HStack
									justifyContent="space-between"
									alignItems="center"
								>
									<HStack gap={5} alignItems="center">
										<Text
											fontFamily="$fontContent"
											fontSize={
												windowWidth > 480 ? 18 : 15
											}
											fontWeight="bold"
										>
											{props
												? props?.name
												: profile?.name}
										</Text>
										{publicState !== 'public' && (
											<Pressable
												onPress={() => {
													showPublicView(userId)
												}}
											>
												<Icon
													as={ExternalLinkIcon}
													color={'$primary500'}
													size={
														windowWidth > 480
															? 'md'
															: 'xs'
													}
												/>
											</Pressable>
										)}
									</HStack>
									<Box>
										{publicState === 'public' ? null : (
											<Box>
												<Pressable
													ref={ref}
													onPress={
														showEditProfileModal
													}
													alignItems="center"
													justifyContent="center"
												>
													<Image
														source={require('../../assets/Images/edit.png')}
														h={18}
														w={18}
													/>
												</Pressable>
											</Box>
										)}
									</Box>
								</HStack>
								<Box>
									<Text
										fontFamily="$fontContent"
										fontSize={windowWidth > 480 ? 12 : 11}
										lineHeight={15}
										// fontWeight="bold"
									>
										{props
											? props?.expertise
											: profile?.expertise}
									</Text>
								</Box>
								<Box>
									<Text
										fontFamily="$fontContent"
										fontSize={windowWidth > 480 ? 12 : 11}
										lineHeight={15}
										// fontWeight="bold"
									>
										Experience:{' '}
										{props
											? props?.experience
											: profile?.experience}
									</Text>
								</Box>
							</Box>
							<Box>
								<EditProfileModal
									isOpen={showModal}
									onClose={hideModal}
									ref={ref}
								/>
							</Box>
						</HStack>
						{(props ? props?.about : profile?.about) && (
							<Box
								marginTop={windowWidth > 480 ? 20 : 10}
								// borderWidth={1}
								// borderRadius={6}
								// borderColor="$borderDark0"
								// padding={10}
								gap={6}
							>
								<Text
									fontFamily="$fontHeading"
									fontWeight="700"
								>
									About
								</Text>
								<Text
									fontFamily="$fontHeading"
									fontSize={
										windowWidth > 480
											? '$fontContent'
											: '$fontContentMobile'
									}
									lineHeight={15}
								>
									{props ? props?.about : profile?.about}
								</Text>
							</Box>
						)}
					</Box>
				</Box>
			)}
		</>
	)
}
