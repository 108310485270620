import {
	StyleSheet,
	Text,
	View,
	Image,
	Dimensions,
	TouchableOpacity,
} from 'react-native'
import React from 'react'
import { Divider, HStack } from 'native-base'
import Profile from '../screens/Profile'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
import { useNavigation } from '@react-navigation/native'
import { Auth } from 'aws-amplify'
import useImageFileLink from '../customhook/useImageLinkhook'
import UserAvatar from 'react-native-user-avatar'
const UserDetails = ({ props, params, hidi }) => {
	const fileKey = props.imagekey
	const fileLink = useImageFileLink(fileKey)

	async function getUserData() {
		// Auth.signOut()

		await Auth.currentAuthenticatedUser()
			.then((response) => {
				if (
					response.signInUserSession.accessToken.payload[
						'cognito:groups'
					][0] == 'companys'
				) {
					if (params?.companyname) {
						navigation.push('NewProfile', {
							PreferenceId: params?.PreferenceId,
							companyname: params?.companyname,
							rolename: params?.rolename,
							Shortlisted: params?.Shortlisted,
							UserId: props.UserId,
						})
					} else {
						navigation.push('NewProfile', { UserId: props.UserId })
					}
				} else {
					if (response.attributes.sub == props.UserId) {
						navigation.push('Profile')
					} else {
						navigation.push('UserProfile', { UserId: props.UserId })
					}
				}

				// checkprofile()
			})
			.catch((error) => {
				navigation.push('UserProfile', { UserId: props.UserId })
				console.log('Authentication', error)
			})
	}
	const navigation = useNavigation()
	return (
		<View style={styles.headercontainer}>
			<HStack style={styles.imagetextcontainer}>
				<View style={styles.imageContainer}>
					<TouchableOpacity onPress={() => getUserData()}>
						{fileLink ? (
							<Image
								style={styles.profileimage}
								source={{ uri: fileLink }}
								defaultSource={require('../assets/Images/blueuserprofile.png')}
							/>
						) : (
							<UserAvatar
								style={{
									...styles.headerIcons,
									justifyContent: 'center',
									alignSelf: 'flex-end',
								}}
								size={40}
								name={props.name}
							/>
						)}
					</TouchableOpacity>
				</View>

				<View style={styles.nametext}>
					<TouchableOpacity onPress={() => getUserData()}>
						<Text style={styles.nametext1}>{props.name}</Text>
					</TouchableOpacity>

					<Text style={styles.generatltext}>{props.expertise}</Text>
					<Text style={styles.generatltext}>
						Experience: {props.experience}
					</Text>
				</View>
			</HStack>
			<View>
				<TouchableOpacity
					onPress={() => getUserData()}
					style={styles.viewprofilebutton}
				>
					{/* <Image
						style={styles.profilestyle}
						source={require('../assets/Images/profilepadlock.png')}
					/> */}
					<Text style={styles.viewprofiletext}>View Profile</Text>
				</TouchableOpacity>
			</View>
		</View>
	)
}

export default UserDetails

const styles = StyleSheet.create({
	headercontainer: {
		flex: 1,
		width: windowWidth > 480 ? windowWidth * 0.12 : windowWidth * 0.5,
	},
	imagetextcontainer: {
		flex: 1,
		marginBottom: 10,
	},
	imageContainer: {
		flex: 0.2,
		marginRight: 10,
	},
	profileimage: {
		width: 40,
		height: 40,
		borderRadius: 100,
	},
	nametext: {
		flex: 0.8,
	},
	nametext1: {
		fontWeight: 'bold',
		fontSize: 16,
		flexWrap: 'wrap',
	},
	generatltext: {
		fontSize: 13,
		flexWrap: 'wrap',
	},
	viewprofilebutton: {},
	profilestyle: {},
	viewprofiletext: {
		paddingVertical: 10,
		width: '100%',
		textAlign: 'center',
		color: 'white',
		backgroundColor: '#246BFD',
		borderRadius: 20,
		fontSize: 13,
		fontWeight: 'bold',
		alignContent: 'center',
		justifyContent: 'center',
	},

	headerIcons: {
		height: windowHeight * 0.04,
		width: windowHeight * 0.04,
	},
})
