import { API } from 'aws-amplify'
import {
	listInitiativesByCompanyProfileId,
	listInitiativesByCompanyQrewId,
	listInitiativesBydeployedQrewId,
	listInitiativesByObjectiveId,
	listInistativeByObjectiveKeyResultId
} from '../graphql/custom-queries'
import { updateInitiative } from '../graphql/custom-mutations'
import { updateObjectiveKeyResult, updateDeployedQrew } from '../graphql/custom-mutations'
import { createObjectiveKeyResultProgressHistory } from '../graphql/custom-mutations'

export const listInitiativesByQrew = async (id) => {
	try {
		const response = await API.graphql({
			query: listInitiativesByCompanyQrewId,
			authMode: 'API_KEY',
			variables: {
				companyQrewId: id,
			},
		})
		return response.data.listInitiativesByCompanyQrewId.items
	} catch (error) {
		console.log('Error fetching initiatives', error)
	}
}
export const listInitiativesByObjectiveKeyResult = async (id) => {
	try {
		const response = await API.graphql({
			query: listInistativeByObjectiveKeyResultId,
			authMode: 'API_KEY',
			variables: {
				objectiveKeyResultId: id,
			},
		})
		return response.data.listInistativeByObjectiveKeyResultId.items
	} catch (error) {
		console.log('Error fetching initiatives', error)
	}
}
export const listAllInitiativesByCompanyProfileId = async (id) => {
	try {
		const response = await API.graphql({
			query: listInitiativesByCompanyProfileId,
			authMode: 'API_KEY',
			variables: {
				companyProfileId: id,
			},
		})
		return response.data.listInitiativesByCompanyProfileId.items
	} catch (error) {
		console.log('Error fetching initiatives by company profile ID', error)
	}
}

export const updateInitiativeFunction = async (input) => {
	try {
		const response = await API.graphql({
			query: updateInitiative,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
		return response.data.updateInitiative
	} catch (error) {
		console.log('Error updating initiative', error)
	}
}
export const updateObjectiveKeyFunction = async (input) => {
	try {
		const response = await API.graphql({
			query: updateObjectiveKeyResult,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
		return response.data.updateObjectiveKeyResult
	} catch (error) {
		console.log('Error updating initiative', error)
	}
}

export const createObjectiveKeyResulProgressHistoryFunction = async (input) => {
	try {
		const response = await API.graphql({
			query: createObjectiveKeyResultProgressHistory,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
		return response.data.createObjectiveKeyResultProgressHistory
	} catch (error) {
		console.log('Error updating initiative', error)
	}
}
export const listInitivesByCandidateDeployId = async (id) => {
	try {
		const response = await API.graphql({
			query: listInitiativesBydeployedQrewId,
			authMode: 'API_KEY',
			variables: {
				deployedQrewId: id,
			},
		})
		return response.data.listInitiativesBydeployedQrewId.items
	} catch (error) {
		console.log('Error fetching initiatives by candidate deploy ID', error)
	}
}
export const UpdateDeployedQrew = async (input) => {
	try {
		const response = await API.graphql({
			query: updateDeployedQrew,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
		return response.data.updateDeployedQrew
	} catch (error) {
		console.log('Error creating update deployed', error)
	}
}