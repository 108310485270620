import {
	ArrowRightIcon,
	Box,
	HStack,
	Icon,
	Image,
	Pressable,
	Text,
	VStack,
	FlatList,
} from '@gluestack-ui/themed'
import React, { useEffect, useState } from 'react'
import { Dimensions } from 'react-native'
import EligibilityModal from '../../FeedComponents/CheckEligibility/EligibilityModal'
import SpinnerComponent from '../../newComponents/SpinnerComponent'
import { list_JobPost_by_Domain } from '../../graphql/queries'
import { API } from 'aws-amplify'
import useUserStore from '../../Userstore/UserStore'
import PreferencesModal from './components/PreferencesModal'
import GetOpportunities from '../../customhook/GetOpportunities'
import ButtonComponent from '../../newComponents/ButtonComponent'
import SelectCapabilitiesModal from '../../newComponents/keyCapabilities/SelectCapabilitiesModal'
import { listCapabilitiesByProfileAndJobConnected } from '../../graphql/custom-queries'

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

const ForYou = ({ navigation, inModal, closeModal, potentialOpp }) => {
	const jobDetails = potentialOpp
		? useUserStore(
				(state) =>
					state.potentialOpp &&
					state.potentialOpp.map((potential) => potential[0])
		  )
		: useUserStore((state) => state.jobDetails)

	const getJobs = GetOpportunities()

	const [loading, setLoading] = useState(true)
	const [locationPreference, setLocationPreference] = useState([])
	const empty = []
	const [showModal, setShowModal] = useState(false)
	const [modalShow, setModalShow] = useState(false)
	const [candidatePrefs, setCandidatePrefs] = useState([])
	const domain = useUserStore((state) => state.domain)
	const setPotentialOpp = useUserStore((state) => state.setPotentialOpp)
	const profile = useUserStore((state) => state.profile)

	const filterAndConsolidateJobPosts = (items) => {
		const consolidatedJobPosts = {}

		items.forEach((item) => {
			const connectedJobPosts =
				item.CapabilityConfig.connectedJobPosts.items

			connectedJobPosts.forEach((jobPost) => {
				const jobPostId = jobPost.jobPostID

				if (jobPost.jobPost.AvailablityStatus === 'true') {
					if (consolidatedJobPosts[jobPostId]) {
						consolidatedJobPosts[jobPostId].push(jobPost.jobPost)
					} else {
						consolidatedJobPosts[jobPostId] = [jobPost.jobPost]
					}
				}
			})
		})

		const consolidatedItems = Object.values(consolidatedJobPosts)

		return consolidatedItems
	}

	const getJobDetails = async () => {
		try {
			const response = await API.graphql({
				query: listCapabilitiesByProfileAndJobConnected,
				variables: {
					ProfileId: profile?.id,
				},
			})

			const items =
				response &&
				response?.data?.listCapabilitiesByProfileIdByDate?.items

			if (items) {
				const consolidatedItems = filterAndConsolidateJobPosts(items)
				setPotentialOpp(consolidatedItems)
			}
		} catch (error) {
			console.error('Error fetching potential opportunities:', error)
		}
	}

	useEffect(() => {
		getJobDetails()
	}, [profile?.id])

	function getCandidatePrefs() {
		const candidatePreferences = profile?.CandidatePreferences?.items[0]

		setCandidatePrefs(candidatePreferences)

		if (candidatePreferences) {
			const { location1, location2, location3 } = candidatePreferences

			const newLocations = []

			if (location1) {
				newLocations.push(location1)
			}
			if (location2) {
				newLocations.push(location2)
			}
			if (location3) {
				newLocations.push(location3)
			}

			setLocationPreference(newLocations)
		}
	}

	useEffect(() => {
		getCandidatePrefs()
	}, [])

	useEffect(() => {
		if (
			candidatePrefs &&
			typeof candidatePrefs === 'object' &&
			candidatePrefs.location1 !== '' &&
			candidatePrefs.location2 !== '' &&
			candidatePrefs.location3 !== '' &&
			candidatePrefs.workType !== '' &&
			candidatePrefs.Salaryexpectation !== '' &&
			candidatePrefs.noticePeriod !== '' &&
			candidatePrefs.servingNotice !== '' &&
			candidatePrefs.AvailableDate !== ''
		) {
			setShowModal(false)
		} else {
			setShowModal(true)
		}
	}, [candidatePrefs])

	const [showModalArray, setShowModalArray] = useState(
		Array(jobDetails ? jobDetails.length : null).fill(false)
	)

	const handleShowModal = (index) => {
		const updatedShowModalArray = [...showModalArray]
		updatedShowModalArray[index] = true
		setShowModalArray(updatedShowModalArray)
	}

	const handleCloseModal = (index) => {
		const updatedShowModalArray = [...showModalArray]
		updatedShowModalArray[index] = false
		setShowModalArray(updatedShowModalArray)
	}

	const sortDataByLocationAndCompanyPreference = (
		jobDetails,
		locationPreference
	) => {
		const locationIndexMap = {}
		locationPreference.forEach((location, index) => {
			locationIndexMap[location] = index
		})

		jobDetails &&
			jobDetails.sort((item1, item2) => {
				const location1 = item1?.Location
					? JSON.parse(item1.Location)
					: []
				const location2 = item2?.Location
					? JSON.parse(item2.Location)
					: []

				const locationName1 = Array.isArray(location1)
					? location1[0]?.S
					: null
				const locationName2 = Array.isArray(location2)
					? location2[0]?.S
					: null

				const index1 = locationIndexMap[locationName1]
				const index2 = locationIndexMap[locationName2]

				const defaultIndex = locationPreference.length

				const hasCompanyPreference1 = !!item1?.CompanyPreference
				const hasCompanyPreference2 = !!item2?.CompanyPreference

				if (hasCompanyPreference1 && !hasCompanyPreference2) {
					return -1
				} else if (!hasCompanyPreference1 && hasCompanyPreference2) {
					return 1
				} else {
					return (
						(index1 !== undefined ? index1 : defaultIndex) -
						(index2 !== undefined ? index2 : defaultIndex)
					)
				}
			})

		return jobDetails
	}

	const sortedData = sortDataByLocationAndCompanyPreference(
		jobDetails,
		locationPreference
	)

	const renderItem = ({ item, index }) => {
		let logoUrl = item?.logoUrl
			? item.logoUrl
			: 'https://logo.clearbit.com/' +
			  item?.companyName?.split(' ')[0].trim() +
			  '.com'

		const currentDate = new Date()
		const createdAtDate = new Date(item?.createdAt)

		const timeDifference = currentDate - createdAtDate

		const daysPassed = Math.floor(timeDifference / (1000 * 3600 * 24))

		const locationCompanyExternal = item?.Location
			? JSON.parse(item?.Location)
			: null
		return (
			<>
				<Pressable
					width={
						windowWidth > 480 ? (inModal ? '100%' : '33%') : '100%'
					}
					borderWidth={inModal ? null : 1}
					borderColor="$borderDark0"
					borderRadius={6}
					bg="$primary0"
					p={
						windowWidth > 480
							? inModal
								? 10
								: 20
							: inModal
							? 5
							: 10
					}
					gap={windowWidth > 480 ? (inModal ? 10 : 20) : 10}
					onPress={() => {
						navigation.push('JobDetails', {
							jobPostId: item?.id,
						})
						closeModal && closeModal()
					}}
					m={5}
				>
					<Box
						flexDirection={inModal ? 'row' : 'column'}
						justifyContent={inModal ? 'space-between' : null}
						alignItems={inModal ? 'center' : null}
						gap={windowWidth > 480 ? 10 : 5}
					>
						<HStack
							gap={windowWidth > 480 ? (inModal ? 5 : 10) : 5}
						>
							<Box>
								<Image
									h={windowWidth > 480 ? 40 : 30}
									w={windowWidth > 480 ? 40 : 30}
									source={{ uri: logoUrl }}
									alt="company logo"
									borderRadius={6}
									defaultSource={require('../../assets/Images/experience.png')}
								/>
							</Box>
							<Box
								flexWrap="wrap"
								w={windowWidth < 480 && windowWidth * 0.5}
							>
								{inModal && (
									<Box>
										{item?.CompanyPreference && (
											<Text
												fontFamily="$fontHeading"
												color="$fontGray100"
												fontSize={
													windowWidth > 480 ? 8 : 6
												}
												fontWeight="600"
												lineHeight={10}
											>
												{item
													? daysPassed === 0
														? 'Today'
														: `${daysPassed} days ago`
													: ''}
											</Text>
										)}
										<Text
											fontFamily="$fontHeading"
											fontSize={
												windowWidth > 480 ? 18 : 14
											}
											fontWeight="700"
											lineHeight={
												windowWidth > 480 ? 20 : 15
											}
										>
											{item?.title}
										</Text>
									</Box>
								)}

								<Text
									fontFamily="$fontHeading"
									color="$primary950"
									fontSize={
										windowWidth > 480
											? '$fontContent'
											: '$fontContentMobile'
									}
									fontWeight="500"
									lineHeight={17}
								>
									{item?.companyName}
								</Text>
								<Text
									fontFamily="$fontHeading"
									color="$fontGray"
									fontSize={
										windowWidth > 480
											? '$fontSize13'
											: '$fontSize11'
									}
									fontWeight="500"
									lineHeight={17}
								>
									{item?.CompanyPreference
										? item?.CompanyPreference?.modeofwork
										: locationCompanyExternal
										? locationCompanyExternal[0]?.S
										: null}
								</Text>
							</Box>
						</HStack>
						<Box>
							{inModal ? null : (
								<Text
									fontFamily="$fontHeading"
									fontSize={
										windowWidth > 480
											? 18
											: '$fontHeadingMobile'
									}
									fontWeight="700"
									color="$primary950"
								>
									{item?.title}
								</Text>
							)}
						</Box>
						<Box>
							{inModal ? null : (
								<Text
									fontFamily="$fontHeading"
									fontSize={
										windowWidth > 480 ? '$fontSize11' : 10
									}
									fontWeight="600"
									color="$fontGray"
								>
									{item
										? item?.CompanyPreference
											? `${daysPassed} days ago`
											: null
										: ''}
								</Text>
							)}
						</Box>
						{inModal && (
							<Box>
								<Icon as={ArrowRightIcon} />
							</Box>
						)}
					</Box>
				</Pressable>
				{/* <EligibilityModal
					isOpen={showModalArray[index]}
					jobPostsId={item?.id}
					onClose={() => handleCloseModal(index)}
					navigation={navigation}
					setModalShow={() => {}}
					closeModal={closeModal}
				/> */}
			</>
		)
	}

	return (
		<>
			<Box marginTop={windowWidth > 480 ? 20 : 10}>
				<SelectCapabilitiesModal
					isOpen={modalShow}
					setModalShow={setModalShow}
					onClose={() => {
						setModalShow(false)
					}}
				/>
				{candidatePrefs?.currentSalary ? (
					<FlatList
						data={sortedData}
						numColumns={inModal ? 1 : windowWidth > 480 ? 3 : 1}
						renderItem={renderItem}
						keyExtractor={(item) => item.id}
						ListEmptyComponent={() => {
							return (
								<Box alignItems="center" marginVertical={10}>
									{potentialOpp ? (
										<>
											<Text
												fontWeight="700"
												fontFamily="$fontHeading"
												fontSize={
													windowWidth > 480
														? '$fontContent'
														: '$fontContentMobile'
												}
											>
												Claim capabiltiies to get the
												recommended opportunities!
											</Text>
											<ButtonComponent
												text={'Claim Now'}
												marginVertical={
													windowWidth > 480 ? 10 : 5
												}
												onPress={() => {
													setModalShow(true)
												}}
											/>
										</>
									) : (
										<>
											<SpinnerComponent
												smallText={'Loading..'}
											/>
										</>
									)}
								</Box>
							)
						}}
					/>
				) : potentialOpp ? (
					<FlatList
						data={sortedData}
						numColumns={inModal ? 1 : windowWidth > 480 ? 3 : 1}
						renderItem={renderItem}
						keyExtractor={(item) => item.id}
						ListEmptyComponent={() => {
							return (
								<Box alignItems="center" marginVertical={10}>
									<Text
										fontFamily="$fontContent"
										fontSize={windowWidth > 480 ? 20 : 15}
										lineHeight={windowWidth > 480 ? 25 : 20}
										fontWeight="bold"
										textAlign="center"
									>
										Claim capabiltiies to get the
										recommended opportunities!
									</Text>
									<ButtonComponent
										text={'Claim Now'}
										marginVertical={
											windowWidth > 480 ? 10 : 5
										}
										onPress={() => {
											setModalShow(true)
										}}
									/>
								</Box>
							)
						}}
					/>
				) : (
					<Box
						justifyContent="center"
						alignItems="center"
						h={windowHeight * 0.3}
					>
						<Box justifyContent="center" alignItems="center">
							<Text
								fontFamily="$fontContent"
								fontSize={windowWidth > 480 ? 20 : 15}
								lineHeight={windowWidth > 480 ? 25 : 20}
								fontWeight="bold"
							>
								Set your preferences to be able to
							</Text>
							<Text
								fontFamily="$fontContent"
								lineHeight={windowWidth > 480 ? 25 : 20}
								fontSize={windowWidth > 480 ? 20 : 15}
								fontWeight="bold"
							>
								view all the{' '}
								<Text
									fontFamily="$fontContent"
									color="$primary500"
									lineHeight={windowWidth > 480 ? 25 : 20}
									fontWeight="bold"
								>
									opportunities
								</Text>
							</Text>
						</Box>
						<ButtonComponent
							text={'Set Preferences'}
							marginVertical={windowWidth > 480 ? 15 : 7}
							onPress={() => {
								navigation.push('CandidatePreferences')
							}}
						/>
					</Box>
				)}
			</Box>
		</>
	)
}

export default ForYou
