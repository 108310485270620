/* fonts */


export const FontFamily = {
  bodyLargeBold: "Urbanist",
  urbanistSemiBold: "Urbanist",
  urbanistRegular: "Urbanist",
  urbanistMedium: "Urbanist",
};
/* font sizes */
export const FontSize = {
  bodyLargeBold_size: 16,
  size_sm: 12,
  size_xs: 12,
  size_3xs: 10,
  h5Bold_size: 20,
};
/* Colors */
export const Color = {
  othersWhite: "#fff",
  colorWhitesmoke_100: "#eee",
  primary500: "#246bfd",
  alertsStatusButtonDisabled: "#3062c8",
  colorLavender: "#e9f0ff",
  themePrimary: "#00677f",
  colorMidnightblue: "#150b3d",
  colorCrimson: "#fd2458",
  colorHoneydew: "rgba(230, 254, 238, 0.5)",
  colorLightgreen: "#50db7b",
  colorDimgray_100: "#656565",
  colorDimgray_200: "#524b6b",
  colorGray_100: "#2b2b2b",
  colorGray_200: "#212121",
  colorGray_300: "#0f1828",
  colorDarkslategray: "#424242",
  colorBlack: "#000",
  colorGhostwhite: "#f8faff",

};
/* Paddings */
export const Padding = {
  p_xl: 20,
  p_5xl: 24,
  p_base: 16,
  p_lg: 18,
  p_3xs: 15,
  p_6xs: 7,
  p_9xs: 4,
  p_10xs: 3,
  p_8xs: 5,
  p_5xs: 8,
};
/* border radiuses */
export const Border = {
  br_81xl: 100,
  br_381xl: 400,
  br_5xl: 24,
  br_smi: 13,
  br_xl: 20,
};
