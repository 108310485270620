import React, { useState, useCallback } from 'react'
import { Dimensions } from 'react-native'
import { useNavigation } from '@react-navigation/native'
// import AutoLayoutVertical from "./AutoLayoutVertical";

import CapabilitiesRenderComponent from '../newComponents/keyCapabilities/CapabilitiesRenderComponent'
import { Box, Divider, FlatList, Pressable, Text } from '@gluestack-ui/themed'
import Hidiheader from '../components/Hidiheader'
import ButtonComponent from '../newComponents/ButtonComponent'
import EligibilityModal from './CheckEligibility/EligibilityModal'
import AboutCompany from '../newScreens/opportunities/AboutCompany'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const JobPostFeed = React.memo(
	({
	  item,
	  modalShow,
	  setModalShow,
	  candidateCapabilities,
	  navigation,
	}) => {
	const [modalShowEligibility, setModalShowEligibility] = useState(false)

	// const renderItem = ({ item, index }) => {
	// 	return (
	// 		<Box
	// 			w={280}
	// 			marginHorizontal={5}
	// 			borderWidth={1}
	// 			borderColor="$borderDark0"
	// 			paddingVertical={10}
	// 			paddingHorizontal={15}
	// 			borderRadius={6}
	// 		>
	// 			<CapabilitiesRenderComponent
	// 				item={item}
	// 				setShowModal={setModalShow}
	// 				navigation={navigation}
	// 				screen={'feed'}
	// 				disable={true}
	// 			/>
	// 		</Box>
	// 	)
	// }
	let logoUrl =
		'https://logo.clearbit.com/' +
		item?.jobPost?.companyName.split(' ')[0].trim() +
		'.com'
	return (
		<Box
			w={'100%'}
			borderWidth={1}
			borderColor="$borderDark0"
			p={windowWidth > 480 ? 20 : 10}
			bg="$primary0"
			marginVertical={10}
			h={'fit-content'}
		>
			<EligibilityModal
				isOpen={modalShowEligibility}
				setModalShow={setModalShowEligibility}
				onClose={() => setModalShowEligibility(false)}
				jobPosts={item}
				candidateCapabilities={candidateCapabilities}
				navigation={navigation}
			/>

			<Box>
				<Text
					fontFamily="$fontContent"
					fontSize={windowWidth > 480 ? '$fontSize12' : 10}
					fontWeight="700"
					lineHeight={15}
				>
					<Text
						color={'$primary500'}
						fontFamily="$fontContent"
						fontSize={windowWidth > 480 ? '$fontSize12' : 10}
						fontWeight="700"
						lineHeight={15}
					>
						{item?.jobPost?.companyName}
					</Text>{' '}
					has opportunities available for candidates with the
					following capabilities
				</Text>
			</Box>
			<Divider marginVertical={10} h={0.5} />
			<Hidiheader
				props={item?.jobPost}
				screen={'feed'}
				onPress={() => {}}
				company={true}
				logoUrl={logoUrl}
				navigation={navigation}
			/>
			{/* <Box marginVertical={10}>
				<FlatList
					data={item.jobPost?.CapabilitiesRequired.items}
					renderItem={renderItem}
					keyExtractor={(item) => item.id.toString()}
					horizontal
					showsHorizontalScrollIndicator={false}
					scrollEnabled
				/>
			</Box> */}
			<Divider marginTop={10} h={0.5} />

			{item?.jobPost?.CompanyDescription ||
			item?.jobPost?.description ||
			item?.jobPost?.CompanyPreference?.JobType ||
			item?.jobPost?.CompanyPreference?.modeofwork ? (
				<AboutCompany
					companyName={item?.jobPost?.companyName}
					companyDescription={item?.jobPost?.CompanyDescription}
					opportunity={item?.jobPost?.description}
					jobType={item?.jobPost?.CompanyPreference?.JobType}
					location={item?.jobPost?.CompanyPreference?.Location}
					modeOfWork={item?.jobPost?.CompanyPreference?.modeofwork}
					postedOn={item?.jobPost?.createdAt?.split('T')[0]}
				/>
			) : null}

			<Divider marginVertical={10} h={0.5} />
			<Box alignItems="flex-end" w={'$full'}>
				<ButtonComponent
					text={'Check Eligibility'}
					width={'fit-content'}
					onPress={() =>
						navigation.push('JobDetails', {
							jobPostId: item?.jobPost?.id,
						})
					}
				/>
			</Box>
		</Box>
	)
}
);

export default JobPostFeed
