import React, { useState } from 'react'
import { Dimensions } from 'react-native'
import { Box } from '@gluestack-ui/themed'
import JourneyTabs from './JourneyTabs'
import useUserStore from '../../Userstore/UserStore'
import { useEffect } from 'react'
import NewKeyCapabilities from '../../newComponents/keyCapabilities/NewKeyCapabilities'
import WhatIDidView from '../profileScreen/WhatIDid/WhatIDidView'

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

const Journey = ({ navigation }) => {
	const profile = useUserStore((state) => state.profile)
	const hidis = useUserStore((state) => state.hidis)
	const [tabState, setTabState] = useState('cap')

	useEffect(() => {
	})

	return (
		<Box
			bg="$backgroundGray"
			minHeight={windowHeight - 62}
			marginTop={62}
			marginBottom={windowWidth > 480 ? null : 50}
			paddingHorizontal={windowWidth > 480 ? 100 : 5}
			paddingVertical={windowWidth > 480 ? 20 : 5}
			paddingBottom={windowWidth > 480 ? null : 50}
		>
			<JourneyTabs
				setTabState={setTabState}
				tabState={tabState}
				labels={['All Capabilities', 'Experiences']}
				stateName={['cap', 'experiences']}
			/>
			{tabState === 'cap' && (
				<Box>
					<NewKeyCapabilities
						tabState={tabState}
						profile={profile}
						setShowModal={() => {}}
						screen={'Journey'}
					/>
				</Box>
			)}
			{tabState === 'experiences' && (
				<Box>
					<WhatIDidView
						navigation={navigation}
						data={hidis}
						screen={'Journey'}
					/>
				</Box>
			)}
		</Box>
	)
}

export default Journey
