import create from 'zustand'
import { persist, devtools } from 'zustand/middleware'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Auth } from 'aws-amplify'
import { showToast } from '../components/ErrorToast'

const userStore = (set, get) => ({
	loaded: false,
	userId: '',
	profileId: '',
	companyProfileId: '',
	companyuserprofileId: '',
	companyName: '',
	companyUsername: '',
	firstTimeUser: null,
	isLoggedIn: false,
	firstTimeLogin: false,
	error: '',
	group: '',
	countCapabilities: '',
	claimedCapabilities: '',
	userSkills: '',
	hidis: '',
	savedurl: '',
	checkScreen: '',
	screenSideNav: 'Home',
	selectedOption: 0,
	domain: '',
	jobsAppliedDetails: '',
	jobDetails: '',
	potentialOpp: '',
	name: '',
	imageprofile: null,
	imagekeyprofile: null,
	profile: '',
	GlobalCapabilities: '',
	userEmail: null,
	user: {
		email: '',
		password: '',
	},
	resetPassword: {
		code: '',
		newPassword: '',
		confirmPassword: '',
	},
	newPassParams: {
		newPassword: '',
		confirmPassword: '',
	},
	setCountCapabailities: (countCapabilities) => set({ countCapabilities }),
	setUserSkills: (userSkills) => set({ userSkills }),
	setSelectedOption: (selectedOption) => set({ selectedOption }),
	setPotentialOpp: (potentialOpp) => set({ potentialOpp }),
	setJobDetails: (jobDetails) => set({ jobDetails }),
	setJobsAppliedDetails: (jobsAppliedDetails) => set({ jobsAppliedDetails }),
	setDomain: (domain) => set({ domain }),
	setCheckScreen: (checkScreen) => set({ checkScreen }),
	setScreenSideNav: (screenSideNav) => set({ screenSideNav }),
	setClaimedCapabailities: (claimedCapabilities) =>
		set({ claimedCapabilities }),
	setUserProfile: (profile) => set({ profile }),
	setHidis: (hidis) => set({ hidis }),
	setGlobalCapabilities: (GlobalCapabilities) => set({ GlobalCapabilities }),
	setUserId: (userId) => set({ userId }),
	setProfileId: (profileId) => set({ profileId }),
	setSavedUrl: (savedurl) => set({ savedurl }),
	setName: (name) => set({ name }),
	setImage: (imageprofile) => set({ imageprofile }),
	setImagekey: (imagekeyprofile) => set({ imagekeyprofile }),
	setcompanyuserProfileId: (companyuserprofileId) =>
		set({ companyuserprofileId }),
	setcompanyProfileId: (companyProfileId) => set({ companyProfileId }),
	setcompanyName: (companyName) => set({ companyName }),
	setcompanyUsername: (companyUsername) => set({ companyUsername }),
	setFirstTimeLogin: (firstTimeLogin) => set({ firstTimeLogin }),
	setNewPassParams: (data) => set({ newPassParams: data }),
	updateUser: (loginParams) => set({ user: loginParams }),
	updateResetPassword: (resetParams) => set({ resetPassword: resetParams }),
	isLoading: false,

	signInWithGoogle: async () => {
		try {
			set(() => ({
				isLoading: true,
			}))
			await Auth.federatedSignIn({ provider: 'Google' })
			const userInfo = await Auth.currentAuthenticatedUser()

			if (!userInfo.attributes.email_verified) {
				await Auth.updateUserAttributes(userInfo, {
					email_verified: true,
				})
			}
		} catch (error) {
			console.error('Error signing in with Google:', error)
		}
	},

	checkUserLoggedIn: async () => {
		try {
			const data = await Auth.currentAuthenticatedUser({
				bypassCache: true,
			})
			set(() => ({
				userId: data?.attributes.sub,
				group: data.signInUserSession.accessToken.payload[
					'cognito:groups'
				][0],
				userEmail: data?.attributes.email,
				firstTimeUser: null,
				isLoggedIn: true,
				loaded: true,
				isLoading: false,
				error: '',
				name: '',
				imageprofile: '',
				imagekeyprofile: '',
			}))
		} catch (err) {
			set(() => ({
				isLoggedIn: false,
				isLoading: false,
				firstTimeLogin: null,
				loaded: true,
				error: '',
			}))
		}
	},
	setFirstTimePass: async () => {
		const user = get().firstTimeUser

		const { Newpassword } = get().newPassParams
		try {
			const newUser = await Auth.completeNewPassword(user, Newpassword)
			console.log('Logged In Successfully')
			newUser &&
				set(() => ({
					userId: newUser?.username,
					group: newUser.signInUserSession.accessToken.payload[
						'cognito:groups'
					][0],
					isLoggedIn: true,
					firstTimeUser: null,
					loaded: true,
					firstTimeLogin: false,
					error: '',
				}))
		} catch ({ message }) {
			set(() => ({
				error: 'Your profile is not setted up properly pls contact our customer support',
			}))
		}
	},

	signUp: async () => {
		try {
			const { email, password } = get().user

			if (email && password) {
				try {
					await Auth.signUp({
						username: email,
						password: password,
						attributes: {
							email,
						},
					})

					return true
					// setModalVisible(true)
				} catch (error) {
					if (error.code === 'UsernameExistsException') {
						try {
							await Auth.resendSignUp(email)
							return true
						} catch (error) {
							if (error.code === 'LimitExceededException') {
								showToast({
									message:
										'OTP Limit exceeded!. Please try again later',
								})
							} else {
								set(() => ({
									error:
										'You already have an account with ' +
										email,
								}))
							}
							return false
						}
					} else {
						set(() => ({
							error: error.message,
						}))
					}
				}
			} else {
				set(() => ({
					error: 'plz fill email and password',
				}))
				// setLoading(false)
			}
		} catch ({ message }) {
			set(() => ({
				error: message,
			}))
		}
	},

	signIn: async () => {
		try {
			const { email, password } = get().user
			const user = await Auth.signIn(email, password)

			if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
				set(() => ({
					firstTimeUser: user,
					firstTimeLogin: true,
					error: '',
				}))
				return
			}
			if (
				!user?.signInUserSession?.accessToken?.payload['cognito:groups']
			) {
				set(() => ({
					loaded: true,
					error: 'Your profile is being updated. Please Try after sometime',
				}))
			} else {
				const group =
					user?.signInUserSession?.accessToken?.payload[
						'cognito:groups'
					][0]

				if (!group) {
					set(() => ({
						loaded: true,
						error: 'Your profile is being updated. Please Try after sometime',
					}))
				} else {
					set(() => ({
						userId: user?.attributes.sub,
						userEmail: user?.attributes.email,
						group: group,
						isLoggedIn: true,
						profile: null,
						profileId: null,
						firstTimeUser: null,
						loaded: true,
						error: '',
					}))
				}
			}
		} catch ({ message }) {
			set(() => ({
				error: message,
			}))
			showToast({ message: `${message}` })
		}
	},
	signOut: async () => {
		try {
			await Auth.signOut()
			console.log('Logged Out')
			set(() => ({
				isLoggedIn: false,
				group: '',
				error: '',
				userId: '',
				profileId: '',
				profile: '',
				companyuserprofileId: '',
				userEmail: '',
				firstTimeLogin: null,
			}))
		} catch ({ message }) {
			set(() => ({
				error: message,
			}))
		}
	},
	sendOTP: async () => {
		try {
			const { email } = get().user
			const data = await Auth.forgotPassword(email)
			await showSuccess('Please check your mail for OTP')
			data &&
				set({
					resetPassword: {
						code: '',
						newPassword: '',
						confirmPassword: '',
						error: '',
					},
				})
		} catch ({ message }) {
			set(() => ({
				error: message,
			}))
		}
	},
	forgotPassword: async () => {
		const { code, newPassword, confirmPassword } = get().resetPassword
		const { email } = get().user
		const password = newPassword
		try {
			const data = await Auth.forgotPasswordSubmit(email, code, password)
			await showSuccess('Password is successfully changed')
			data &&
				set({
					user: { email, password },
					error: '',
				})
			return
		} catch ({ message }) {
			set(() => ({
				error: message,
			}))
		}
	},
})

const useUserStore = create(
	devtools(
		persist(userStore, {
			name: 'user-store',
			getStorage: () => AsyncStorage,
		}),
		{
			name: 'user-store',
		}
	)
)

export default useUserStore
