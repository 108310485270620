import React from 'react'
import { Dimensions } from 'react-native'
import { useDropzone } from 'react-dropzone'
import { Box, HStack, Image, Text, Pressable } from '@gluestack-ui/themed'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const UploadComponent = ({
	handleChange,
	width,
	height,
	flexDirection,
	text,
}) => {
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: handleChange,
	})

	return (
		<Box
			{...getRootProps()}
			w={width}
			h={height}
			bg="$backgroundGray"
			alignSelf="center"
			borderWidth={1}
			borderRadius={6}
			borderStyle="dashed"
			borderColor="$primary950"
			alignItems="center"
			justifyContent="center"
			marginVertical={5}
		>
			<input
				{...getInputProps()}
				onChange={(e) => {
					handleChange(e)
				}}
			/>
			{isDragActive ? (
				<Text textAlign="center">Drop the files here</Text>
			) : (
				<Box
					alignItems="center"
					justifyContent="center"
					gap={5}
					flexDirection={flexDirection}
				>
					<Image
						source={require('../assets/Images/file-upload.png')}
						w={windowWidth > 480 ? 30 : 20}
						h={windowWidth > 480 ? 30 : 20}
						alt="Upload PDF Image"
					/>
					<Text
						textAlign="center"
						fontFamily="$fontContent"
						fontSize={windowWidth > 480 ? 13 : 11}
						color="$primary950"
						fontWeight="700"
					>
						{text}
					</Text>
				</Box>
			)}
		</Box>
	)
}

export default UploadComponent
