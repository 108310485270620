import {
	View,
	Text,
	Image,
	TouchableOpacity,
	Linking,
	ViewBase,
	ScrollView,
	StyleSheet,
} from 'react-native'
import React from 'react'
import AboutBox from '../newComponents/aboutBox/AboutBox'
import { Divider } from 'native-base'
const Capabilities = () => {
	return (
		<View style={{ paddingVertical: 16, paddingHorizontal: 24 }}>
			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignContent: 'center',
				}}
			>
				<Text
					style={{
						fontFamily: '',
						fontSize: 24,
						fontWeight: 'Bold',
						color: '#212121',
					}}
				>
					Profile
				</Text>
				<TouchableOpacity>
					<Image
						style={{ height: 28, width: 28 }}
						source={require('../assets/Images/setting-black.png')}
					/>
				</TouchableOpacity>
			</View>
			<AboutBox />
			<Divider thickness="1" bg="#eeeeee" />
			<View style={styles.box}>
				<Text style={styles.headings}>Key Capabilities</Text>
				<Divider thickness="1" bg="#eeeeee" />
				<View>
					<View style={styles.capabilityItem}>
						<View
							style={{
								borderRadius: 13,
								padding: 10,
								borderWidth: 1,
								borderColor: '#EEEEEE',
								width: 50,
								height: 50,
							}}
						>
							<Image
								style={{ height: 30, width: 30 }}
								source={require('../assets/Images/3star.png')}
							/>
						</View>
						<View
							style={{
								flexDirection: 'column',
								gap: 8.5,
								flex: 1,
							}}
						>
							<Text style={styles.capHeading}>
								Generate Viral Marketing Content
							</Text>
							<View style={{ flexDirection: 'row', gap: 10 }}>
								<Text
									style={[
										styles.capText,
										{ flex: 3, alignSelf: 'center' },
									]}
								>
									Worked on
								</Text>
								<ScrollView
									style={{ flex: 8 }}
									showsHorizontalScrollIndicator={false}
									horizontal={true}
								>
									<View style={styles.capTag}>
										<Text
											style={{
												color: '#246BFD',
												fontSize: 10,
											}}
										>
											Short Format Videos
										</Text>
									</View>
									<View style={styles.capTag}>
										<Text
											style={{
												color: '#246BFD',
												fontSize: 10,
											}}
										>
											Short Format Videos
										</Text>
									</View>
									<View style={styles.capTag}>
										<Text
											style={{
												color: '#246BFD',
												fontSize: 10,
											}}
										>
											Ads
										</Text>
									</View>
									<View style={styles.capTag}>
										<Text
											style={{
												color: '#246BFD',
												fontSize: 10,
											}}
										>
											Meme
										</Text>
									</View>
								</ScrollView>
							</View>
							<Text style={styles.capText}>
								Based on 4 things Andrew did
							</Text>
						</View>
					</View>
					<View style={styles.capabilityItem}>
						<View
							style={{
								borderRadius: 13,
								padding: 10,
								borderWidth: 1,
								borderColor: '#EEEEEE',
								width: 50,
								height: 50,
							}}
						>
							<Image
								style={{ height: 30, width: 30 }}
								source={require('../assets/Images/3star.png')}
							/>
						</View>
						<View
							style={{
								flexDirection: 'column',
								gap: 8.5,
								flex: 1,
							}}
						>
							<Text style={styles.capHeading}>
								Create Marketing Campaigns for B2C products
							</Text>
							<View style={{ flexDirection: 'row', gap: 10 }}>
								<Text
									style={[
										styles.capText,
										{ flex: 3, alignSelf: 'center' },
									]}
								>
									Worked on
								</Text>
								<ScrollView
									style={{ flex: 8 }}
									showsHorizontalScrollIndicator={false}
									horizontal={true}
								>
									<View style={styles.capTag}>
										<Text
											style={{
												color: '#246BFD',
												fontSize: 10,
											}}
										>
											Google Ads
										</Text>
									</View>
									<View style={styles.capTag}>
										<Text
											style={{
												color: '#246BFD',
												fontSize: 10,
											}}
										>
											Meta Ads
										</Text>
									</View>
									<View style={styles.capTag}>
										<Text
											style={{
												color: '#246BFD',
												fontSize: 10,
											}}
										>
											Print Media
										</Text>
									</View>
								</ScrollView>
							</View>
							<Text style={styles.capText}>
								Based on 3 things Andrew did
							</Text>
						</View>
					</View>
					<View style={styles.capabilityItem}>
						<View
							style={{
								borderRadius: 13,
								padding: 10,
								borderWidth: 1,
								borderColor: '#EEEEEE',
								width: 50,
								height: 50,
							}}
						>
							<Image
								style={{ height: 30, width: 30 }}
								source={require('../assets/Images/1star.png')}
							/>
						</View>
						<View
							style={{
								flexDirection: 'column',
								gap: 8.5,
								flex: 1,
							}}
						>
							<Text style={styles.capHeading}>
								Performance Marketing for D2C brands
							</Text>
							<View style={{ flexDirection: 'row', gap: 10 }}>
								<Text
									style={[
										styles.capText,
										{ flex: 3, alignSelf: 'center' },
									]}
								>
									Worked on
								</Text>
								<ScrollView
									style={{ flex: 8 }}
									showsHorizontalScrollIndicator={false}
									horizontal={true}
								>
									<View style={styles.capTag}>
										<Text
											style={{
												color: '#246BFD',
												fontSize: 10,
											}}
										>
											Shopify
										</Text>
									</View>
								</ScrollView>
							</View>
							<Text style={styles.capText}>
								Based on 1 things Andrew did
							</Text>
						</View>
					</View>
					<View
						style={{
							flexDirection: 'column',
							borderRadius: 13,
							marginVertical: 10,
							padding: 10,
							paddingHorizontal: 20,
							backgroundColor: '#FFF2F2',
							flexDirection: 'column',
							alignSelf: '',
							gap: 3,
						}}
					>
						<Text style={styles.capHeading}>Next Steps</Text>
						<Text>Add more evidences to boost capability</Text>
					</View>
				</View>
			</View>
			<View style={styles.box}>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<Text style={styles.headings}>Work Experience</Text>
					<TouchableOpacity>
						<Image
							style={{ height: 24, width: 24 }}
							source={require('../assets/Images/dropdown.png')}
						/>
					</TouchableOpacity>
				</View>
				<Divider thickness="1" bg="#eeeeee" />
				<View>
					<View style={styles.capabilityItem}>
						<View
							style={{
								borderRadius: 13,
								padding: 10,
								borderWidth: 1,
								borderColor: '#EEEEEE',
								width: 50,
								height: 50,
							}}
						>
							<Image
								style={{ height: 30, width: 30 }}
								source={require('../assets/Images/qrusible-black-logo.png')}
							/>
						</View>
						<View
							style={{
								flexDirection: 'column',
								gap: 8.5,
								flex: 1,
							}}
						>
							<Text style={styles.capHeading}>
								Social Media Manager
							</Text>
							<Text style={styles.capText}>Qrusible Pvt Ltd</Text>
							<Text style={styles.capText}>
								Dec 2021 - Present
							</Text>
						</View>
					</View>
					<View style={styles.capabilityItem}>
						<View
							style={{
								borderRadius: 13,
								padding: 10,
								borderWidth: 1,
								borderColor: '#EEEEEE',
								width: 50,
								height: 50,
							}}
						>
							<Image
								style={{ height: 30, width: 30 }}
								source={require('../assets/Images/oviyum logo.png')}
							/>
						</View>
						<View
							style={{
								flexDirection: 'column',
								gap: 8.5,
								flex: 1,
							}}
						>
							<Text style={styles.capHeading}>
								Digital Marketing Consultant
							</Text>
							<Text style={styles.capText}>
								Oviyum Technologies
							</Text>
							<Text style={styles.capText}>
								Dec 2018 - Dec 2021
							</Text>
						</View>
					</View>
				</View>
			</View>
			<View style={styles.box}>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<Text style={styles.headings}>Work Experience</Text>
					<TouchableOpacity>
						<Image
							style={{ height: 24, width: 24 }}
							source={require('../assets/Images/dropdown.png')}
						/>
					</TouchableOpacity>
				</View>
				<Divider thickness="1" bg="#eeeeee" />
				<View>
					<View style={styles.capabilityItem}>
						<View
							style={{
								borderRadius: 13,
								padding: 10,
								borderWidth: 1,
								borderColor: '#EEEEEE',
								width: 50,
								height: 50,
							}}
						>
							<Image
								style={{ height: 30, width: 30 }}
								source={require('../assets/Images/qrusible-black-logo.png')}
							/>
						</View>
						<View
							style={{
								flexDirection: 'column',
								gap: 8.5,
								flex: 1,
							}}
						>
							<Text style={styles.capHeading}>
								Social Media Manager
							</Text>
							<Text style={styles.capText}>Qrusible Pvt Ltd</Text>
							<Text style={styles.capText}>
								Dec 2021 - Present
							</Text>
						</View>
					</View>
					<View style={styles.capabilityItem}>
						<View
							style={{
								borderRadius: 13,
								padding: 10,
								borderWidth: 1,
								borderColor: '#EEEEEE',
								width: 50,
								height: 50,
							}}
						>
							<Image
								style={{ height: 30, width: 30 }}
								source={require('../assets/Images/oviyum logo.png')}
							/>
						</View>
						<View
							style={{
								flexDirection: 'column',
								gap: 8.5,
								flex: 1,
							}}
						>
							<Text style={styles.capHeading}>
								Digital Marketing Consultant
							</Text>
							<Text style={styles.capText}>
								Oviyum Technologies
							</Text>
							<Text style={styles.capText}>
								Dec 2018 - Dec 2021
							</Text>
						</View>
					</View>
				</View>
			</View>
			<View style={styles.box}>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<Text style={styles.headings}>Education</Text>
					<TouchableOpacity>
						<Image
							style={{ height: 24, width: 24 }}
							source={require('../assets/Images/dropdown.png')}
						/>
					</TouchableOpacity>
				</View>
				<Divider thickness="1" bg="#eeeeee" />
				<View>
					<View style={styles.capabilityItem}>
						<View
							style={{
								borderRadius: 13,
								padding: 10,
								borderWidth: 1,
								borderColor: '#EEEEEE',
								width: 50,
								height: 50,
							}}
						>
							<Image
								style={{ height: 30, width: 30 }}
								source={require('../assets/Images/lpu.png')}
							/>
						</View>
						<View
							style={{
								flexDirection: 'column',
								gap: 8.5,
								flex: 1,
							}}
						>
							<Text style={styles.capHeading}>
								MBA - Marketing
							</Text>
							<Text style={styles.capText}>LPU</Text>
							<Text style={styles.capText}>
								Dec 2019 - Jul 2021
							</Text>
							<Text style={styles.capText}>GPA: 4.35</Text>
						</View>
					</View>
					<View style={styles.capabilityItem}>
						<View
							style={{
								borderRadius: 13,
								padding: 10,
								borderWidth: 1,
								borderColor: '#EEEEEE',
								width: 50,
								height: 50,
							}}
						>
							<Image
								style={{ height: 30, width: 30 }}
								source={require('../assets/Images/lpu.png')}
							/>
						</View>
						<View
							style={{
								flexDirection: 'column',
								gap: 8.5,
								flex: 1,
							}}
						>
							<Text style={styles.capHeading}>
								BBA - Marketing
							</Text>
							<Text style={styles.capText}>LPU</Text>
							<Text style={styles.capText}>
								Dec 2019 - Jul 2021
							</Text>
							<Text style={styles.capText}>GPA: 4.35</Text>
						</View>
					</View>
				</View>
			</View>
			<View style={styles.box}>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<Text style={styles.headings}>
						Certifications And Lisences
					</Text>
					<TouchableOpacity>
						<Image
							style={{ height: 24, width: 24 }}
							source={require('../assets/Images/dropdown.png')}
						/>
					</TouchableOpacity>
				</View>
				<Divider thickness="1" bg="#eeeeee" />
				<View>
					<View style={styles.capabilityItem}>
						<View
							style={{
								borderRadius: 13,
								padding: 10,
								borderWidth: 1,
								borderColor: '#EEEEEE',
								width: 50,
								height: 50,
							}}
						>
							<Image
								style={{ height: 30, width: 30 }}
								source={require('../assets/Images/google certificate.png')}
							/>
						</View>
						<View
							style={{
								flexDirection: 'column',
								gap: 8.5,
								flex: 1,
							}}
						>
							<Text style={styles.capHeading}>
								Marketing Fundamentals
							</Text>
							<Text style={styles.capText}>
								Google Academy - Sep 2024
							</Text>
							<Text style={styles.capText}>RRUNNEI&6IR</Text>
						</View>
					</View>
					<View style={styles.capabilityItem}>
						<View
							style={{
								borderRadius: 13,
								padding: 10,
								borderWidth: 1,
								borderColor: '#EEEEEE',
								width: 50,
								height: 50,
							}}
						>
							<Image
								style={{ height: 30, width: 30 }}
								source={require('../assets/Images/lpu.png')}
							/>
						</View>
						<View
							style={{
								flexDirection: 'column',
								gap: 8.5,
								flex: 1,
							}}
						>
							<Text style={styles.capHeading}>
								Digital Marketing for Growing Brands
							</Text>
							<Text style={styles.capText}>
								Coursera, Colombia University - Jan 2020
							</Text>
							<Text style={styles.capText}>IYPM34165C6-IR</Text>
						</View>
					</View>
				</View>
			</View>
		</View>
	)
}

export default Capabilities
const styles = StyleSheet.create({
	headings: {
		color: '#212121',
		fontFamily: 'Urbanist',
		fontWeight: 900,
		fontSize: 20,
		paddingBottom: 20,
		alignSelf: 'left',
		letterSpacing: 1,
	},
	biotext: {
		color: '#656565',
		fontSize: 16,
		fontWeight: 400,
	},
	box: {
		marginVertical: 12,
		flexDirection: 'column',
		borderRadius: 24,
		padding: 20,
		borderWidth: 1,
		borderColor: '#EEEEEE',
	},
	capabilityItem: {
		flexDirection: 'row',
		gap: 20,
		paddingTop: 20,
	},
	capHeading: {
		color: '#424242',
		fontSize: 14,
		fontWeight: 700,
	},
	capText: {
		color: '#424242',
		fontSize: 12,
		fontWeight: 500,
		alignContent: 'center',
	},
	capTag: {
		paddingVertical: 5,
		paddingHorizontal: 10,
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 100,
		borderWidth: 1,
		borderColor: '#246BFD',
		marginHorizontal: 5,
	},
})
