import { StyleSheet, Text, View, Dimensions, TouchableOpacity } from 'react-native'
import React from 'react'
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
import {useNavigation} from '@react-navigation/native';
const DashBoardViewComponent = ({props}) => {
    const navigation = useNavigation()
  return (
    <TouchableOpacity onPress={() => navigation.push("DashboardInsights")}
     style ={styles.ViewContainer}>
       
            <View style ={styles.subcontainer}>
            <Text style ={styles.numbertext}
            > {(props?.profileviews ) ? props?.profileviews : 0}</Text>
            <Text 
            style ={styles.texthead}>
                Profile Views
            </Text>
            
            </View>
            <View style ={{height: 50, width: 1, backgroundColor: "#D9D9D9", alignSelf: "center"}}/>
            <View style ={styles.subcontainer}>
            <Text style ={styles.numbertext}>
                {props?.inpreference?.items.length}</Text>
            <Text numberOfLines={2}
            style ={styles.texthead}>
                Employers Views
            </Text>
            </View>
            <View style ={{height: 50, width: 1, backgroundColor: "#D9D9D9", alignSelf: "center"}}/>
            <View style ={styles.subcontainer}>
            <Text style ={styles.numbertext}>
                {props?.comapanyshortlists?.items.length}</Text>
            <Text numberOfLines={2}
            style ={styles.texthead}>
                Employers Interested
            </Text>
            </View>
            
        
    </TouchableOpacity>
  )
}

export default DashBoardViewComponent

const styles = StyleSheet.create({
    ViewContainer:{
        backgroundColor:"#F5F8FC",
        width: (windowWidth < 480) ? "90%" : "79.9%",
        // width: "90%",
        // height: (windowWidth < 480) ? windowHeight*0.38 : windowHeight*0.45,  
        borderRadius: 10,
        alignSelf: "center",
        marginTop: 15,
        height: 70,
        justifyContent: "space-around",
        marginTop: 30,
        
       
    paddingVertical: 20,
    flexDirection: "row"
        // borderWidth: 1
      
    },
    numbertext: {
        fontWeight: "bold",
        fontSize: 16,
        textAlign: "center",
        fontFamily: "Open Sans"
    },
    texthead:{
        fontFamily: "Lexend",
        fontSize: 16,
        textAlign: "center"
    },
    subcontainer:{
        alignSelf: "center",
        // borderWidth: 1,
        width: "30%"
    }
})