import { Motion } from '@legendapp/motion'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'

const ProgressBar = ({
	progress,
	bgColor,
	width,
	borderColor,
	animationType,
	normalAnimation,
	bgPlaceHolder,
}) => {
	const [isComplete, setIsComplete] = useState(true)

	useEffect(() => {
		if (progress === 100 && !normalAnimation) {
			setIsComplete(true)
			const timeoutId = setTimeout(() => {
				setIsComplete(false)
			}, 80)
			return () => clearTimeout(timeoutId)
		} else {
			setIsComplete(false)
		}
	}, [progress])

	return (
		<Motion.View
			style={{
				width: width,
				h: 7,
				borderRadius: 10,
				borderWidth: 1,
				overflow: 'hidden',
				borderColor: borderColor ? borderColor : '#f2f2f2',
				backgroundColor: bgPlaceHolder,
			}}
			animate={{
				x: isComplete ? 10 : 0,
			}}
			transition={{
				type: 'spring',
				damping: 20,
				stiffness: 400,
				easing: 'linear',
			}}
		>
			<Motion.View
				animate={{
					width: `${progress}%`,
				}}
				transition={{
					type: animationType,
					damping: 20,
					delay: 200,
					duration: 2202,
					stiffness: 4000,
				}}
				style={{
					width: 0,
					height: 6,
					backgroundColor: bgColor,
					borderRadius: 10,
				}}
			/>
		</Motion.View>
	)
}

export default ProgressBar
