import { Dimensions } from 'react-native'

import React, { useEffect, useState } from 'react'
import Hidiheader from '../components/Hidiheader'
import { Linking } from 'react-native'
import Tag from '../components/tag'
import Editor from 'react-medium-editor'
import NewHIdiSmallComponent from './NewHIdiSmallComponent'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
import OutcomeComponent from './OutcomeComponent'
import {
	Box,
	Divider,
	Heading,
	Text,
	HStack,
	Pressable,
	VStack,
	Tooltip,
	Image,
	TooltipContent,
	TooltipText,
} from '@gluestack-ui/themed'
import useUserStore from '../Userstore/UserStore'
const HIdidetails = ({
	props,
	routeParams,
	profileData,
	title,
	heading,
	refInputs,
	background,
	profile,
	summary,
	outcomePreview,
	outcomes,
	screen,
	navigation,
	setTab,
	capabilityName,
	inModal,
	setHack,
	setExperience,
	newHidiViewProps,
	hideEdit,
	exampleView,
	hidiSkills,
	view,
}) => {
	const hackModalView = props && props[0]
	const challengeModalView = props && props[1]
	const experienceModalView = props && props[2]
	const projectLinkModalView = props && props[3]
	const peersModalView = props && props[4]
	const helpedModalView = props && props[5]

	const problemHidiViewProps = newHidiViewProps && newHidiViewProps[0]
	const experienceHidiViewProps = newHidiViewProps && newHidiViewProps[1]
	const challengeHidiViewProps = newHidiViewProps && newHidiViewProps[2]
	const hackHidiViewProps = newHidiViewProps && newHidiViewProps[3]
	const helpedHidiViewProps = newHidiViewProps && newHidiViewProps[4]
	const projectLinkHidiViewProps = newHidiViewProps && newHidiViewProps[5]
	const peersHidiViewProps = newHidiViewProps && newHidiViewProps[6]

	const currentUserProfile = useUserStore((state) => state.profile)
	const typeOfUser = useUserStore((state) => state.group)

	const showHidi = props?.profile?.id === currentUserProfile?.id
	const DataDisplay = ({ text, title }) => {
		return (
			<Box marginVertical={5}>
				<Text color="#A0AEC0" fontSize={12} fontFamily="$fontHeading">
					{title}
				</Text>
				<Text fontFamily="$fontContent" fontSize={13}>
					{text}
				</Text>
			</Box>
		)
	}
	const HidiHeading = () => {
		const item = props

		var impactarr = item?.impact
		if (item?.impact) {
			impactarr = impactarr?.split('///')
		}

		const editHidi = () => {
			navigation.navigate('CapCreateHidi', {
				hidiId: props?.id,
				capabilityName:
					props?.ConnectedCapability?.items[0]?.Capability
						?.CapabilityConfig?.name,
				capabilityId:
					props?.ConnectedCapability?.items[0]?.CapabilityId,
				capability: props?.ConnectedCapability?.items[0]?.Capability,
			})
		}

		return (
			<Box>
				<HStack alignItems="center">
					<Pressable
						disabled={!inModal}
						onPress={() => {
							setTab('Problem')
						}}
						flex={1}
					>
						{(props?.outcomes?.items.length > 0 ||
							item?.htmlheading ||
							outcomePreview ||
							outcomePreview === '' ||
							props?.problem ||
							newHidiViewProps) && (
							<>
								{!inModal && (
									<Text
										color="$primary950"
										borderRadius={4}
										fontFamily="$fontHeading"
										fontSize={windowWidth > 480 ? 12 : 10}
										lineHeight={17}
									>
										Problem I Solved:
									</Text>
								)}
								<Heading
									fontFamily="$fontHeading"
									fontSize={windowWidth > 480 ? 16 : 15}
									color={
										inModal && outcomePreview === ''
											? '#E2E2E2'
											: '$primary950'
									}
									fontWeight={
										inModal && outcomePreview === ''
											? '500'
											: '700'
									}
									lineHeight={22}
								>
									{inModal && outcomePreview === ''
										? 'Brief overview'
										: outcomePreview}
									{newHidiViewProps
										? problemHidiViewProps
										: null}
									{item?.htmlheading
										? item?.htmlheading
										: props?.problem
										? props?.problem
										: props?.outcomes?.items[0]?.Outcome}
								</Heading>
							</>
						)}
					</Pressable>
					<Box>
						{inModal && outcomePreview !== '' && (
							<Pressable
								disabled={!inModal}
								onPress={() => {
									setTab('Problem')
								}}
							>
								<Image
									source={require('../assets/Images/edit.png')}
									h={18}
									w={18}
								/>
							</Pressable>
						)}
						{hideEdit || typeOfUser !== 'candidates'
							? null
							: showHidi &&
							  !inModal &&
							  !newHidiViewProps && (
									<Tooltip
										placement="top"
										trigger={(triggerProps) => {
											return (
												<Pressable
													{...triggerProps}
													onPress={editHidi}
												>
													<Image
														source={require('../assets/Images/edit.png')}
														h={18}
														w={18}
													/>
												</Pressable>
											)
										}}
									>
										<TooltipContent bg={'$backgroundGray'}>
											<TooltipText
												fontFamily="$fontHeading"
												fontSize={12}
												color={'$fontGray100'}
												fontWeight="600"
											>
												Edit
											</TooltipText>
										</TooltipContent>
									</Tooltip>
							  )}
					</Box>
				</HStack>

				<HStack>
					{item?.title && (
						<Heading
							fontFamily="$fontHeading"
							fontSize={
								windowWidth > 480
									? '$fontHeading'
									: '$fontHeadingContent'
							}
							fontWeight="700"
							lineHeight={50}
						>
							{screen === 'edit' ? title : item?.title}
						</Heading>
					)}
				</HStack>
				{!inModal && (
					<Box>
						{(props?.ConnectedCapability?.items?.length == 1 ||
							capabilityName) && (
							<Box paddingVertical={5}>
								<Text
									color="$primary950"
									borderRadius={4}
									fontFamily="$fontHeading"
									fontSize={windowWidth > 480 ? 12 : 10}
								>
									For the capability:
								</Text>
								<Heading
									fontFamily="$fontHeading"
									fontSize={windowWidth > 480 ? 18 : 15}
									borderRadius={6}
									color="$primary500"
									lineHeight={windowWidth > 480 ? 22 : 19}
								>
									{capabilityName
										? capabilityName
										: props.ConnectedCapability.items[0]
												.Capability.CapabilityConfig
												.name}
								</Heading>
							</Box>
						)}
					</Box>
				)}
				{!inModal && (
					<Hidiheader
						params={routeParams}
						navigation={navigation}
						props={
							inModal
								? profile
								: screen === 'edit'
								? profile
								: props?.profile
						}
						inModal={inModal}
						screen={screen}
						timeUpdatedAt={item?.updatedAt}
					/>
				)}
			</Box>
		)
	}
	const Stringslice = () => {
		const text = props?.description
		const arr = text.split('///')

		if (props?.outcome) {
			const text1 = props?.outcome
			const arr1 = text1.split('///')
			// setoutcome(arr1)
		}

		// setsteps(arr)
	}
	const OldhidiView = () => {
		return (
			<Box>
				<Box>
					<Text
						fontFamily="$fontContent"
						fontWeight="500"
						fontSize={
							windowWidth > 480
								? '$fontContent'
								: '$fontContentMobile'
						}
						lineHeight={windowWidth > 480 ? 20 : 15}
					>
						{screen === 'edit' ? background : props?.summary}
					</Text>
				</Box>
				<Box>
					<Editor
						style={{
							fontFamily: 'Open Sans',
							fontWeight: 500,
							fontSize: windowWidth > 480 ? 14 : 11,
						}}
						text={screen === 'view' ? summary : props?.htmlsummary}
						options={{
							toolbar: false,
							disableEditing: true,
						}}
					/>
				</Box>
				<Divider marginVertical={windowWidth > 480 ? 10 : 5} />
				<Box marginVertical={windowWidth > 480 ? 5 : 2}>
					<Text
						fontFamily="$fontHeading"
						fontSize={
							windowWidth > 480 ? '$fontHeading' : '$fontSize16'
						}
						fontWeight="700"
					>
						Outcomes
					</Text>
					<Box marginVertical={windowWidth > 480 ? 10 : 0}>
						{(props?.outcomes || outcomes?.items?.length > 0) && (
							<OutcomeComponent
								props={props?.outcomes || outcomes}
							/>
						)}

						{refInputs?.current && refInputs?.current.length && (
							<Box>
								{refInputs?.current.map((value, i) => {
									const editOutcomeNumber =
										outcomes?.items?.length > 0
											? outcomes?.items?.length + i + 1
											: i + 1
									return (
										<>
											{value ? (
												<>
													<Box>
														<HStack
															gap={5}
															marginBottom={5}
														>
															<Box>
																<Text
																	fontFamily={
																		'$fontContent'
																	}
																	fontSize={
																		windowWidth >
																		480
																			? '$fontContent'
																			: '$fontContentMobile'
																	}
																	fontWeight="bold"
																	textAlign="center"
																>
																	{
																		editOutcomeNumber
																	}
																	.
																</Text>
															</Box>
															<Text
																fontFamily={
																	'$fontContent'
																}
																fontSize={
																	windowWidth >
																	480
																		? '$fontContent'
																		: '$fontContentMobile'
																}
																fontWeight="bold"
															>
																{value}
															</Text>
														</HStack>
													</Box>
												</>
											) : null}
										</>
									)
								})}
							</Box>
						)}
					</Box>

					{props?.jobs && (
						<View>
							<Text>Jobs to be done</Text>
							<Text>{props?.jobs}</Text>
						</View>
					)}
				</Box>
			</Box>
		)
	}
	useEffect(() => {
		if (props?.description) {
			Stringslice()
		}
	}, [])

	const NewHidiView = (field, text) => {
		const splitString = props?.whereitwasdone?.split(/\(([^)]+)\)/)

		const textOutside = splitString ? splitString[0]?.trim() : null
		const textInside = splitString ? splitString[1]?.trim() : null

		return (
			<>
				{inModal ? (
					<Box>
						<NewHIdiSmallComponent
							field={'The process of how i solved it:'}
							text={experienceModalView}
							inModal={inModal}
							experience={experienceModalView}
							exampleView={exampleView}
							problem={outcomePreview}
							onPressIcon={() => {
								setTab('Experience')

								hackModalView
									?.split(/\s+/)
									?.filter((word) => word !== '')?.length <
									10 && setHack('')
							}}
							fillerText={'Summarize your strategy'}
						/>
						<NewHIdiSmallComponent
							field={'Challenges i encountered:'}
							text={challengeModalView}
							inModal={inModal}
							exampleView={exampleView}
							experience={experienceModalView}
							problem={outcomePreview}
							onPressIcon={() => {
								setTab('Challenge')
								experienceModalView
									?.split(/\s+/)
									?.filter((word) => word !== '')?.length <
									20 && setExperience('')
								hackModalView
									?.split(/\s+/)
									?.filter((word) => word !== '')?.length <
									10 && setHack('')
							}}
							fillerText={'Highlight specific challenges'}
						/>
						<NewHIdiSmallComponent
							field={
								'Ideas or Hacks i came up with to overcome the problem:'
							}
							text={hackModalView}
							inModal={inModal}
							exampleView={exampleView}
							experience={experienceModalView}
							problem={outcomePreview}
							onPressIcon={() => {
								setTab('Hacks')
								experienceModalView
									?.split(/\s+/)
									?.filter((word) => word !== '')?.length <
									20 && setExperience('')
							}}
							fillerText={
								'Brief explanation of any distinctive methods'
							}
						/>
						<NewHIdiSmallComponent
							field={'How the solution helped somebody:'}
							fillerText={
								'Quick insight into the positive outcome'
							}
							text={helpedModalView}
							inModal={inModal}
							experience={experienceModalView}
							exampleView={exampleView}
							problem={outcomePreview}
							onPressIcon={() => {
								setTab('Helped')
								experienceModalView
									?.split(/\s+/)
									?.filter((word) => word !== '')?.length <
									20 && setExperience('')
								hackModalView
									?.split(/\s+/)
									?.filter((word) => word !== '')?.length <
									10 && setHack('')
							}}
						/>
						<NewHIdiSmallComponent
							field={'Other Material for reference:'}
							fillerText={'If applicable, provide relevant links'}
							text={projectLinkModalView}
							inModal={inModal}
							experience={experienceModalView}
							problem={outcomePreview}
							exampleView={exampleView}
							sharedLink={true}
							onPressIcon={() => {
								setTab('ProjectLink')
								experienceModalView
									?.split(/\s+/)
									?.filter((word) => word !== '')?.length <
									20 && setExperience('')
								hackModalView
									?.split(/\s+/)
									?.filter((word) => word !== '')?.length <
									10 && setHack('')
							}}
						/>
						<NewHIdiSmallComponent
							field={'Peers who can vouch:'}
							fillerText={'Who you have worked with'}
							text={peersModalView}
							experience={experienceModalView}
							inModal={inModal}
							problem={outcomePreview}
							exampleView={exampleView}
							onPressIcon={() => {
								setTab('Peers')
								experienceModalView
									?.split(/\s+/)
									?.filter((word) => word !== '')?.length <
									20 && setExperience('')
								hackModalView
									?.split(/\s+/)
									?.filter((word) => word !== '')?.length <
									10 && setHack('')
							}}
						/>
					</Box>
				) : (
					<Box>
						{props?.whereitwasdone && (
							<>
								<DataDisplay title={'While working as:'} />

								<Text
									fontFamily="$fontHeading"
									color={'$textDark1000'}
									fontSize={'$fontSize14'}
									fontWeight="$bold"
								>
									{textOutside}{' '}
									<Text color={'$fontGray'}>@</Text>{' '}
									<Text
										fontFamily="$fontHeading"
										color={'$textDark1000'}
										fontSize={'$fontSize14'}
									>
										{textInside}
									</Text>
								</Text>
							</>
						)}

						{hidiSkills?.length > 0 && (
							<Box mt={10}>
								<Text
									fontFamily="$fontHeading"
									fontSize={windowWidth > 480 ? 12 : 11}
									color={'$fontGray'}
									fontWeight="500"
								>
									Skills used:
								</Text>
								<HStack gap={10} flexWrap={'wrap'} my={10}>
									{hidiSkills?.map((skill) => {
										return (
											<Box
												borderWidth={1}
												px={6}
												borderRadius={10}
												borderColor={'$coolGray300'}
												display={
													view === 'CapCreateHidi'
														? skill.Skill
															? 'flex'
															: 'none'
														: skill.Skill?.Skill
														? 'flex'
														: 'none'
												}
												// bg="#D3D3D3"
											>
												<Text
													fontFamily="$fontHeading"
													color={'$coolGray500'}
												>
													{view === 'CapCreateHidi'
														? skill?.Skill
														: skill?.Skill?.Skill}
												</Text>
											</Box>
										)
									})}
								</HStack>
							</Box>
						)}

						{(props?.experience || experienceHidiViewProps) && (
							<NewHIdiSmallComponent
								fillerText={
									'Brief explanation of any distinctive methods'
								}
								field={'The process of how i solved it:'}
								text={
									experienceHidiViewProps
										? experienceHidiViewProps
										: props.experience
								}
							/>
						)}
						{(props?.challenges?.items?.length > 0 ||
							challengeHidiViewProps) && (
							<NewHIdiSmallComponent
								field={'Challenges i encountered:'}
								text={
									challengeHidiViewProps
										? challengeHidiViewProps
										: props?.challenges?.items[0]?.challenge
								}
							/>
						)}
						{(props?.Hack || hackHidiViewProps) && (
							<NewHIdiSmallComponent
								field={
									'Ideas or Hacks i came up with to overcome the problem:'
								}
								text={
									hackHidiViewProps
										? hackHidiViewProps
										: props.Hack
								}
							/>
						)}
						{(props?.helped || helpedHidiViewProps) && (
							<NewHIdiSmallComponent
								field={'How the solution helped somebody:'}
								text={
									helpedHidiViewProps
										? helpedHidiViewProps
										: props.helped
								}
							/>
						)}
						{(props?.projectLink || projectLinkHidiViewProps) && (
							<NewHIdiSmallComponent
								field={'Other Material for reference:'}
								text={
									projectLinkHidiViewProps
										? projectLinkHidiViewProps
										: props.projectLink
								}
								sharedLink={true}
							/>
						)}
						{(props?.peerEmails || peersHidiViewProps) && (
							<NewHIdiSmallComponent
								field={'Peers who can vouch:'}
								text={
									peersHidiViewProps
										? peersHidiViewProps
										: props.peerEmails
								}
							/>
						)}
					</Box>
				)}
			</>
		)
	}
	return (
		<Box
			w={'100%'}
			bg="white"
			paddingVertical={windowWidth > 480 ? 20 : 10}
			paddingHorizontal={
				windowWidth > 480 ? (newHidiViewProps ? 0 : 20) : 2
			}
		>
			<HidiHeading />

			<Divider marginVertical={windowWidth > 480 ? 10 : 5} />

			<Box>
				{props?.htmlheading || heading ? (
					<OldhidiView />
				) : (
					<NewHidiView />
				)}
			</Box>
		</Box>
	)
}

export default HIdidetails
