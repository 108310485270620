import {
	View,
	TouchableOpacity,
	Text,
	FlatList,
	StyleSheet,
	Modal,
	Dimensions,
} from 'react-native'

import React, { useState } from 'react'
import UserAvatar from 'react-native-user-avatar'
import { Divider, ScrollView, HStack, VStack } from 'native-base'
import Hidicontainer from './HidiContainer'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const ExampleHidi = () => {
	const [showModal, setShowModal] = useState(false)
	const [selectedItemIndex, setSelectedItemIndex] = useState(null)
	const DATA = [
		{
			id: '462f89e1-ecf3-45e5-8da2-bf417d688c0d',
			hidiId: '',
			name: 'Aakash Sharma',
			expertise: 'React Native Engineer',
			experience: '3 Years',
			HidiTitle: `Architecting a GraphQL API and making it futureproof`,
			HidiDescription: `First, I dived into the GraphQL documentation, leveraging my existing knowledge of REST principles to understand the fundamental differences. I started by defining the schema, carefully selecting the types, queries, and mutations we would need. Collaboration with front-end developers and stakeholders was crucial at this stage to ensure we catered to their specific data requirements.

One significant challenge we encountered was dealing with nested queries. It was a bottleneck in the initial setup, causing high database load and performance issues. I needed to find an innovative solution.
			
After some research and brainstorming, I devised a caching mechanism using DataLoader and persisted queries. By pre-fetching frequently requested nested data and storing it in memory, we significantly reduced database hits and improved response times. This hack not only improved performance but also allowed us to better control our API's resource usage.`,
			HidiBackground: `My role in this project was pivotal. I was tasked with architecting the GraphQL API, ensuring it not only met current requirements but was scalable for future expansion. It was a thrilling yet daunting task. GraphQL's flexible nature presented us with endless possibilities, but it also meant we needed to be cautious not to create an overly complex and slow API.
			`,
			HidiOutcomes: [
				`The team gained valuable experience in GraphQL, and the reusable query structures streamlined front-end development.`,
				`The GraphQL API's improved performance led to data retrieval for our clients. The GraphQL API's improved performance led to quicker data retrieval for our clients.`,
			],
		},
		{
			id: '6d99be9d-07b1-4f02-8e1e-f312521cdbd9',
			hidiId: '',
			name: 'Rahul Sharma',
			expertise: 'Fullstack Engineer',
			experience: '3 Years',
			HidiTitle:
				'Overcoming Challenges and Driving Business Impact using cloud services',
			HidiDescription: `I began by architecting a microservices-based application, breaking down the monolithic codebase into smaller, manageable components. This allowed us to scale each service independently. We adopted AWS Lambda for serverless computing, enabling automatic scaling in response to traffic spikes. For the database, we opted for Amazon RDS, which offered both performance and scalability.

However, as we were scaling up the Lambda functions, we ran into an unexpected issue. The startup's budget couldn't handle the increased cost of the Lambda invocations during traffic surges. We needed to find a creative and efficient way to reduce this cost without compromising on performance.
			
After some brainstorming, I came up with a unique solution: I introduced caching for frequently requested data using AWS ElastiCache (Redis). By caching the results of expensive database queries and API calls, we significantly reduced the number of Lambda invocations. Additionally, we set up CloudWatch Alarms to monitor cache usage and automatically refresh the cache when needed. This not only cut down Lambda costs but also improved the overall application response time.`,

			HidiBackground: `I had to develop an application using cloud services for a fast-growing e-commerce startup. Our task was to create a scalable and highly available application to handle the surge in traffic during the event. We had decided to leverage cloud services, specifically AWS, to meet these requirements. The entire team was excited about the potential, but there was one major problem: budget constraints. We had to find a cost-effective solution to ensure the application's performance while not breaking the bank.
			`,
			HidiOutcomes: [
				'By implementing caching, we managed to reduce Lambda invocation costs by 40%. ',
				`The application not only handled the surge in traffic during the sales event but did so with remarkable speed and reliability.`,
			],
		},
		{
			id: '4ec8f4fd-2429-4057-a88f-572d2099ad40',
			hidiId: '',
			name: 'Priya Sharma',
			expertise: 'MERN Stack Developer',
			experience: '2 Years',
			HidiTitle:
				'Transforming User Experience of the Company website for increase in mobile traffic and better lead generation',
			HidiDescription: `My first step was to assess the current website design thoroughly. I identified areas that needed responsive adjustments, including the navigation menu, images, text size, and overall layout.
			
Given the tight timeline, I decided to utilize the Bootstrap framework to expedite the development process. Bootstrap provided a solid foundation for responsive design components, making it easier to ensure our site worked seamlessly across different screen sizes.
			
I used CSS media queries extensively to customize the layout and styling for various screen sizes. This allowed me to adapt our content and design based on the user's device, ensuring a smooth and intuitive experience.
			
During the development process, I encountered a complex issue with one of our interactive features. The dropdown menu, which contained essential navigation links, was causing layout problems on smaller screens. The default behavior of Bootstrap's dropdowns was not suitable for the limited screen space available on mobile devices.
			
To address this problem, I came up with a unique and smart hack. Instead of using the default dropdown behavior, I decided to transform the navigation links into a slide-in sidebar menu for mobile users. This not only saved screen real estate but also made navigation more user-friendly.
			
I achieved this by leveraging CSS animations and jQuery to create a smooth sliding effect. It took some time to fine-tune the animations and ensure they worked flawlessly on various devices, but the end result was a sleek and efficient mobile navigation solution.`,

			HidiBackground: `I was tasked to develop a mobile-responsive frontend for our company's website. The context was crucial – our website had been losing mobile traffic steadily, and it was clear that our user experience on smaller screens needed a significant improvement.

The situation was urgent, and the pressure was on to deliver a solution quickly.

`,

			HidiOutcomes: [
				'After implementing the mobile-responsive frontend, our website experienced a significant increase in mobile traffic. Users were staying longer, engaging with our content, and the bounce rate decreased noticeably.',
				'We started conducting regular design and development meetings to align our goals and priorities.',
			],
		},
	]

	const showModalVisible = (index) => {
		setSelectedItemIndex(index)
	}

	const hideModal = () => {
		setSelectedItemIndex(null)
	}

	const renderItemMethod = ({ item, index }) => {
		const isModalVisible = selectedItemIndex === index

		return (
			<>
				<Modal
					visible={isModalVisible}
					onRequestClose={hideModal}
					transparent={true}
					animationType="fade"
					ariaHideApp={false}
				>
					<View style={styles.modalContainer}>
						<View style={styles.modalContent}>
							<Hidicontainer
								item={item}
								ownerName={item.name}
								heading={item.HidiTitle}
								refInputs={item.HidiOutcomes}
								background={item.HidiBackground}
								summary={item.HidiDescription}
								id={item.id}
								screen="example"
								hideModal={hideModal}
							/>
						</View>
					</View>
				</Modal>
				<View>
					<TouchableOpacity
						onPress={() => showModalVisible(index)}
						style={styles.exampleContent}
					>
						<Text style={styles.HidiTitle}>{item.HidiTitle}</Text>
						<HStack style={{ marginTop: 5, alignItems: 'center' }}>
							<View style={{ paddingRight: 6 }}>
								<UserAvatar size={26} name={item.name} />
							</View>
							<VStack>
								<Text style={styles.HidiOwner}>
									{item.name}
								</Text>
								<Text style={styles.HidiOwner}>
									{item.expertise}
								</Text>
							</VStack>
						</HStack>
					</TouchableOpacity>
				</View>
			</>
		)
	}

	return (
		<View style={styles.container}>
			<Text style={styles.title}>Examples to help you write!</Text>
			<FlatList
				data={DATA}
				renderItem={renderItemMethod}
				keyExtractor={(item) => item.id}
			/>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		marginVertical: 15,
		paddingVertical: 10,
		paddingHorizontal: 10,
		width: '100%',
		borderWidth: 1,
		borderRadius: 20,
		borderColor: '#EEEEEE',
	},
	title: {
		fontSize: 17,
		width: '100%',
		marginBottom: 5,
		paddingBottom: 5,
		fontFamily: 'Urbanist',
		fontWeight: 'bold',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'left',
		color: '#212121',
		borderBottomWidth: 1,
		borderBottomColor: '#EEEEEE',
	},
	exampleContent: {
		paddingVertical: 10,
		paddingHorizontal: 10,
		borderRadius: 20,
		borderWidth: 1,
		borderColor: '#EEEEEE',
		marginTop: 10,
		marginBottom: 5,
	},
	HidiTitle: {
		fontSize: 14,
		color: '#424242',
		letterSpacing: 0.7,
		fontWeight: 'bold',
		marginBottom: 5,
	},
	HidiOwner: {
		fontSize: 12,
		color: '#424242',
		fontFamily: 'Helvetica',
		fontWeight: 'semibold',
	},
	modalContainer: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		alignItems: 'center',
		backgroundColor: 'rgba(84, 84, 84, 0.6)',
	},
	modalContent: {
		width: windowWidth > 480 ? '62%' : '95%',
		justifyContent: 'center',
		alignItems: 'center',
		maxHeight: '90%',
		backgroundColor: 'white',
		borderRadius: 20,
		paddingVertical: 15,
		paddingHorizontal: 20,
	},
})

export default ExampleHidi
