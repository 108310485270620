import { Box, HStack, View } from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'
import React, { useEffect, useState } from 'react'

import { API, graphqlOperation } from 'aws-amplify'
import { ResumeParser } from '../../graphql/queries'
import {
	createCandidateEducation,
	createCandidateExperience,
} from '../../graphql/mutations'
import { MobileHeader } from '../../newComponents/header/Header'
import EditProfile from '../../newComponents/editProfile/EditProfile'
import { showToast } from '../../components/ErrorToast'
import UploadResume from './UploadResume'
import useUserStore from '../../Userstore/UserStore'
import CareerEditComponent from '../../newComponents/editCareer/CareerEditComponent'
import SelectCapabilities from '../../newComponents/selectCapabilities/SelectCapabilities'

import { onCreateUserParsedResumeByUserId } from '../../graphql/subscriptions'
import ProgressBubbles from './ProgressBubbles'
import CandidatePreferences from '../../newComponents/CandidatePreferences'
import VideoRecordingScreen from '../../newComponents/videoComponent/VideoRecordingScreen'
import ProgressBarsOnboarding from './ProgressBarsOnboarding'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

export default function OnboardingScreen({
	navigation,
	route,
	inModal,
	candidatePreference,
	setJobOnboardModal,
}) {
	console.log(route.params.stepState)
	const [activeSection, setActiveSection] = useState(
		route?.params?.stepState
			? route?.params?.stepState
			: candidatePreference
			? 1
			: 0
	)
	const [totalScreens, setTotalScreens] = useState(0)
	const setCheckScreen = useUserStore((state) => state.setCheckScreen)
	const [responseLambda, setResponseLambda] = useState(false)
	const [mapData, setMapData] = useState([])

	const skipState = () => {
		setResponseLambda(false)
		setMapData([])
	}

	const handleProceed = () => {
		if (activeSection < totalScreens) {
			const newStepState = activeSection + 1
			setActiveSection(newStepState)
			if (!inModal) {
				navigation.navigate('Onboarding', { stepState: newStepState })
			}
		}
	}

	const handleGoBack = () => {
		if (activeSection > 0) {
			const newStepState = activeSection - 1
			setActiveSection(newStepState)

			if (!inModal) {
				navigation.navigate('Onboarding', { stepState: newStepState })
			}
		}
	}

	useEffect(() => {
		setCheckScreen('Onboarding')
	})

	return (
		<View
			overflowX="hidden"
			bg="$backgroundGray"
			minHeight={windowHeight - 50}
			marginTop={50}
			w={'100%'}
		>
			<ProgressBubbles
				activeSection={activeSection}
				setTotalScreen={setTotalScreens}
				setActiveSection={setActiveSection}
			/>

			{activeSection === 0 && (
				<Box
					w={
						windowWidth > 480
							? windowWidth * 0.7
							: windowWidth * 0.95
					}
					alignSelf="center"
					mb={windowWidth < 480 && 20}
				>
					<EditProfile
						handleProceed={handleProceed}
						handleGoBack={handleGoBack}
						responseLambda={responseLambda}
						mapData={mapData}
						setMapData={setMapData}
						screen={'onboarding'}
						navigation={navigation}
					/>
				</Box>
			)}
			{/* {activeSection === 1 && (
				<Box
					w={
						windowWidth > 480
							? inModal
								? '90%'
								: windowWidth * 0.7
							: windowWidth * 0.95
					}
					alignSelf="center"
				>
					<VideoRecordingScreen
						navigation={navigation}
						screen={'Onboarding'}
						handleProceed={handleProceed}
						handleGoBack={handleGoBack}
					/>
				</Box>
			)} */}
			{activeSection === 1 && (
				<Box
					w={
						windowWidth > 480
							? inModal
								? '90%'
								: windowWidth * 0.7
							: windowWidth * 0.95
					}
					alignSelf="center"
				>
					<CandidatePreferences
						handleProceed={handleProceed}
						handleGoBack={handleGoBack}
						screen={'onboarding'}
						navigation={navigation}
						inModal={inModal}
						setJobOnboardModal={setJobOnboardModal}
					/>
				</Box>
			)}
			{/* {activeSection === 2 && (
				<Box
					w={
						windowWidth > 480
							? windowWidth * 0.7
							: windowWidth * 0.95
					}
					alignSelf="center"
				>
					<SelectCapabilities
						handleGoBack={handleGoBack}
						navigation={navigation}
						inModal={inModal}
						setJobOnboardModal={setJobOnboardModal}
					/>
				</Box>
			)} */}
		</View>
	)
}
