import React, { Component } from 'react'
import { View, Image, Animated, Easing } from 'react-native'

class BreathingImage extends Component {
	constructor(props) {
		super(props)
		this.opacityValue = new Animated.Value(1)
		this.scaleValue = new Animated.Value(1)
	}

	componentDidMount() {
		// this.breathAnimation()
		this.scaleAnimation()
	}

	// breathAnimation = () => {
	// 	Animated.loop(
	// 		Animated.sequence([
	// 			Animated.timing(this.opacityValue, {
	// 				toValue: 0.2,
	// 				duration: 1500,
	// 				easing: Easing.linear,
	// 				useNativeDriver: true,
	// 			}),
	// 			Animated.timing(this.opacityValue, {
	// 				toValue: 1,
	// 				duration: 1500,
	// 				easing: Easing.linear,
	// 				useNativeDriver: true,
	// 			}),
	// 		])
	// 	).start()
	// }

	scaleAnimation = () => {
		Animated.loop(
			Animated.sequence([
				Animated.timing(this.scaleValue, {
					toValue: 0.8,
					duration: 1000,
					easing: Easing.easeInOut,
					useNativeDriver: true,
				}),
				Animated.timing(this.scaleValue, {
					toValue: 1,
					duration: 1000,
					easing: Easing.easeInOut,
					useNativeDriver: true,
				}),
			])
		).start()
	}

	render() {
		const { imageSource, width, height } = this.props
		return (
			<View>
				<Animated.Image
					source={imageSource}
					style={{
						width: width,
						height: height,
						opacity: this.opacityValue,
						transform: [{ scale: this.scaleValue }],
					}}
				/>
			</View>
		)
	}
}

export default BreathingImage
