import React, { useState } from 'react'
import { Dimensions } from 'react-native'
import { Box } from '@gluestack-ui/themed'
import EditCandidateCareerModal from '../EditCandidateCareerModal'

const windowWidth = Dimensions.get('window').width

const EditCertifications = ({
	onSave,
	setShowModal,
	setFields,
	fields,
	toast,
	modalVisibility,
	ref,
}) => {
	const [loading, setLoading] = useState(false)

	const saveFields = async () => {
		setLoading(true)
		if (validateFields(fields)) {
			await onSave(fields)
			setLoading(false)
			setShowModal(false)
			setFields({
				id: '',
				Certificatename: '',
				certificateId: '',
				// validity: '',
				Source: '',
			})
		} else {
			setLoading(false)
			toast()
		}
	}

	const closeModal = () => {
		setShowModal(false)
		setFields({
			id: '',
			Certificatename: '',
			certificateId: '',
			// validity: '',
			Source: '',
		})
	}

	const validateFields = () => {
		if (fields.Certificatename === '') {
			return false
		}
		return true
	}

	const setCertificateNameChange = (value) =>
		setFields({ ...fields, Certificatename: value })

	const setSourceChange = (value) => setFields({ ...fields, Source: value })

	const setCertificateIdChange = (value) =>
		setFields({ ...fields, certificateId: value })

	return (
		<Box>
			<EditCandidateCareerModal
				heading={'Certificate'}
				isOpen={modalVisibility}
				onClose={closeModal}
				ref={ref}
				labels={['Certificate Name', 'Source', 'Certificate ID']}
				importants={[true, true, false]}
				placeholders={[
					'Advanced React Native',
					'Enter the source of certification',
					'Enter Certificate ID',
				]}
				values={[
					fields?.Certificatename,
					fields?.Source,
					fields?.certificateId,
				]}
				setValueChanges={[
					setCertificateNameChange,
					setSourceChange,
					setCertificateIdChange,
				]}
				handleSave={saveFields}
				date={false}
				loading={loading}
			/>
		</Box>
	)
}

export default EditCertifications
