import { Box, Text } from '@gluestack-ui/themed'
import React, { useEffect, useState } from 'react'
import useUserStore from '../../Userstore/UserStore'
import CreateAccount from '../loginFlow/CreateAccount/CreateAccount'
import SignUpOptions from '../loginFlow/SignUpOptions/SignUpOptions'
import Login from '../loginFlow/LogIn/Login'
import { getNewprofileByUserId } from '../../graphql/custom-queries'
import { API } from 'aws-amplify'

const CreateProfileModalBody = ({ setModal, modal, jobType, navigation }) => {
	const setProfileId = useUserStore((state) => state.setProfileId)
	const setHidisState = useUserStore((state) => state.setHidis)
	const setFullName = useUserStore((state) => state.setName)
	const setImageProfile = useUserStore((state) => state.setImage)
	const setImagekeyProfile = useUserStore((state) => state.setImagekey)
	const setUserProfile = useUserStore((state) => state.setUserProfile)
	const setDomain = useUserStore((state) => state.setDomain)
	const setClaimedCapabailities = useUserStore(
		(state) => state.setClaimedCapabailities
	)

	const profileId = useUserStore((state) => state.profileId)
	const userId = useUserStore((state) => state.userId)
	const isLoggedIn = useUserStore((state) => state.isLoggedIn)

	const [tab, setTab] = useState('CreateAccount')

	const getProfile = async () => {
		try {
			const response = await API.graphql({
				query: getNewprofileByUserId,
				variables: { UserId: userId },
			})

			const profileData = await response.data.getNewprofileByUserId
				?.items[0]

			if (!profileData) {
				setModal(true)
				setTab('Onboarding')
			}

			await setDomain(
				profileData?.CandidatePreferences?.items[0]?.userDomain
			)

			await setClaimedCapabailities(profileData?.Capabilities?.items)
			await setUserProfile(profileData)
			await setProfileId(profileData?.id)
			await setFullName(profileData?.name)
			await setImageProfile(profileData?.image)
			await setImagekeyProfile(profileData?.imagekey)
			await setHidisState(profileData?.hidis.items)
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if (userId) {
			getProfile()
		}
	}, [profileId, userId])

	return (
		<Box>
			{tab === 'CreateAccount' && (
				<Box>
					<CreateAccount inModal={true} setTab={setTab} />
				</Box>
			)}
			{tab === 'Login' && (
				<Box>
					<Login
						inModal={true}
						setTab={setTab}
						setModal={setModal}
						navigation={navigation}
						getProfile={getProfile}
					/>
				</Box>
			)}
		</Box>
	)
}

export default CreateProfileModalBody
