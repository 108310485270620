import {
	StyleSheet,
	Text,
	View,
	Dimensions,
	FlatList,
	TouchableOpacity,
} from 'react-native'
import React from 'react'
import { HStack } from 'native-base'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
import { MobileHeader } from '../newComponents/header/Header'
import { DekstopHeader } from '../newComponents/header/Header'
import { Drawer } from '../newComponents/header/Header'
import ProfileFeedback from '../components/ProfileFeedback'
export default function InsightScreen({ route, navigation }) {
	const profiledata = route.params.profiledata

	return (
		<View
			style={{
				backgroundColor: '#fff',
				height: windowHeight,
				width: windowWidth,
			}}
		>
			{windowWidth >= 480 && <DekstopHeader />}
			{windowWidth < 480 && (
				<View style={{ zIndex: 1, flexDirection: 'row' }}>
					<MobileHeader props={'QRUSIBLE RECOMMENDED TALENT'} />
					<Drawer />
				</View>
			)}
			{route.params?.companyname && (
				<View
					style={{
						width: windowWidth < 480 ? '90%' : '75%',
						alignSelf: 'center',
						marginTop: 20,
						flexDirection: 'row',
						flexWrap: 'wrap',
					}}
				>
					<TouchableOpacity
						onPress={() => navigation.push('PreferencesScreen')}
					>
						<Text
							style={{ ...styles.breadcrumps, color: '#5B5B5B' }}
						>
							{route.params?.companyname}/
						</Text>
					</TouchableOpacity>

					<TouchableOpacity
						onPress={() =>
							navigation.push('RecomendedScreen', {
								PreferenceId: route.params?.PreferenceId,
								name: route.params?.companyname,
								rolename: route.params?.rolename,
							})
						}
					>
						<Text
							style={{ ...styles.breadcrumps, color: '#5B5B5B' }}
						>
							{route.params.rolename}/
						</Text>
					</TouchableOpacity>
					{route.params.Shortlisted && (
						<TouchableOpacity
							onPress={() =>
								navigation.push('Shortlist', {
									PreferenceId: route.params?.PreferenceId,
									companyname: route.params?.companyname,
									rolename: route.params?.rolename,
								})
							}
						>
							<Text
								style={{
									...styles.breadcrumps,
									color: '#5B5B5B',
								}}
							>
								{route.params?.Shortlisted}/
							</Text>
						</TouchableOpacity>
					)}
					<TouchableOpacity
						onPress={() =>
							navigation.push('NewProfile', {
								PreferenceId: route.params?.PreferenceId,
								companyname: route.params?.companyname,
								rolename: route.params?.rolename,
								Shortlisted: route.params?.Shortlisted,
								UserId: profiledata.UserId,
							})
						}
					>
						<Text
							style={{
								...styles.breadcrumps,
								color: '#5B5B5B',
								textDecorationLine: 1,
							}}
						>
							{route.params.profilename}/
						</Text>
					</TouchableOpacity>
					<TouchableOpacity>
						<Text
							style={{
								...styles.breadcrumps,
								color: '#335EF7',
								textDecorationLine: 1,
							}}
						>
							Insights
						</Text>
					</TouchableOpacity>
				</View>
			)}
			<View style={styles.container}>
				<HStack style={styles.salarybox}>
					{profiledata.salarycurrent && (
						<View style={{ alignSelf: 'center' }}>
							<Text style={styles.numbertext}>
								{' '}
								{profiledata.salarycurrent}
							</Text>
							<Text style={{ fontFamily: 'Lexend' }}>
								Current Salary
							</Text>
						</View>
					)}

					<View
						style={{
							height: '80%',
							width: 1,
							backgroundColor: '#D9D9D9',
							alignSelf: 'center',
						}}
					/>
					<View style={{ alignSelf: 'center' }}>
						<Text style={styles.numbertext}>
							{profiledata.salaryexpectation}
						</Text>
						<Text style={{ fontFamily: 'Lexend' }}>
							Expected Salary
						</Text>
					</View>
				</HStack>
				<View style={styles.currentofferbox}>
					<Text style={styles.texthead}>Notice Period</Text>
					<Text style={styles.textsubhead}>
						{profiledata.noticeperiod}
					</Text>
					{profiledata.currentoffer && (
						<>
							<Text style={styles.texthead}>Current Offer</Text>
							<Text style={styles.textsubhead}>
								{profiledata.currentoffer}
							</Text>
						</>
					)}
				</View>
			</View>
			{profiledata.profileFeedback.items[0] && (
				<View
					style={{
						width: windowWidth < 480 ? '100%' : '75%',
						alignSelf: 'center',
					}}
				>
					<Text
						style={{
							fontFamily: 'Lexend',
							fontSize: 16,
							fontWeight: '600',
							marginLeft: windowWidth < 480 ? '5%' : '0%',
							marginTop: 10,
						}}
					>
						Profile Feedback
					</Text>
					<FlatList
						style={{ alignSelf: 'center' }}
						contentContainerStyle={{
							justifyContent: 'space-between',
							alignSelf: 'center',
						}}
						data={profiledata.profileFeedback.items}
						keyExtractor={(item) => item.id}
						renderItem={(item) => (
							<ProfileFeedback props={item.item} />
						)}
					/>
				</View>
			)}
		</View>
	)
}

const styles = StyleSheet.create({
	salarybox: {
		height: 60,
		width: '100%',
		alignSelf: 'center',
		// borderWidth: 1,
		backgroundColor: '#fff',
		justifyContent: 'space-around',
		marginTop: 30,
		borderRadius: 10,
	},
	numbertext: {
		fontWeight: 'bold',
		fontSize: 16,
		textAlign: 'center',
		fontFamily: 'Open Sans',
	},
	currentofferbox: {
		width: '100%',
		alignSelf: 'center',
	},
	texthead: {
		fontWeight: 'bold',
		marginTop: 10,
		fontFamily: 'Lexend',
	},
	container: {
		marginTop: 50,
		backgroundColor: '#F5F8FC',
		width: windowWidth <= 480 ? windowWidth * 0.95 : windowWidth * 0.31,

		alignSelf: 'center',
		borderRadius: 15,
		padding: 20,
	},
	textsubhead: {
		color: '#5B5B5B',
		paddingVertical: 5,
		fontFamily: 'Open Sans',
	},
	breadcrumps: {
		fontSize: 16,
		fontFamily: 'Lexend',
		fontWeight: '900',
	},
})
