import React, { useState } from 'react'
import {
	Modal,
	ModalBackdrop,
	ModalBody,
	ModalContent,
	ModalFooter,
	Text,
	Pressable,
	HStack,
	Icon,
	Image,
	AddIcon,
	CheckIcon,
} from '@gluestack-ui/themed'
import { Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const SkillSelectionModal = ({
	isOpen,
	onClose,
	capabilityText,
	setSkills,
	addCapability,
}) => {
	const [selectedSkills, setSelectedSkills] = useState([])
	const skillsData = [
		'AWS Cognito',
		'DynamoDB',
		'Azure',
		'ReactJS',
		'React Native',
	]

	const toggleSkillSelection = (skill) => {
		setSelectedSkills((prevSkills) =>
			prevSkills.includes(skill)
				? prevSkills.filter((selected) => selected !== skill)
				: [...prevSkills, skill]
		)
	}

	const addSkillsToCapability = () => {
		setSkills(selectedSkills)
		addCapability()
		onClose()
	}

	const renderSkillItem = (skill, index) => {
		const isSelected = selectedSkills.includes(skill)

		return (
			<Pressable
				key={index}
				onPress={() => {
					toggleSkillSelection(skill)
				}}
				borderWidth={1}
				borderRadius={10}
				borderColor={isSelected ? '$primary500' : '$fontGray'}
				flexDirection="row"
				gap={5}
				alignItems="center"
				p={10}
				m={5}
			>
				<Icon
					as={isSelected ? CheckIcon : AddIcon}
					size={windowWidth > 480 ? 'xl' : 'lg'}
					color={isSelected ? '$primary500' : '$fontGray'}
				/>
				<Text
					fontFamily="$fontHeading"
					fontSize={'$fontSize15'}
					fontWeight="600"
					color={isSelected ? '$primary500' : '$fontGray'}
				>
					{skill}
				</Text>
			</Pressable>
		)
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			position="fixed"
			minHeight={windowHeight}
		>
			<ModalBackdrop />
			<ModalContent w={320} padding={0}>
				<ModalBody p={0}>
					<HStack
						paddingHorizontal={30}
						paddingVertical={20}
						alignItems="center"
						gap={10}
						bg="$faintPink"
					>
						<Image
							source={require('../../assets/Images/experienceSymbol.png')}
							w={25}
							h={25}
						/>
						<Text
							marginVertical={5}
							fontSize={17}
							fontFamily="$fontHeading"
							fontWeight="600"
							color={'$primary950'}
							lineHeight={22}
						>
							{capabilityText}
						</Text>
					</HStack>
					<HStack flexWrap="wrap" p={10}>
						{skillsData.map((skill, index) =>
							renderSkillItem(skill, index)
						)}
					</HStack>
				</ModalBody>
				<ModalFooter justifyContent="center">
					<Pressable onPress={addSkillsToCapability}>
						<Text
							paddingHorizontal={60}
							paddingVertical={15}
							fontSize={15}
							fontFamily="$fontHeading"
							fontWeight="600"
							borderRadius={20}
							color="#FF8FA2"
							bgColor="#FFE9E9"
						>
							Add
						</Text>
					</Pressable>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default SkillSelectionModal
