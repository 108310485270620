import {
	Box,
	Divider,
	FlatList,
	HStack,
	Text,
	VStack,
} from '@gluestack-ui/themed'
import { Dimensions, Pressable } from 'react-native'
import React from 'react'
import { useEffect, useState, useCallback } from 'react'
import { MobileHeader } from '../../newComponents/header/Header'
import { API } from 'aws-amplify'
import Preferencescontainer from './PreferencesComponent'
import { getcompanyusersbycognitoId, listPreferences, listsPreferencesByBusinessProfileIdIdByDate } from '../../graphql/custom-queries'

import useUserStore from '../../Userstore/UserStore'
import SpinnerComponent from '../../newComponents/SpinnerComponent'
import ButtonComponent from '../../newComponents/ButtonComponent'
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

export default function PreferencesScreen({ route, navigation }) {
	const id = route?.params?.id
	const userId = useUserStore((state) => state.userId)
	const [preferencelist, setPreferenceList] = useState('')
	const [loading, setLoading] = useState(true)
	const [companydata, setCompanyData] = useState('')
	const typeofuser = useUserStore((state) => state.group)
	
	const setCompanyProfileId = useUserStore(
		(state) => state.setcompanyProfileId
	)
	const setcompanyuserProfileId = useUserStore(
		(state) => state.setcompanyuserProfileId
	)
	const setcompanyName = useUserStore(
		(state) => state.setcompanyName
	)
	const setcompanyUsername =  useUserStore(
		(state) => state.setcompanyUsername )
	const getProfiles = useCallback(async () => {
		try {
			const res = await API.graphql({
				query: getcompanyusersbycognitoId,
				variables: {
					cognitoId: userId,
				},
			})
			
			setCompanyData(
				res.data.getcompanyusersbycognitoId.items[0].companyprofile
			)
			if(typeofuser != "QrusibleSuperAdmin") {
				setPreferenceList(
					res.data.getcompanyusersbycognitoId.items[0].companyprofile
						.preferenceslist.items
				)
			}

			
			setCompanyProfileId(
				res.data.getcompanyusersbycognitoId.items[0].companyprofile.id
			)
			setcompanyName(
				res.data.getcompanyusersbycognitoId.items[0].companyprofile.name
			)
			setcompanyuserProfileId(
				res.data.getcompanyusersbycognitoId.items[0].id
			)
			setcompanyUsername(res.data.getcompanyusersbycognitoId.items[0].username)
			setLoading(false)
		} catch (error) {
			setLoading(false)
			console.log(error)
		}
	}, [])
	const ListPreferences = async () => {
		

		try {
			const res = await API.graphql({
				query: listsPreferencesByBusinessProfileIdIdByDate,
				variables: {
					businessProfileId: id,
					sortDirection: 'DESC', // or 'DESC' based on your requirement
				},
			})
			
			
		
			if(typeofuser == "QrusibleSuperAdmin") {
				setPreferenceList(
					res.data.listsPreferencesByBusinessProfileIdIdByDate.items
				)
			}

			
			
			setLoading(false)
		} catch (error) {
			setLoading(false)
			console.log(error)
		}
	}
	
	
	useEffect(() => {
		getProfiles()
		if(typeofuser == "QrusibleSuperAdmin" && id){
			ListPreferences()
		}
	}, [])

	const renderItem = (item) => {
		const currentDate = new Date()
		const createdAtDate = new Date(item?.item?.createdAt)

		const timeDifference = currentDate - createdAtDate

		const daysPassed = Math.floor(timeDifference / (1000 * 3600 * 24))

		return (
			<Box>
			{item.item.JobPublished == false  ?
			 ( typeofuser == 'QrusibleSuperAdmin' ? (
				<Preferencescontainer
				companyname={companydata?.name}
				props={item?.item}
				daysPassed={daysPassed}
				companyProfileId={id}
			/>
			) : (null)) :(
				<Preferencescontainer
				companyname={companydata?.name}
				props={item?.item}
				daysPassed={daysPassed}
				companyProfileId={id}
			/>
			)
			}
				
			</Box>
		)
	}

	return (
		<Box>
			<Box zIndex={1}>
				<MobileHeader props={'Active Roles'} navigation={navigation} />
			</Box>
			
			<Box
				bg="$backgroundGray"
				minHeight={windowHeight - 62}
				marginTop={62}
				// marginBottom={windowWidth > 480 ? null : 50}
				paddingHorizontal={windowWidth > 480 ? 100 : 5}
				paddingVertical={windowWidth > 480 ? 20 : 5}
				paddingBottom={windowWidth > 480 ? null : 50}
			>
			{typeofuser == 'QrusibleSuperAdmin' && 
			<ButtonComponent
			loading={loading}
			disabled={
				false
			}
			onPress={ () => navigation.push('CreateJobPost', {companyProfileId: id})}
			text={
			
					'Create Job Post'
					
			}
			marginVertical={windowWidth > 650 ? 25 : 5}
			width={
				210
					
			}/>
			}
				
			
				<Box
					bg="$primary0"
					marginBottom={12}
					p={windowWidth > 480 ? 20 : 10}
					borderWidth={1}
					borderColor="$borderDark0"
					borderRadius={6}
				>
					<Text
						color="$fontGray100"
						fontWeight="600"
						fontFamily="$fontHeading"
						fontSize={
							windowWidth > 480
								? '$fontContent'
								: '$fontContentMobile'
						}
					>
						Active Opportunities
					</Text>
					<Divider
						marginVertical={windowWidth > 480 ? 20 : 10}
						bg="$borderDark0"
					/>
					
					<HStack>
						{loading ? (
							<Box
								alignItems="center"
								justifyContent="center"
								w={'$full'}
								marginVertical={windowWidth > 480 ? 20 : 10}
							>
								<SpinnerComponent smallText={'Loading...'} />
							</Box>
						) : (
							<FlatList
								data={preferencelist}
								renderItem={renderItem}
								keyExtractor={(item) => item.id}
							/>
						)}
					</HStack>
				</Box>
			</Box>
		</Box>
	)
}
