import { createSkill, updateSkill } from '../../../graphql/custom-mutations'
import { listSkillsByUserIdByDate } from '../../../graphql/custom-queries'
import { API } from 'aws-amplify'
import { listCandidateExperienceByUserIdByDate } from '../../../graphql/queries'
import { createCandidateExperience } from '../../../graphql/mutations'


const listSkills = async (userId) => {
	try {
		const response = await API.graphql({
			query: listSkillsByUserIdByDate,
			authMode: 'API_KEY',
			variables: {
				UserId: userId,
			},
		})

		return response.data.listSkillsByUserIdByDate.items
	} catch (error) {
		console.log('Error fetching skills', error)
	}
}

const createSkills = async (input) => {
	try {
		const response = await API.graphql({
			query: createSkill,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
	} catch (error) {
		console.log('Error creating skill', error)
	}
}

const updateSkills = async (input) => {
	try {
		const response = await API.graphql({
			query: updateSkill,
			authMode: 'API_KEY',
			variables: {
				input: input,
			},
		})
	} catch (error) {
		console.log('Error creating skill', error)
	}
}

const listWorkExperiences = async (userId) => {
	try {
		const response = await API.graphql({
			query: listCandidateExperienceByUserIdByDate,
			authMode: 'API_KEY',
			variables: { UserId: userId },
		})
		return response.data.listCandidateExperienceByUserIdByDate.items
	} catch (error) {
		console.log('Error fetching work experience', error)
	}
}

const createWorkExperience = async (input) => {
	try {
		const response = await API.graphql({
			query: createCandidateExperience,
			authMode: 'AMAZON_COGNITO_USER_POOLS',
			variables: {
				input: input,
			},
		})
	} catch (error) {
		console.log('Error creating experience', error)
	}
}

export {
	listSkills,
	createSkills,
	listWorkExperiences,
	createWorkExperience,
	updateSkills,
}
