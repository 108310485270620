import React from 'react'
import { Dimensions } from 'react-native'
import {
	Box,
	Modal,
	ModalBackdrop,
	ModalContent,
	ModalHeader,
	Heading,
	ModalCloseButton,
	CloseIcon,
	Icon,
	ModalBody,
	HStack,
	Image,
	Text,
	Divider,
	ModalFooter,
	VStack,
} from '@gluestack-ui/themed'
import CandidatePreferences from '../../../newComponents/CandidatePreferences'

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

function PreferencesModal({ isOpen, onClose, navigation }) {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			minHeight={windowHeight}
			position="fixed"
		>
			<ModalBackdrop />
			<ModalContent
				maxHeight={windowHeight * 0.9}
				minWidth={
					windowWidth > 480 ? windowWidth * 0.7 : windowWidth * 0.9
				}
				minHeight={windowHeight * 0.3}
				overflow={false}
				bg="white"
			>
				<ModalHeader>
					<VStack>
						<Heading
							fontFamily="$fontHeading"
							fontSize={windowWidth > 480 ? '$fontSize12' : 10}
							color={'$primary950'}
							lineHeight={15}
						>
							Select Your Preferences
						</Heading>
						<Text
							color={'$secondary500'}
							fontFamily={'$fontContent'}
							fontSize={windowWidth > 480 ? 9 : 9}
							fontWeight="bold"
							lineHeight={15}
						>
							*Please fill all the details
						</Text>
					</VStack>
					<ModalCloseButton>
						<Icon
							as={CloseIcon}
							size={windowWidth > 480 ? 'md' : 'xs'}
						/>
					</ModalCloseButton>
				</ModalHeader>
				<ModalBody showsHorizontalScrollIndicator={false} bg="white">
					<Divider h={0.5} />
					<Box>
						{/* <CandidatePreferences
							isModal={true}
							closeModal={onClose}
							navigation={navigation}
						/> */}
					</Box>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default PreferencesModal
