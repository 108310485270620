import { Box } from '@gluestack-ui/themed'
import React from 'react'
import ShareSkeleton from './ShareSkeleton'
import { points } from '../../../newComponents/pointsComponents/pointsconfig'

const ShareProblem = ({
	setTab,
	problem,
	setProblem,
	totalCapabilityPoints,
	capEditView,
	capabilityName,
	textAreaHeight,
}) => {
	const wordCount = problem
		?.split(/\s+/)
		?.filter((word) => word !== '').length

	return (
		<Box>
			<ShareSkeleton
				titleNormal1={'Describe a '}
				titleColored1={'problem '}
				titleNormal2={'you have '}
				titleColored2={'solved '}
				titleNormal3={'that demonstrates the capability:'}
				titleNormal4={capabilityName}
				subtitle={
					'Problem from product/process/task related to your work'
				}
				screen={'Problem'}
				valueInput={problem}
				setInputValueChange={setProblem}
				wordCount={wordCount}
				maxWordCount={25}
				textArea={true}
				infoStripText={
					'Employers highly value candidates who share real-world problem-solving experiences, providing concrete examples that demonstrate practical skills, critical thinking, and a results-oriented approach while offering insights into behavior and cultural alignment'
				}
				buttonText={'Next'}
				buttonOnPress={() => {
					setTab('Experience')
				}}
				hideBackButton={true}
				disabled={problem === ''}
				totalCapabilityPoints={totalCapabilityPoints}
				pointsAssigned={points.problem}
				capEditView={capEditView}
				textAreaHeight={textAreaHeight}
				disableCopyPaste={true}
				disableRightClick={true}
				// disableRightClick

			/>
		</Box>
	)
}

export default ShareProblem
