import React from 'react'
import { Box, HStack, Text } from '@gluestack-ui/themed'
import ButtonComponent from '../../../newComponents/ButtonComponent'
import { Dimensions } from 'react-native'
const windowWidth = Dimensions.get('window').width

const ImproveApplication = ({ onPressBtn, applicationProgress }) => {
	return (
		<HStack
			justifyContent="space-between"
			px={20}
			py={10}
			alignItems="center"
			borderWidth={1}
			borderColor="$borderDark50"
			borderRadius={10}
		>
			<Box flex={1}>
				<Text
					fontFamily="$fontHeading"
					fontSize={14}
					fontWeight="600"
					color="$primary950"
				>
					{applicationProgress >= 100
						? 'Great! You have completed your application'
						: 'Finish your application'}
				</Text>
				<Text color="#4A789C" fontFamily="$fontHeading" fontSize={13}>
					{applicationProgress >= 100
						? 'Make sure to share genuine and descriptive experiences!'
						: 'Share more experiences to get in the top list of employers'}
				</Text>
			</Box>
			<ButtonComponent
				text={'Improve Application'}
				customFontSize={windowWidth > 480 ? 12 : 10}
				height={30}
				onPress={() => {
					onPressBtn()
				}}
			/>
		</HStack>
	)
}

export default ImproveApplication
