import {
	ArrowRightIcon,
	Box,
	HStack,
	Icon,
	Text,
	Pressable,
} from '@gluestack-ui/themed'
import React from 'react'
import { Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const CountBox = ({ heading, count, text, bg, countBig, onPress }) => {
	return (
		<Pressable
			onPress={onPress}
			flexGrow={1}
			flexBasis={165}
			flexShrink={1}
			bg={bg}
			borderRadius={6}
			borderWidth={2}
			borderColor="$borderGrey100"
			p={windowWidth > 480 ? 15 : 10}
			justifyContent="space-around"
		>
			<Box gap={windowWidth > 480 ? 10 : 5}>
				<Text
					fontSize={windowWidth > 480 ? 13 : 9}
					fontWeight="900"
					color="$fontGray"
					fontFamily="$fontContent"
				>
					{heading}
				</Text>
				<Text
					fontSize={windowWidth > 480 ? 35 : 25}
					fontFamily="$fontContent"
					color="$primary500"
					fontWeight="700"
				>
					{countBig}
				</Text>
				<HStack
					gap={5}
					alignItems="center"
					justifyContent="space-between"
				>
					<Text
						fontFamily="$fontContent"
						fontSize={
							windowWidth > 480
								? '$fontContent'
								: '$fontContentMobile'
						}
						fontWeight="900"
						color="$primary500"
					>
						{count}{' '}
						<Text
							fontFamily="$fontContent"
							fontSize={
								windowWidth > 480
									? '$fontContent'
									: '$fontContentMobile'
							}
							fontWeight="400"
						>
							{text}
						</Text>
					</Text>
					<Icon as={ArrowRightIcon} />
				</HStack>
			</Box>
		</Pressable>
	)
}

export default CountBox
