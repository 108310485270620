import {
	Modal,
	ModalBackdrop,
	ModalBody,
	ModalContent,
	ModalCloseButton,
	ModalFooter,
	ModalHeader,
	Heading,
	Text,
	Box,
	Pressable,
	VStack,
	HStack,
	Icon,
	CloseIcon,
	Image,
} from '@gluestack-ui/themed'

import React from 'react'
import { Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const PopUpOnFeed = ({
	isOpen,
	onClose,
	onPress,
	capabilitiesClaimed,
	potentialOpportunities,
}) => {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			position="fixed"
			minHeight={windowHeight}
		>
			<ModalBackdrop />
			<ModalContent
				minWidth={
					windowWidth > 480 ? windowWidth * 0.7 : windowWidth * 0.95
				}
				maxHeight={windowHeight * 0.9}
				overflow={false}
			>
				<ModalHeader justifyContent="flex-end">
					<ModalCloseButton>
						<Icon as={CloseIcon} />
					</ModalCloseButton>
				</ModalHeader>
				<ModalBody
					alignItems="center"
					justifyContent="center"
					paddingVertical={windowWidth > 480 ? 80 : 40}
				>
					<VStack>
						<HStack>
							<Text
								textAlign="center"
								fontFamily="$fontHeading"
								fontSize={windowWidth > 480 ? 40 : 18}
								lineHeight={windowWidth > 480 ? 50 : 25}
								fontWeight="bold"
							>
								Since, you have claimed{' '}
								<Image
									source={require('../assets/Images/addSymbol.png')}
									h={windowWidth > 480 ? 30 : 20}
									w={windowWidth > 480 ? 30 : 20}
								/>{' '}
								<Text
									color={'$primary500'}
									fontFamily="$fontHeading"
									fontSize={windowWidth > 480 ? 40 : 18}
									lineHeight={windowWidth > 480 ? 50 : 25}
									fontWeight="bold"
								>
									{capabilitiesClaimed}
								</Text>{' '}
								capabilities,
							</Text>
						</HStack>
						<HStack>
							<Text
								textAlign="center"
								fontFamily="$fontHeading"
								fontSize={windowWidth > 480 ? 40 : 18}
								lineHeight={windowWidth > 480 ? 50 : 25}
								fontWeight="bold"
							>
								you've matched with{' '}
								<Image
									source={require('../assets/Images/jobMatches.png')}
									h={windowWidth > 480 ? 30 : 20}
									w={windowWidth > 480 ? 30 : 20}
								/>{' '}
								<Text
									color={'$primary500'}
									fontFamily="$fontHeading"
									fontSize={windowWidth > 480 ? 40 : 18}
									lineHeight={windowWidth > 480 ? 50 : 25}
									fontWeight="bold"
								>
									{potentialOpportunities}
								</Text>{' '}
								opportunities.
							</Text>
						</HStack>
						<Box marginVertical={windowWidth > 480 ? 10 : 5}>
							<Text
								textAlign="center"
								fontWeight="600"
								fontFamily="$fontHeading"
								fontSize={windowWidth > 480 ? 20 : 10}
								color={'$fontGray100'}
							>
								300+{' '}
								<Text
									color={'$primary500'}
									textAlign="center"
									fontWeight="600"
									fontFamily="$fontHeading"
									fontSize={windowWidth > 480 ? 20 : 10}
								>
									Full Stack, Cybersecurity, HR, Content Writing
								</Text>{' '}
								Opportunities on Qrusible Network.
							</Text>
						</Box>
						<Box
							alignItems="center"
							justifyContent="center"
							marginTop={70}
							// marginBottom={50}
						>
							<Pressable onPress={onPress}>
								<Text
									bg="$primary500"
									color="$primary0"
									paddingVertical={windowWidth > 480 ? 20 : 5}
									borderRadius={6}
									paddingHorizontal={
										windowWidth > 480 ? 40 : 10
									}
									fontWeight="600"
									fontFamily="$fontHeading"
									fontSize={
										windowWidth > 480
											? '$fontSize18'
											: '$fontContentMobile'
									}
								>
									Check eligibility for{' '}
									{potentialOpportunities} jobs
								</Text>
							</Pressable>
						</Box>
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default PopUpOnFeed
