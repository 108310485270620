const LevelConfig = {
	Level1: {
		FieldName: 'Start',
		CapabilityClaimed: 5, // 100%
		UnlockedCapability: 0,
		PeerVouch: 0,
	},
	Level2: {
		FieldName: 'Level 2',
		CapabilityClaimed: 5,
		minUnlockedCapability: 1,
		maxUnLockedCapability: 2, // 100%
		PeerVouch: 0,
	},
	Level3: {
		FieldName: 'Level 3',
		CapabilityClaimed: 5,
		minUnlockedCapability: 3,
		maxUnLockedCapability: 3, // 100%
		PeerVouch: 0,
	},
	Level4: {
		FieldName: 'End',
		CapabilityClaimed: 5,
		minUnlockedCapability: 4,
		maxUnLockedCapability: 5, // 100%
		PeerVouch: 0,
	},
}

export default LevelConfig
